import { useSelector } from "react-redux";
import { UserData, UserRole } from "../../actions/authentificationActions";
import { Breadcrumbs } from "../../components/Breadcrumbs/Breadcrumbs";
import { FormsOverviewTable } from "../../components/FormsOverviewTable/FormsOverviewTable";
import { NclpForms } from "../../components/NclpForms/NclpForms";
import { AppState } from "../../store/configureStore";

export const FormsResults = () => {
    const user: UserData = useSelector((state: AppState) => state.user);

    return (
        <div>
            <div className="container-header">
                {user.role === UserRole.Admin ? <h1>Results</h1> : <h1>Forms</h1>}
            </div>
            <Breadcrumbs />
            <FormsOverviewTable overview={false} />
            {user.role === UserRole.NCLP && <NclpForms />}
        </div>
    );
};
