import { Button, Dimmer, Loader } from "semantic-ui-react";

import "./ImpLoading.css";

export interface ILoadingProps {
    message?: string;
    show: boolean;
    cancelFunction?: () => void;
}

export const ImpLoading = ({ message, show, cancelFunction }: ILoadingProps) => {
    return (
        <div>
            <Dimmer className="loading-container" active={show} page>
                <Loader />
                <div className="loading-content">
                    <p>{message || "Loading"}</p>
                    {cancelFunction && <Button content="Cancel" onClick={cancelFunction} />}
                </div>
            </Dimmer>
        </div>
    );
};
