import { Dispatch, SetStateAction } from "react";
import { Tab } from "semantic-ui-react";
import { ThirdLevelTabs } from "./ThirdLevelTabs";
import "../Hierarchy/Hierarchy.css";

interface IProps {
    isPgm: boolean;
    paneNames: string[];
    years?: number[];
    setYears: Dispatch<SetStateAction<number[]>>;
    allowMultipleYears: Dispatch<SetStateAction<boolean>>;
}

const thirdLevelTabsPGM = ["SPGMs", "PPMs (onshore)", "PPMs (offshore)", "PPMs total", "Total"];

const getPaneArray = ({ paneNames, isPgm, years, setYears, allowMultipleYears }: IProps) => {
    return paneNames.map(name => {
        return {
            menuItem: name,
            render: () => (
                <ThirdLevelTabs
                    chartType={name}
                    paneNames={thirdLevelTabsPGM}
                    isPgm={isPgm}
                    years={years}
                    setYears={setYears}
                    allowMultipleYears={allowMultipleYears}
                />
            ),
        };
    });
};

export const SecondLevelTabs = ({
    paneNames,
    isPgm,
    years,
    setYears,
    allowMultipleYears,
}: IProps) => {
    return (
        <Tab.Pane>
            <div className="second-level-tabs">
                <Tab
                    menu={{ fluid: false, vertical: false, tabular: true }}
                    panes={getPaneArray({ paneNames, isPgm, years, setYears, allowMultipleYears })}
                ></Tab>
            </div>
        </Tab.Pane>
    );
};
