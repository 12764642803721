import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Dropdown, Icon, Menu } from "semantic-ui-react";
import { logout } from "../../actions/authentificationActions";
import { AppState } from "../../store/configureStore";
import Logo from "../../images/entsoe_logo.svg";
import { NotificationsCount } from "../../components/NotificationsCount/NotificationsCount";
import "./Header.css";

export const ViewerHeader = () => {
    const dispatch = useDispatch();
    const user = useSelector((state: AppState) => state.user);

    return (
        <Menu secondary>
            <Menu.Item as={Link} to="/" header>
                <div id="logo-container">
                    <img src={Logo} alt="Logo" />
                    <p id="imp-logo-text">Implementation Monitoring Platform</p>
                </div>
            </Menu.Item>
            <Dropdown item text="Reports" icon="chevron down">
                <Dropdown.Menu>
                    <Dropdown item text="Data View" icon="chevron right">
                        <Dropdown.Menu>
                            <Menu.Item
                                as={Link}
                                to="/reports/data-view/generation-capacity"
                                content="Generation and HVDC capacities"
                            />
                            <Menu.Item
                                as={Link}
                                to="/reports/data-view/frt"
                                content="FRT non-exhaustive parameters"
                            />
                            <Menu.Item
                                as={Link}
                                to="/reports/data-view/frt-additional"
                                content="Other non-exhaustive parameters"
                            />
                        </Dropdown.Menu>
                    </Dropdown>
                    <Menu.Item
                        as={Link}
                        to="/reports/non-exhaustive-parameters"
                        content="Non-exhaustive parameters"
                    />
                    <Menu.Item as={Link} to="/reports/download" content="Download" />
                </Dropdown.Menu>
            </Dropdown>

            <Menu.Item position="right" as={Link} to="/">
                <NotificationsCount />
            </Menu.Item>
            <Menu.Item as={Link} to="/">
                <span>{user.displayName}</span>
                <Icon name="user circle" size="large" />
            </Menu.Item>
            <Menu.Item onClick={() => dispatch(logout())}>
                <span>Logout</span>
                <Icon name="sign-out" size="large" />
            </Menu.Item>
        </Menu>
    );
};
