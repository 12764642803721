import React, { Dispatch, SetStateAction, useCallback, useEffect, useState } from "react";
import { Button, DropdownItemProps, Form, Input, Modal, Select } from "semantic-ui-react";
import { figureDtoInit, IFigureDto } from "../../models/figure";
import staticFilterOptions from "../../components/FormsOverviewTable/staticFilterOptions.json";
import { IForm } from "../../models/form";
import { editUploadedFigure } from "../../services/figuresService";
import { toast } from "../..";

interface IProps {
    uploadedEditModalOpen: boolean;
    figureToEdit: IFigureDto;
    setFigureToEdit: Dispatch<SetStateAction<IFigureDto>>;
    allFormsWithAttributes: IForm[];
    handleClearFilter: () => void;
    formCategories: DropdownItemProps[];
}

export const UploadEditModal = ({
    uploadedEditModalOpen,
    figureToEdit,
    setFigureToEdit,
    allFormsWithAttributes,
    handleClearFilter,
    formCategories,
}: IProps) => {
    const [loading, setLoading] = useState(false);
    const [formDropdownProps, setFormDropdownProps] = useState<DropdownItemProps[]>([]);

    useEffect(() => {
        const formsArray: DropdownItemProps[] = [];
        allFormsWithAttributes.forEach(form => {
            if (!formsArray.some(e => e.value === form.name)) {
                formsArray.push({ key: form.id, text: form.name, value: form.name });
            }
        });
        setFormDropdownProps(formsArray);
    }, [allFormsWithAttributes]);

    const fetchFormsAsDropdownProps = useCallback(async () => {
        if (
            figureToEdit.category &&
            figureToEdit.category !== "00000000-0000-0000-0000-000000000000"
        ) {
            const filteredForms = allFormsWithAttributes.filter(
                form => form.category === figureToEdit.category
            );
            if (Array.isArray(filteredForms)) {
                const props: DropdownItemProps[] = [];
                filteredForms.forEach(f => {
                    if (!props.some(e => e.value === f.name)) {
                        props.push({ key: f.id, text: f.name, value: f.name });
                    }
                });
                setFormDropdownProps(props);
            }
        } else if (figureToEdit.formName) {
            const selectedForm = allFormsWithAttributes.find(
                form => form.name === figureToEdit.formName
            );
            setFigureToEdit({ ...figureToEdit, category: selectedForm!.category });
        }
    }, [allFormsWithAttributes, figureToEdit, setFigureToEdit]);

    useEffect(() => {
        fetchFormsAsDropdownProps();
    }, [fetchFormsAsDropdownProps]);

    const handleEditUploadedFigure = async () => {
        setLoading(true);
        try {
            await editUploadedFigure(figureToEdit);
            handleClearFilter();
            toast("Successfully edited figure", true, 1000);
        } catch {
            toast("Error editing figure", false, 1000);
        } finally {
            setFigureToEdit(figureDtoInit);
            setLoading(false);
        }
    };

    return (
        <Modal open={uploadedEditModalOpen}>
            <Modal.Header>Edit {figureToEdit.name} </Modal.Header>
            <Modal.Content>
                <Form>
                    <Form.Field>
                        <label>Name</label>
                        <Input
                            value={figureToEdit.name}
                            onChange={(e, data) =>
                                setFigureToEdit({ ...figureToEdit, name: data.value })
                            }
                        />
                    </Form.Field>
                    <Form.Field>
                        <label>Year</label>
                        <Select
                            fluid
                            value={figureToEdit.year.toString()}
                            options={staticFilterOptions.year}
                            onChange={(e, data) =>
                                setFigureToEdit({ ...figureToEdit, year: data.value as string })
                            }
                        />
                    </Form.Field>
                    <Form.Field>
                        <label>Category</label>
                        <Select
                            fluid
                            value={figureToEdit.category}
                            options={formCategories}
                            onChange={(e, data) =>
                                setFigureToEdit({
                                    ...figureToEdit,
                                    category: data.value as string,
                                })
                            }
                        />
                    </Form.Field>
                    <Form.Field>
                        <label>Form</label>
                        <Select
                            fluid
                            value={figureToEdit.formName}
                            options={formDropdownProps}
                            onChange={(e, data) =>
                                setFigureToEdit({
                                    ...figureToEdit,
                                    formName: data.value as string,
                                })
                            }
                        />
                    </Form.Field>
                </Form>
            </Modal.Content>
            <Modal.Actions>
                <Button onClick={() => setFigureToEdit(figureDtoInit)} content="Cancel" />
                <Button
                    primary
                    loading={loading}
                    disabled={loading || figureToEdit.name === ""}
                    content="Edit"
                    onClick={handleEditUploadedFigure}
                />
            </Modal.Actions>
        </Modal>
    );
};
