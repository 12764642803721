import { useCallback, useEffect, useState } from "react";
import Carousel from "react-multi-carousel";
import { IForm } from "../../models/form";
import { getInProgressForms } from "../../services/formsService";
import { AdminFormCard } from "../../containers/FormCard/AdminFormCard";
import { Loader } from "semantic-ui-react";
import "./AdminForms.css";

interface IProps {
    withDelete?: boolean;
}

// Prop for Carousel
const responsive = {
    desktop: {
        breakpoint: { max: 4000, min: 200 },
        items: 4,
    },
};

export const AdminForms = (props: IProps) => {
    const [inProgressForms, setInProgressForms] = useState<IForm[]>([]);
    const [loadingForms, setLoadingForms] = useState(true);

    const fetchInProgressForms = useCallback(async () => {
        setLoadingForms(true);
        const inProgressForms = await getInProgressForms();
        Array.isArray(inProgressForms) && setInProgressForms(inProgressForms);
        setLoadingForms(false);
    }, []);

    useEffect(() => {
        fetchInProgressForms();
    }, [fetchInProgressForms]);

    return (
        <>
            <Loader active={loadingForms} inline="centered" />
            <Carousel responsive={responsive}>
                {inProgressForms.map(x => (
                    <div className="action-bar-container" key={x.id}>
                        <AdminFormCard
                            form={x}
                            withDelete={props.withDelete}
                            refresh={fetchInProgressForms}
                        />
                    </div>
                ))}
            </Carousel>
            {inProgressForms.length === 0 && !loadingForms && <b>No forms in progress.</b>}
        </>
    );
};
