import { ICountry } from "./country";

interface ICategoryFilter {
    id: string;
    name: string;
}

interface IBlockFilter {
    id: string;
    name: string;
}

export interface IResultsFilter {
    countries: ICountry[];
    categories: ICategoryFilter[];
    blocks: IBlockFilter[];
}

export interface IResultsFilterData {
    country: string;
    category: string;
    block: string;
    name: string;
    submission: string;
    status: string;
    year: string;
    nclpMode?: boolean;
}

export const resultsFilterInit: IResultsFilterData = {
    country: "",
    category: "",
    block: "",
    name: "",
    submission: "",
    status: "",
    year: "",
};
