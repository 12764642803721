import { Dispatch, SetStateAction } from "react";
import { Checkbox, Icon } from "semantic-ui-react";
import { IFigureDto } from "../../models/figure";
import "./Figures.css";

interface IProps {
    figure: IFigureDto;
    setFigureToEdit: Dispatch<SetStateAction<IFigureDto>>;
    setFigureToOpen: Dispatch<SetStateAction<IFigureDto>>;
    setFigureToDelete: Dispatch<SetStateAction<IFigureDto>>;
    handleFigureDownload: (id: string, name: string) => void;
    selectedForDelete: boolean;
    setSelectedForDelete: (id: string, selected: boolean) => void;
}

export const FigureDisplay = ({
    figure,
    setFigureToEdit,
    setFigureToOpen,
    setFigureToDelete,
    handleFigureDownload,
    selectedForDelete,
    setSelectedForDelete,
}: IProps) => {
    return (
        <div className="action-bar-container list-view" key={figure.id}>
            <Checkbox
                className="delete-select-checkbox"
                checked={selectedForDelete}
                onChange={(e, data) => setSelectedForDelete(figure.id, data.checked as boolean)}
            />
            <div className="figure-row-container">
                <div className="figure-row" onClick={() => setFigureToOpen(figure)}>
                    <Icon name="image outline" />
                    <p>{figure.name}</p>
                </div>
            </div>

            <div className="action-bar-container-options">
                <Icon
                    id="button-edit"
                    name="edit"
                    bordered
                    onClick={() => setFigureToEdit(figure)}
                />
                <Icon
                    id="button-download"
                    name="download"
                    bordered
                    onClick={() => handleFigureDownload(figure.id, figure.name)}
                />
                <Icon
                    id="button-delete"
                    name="delete"
                    bordered
                    onClick={() => setFigureToDelete(figure)}
                />
            </div>
        </div>
    );
};
