import { useCallback, useEffect, useState } from "react";
import Carousel from "react-multi-carousel";
import { Loader } from "semantic-ui-react";
import { InProgressReportCard } from "../../containers/ReportCard/InProgressReportCard";
import { IReport } from "../../models/report";
import { getInProgressReports } from "../../services/reportsService";

interface IProps {
    withDelete?: boolean;
}

// Prop for Carousel
const responsive = {
    desktop: {
        breakpoint: { max: 4000, min: 200 },
        items: 4,
    },
};

export const ReportsCarousel = ({ withDelete }: IProps) => {
    const [inProgressReports, setInProgressReports] = useState<IReport[]>([]);
    const [loadingReports, setLoadingReports] = useState(true);

    const fetchInProgressReports = useCallback(async () => {
        setLoadingReports(true);
        const reports = await getInProgressReports();
        Array.isArray(reports) && setInProgressReports(reports);
        setLoadingReports(false);
    }, []);

    //get reports for the year?
    useEffect(() => {
        fetchInProgressReports();
    }, [fetchInProgressReports]);

    return (
        <>
            <Loader active={loadingReports} inline="centered" />
            <Carousel responsive={responsive}>
                {inProgressReports.map(x => (
                    <div className="action-bar-container" key={x.id}>
                        <InProgressReportCard
                            report={x}
                            withDelete={withDelete}
                            refresh={fetchInProgressReports}
                        />
                    </div>
                ))}
            </Carousel>
            {inProgressReports.length === 0 && !loadingReports && <b>No reports in progress.</b>}
        </>
    );
};
