import { IFigureChapter } from "../models/figureChapters";
import { getAuthorizationProperties } from "./authorizationHelper";

export async function getFiguresForChapters(guid: string): Promise<IFigureChapter[]> {
    let res = await fetch(`/api/figureChapters/${guid}`, {
        method: "GET",
        ...getAuthorizationProperties({ "Content-Type": "application/json" }),
    });
    return res.json();
}

export async function addFigureToChapter(
    figureChapter: Partial<IFigureChapter>
): Promise<IFigureChapter> {
    let res = await fetch(`/api/figureChapters`, {
        method: "POST",
        ...getAuthorizationProperties({ "Content-Type": "application/json" }),
        body: JSON.stringify(figureChapter),
    });
    return res.json();
}

export async function removeFigureFromChapter(guid: string) {
    return await fetch(`/api/figureChapters/${guid}`, {
        method: "DELETE",
        ...getAuthorizationProperties({ "Content-Type": "application/json" }),
    });
}

export async function updateFigureChapter(figureChapter: IFigureChapter) {
    return await fetch(`/api/figureChapters/${figureChapter.id}`, {
        method: "PUT",
        ...getAuthorizationProperties({ "Content-Type": "application/json" }),
        body: JSON.stringify(figureChapter),
    });
}
