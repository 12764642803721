interface IData {
    guid: string;
    name: string;
}

export function addReportToSessionStorage(guid: string, name: string): void {
    const reportsString = sessionStorage.getItem("reports");
    if (!reportsString) {
        let reports: IData[] = [];
        reports.push({
            guid: guid,
            name: name,
        });
        const reportsJSON = JSON.stringify(reports);
        sessionStorage.setItem("reports", reportsJSON);
    } else {
        let reports: IData[] = JSON.parse(reportsString);
        let report: IData | undefined = reports.find((r: IData) => r.guid === guid);
        if (report === undefined) {
            reports.push({
                guid: guid,
                name: name,
            });
        } else {
            report.name = name;
        }
        const reportsJSON = JSON.stringify(reports);
        sessionStorage.setItem("reports", reportsJSON);
    }
}

export function addFormToSessionStorage(guid: string, name: string): void {
    const formsString = sessionStorage.getItem("forms");
    if (!formsString) {
        let forms: IData[] = [];
        forms.push({
            guid: guid,
            name: name,
        });
        const formsJSON = JSON.stringify(forms);
        sessionStorage.setItem("forms", formsJSON);
    } else {
        let forms: IData[] = JSON.parse(formsString);
        let form: IData | undefined = forms.find((r: IData) => r.guid === guid);
        if (form === undefined) {
            forms.push({
                guid: guid,
                name: name,
            });
        } else {
            form.name = name;
        }
        const formsJSON = JSON.stringify(forms);
        sessionStorage.setItem("forms", formsJSON);
    }
}

export function getReportNameFromSessionStorage(guid: string): string | undefined {
    const reportsString = sessionStorage.getItem("reports");
    if (!reportsString) return undefined;
    const reports: IData[] = JSON.parse(reportsString);
    return reports.find(r => r.guid === guid)?.name;
}

export function getFormNameFromSessionStorage(guid: string): string | undefined {
    const formsString = sessionStorage.getItem("forms");
    if (!formsString) return undefined;
    const forms: IData[] = JSON.parse(formsString);
    return forms.find(r => r.guid === guid)?.name;
}
