import React, { useEffect, useState } from "react";
import {
    Bar,
    BarChart,
    CartesianGrid,
    Legend,
    ResponsiveContainer,
    Tooltip,
    XAxis,
    YAxis,
} from "recharts";
import { CustomizedAxisTick } from "../../../../components/CustomizedAxisTick/CustomizedAxisTick";
import { getGradientBetween } from "../../../../helpers/gradient";
import { ICountry } from "../../../../models/country";
import { getChartData } from "../../../../services/figuresService";
import { GenerationCapacityData } from "../../Hierarchy/GraphDisplay";

interface IProps {
    type: string;
    isPgm: boolean;
    years?: number[];
    selectedCountries: ICountry[];
    totalYears: number;
    technology?: string;
}

interface MultiYearGenerationCapacityData {
    [year: number]: GenerationCapacityData;
}

function getCategoryDistance(countiresCount: number) {
    if (countiresCount < 2) {
        return "45%";
    } else if (countiresCount < 3) {
        return "40%";
    } else if (countiresCount > 7) {
        return undefined;
    } else {
        return `${100 / countiresCount}%`;
    }
}
export const GenerationCapacityChange = ({
    type,
    years = [],
    selectedCountries,
    isPgm,
    totalYears,
    technology,
}: IProps) => {
    const [pgmData, setPgmData] = useState<MultiYearGenerationCapacityData>({});
    const [hvdcData, setHvdcData] = useState<MultiYearGenerationCapacityData>({});
    const [withoutDerogationsColors, setWithoutDerogationsColors] = useState<string[]>([]);
    const [nonCompliantColors, setNonCompliantColors] = useState<string[]>([]);
    const [chartData, setChartData] = useState<{}[]>([]);
    const [domainUpperLimit, setDomainUpperLimit] = useState<number>(30000);
    const [technologyTabLoaded, setTechnologyTabLoaded] = useState<string>("");

    useEffect(() => {
        setNonCompliantColors(getGradientBetween("#AE1937", "#FFA347", totalYears));
        setWithoutDerogationsColors(getGradientBetween("#19AFDF", "#1167B2", totalYears).reverse());
    }, [totalYears]);

    useEffect(() => {
        const updateData = async () => {
            const data = isPgm ? pgmData : hvdcData;
            let yearsWithData = Object.keys(data).map(s => parseInt(s));
            let yearsToFetch = years.filter(y => !yearsWithData.includes(y));
            if (yearsToFetch.length > 0 || technologyTabLoaded !== technology) {
                let newData = { ...data };
                const chartType = isPgm ? "pgm_percentage" : "hvdc_percentage";
                for (const y of years) {
                    const yearData = await getChartData<GenerationCapacityData>(
                        chartType,
                        y,
                        technology
                    );
                    newData = { ...newData, [y]: yearData };
                }
                setTechnologyTabLoaded(technology ?? "");
                isPgm ? setPgmData(newData) : setHvdcData(newData);
            }
        };

        updateData();
    }, [years, pgmData, hvdcData, isPgm, technology, technologyTabLoaded]);

    useEffect(() => {
        const formatData = (
            data: MultiYearGenerationCapacityData,
            type: string,
            selectedCountries: ICountry[],
            selectedYears: number[]
        ) => {
            let max = 0;

            let res = selectedCountries.map(country => {
                let countryData: { [key: string]: string | number } = {};
                countryData.name = country.name;

                selectedYears.forEach(year => {
                    const yearData = data[year];
                    let withoutDerogations = 0;
                    let nonCompliant = 0;

                    if (yearData) {
                        const data = yearData[type][country.abbreviation];
                        withoutDerogations = data ? data[0] : 0;
                        nonCompliant = data ? data[3] : 0;
                    }

                    countryData[`${year} without derogations`] = withoutDerogations;
                    countryData[`${year} non compliant`] = nonCompliant;
                    max = Math.max(max, withoutDerogations, nonCompliant);
                });
                return countryData;
            });

            setDomainUpperLimit(max * 2);
            return res;
        };

        setChartData(formatData(isPgm ? pgmData : hvdcData, type, selectedCountries, years));
    }, [pgmData, hvdcData, isPgm, type, selectedCountries, years]);

    return (
        <>
            <ResponsiveContainer>
                <BarChart
                    data={chartData}
                    barCategoryGap={getCategoryDistance(selectedCountries.length)}
                >
                    <YAxis
                        width={100}
                        scale="log"
                        allowDataOverflow
                        domain={[1, domainUpperLimit]}
                        tickFormatter={t => `${t} MW`}
                    />
                    <XAxis
                        dataKey="name"
                        height={100}
                        xAxisId={0}
                        interval={0}
                        tick={selectedCountries.length > 0 && <CustomizedAxisTick />}
                    />
                    <XAxis dataKey="name" xAxisId={1} hide={true} />
                    <CartesianGrid strokeDasharray="3 3" />
                    <Tooltip />
                    <Legend />
                    {years.map((year, i) => (
                        <React.Fragment key={year}>
                            <Bar
                                isAnimationActive={false}
                                dataKey={`${year} non compliant`}
                                xAxisId={0}
                                fill={nonCompliantColors[i]}
                                maxBarSize={30}
                            />
                            <Bar
                                isAnimationActive={false}
                                dataKey={`${year} without derogations`}
                                xAxisId={1}
                                fill={withoutDerogationsColors[i]}
                                maxBarSize={30}
                            />
                        </React.Fragment>
                    ))}
                </BarChart>
            </ResponsiveContainer>
        </>
    );
};
