import { Dispatch, SetStateAction, useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Button, Form, Modal, Table } from "semantic-ui-react";
import { toast } from "../..";
import { UserRole } from "../../actions/authentificationActions";
import { QuestionWrapper } from "../../components/QuestionWrapper/QuestionWrapper";
import generateGuid from "../../helpers/guid";
import { IFile } from "../../models/file";
import { IForm } from "../../models/form";
import { IQuestion } from "../../models/question";
import { IResult } from "../../models/result";
import { ISubValue, IValue } from "../../models/value";
import { updateSingleValue } from "../../services/resultsService";
import { AppState } from "../../store/configureStore";
import { validateQuestion, validateTableInput } from "../FormView/validationHelper";
import "./NonExhaustiveTabular.css";

export interface IResultsTableProps {
    form: IForm;
    results: IResult[];
    updateResults: Dispatch<SetStateAction<IResult[]>>;
    selectedQuestions: string[];
}

// Helper interface so that we can set target question for edit and results for
// country in a single setState call
interface IQuestionResults {
    question: IQuestion;
    result: IResult;
}

export const ResultsTable = ({
    form,
    results,
    updateResults,
    selectedQuestions,
}: IResultsTableProps) => {
    const user = useSelector((state: AppState) => state.user);
    const [filteredQuestions, setFilteredQuestions] = useState<IQuestion[]>([]);

    // States needed for question editing.
    const [editQuestionResult, setEditQuestionResult] = useState<IQuestionResults>();
    const [editValue, setEditValue] = useState<IValue>();
    const [editError, setEditError] = useState<boolean>(false);
    const [subValueErrors, setSubValueErrors] = useState<[string, string, string][]>([]);

    useEffect(() => {
        const newFilteredQuestions: IQuestion[] = [
            ...form.sections
                .flatMap(s => s.questions)
                .filter(q => selectedQuestions.includes(q.id)),
        ];

        setFilteredQuestions(newFilteredQuestions);
    }, [form, selectedQuestions]);

    // When edit question changes update the value that is displayed in the modal.
    useEffect(() => {
        if (editQuestionResult === undefined) {
            setEditValue(undefined);
        } else {
            let existingValue: IValue | undefined = editQuestionResult.result.values.find(
                v => v.question === editQuestionResult.question.id
            );

            if (existingValue === undefined) {
                existingValue = {
                    id: generateGuid(),
                    question: editQuestionResult.question.id,
                    files: [] as IFile[],
                    subValues: [] as ISubValue[],
                    result: editQuestionResult.result.id,
                };
            }

            setEditValue(existingValue);
        }
    }, [editQuestionResult, results]);

    useEffect(() => {
        setEditError(false);
        setSubValueErrors([]);
    }, [editValue]);

    const getDisplayAnswer = useCallback(
        (country: string, question: string) => {
            const targetResults: IResult | undefined = results.find(r => r.country === country);
            if (!targetResults) return "";

            const targetQuestion: IQuestion | undefined = filteredQuestions.find(
                q => q.id === question
            );
            // TODO: Think about this, it probably resulted from some bug that happended earlier.
            if (!targetQuestion) return "";

            const targetValue: IValue | undefined = targetResults.values.find(
                v => v.question === question
            );
            if (!targetValue) return "";

            switch (targetQuestion.type) {
                case "MultipleChoice":
                    if (!targetValue.options || targetValue.options.length !== 1) return "";
                    return targetQuestion.options.find(q => q.id === targetValue.options![0])
                        ?.value;
                case "Checkboxes":
                    if (!targetValue.options || targetValue.options.length === 0) return "";
                    return targetQuestion.options
                        .filter(o => targetValue.options?.includes(o.id))
                        .map(o => o.value)
                        .join(", ");
                case "Number":
                    return targetValue.numeric;
                case "Paragraph":
                    return targetValue.textual;
                case "ShortText":
                    return targetValue.textual;
                case "TableInput":
                    return "[Table answer minimized...]";
            }
        },
        [results, filteredQuestions]
    );

    const canEdit = useCallback(
        country => {
            return (
                user.role === UserRole.Admin ||
                (user.role === UserRole.NCLP && country === user.countryGuid)
            );
        },
        [user]
    );

    const handleUpdateValue = useCallback(
        (value: IValue, doSave: boolean) => {
            setEditValue(value);
        },
        [setEditValue]
    );

    const updateValueSuccess = useCallback(
        (updateValue: IValue) => {
            const copyResults = [...results];
            const copyResultIndex = results.findIndex(r => r.id === updateValue.result);

            if (copyResultIndex === -1) return;

            const copyValues = [...copyResults[copyResultIndex].values];
            const copyValueIndex = copyValues.findIndex(v => v.id === updateValue.id);

            if (copyValueIndex === -1) {
                copyValues.push(updateValue);
            } else {
                copyValues.splice(copyValueIndex, 1, updateValue);
            }
            copyResults[copyResultIndex].values = copyValues;

            updateResults(copyResults);
        },
        [updateResults, results]
    );

    const handleSave = async (question: IQuestion, value: IValue) => {
        let newEditErrors: boolean = false;
        let newSubValueErrors: [string, string, string][] = [];

        // Validate and if validation fails display the messages and stop backend call.
        if (question.type === "TableInput") {
            const tableValidationResult = validateTableInput(question, value);
            newSubValueErrors.push(
                ...(tableValidationResult.map(tv => [question.id, tv[0], tv[1]]) as [
                    string,
                    string,
                    string
                ][])
            );
            newEditErrors = newSubValueErrors.length > 0;
        } else {
            newEditErrors = !validateQuestion(question, value);
        }

        if (newEditErrors || newSubValueErrors.length > 0) {
            setEditError(newEditErrors);
            setSubValueErrors(newSubValueErrors);
            return;
        }

        // If validation passes, call new update backend function and display toast...
        try {
            const res = await updateSingleValue(value, value.result);
            toast("Data saved", true, 3000);
            setEditQuestionResult(undefined);
            setEditError(false);
            setSubValueErrors([]);
            updateValueSuccess(res);
        } catch (err) {
            toast("Unable to save value.", false, 3000);
        }
    };

    return (
        <>
            <Table className="tabular-view-table" celled>
                <Table.Header>
                    <Table.Row>
                        {/** Top left cell in table is empty */}
                        <Table.HeaderCell />
                        {filteredQuestions.map(q => {
                            return <Table.HeaderCell>{q.text}</Table.HeaderCell>;
                        })}
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    {results.map(r => {
                        return (
                            <Table.Row>
                                <Table.Cell>{r.countryName}</Table.Cell>
                                {filteredQuestions.map(q => {
                                    return (
                                        <Table.Cell
                                            className={`results-cell ${
                                                canEdit(r.country) ? "clickable" : ""
                                            }`}
                                            onClick={() => {
                                                if (canEdit(r.country)) {
                                                    setEditQuestionResult({
                                                        question: q,
                                                        result: r,
                                                    });
                                                }
                                            }}
                                        >
                                            {getDisplayAnswer(r.country, q.id)}
                                        </Table.Cell>
                                    );
                                })}
                            </Table.Row>
                        );
                    })}
                </Table.Body>
            </Table>
            {editQuestionResult !== undefined && editValue && (
                <Modal open={editQuestionResult !== undefined}>
                    <Modal.Header>Edit answers</Modal.Header>
                    <Modal.Content>
                        <Form>
                            <QuestionWrapper
                                question={editQuestionResult.question}
                                inEditor={false}
                                value={editValue}
                                saveValue={handleUpdateValue}
                                error={editError}
                                subQuestionsErrors={subValueErrors}
                            />
                            {editError && (
                                <div id="validation-failed-message">
                                    <b>* Validation failed</b>
                                </div>
                            )}

                            <label>Add a remark explaining your edit:</label>
                            <Form.TextArea
                                placeholder="Edit remark..."
                                value={editValue.editRemarkText ?? ""}
                                onChange={(e, data) =>
                                    setEditValue({
                                        ...editValue,
                                        editRemarkText: data.value as string,
                                    })
                                }
                            />
                        </Form>
                    </Modal.Content>
                    <Modal.Actions>
                        <Button content="Cancel" onClick={() => setEditQuestionResult(undefined)} />
                        {/** App.css body ui modal color = gray... Probably should exclude icons */}
                        <Button
                            id="edit-save-button"
                            primary
                            content="Save"
                            icon="save"
                            onClick={() => handleSave(editQuestionResult.question, editValue)}
                        />
                    </Modal.Actions>
                </Modal>
            )}
        </>
    );
};
