import { Button, Modal } from "semantic-ui-react";
import { deleteFigure } from "../../services/figuresService";
import { toast } from "../..";
import { IFigureDto } from "../../models/figure";

interface IProps {
    deleteFigureModalOpen: boolean;
    closeDeleteFigureModal: () => void;
    figureToDelete: IFigureDto;
    handleClearFilter: () => void;
    refreshFigures: () => void;
}

export const DeleteFigureModal = ({
    deleteFigureModalOpen,
    closeDeleteFigureModal,
    figureToDelete,
    refreshFigures,
    handleClearFilter,
}: IProps) => {
    const handleDeleteFigure = async () => {
        const response = await deleteFigure(figureToDelete.id);
        if (response) {
            handleClearFilter();
            toast("Figure deleted successfully", true, 2000);
            refreshFigures();
        } else {
            toast("Error deleting figure", false, 2000);
        }
        closeDeleteFigureModal();
    };

    return (
        <Modal open={deleteFigureModalOpen}>
            <Modal.Header>Delete figure</Modal.Header>
            <Modal.Content>
                Are you sure you want to delete figure {figureToDelete.name}?
            </Modal.Content>
            <Modal.Actions>
                <Button onClick={closeDeleteFigureModal} content="Cancel" />
                <Button primary onClick={handleDeleteFigure} content="Delete" />
            </Modal.Actions>
        </Modal>
    );
};
