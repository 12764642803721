import { useState } from "react";
import { Button } from "semantic-ui-react";
import { FormSelectionModal } from "../CreateFormModal/FormSelectionModal";
import { UserData, UserRole } from "../../actions/authentificationActions";
import { AppState } from "../../store/configureStore";
import { useSelector } from "react-redux";
import { FormsOverviewTable } from "../../components/FormsOverviewTable/FormsOverviewTable";
import { AdminForms } from "../../components/AdminForms/AdminForms";
import { NclpForms } from "../../components/NclpForms/NclpForms";

import "./FormsOverview.css";
import { Breadcrumbs } from "../../components/Breadcrumbs/Breadcrumbs";
import { Redirect } from "react-router-dom";

export const FormsOverview = () => {
    const [createFormOpen, setCreateFormOpen] = useState<boolean>(false);

    const user: UserData = useSelector((state: AppState) => state.user);

    return user.role === UserRole.Viewer ? (
        <Redirect to="/unauthorized" />
    ) : (
        <div className="input-data-editor-container">
            <div className="container-header">
                <h1>Overview of published forms</h1>
                {user.role === UserRole.Admin && (
                    <Button
                        onClick={() => setCreateFormOpen(true)}
                        content="Create new form"
                        primary
                    />
                )}
            </div>
            <Breadcrumbs />
            <FormsOverviewTable overview={true} />

            {user.role === UserRole.Admin && (
                <>
                    <div className="section-title">
                        <h1>Forms under development</h1>
                    </div>
                    <AdminForms withDelete />
                </>
            )}
            {user.role === UserRole.NCLP && <NclpForms />}
            {user.role === UserRole.Admin && (
                <FormSelectionModal
                    open={createFormOpen}
                    onClose={() => setCreateFormOpen(false)}
                />
            )}
        </div>
    );
};
