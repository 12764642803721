import { Redirect } from "react-router-dom";
import { useState, useEffect, useCallback, useRef } from "react";
import {
    Button,
    Dimmer,
    Divider,
    Form,
    Icon,
    Loader,
    Menu,
    Message,
    Modal,
    Segment,
    SemanticICONS,
} from "semantic-ui-react";
import { QuestionWrapper } from "../../components/QuestionWrapper/QuestionWrapper";
import { IForm } from "../../models/form";
import { useHistory, useParams } from "react-router";
import { getForm } from "../../services/formsService";
import Logo from "../../images/entsoe_logo_white.svg";
import {
    getResultOfForm,
    getResult,
    submitResultValues,
    updateResultValues,
    resetResult,
    unsubmitResultValues,
} from "../../services/resultsService";
import { IResult } from "../../models/result";
import { useSelector } from "react-redux";
import { AppState } from "../../store/configureStore";
import { UserData, UserRole } from "../../actions/authentificationActions";
import { IValue } from "../../models/value";
import { IOption } from "../../models/option";
import { ISection } from "../../models/section";
import { validateQuestion, validateTableInput } from "./validationHelper";
import { FileDropzone } from "../../components/Dropzone/FileDropzone";
import { IFile } from "../../models/file";
import { deleteFile, uploadFile } from "../../services/fileService";
import "./FormView.css";

export const FormView = () => {
    const user: UserData = useSelector((state: AppState) => state.user);
    const params: { id: string; resultId?: string } = useParams();
    const history = useHistory();
    const [result, setResult] = useState<IResult>();
    const [disabled, setDisabled] = useState<boolean>(false);
    const [form, setForm] = useState<IForm>();
    const [activeSection, setActiveSection] = useState<string>();
    const [errors, setErrors] = useState<string[]>([]);
    const [sectionsWithErrors, setSectionsWithErrors] = useState<string[]>([]);
    // First string is questionID, then second and third are primarySubQuestionID and secondarySubQuestionID.
    // This way we can show to the user which subvalues specifically failed validation.
    const [subValuesWithErros, setSubValuesWithErrors] = useState<[string, string, string][]>([]);
    const [modalOpen, setModalOpen] = useState<boolean>(false);
    const [resetToPreviousModalOpen, setResetToPreviousModalOpen] = useState<boolean>(false);
    const [validated, setValidated] = useState<boolean>(false);
    const [validationSuccess, setValidationSuccess] = useState<boolean>(false);
    const [submitLoading, setSubmitLoading] = useState<boolean>(false);
    const [uploadFileLoading, setUploadFileLoading] = useState<boolean>(false);
    const [valueForFilesEdit, setValueForFilesEdit] = useState<IValue>();
    const [loadingQuestions, setLoadingQuestions] = useState(true);
    const val = useRef<IResult>();

    const fetchForm = useCallback(async () => {
        setLoadingQuestions(true);
        await new Promise(r => setTimeout(r, 1000));
        const form = await getForm(params.id);
        let result: IResult;

        if (user.role === UserRole.Admin) {
            if (params.resultId) {
                result = await getResult(params.resultId);
                setDisabled(true);
            } else {
                result = {
                    id: "",
                    country: "",
                    form: "",
                    isSubmited: false,
                    values: [],
                };
            }
        } else {
            result = await getResultOfForm(params.id);
            let specialDeadline = form.specialDeadlines[0]?.deadline;
            setDisabled(
                result.isSubmited || new Date() > new Date(specialDeadline || form.deadline)
            );
        }
        setResult(result);

        if (form.id) {
            setForm(form);
            setActiveSection(form.sections[0]?.id);
            setVisibility(result.values, form.sections);
        }
        setLoadingQuestions(false);
    }, [params.id, params.resultId, user]);

    const validate = () => {
        let errors: string[] = [];
        let sections: string[] = [];
        let invalidSubValues: [string, string, string][] = [];
        form?.sections
            .filter(s => s.visible)
            .forEach(section => {
                section.questions
                    .filter(q => q.visible)
                    .forEach(question => {
                        let value = getValue(question.id);

                        if (question.type === "TableInput" && value) {
                            let subQuestionPairs = validateTableInput(question, value);
                            if (subQuestionPairs.length > 0) {
                                invalidSubValues.push(
                                    ...(subQuestionPairs.map(e => [question.id, e[0], e[1]]) as [
                                        string,
                                        string,
                                        string
                                    ][])
                                );
                                sections.push(section.id);
                                errors.push(question.id);
                            }
                        } else {
                            if (!validateQuestion(question, value)) {
                                sections.push(section.id);
                                errors.push(question.id);
                            }
                        }
                    });
            });
        setErrors(errors);
        setSectionsWithErrors(sections);
        setSubValuesWithErrors(invalidSubValues);

        return errors.length === 0;
    };

    const openSubmitModal = () => {
        setModalOpen(true);
        setValidated(false);
        setValidationSuccess(validate());
        setValidated(true);
    };

    const onUnsubmit = async () => {
        setResult({ ...result!, isSubmited: false });
        await unsubmitResultValues(result!.id);
        history.goBack();
    };

    const onSubmit = async () => {
        setResult({ ...result!, isSubmited: true });
        setSubmitLoading(true);
        await submitResultValues(result!);
        setSubmitLoading(false);
        setModalOpen(false);
        history.goBack();
    };

    useEffect(() => {
        fetchForm();
    }, [fetchForm]);

    const setVisibility = (newValues: IValue[], sections: ISection[]): [IValue[], string[]] => {
        let newSections = [...sections];
        let visibleQuestionsIds: string[] = [];
        let update = false;

        let skipToEnd = false;
        newSections.forEach(section => {
            section.visible = !skipToEnd;
            let skipToNextSection = skipToEnd;
            let skipToQuestionId: string | undefined;

            section.questions.forEach(question => {
                let oldVisible = question.visible;
                question.visible = !skipToNextSection && !skipToQuestionId;

                if (skipToQuestionId && question.id === skipToQuestionId) {
                    question.visible = true;
                    skipToQuestionId = undefined;
                }

                if (question.visible && question.branching) {
                    let selectedOption: IOption | undefined;

                    let value = newValues.find(v => v.question === question.id);
                    if (value && value.options) {
                        let selectedOptionId = value.options[0];
                        selectedOption = question.options.find(o => o.id === selectedOptionId);
                    } else {
                        selectedOption =
                            selectedOption ??
                            question.options.find(o => o.branchingAction === "SkipToEnd");
                        selectedOption =
                            selectedOption ??
                            question.options.find(o => o.branchingAction === "SkipToNextSection");

                        if (!selectedOption) {
                            let targetOrderNum = 0;

                            question.options
                                .filter(o => o.branchingAction === "SkipToQuestion")
                                .forEach(o => {
                                    let branchingTarget = section.questions.find(
                                        q => q.id === o.branchTarget
                                    );
                                    if (
                                        branchingTarget &&
                                        branchingTarget.orderNumber > targetOrderNum
                                    ) {
                                        selectedOption = o;
                                        targetOrderNum = branchingTarget.orderNumber;
                                    }
                                });
                        }
                    }

                    if (selectedOption) {
                        switch (selectedOption.branchingAction) {
                            case "SkipToQuestion":
                                skipToQuestionId = selectedOption.branchTarget;
                                break;
                            case "SkipToNextSection":
                                skipToNextSection = true;
                                break;
                            case "SkipToEnd":
                                skipToNextSection = true;
                                skipToEnd = true;
                                break;
                        }
                    }
                }

                if (question.visible) {
                    visibleQuestionsIds.push(question.id);
                }

                update = update || oldVisible !== question.visible;
            });
        });

        if (update) {
            let visibleValues = newValues.filter(v => visibleQuestionsIds.includes(v.question));
            setForm(f => {
                return { ...f!, sections: newSections };
            });
            return [visibleValues, visibleQuestionsIds];
        } else {
            return [newValues, visibleQuestionsIds];
        }
    };

    useEffect(() => {
        if (result && user.role === "NCLP") val.current = result;
    }, [result, user]);

    useEffect(() => {
        return () => {
            if (val.current && !val.current.isSubmited) {
                updateResultValues(val.current);
            }
        };
    }, []);

    const getValue = (questionId: string): IValue | undefined => {
        if (result) {
            return result.values.find(v => v.question === questionId);
        }
        return undefined;
    };

    const updateValue = (value: IValue, doSave: boolean) => {
        let newValues = result!.values.filter(v => v.id !== value.id);
        newValues.push(value);
        let [visibleValues, visibleQuestionsIds] = setVisibility(newValues, form!.sections);
        setResult({ ...result!, values: visibleValues ?? [] });
        let newResult = { ...result!, values: visibleValues ?? [] };
        let newErrors = errors
            .filter(e => e !== value.question)
            .filter(e => visibleQuestionsIds.includes(e));
        setErrors(newErrors);

        let secErrors: string[] = [];
        form?.sections
            .filter(s => sectionsWithErrors.some(se => se === s.id))
            .forEach(section => {
                if (section.questions.some(q => newErrors.some(e => e === q.id))) {
                    secErrors.push(section.id);
                }
            });
        setSectionsWithErrors(secErrors);
        if (doSave) {
            updateResultValues(newResult);
        }
    };

    const resetToPrevious = async () => {
        const newResult = await resetResult(result!.id);
        setResult(newResult);
        setVisibility(newResult.values, form!.sections);
        setResetToPreviousModalOpen(false);
    };

    const handleUploadFile = async (f: File) => {
        if (valueForFilesEdit && result) {
            setUploadFileLoading(true);
            let newResult = { ...result };
            if (result.values.every(v => v.id !== valueForFilesEdit.id)) {
                let newValues = [...result.values, valueForFilesEdit];
                newResult = { ...result, values: newValues };
                setResult(newResult);
            }
            await updateResultValues(newResult);
            let newFile: IFile = await uploadFile(valueForFilesEdit.id, f);
            let newFiles = [...valueForFilesEdit.files, newFile];
            let newValue = { ...valueForFilesEdit, files: newFiles };
            updateValue(newValue, false);
            setValueForFilesEdit(newValue);
            setUploadFileLoading(false);
        }
    };

    const handleDeleteFile = async (file: IFile) => {
        if (valueForFilesEdit) {
            await deleteFile(file.id);
            let newFiles = valueForFilesEdit.files.filter(f => f.id !== file.id);
            let newValue = { ...valueForFilesEdit, files: newFiles };
            updateValue(newValue, false);
            setValueForFilesEdit(newValue);
            setUploadFileLoading(false);
        }
    };

    const getIconForFile = (mime: string, name: string): SemanticICONS => {
        if (name.endsWith(".zip") || name.endsWith(".rar") || name.endsWith(".7z")) {
            return "file archive outline";
        } else if (
            name.endsWith(".doc") ||
            name.endsWith(".docx") ||
            mime.includes("msword") ||
            mime.includes("wordprocessingml")
        ) {
            return "file word outline";
        } else if (
            name.endsWith(".xls") ||
            name.endsWith(".xlsx") ||
            name.endsWith(".xlsm") ||
            mime.includes("ms-excel") ||
            mime.includes("spreadsheetml")
        ) {
            return "file excel outline";
        } else if (name.endsWith(".pdf") || mime.includes("pdf")) {
            return "file pdf outline";
        } else if (mime.includes("compressed")) {
            return "file archive outline";
        } else if (mime.includes("text")) {
            return "file alternate outline";
        } else if (mime.includes("image")) {
            return "file image outline";
        } else {
            return "file outline";
        }
    };

    const setNextVisibleSection = (): void => {
        if (activeSection && form?.sections) {
            const activeSectionIndex = form.sections.findIndex(s => s.id === activeSection);

            if (activeSectionIndex === -1) {
                return;
            }

            const nextSection = form.sections.find(
                s => s.orderNumber > form.sections[activeSectionIndex].orderNumber && s.visible
            );

            if (nextSection === undefined) {
                return;
            }

            return setActiveSection(nextSection.id);
        }
    };

    const isLastSection = (): boolean => {
        if (activeSection && form?.sections) {
            const visibleSections = form.sections.filter(s => s.visible);

            if (visibleSections.length === 0) {
                return false;
            }

            return activeSection === visibleSections[visibleSections.length - 1].id;
        }

        return false;
    };

    return user.role === UserRole.Viewer ? (
        <Redirect to="/unauthorized" />
    ) : (
        <div className="form-view-container">
            <div className="form-header">
                <img src={Logo} alt="Logo" />
                <h3>{form?.name}</h3>
            </div>

            <div className="form-view">
                {activeSection && form?.sections![0].id === activeSection && (
                    <p>{form?.description}</p>
                )}

                <div className="legend">
                    <p className="required">
                        <span>*</span> = answer required,
                    </p>
                    <p className="remark">
                        <span>*</span> = answer or remark required
                    </p>
                    <div className="latest-data">
                        {user.role === UserRole.NCLP && !disabled && form?.isHardCopy && (
                            <Button onClick={setResetToPreviousModalOpen.bind(null, true)}>
                                Load latest available data (previous year)
                            </Button>
                        )}
                    </div>
                </div>

                <Form>
                    <Loader active={loadingQuestions} inline="centered" />
                    {form?.sections.map(s => (
                        <section key={s.id} className={s.id === activeSection ? "active" : ""}>
                            <h2>{s.name}</h2>
                            {s.questions
                                .filter(q => q.visible)
                                .map((q, i) => (
                                    <QuestionWrapper
                                        key={q.id}
                                        displayNumber={q.orderNumber}
                                        question={q}
                                        inEditor={false}
                                        value={getValue(q.id)}
                                        saveValue={updateValue}
                                        disabled={disabled || user.role === UserRole.Viewer}
                                        error={errors.some(e => e === q.id)}
                                        subQuestionsErrors={subValuesWithErros.filter(
                                            e => e[0] === q.id
                                        )}
                                        manageFiles={setValueForFilesEdit}
                                    />
                                ))}
                        </section>
                    ))}
                </Form>

                <div className="buttons-container">
                    <Button onClick={history.goBack}>Back</Button>
                    {user.role === UserRole.Admin &&
                        result &&
                        result.isSubmited &&
                        form?.published && <Button onClick={onUnsubmit}>Unsubmit</Button>}

                    {user.role === UserRole.NCLP && !disabled && form && isLastSection() && (
                        <Button primary onClick={openSubmitModal}>
                            Submit
                        </Button>
                    )}
                    {form && !isLastSection() && (
                        <Button primary onClick={setNextVisibleSection}>
                            Next section
                        </Button>
                    )}
                </div>
            </div>

            <nav id="section-navigation">
                <Menu vertical>
                    {form?.sections
                        .filter(s => s.visible)
                        .map(s => (
                            <Menu.Item
                                key={s.id}
                                className={sectionsWithErrors.some(x => x === s.id) ? "error" : ""}
                                position="right"
                                name={s.name}
                                active={activeSection === s.id}
                                onClick={setActiveSection.bind(null, s.id)}
                            >
                                {s.name}
                            </Menu.Item>
                        ))}
                </Menu>
            </nav>

            <Segment id="form-info-container" className="form-info">
                <div>
                    <b>Country: </b>
                    {result?.countryName}
                </div>
                <Divider />
                <div>
                    <b>Year: </b>
                    {form?.timePublished && new Date(form.timePublished).getFullYear()}
                </div>
            </Segment>

            <Modal onClose={() => setModalOpen(false)} open={modalOpen || submitLoading}>
                <Modal.Header>
                    {validated
                        ? validationSuccess
                            ? "Confirm submit"
                            : "Validation failed"
                        : "Validating"}
                </Modal.Header>
                <Modal.Content>
                    {validated && validationSuccess && (
                        <>
                            <Message icon color="green">
                                <Icon name="check circle outline" color="green" />
                                Validation successfull!
                            </Message>
                            <p>
                                Are you sure you want to submit this form? Submited values can no
                                longer be changed!
                            </p>
                        </>
                    )}
                    {validated && !validationSuccess && (
                        <>
                            <Message icon color="red">
                                <Icon name="times circle outline" color="red" />
                                Validation failed!
                            </Message>
                            <p>Fix errors before sumbiting!</p>
                        </>
                    )}
                </Modal.Content>
                <Modal.Actions>
                    <Button onClick={() => setModalOpen(false)} disabled={submitLoading}>
                        Close
                    </Button>
                    <Button
                        primary
                        loading={submitLoading}
                        disabled={!validated || !validationSuccess}
                        onClick={onSubmit}
                    >
                        Submit
                    </Button>
                </Modal.Actions>
            </Modal>

            <Modal
                onClose={() => setResetToPreviousModalOpen(false)}
                open={resetToPreviousModalOpen}
            >
                <Modal.Header>Confirm reset</Modal.Header>
                <Modal.Content>
                    Are you sure you want to reset all answers to values in previous version of this
                    form? This will result in losing all current progress.
                </Modal.Content>
                <Modal.Actions>
                    <Button onClick={() => setResetToPreviousModalOpen(false)}>Close</Button>
                    <Button primary onClick={resetToPrevious}>
                        Reset
                    </Button>
                </Modal.Actions>
            </Modal>

            <Modal open={valueForFilesEdit !== undefined}>
                <Modal.Header>File attachments</Modal.Header>
                <Dimmer.Dimmable blurring as={Modal.Content} dimmed={uploadFileLoading}>
                    <div className="file-avatars-conatiner">
                        {valueForFilesEdit?.files.map(f => (
                            <div className="file-avatar action-bar-container">
                                <div title={f.name}>
                                    <Icon name={getIconForFile(f.contentType, f.name)} size="big" />
                                    <span>{f.name}</span>
                                </div>
                                <div className="action-bar-container-options">
                                    <a href={`/api/files/${f.id}`}>
                                        <Icon size="large" name="download" bordered />
                                    </a>
                                    {user.role === UserRole.NCLP && (
                                        <Icon
                                            size="large"
                                            name="delete"
                                            bordered
                                            onClick={() => handleDeleteFile(f)}
                                        />
                                    )}
                                </div>
                            </div>
                        ))}
                    </div>

                    <Dimmer inverted active={uploadFileLoading}>
                        <Loader content="Loading" />
                    </Dimmer>

                    {user.role === UserRole.NCLP && (
                        <FileDropzone
                            handleSetFile={(f: File) => handleUploadFile(f)}
                            msg="Drag and drop file here or click to select"
                            acceptAllFormats
                        />
                    )}
                </Dimmer.Dimmable>
                <Modal.Actions>
                    <Button onClick={() => setValueForFilesEdit(undefined)}>Close</Button>
                </Modal.Actions>
            </Modal>
        </div>
    );
};
