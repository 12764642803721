import { useSelector } from "react-redux";
import { UserRole } from "../../actions/authentificationActions";
import { AppState } from "../../store/configureStore";
import { AdminHeader } from "./AdminHeader";
import { NclpHeader } from "./NclpHeader";
import { ViewerHeader } from "./ViewerHeader";
import { PublicHeader } from "./PublicHeader";
import "./Header.css";

export const Header = () => {
    const user = useSelector((state: AppState) => state.user);

    return (
        <header>
            {(() => {
                switch (user?.role) {
                    case UserRole.Admin:
                        return <AdminHeader />;
                    case UserRole.NCLP:
                        return <NclpHeader />;
                    case UserRole.Viewer:
                        return <ViewerHeader />;
                    default:
                        return <PublicHeader />;
                }
            })()}
        </header>
    );
};
