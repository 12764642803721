import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { Accordion, Button, Checkbox, Loader } from "semantic-ui-react";
import { ICountry } from "../../models/country";
import "./CountrySelector.css";

interface IProps {
    selectedCountries: ICountry[];
    setSelectedCountries:
        | Dispatch<SetStateAction<ICountry[]>>
        | Dispatch<SetStateAction<ICountry[] | undefined>>
        | ((countries: ICountry[]) => void);
    initializeUsersCountry?: boolean;
    countries: ICountry[];
    loadingCountries: boolean;
    subOptions: ICountrySubOptions;
    setSubOptions: Dispatch<SetStateAction<ICountrySubOptions>>;
}

export interface ICountrySubOptions {
    [key: string]: { name: string; selected: boolean }[];
}

export const CountrySelector = ({
    selectedCountries,
    setSelectedCountries,
    countries,
    loadingCountries,
    subOptions,
    setSubOptions,
}: IProps) => {
    const [allCountries, setAllCountries] = useState<ICountry[]>([]);
    const [countriesWithSubOptions, setCountriesWithSubOptions] = useState<string[]>([]);

    useEffect(() => {
        const setupOrderedCountries = async (c: ICountry[]) => {
            c = c.sort((a, b) => a.abbreviation.localeCompare(b.abbreviation));
            setAllCountries(c);
        };
        setupOrderedCountries(countries);
    }, [countries]);

    useEffect(() => {
        if (subOptions) {
            setCountriesWithSubOptions(Object.keys(subOptions));
        }
    }, [subOptions]);

    const toggleSelectedCountry = (country: ICountry) => {
        let newOptions: { name: string; selected: boolean }[] = [];
        let hasSubOptions: boolean = Object.keys(subOptions).includes(country.abbreviation);
        if (selectedCountries.some(c => c.abbreviation === country.abbreviation)) {
            setSelectedCountries(
                selectedCountries.filter(c => c.abbreviation !== country.abbreviation)
            );
            if (hasSubOptions) {
                newOptions = subOptions[country.abbreviation].map(so => {
                    return { ...so, selected: false };
                });
            }
        } else {
            setSelectedCountries([...selectedCountries, country]);
            if (hasSubOptions) {
                newOptions = subOptions[country.abbreviation].map(so => {
                    return { ...so, selected: true };
                });
            }
        }
        if (newOptions.length > 0) {
            setSubOptions!({ ...subOptions, [country.abbreviation]: newOptions });
        }
    };

    const setEuCountries = () => {
        const euCountries = allCountries.filter(c => c.isEu);
        euCountries.sort((a, b) => a.name.localeCompare(b.name));
        setSelectedCountries(euCountries);

        if (setSubOptions) {
            const newSubOptions = { ...subOptions };
            Object.keys(newSubOptions).forEach(k => {
                newSubOptions[k] = newSubOptions[k].map(so => {
                    return { ...so, selected: euCountries.some(c => c.abbreviation === k) };
                });
            });
            setSubOptions(newSubOptions);
        }
    };

    const clearAll = () => {
        setSelectedCountries([]);

        if (setSubOptions) {
            const newSubOptions = { ...subOptions };
            Object.keys(newSubOptions).forEach(k => {
                newSubOptions[k] = newSubOptions[k].map(so => {
                    return { ...so, selected: false };
                });
            });
            setSubOptions(newSubOptions);
        }
    };

    const renderCountryCheckbox = (country: ICountry) => (
        <Checkbox
            label={country.abbreviation}
            value={country.abbreviation}
            key={country.abbreviation}
            checked={selectedCountries.includes(country)}
            onChange={toggleSelectedCountry.bind(null, country)}
        />
    );

    const toggleSuboption = (country: ICountry, option: { name: string; selected: boolean }) => {
        let newOptions = [...subOptions[country.abbreviation]];
        let index = newOptions.findIndex(o => o.name === option.name);
        newOptions[index].selected = !option.selected;
        setSubOptions!({ ...subOptions, [country.abbreviation]: newOptions });
    };

    const renderSubOptions = (
        country: ICountry,
        options: { name: string; selected: boolean }[]
    ) => (
        <>
            {options &&
                options.map(o => {
                    let name = o.name
                        .replace(`${country.abbreviation} `, "")
                        .replace("(", "")
                        .replace(")", "");
                    return (
                        <Checkbox
                            label={name}
                            key={name}
                            checked={o.selected}
                            onChange={() => toggleSuboption(country, o)}
                        />
                    );
                })}
        </>
    );

    return (
        <div className="countries-selector">
            <div className="countries">
                <Loader active={loadingCountries} inline />
                {allCountries &&
                    !loadingCountries &&
                    allCountries.map(country =>
                        countriesWithSubOptions.some(c => c === country.abbreviation) ? (
                            <div className="country-with-suboptions">
                                <Checkbox
                                    value={country.abbreviation}
                                    key={country.abbreviation}
                                    checked={selectedCountries.includes(country)}
                                    onChange={toggleSelectedCountry.bind(null, country)}
                                />
                                <Accordion
                                    panels={[
                                        {
                                            as: "div",
                                            key: country.abbreviation,
                                            title: country.abbreviation,
                                            content: {
                                                content: renderSubOptions(
                                                    country,
                                                    subOptions[country.abbreviation]
                                                ),
                                            },
                                        },
                                    ]}
                                />
                            </div>
                        ) : (
                            renderCountryCheckbox(country)
                        )
                    )}
            </div>
            <Button onClick={clearAll} primary>
                Clear all
            </Button>
            <Button onClick={setEuCountries} primary>
                All EU
            </Button>
        </div>
    );
};
