import { DropdownItemProps } from "semantic-ui-react";

export interface IPaginationWrapper {
    totalRows: number;
    result: any[];
}

const currentYear = new Date().getFullYear();

export const yearsProps: DropdownItemProps[] = [...new Array(currentYear - 2018)]
    .fill(currentYear)
    .map((x, i) => ({
        key: x - i,
        text: x - i,
        value: x - i,
    }));
