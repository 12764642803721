import React, { useMemo, useCallback } from "react";
import { useDropzone, DropzoneRootProps } from "react-dropzone";
import { baseStyle, activeStyle, acceptStyle, rejectStyle } from "./dropzoneStyles";

interface IProps {
    handleSetFile: (file: File) => void;
    msg: string;
    acceptAllFormats?: boolean;
}

export const FileDropzone: React.FC<IProps> = ({ handleSetFile, msg, acceptAllFormats = false }) => {
    const onDrop = useCallback(
        acceptedFiles => {
            handleSetFile(acceptedFiles[0]);
        },
        [handleSetFile]
    );
    const { getRootProps, getInputProps, isDragActive, isDragAccept, isDragReject } = useDropzone({
        accept: acceptAllFormats ? undefined : "image/*",
        onDrop,
        maxFiles: 1,
        multiple: false
    });

    const style: DropzoneRootProps = useMemo(
        () => ({
            ...baseStyle,
            ...(isDragActive ? activeStyle : {}),
            ...(isDragAccept ? acceptStyle : {}),
            ...(isDragReject ? rejectStyle : {}),
        }),
        [isDragActive, isDragReject, isDragAccept]
    );
    return (
        <div className="file-dropzone-container">
            <div {...getRootProps({ style })}>
                <input {...getInputProps()} />
                {msg}
            </div>
        </div>
    );
};
