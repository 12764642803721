import { useState } from "react";
import { Link } from "react-router-dom";
import { Button, Card, Icon, Modal } from "semantic-ui-react";
import { toast } from "../..";
import { IForm } from "../../models/form";
import { deleteForm } from "../../services/formsService";
import "./FormCard.css";

interface IProps {
    form: IForm;
    withDelete?: boolean;
    refresh: Function;
}

export const AdminFormCard = ({ form, withDelete, refresh }: IProps) => {
    const [openModal, setOpenModal] = useState<boolean>(false);

    const handleDeleteForm = (id: string) => {
        deleteForm(id)
            .then(() => {
                toast("Form successfully deleted", true, 1000);
                refresh();
            })
            .catch(() => {
                toast("Error on deleting form", false, 1000);
            })
            .finally(() => {
                setOpenModal(false);
            });
    };

    return (
        <>
            <Card as={Link} to={`/forms/overview/edit/${form.id}`} className="form-card">
                <Card.Content>
                    <Card.Header>{form.name}</Card.Header>
                    <Card.Meta>{form.description}</Card.Meta>
                    <Card.Description>
                        <p>
                            <strong>Category: </strong>
                            {form.categoryObj?.name}
                        </p>
                        <p>
                            <strong>Deadline: </strong>
                            {!form.deadline.startsWith("0001-01-01") && (
                                <span>
                                    {new Date(form.deadline).toLocaleString("hr-HR", {
                                        day: "2-digit",
                                        month: "2-digit",
                                        year: "numeric",
                                    })}
                                </span>
                            )}
                        </p>
                    </Card.Description>
                </Card.Content>
            </Card>
            {withDelete && (
                <div className="action-bar-container-options">
                    <Icon size="large" name="delete" onClick={() => setOpenModal(true)} bordered />
                </div>
            )}

            {withDelete && (
                <Modal open={openModal}>
                    <Modal.Header>Are you sure you want to delete this form?</Modal.Header>
                    <Modal.Content>
                        Deleting the form will delete all of the section and questions in it.
                    </Modal.Content>
                    <Modal.Actions>
                        <Button onClick={() => setOpenModal(false)}>Cancel</Button>
                        <Button
                            onClick={() => {
                                handleDeleteForm(form.id);
                            }}
                        >
                            Delete
                        </Button>
                    </Modal.Actions>
                </Modal>
            )}
        </>
    );
};
