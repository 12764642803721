import { useState } from "react";
import { IQuestion } from "../../models/question";
import { Button, Card, Form, Icon, Modal } from "semantic-ui-react";
import { QuestionCreate } from "./QuestionCreate";
import { QuestionDisplay } from "./QuestionDisplay";
import { IValue } from "../../models/value";
import "./QuestionWrapper.css";

interface IProps {
    inEditor: boolean;
    question: IQuestion;
    value?: IValue;
    error?: boolean;
    subQuestionsErrors?: [string, string, string][]; // Only applies to TableInput
    disabled?: boolean;
    activeEditQuestion?: string;
    otherQuestions?: IQuestion[];
    displayNumber?: number;
    saveValue?: (value: IValue, doSave: boolean) => void;
    saveChanges?: (question: IQuestion) => void;
    hardSave?: () => void;
    deleteQuestion?: (id: string) => void;
    setActiveEditQuestion?: (activeEditQuestion: string) => void;
    copyQuestion?: (question: IQuestion) => void;
    isFormHardCopy?: boolean;
    manageFiles?: (value: IValue) => void;
}

export const QuestionWrapper = ({
    inEditor,
    question,
    activeEditQuestion,
    otherQuestions,
    saveChanges,
    hardSave,
    deleteQuestion,
    value,
    error,
    subQuestionsErrors,
    displayNumber,
    disabled = false,
    saveValue,
    setActiveEditQuestion,
    copyQuestion,
    isFormHardCopy,
    manageFiles,
}: IProps) => {
    const toggleEditMode = () => {
        if (setActiveEditQuestion && activeEditQuestion === "") {
            setActiveEditQuestion(question.id);
        } else if (activeEditQuestion && setActiveEditQuestion) {
            hardSave?.();
            saveChanges!(question);
            activeEditQuestion === question.id
                ? setActiveEditQuestion("")
                : setActiveEditQuestion(question.id);
        }
    };

    const [deleteQuestionModalOpen, setDeleteQuestionModalOpen] = useState(false);

    return (
        <div
            className={`action-bar-container question-wrapper-container ${
                inEditor ? "editor" : "display"
            }`}
        >
            {inEditor && (
                <>
                    <Card className="question-wrapper">
                        <Card.Content>
                            {activeEditQuestion === question.id ? (
                                <QuestionCreate
                                    question={question}
                                    otherQuestions={otherQuestions!}
                                    saveChanges={saveChanges!}
                                    hardSave={hardSave}
                                    isFormHardCopy={isFormHardCopy}
                                />
                            ) : (
                                <Form>
                                    <QuestionDisplay
                                        question={question}
                                        disabled={disabled}
                                        linked={question.isLinked}
                                    />
                                </Form>
                            )}
                        </Card.Content>
                    </Card>
                    <div className="action-bar-container-options question-options">
                        <Icon
                            size="large"
                            name={activeEditQuestion === question.id ? "eye" : "edit"}
                            onClick={toggleEditMode}
                            bordered
                        />
                        <Icon
                            size="large"
                            name="copy"
                            bordered
                            onClick={copyQuestion!.bind(null, question)}
                        />
                        <Icon
                            size="large"
                            name="delete"
                            bordered
                            onClick={() => setDeleteQuestionModalOpen(true)}
                        />
                    </div>
                    <Modal
                        open={deleteQuestionModalOpen}
                        onClose={() => setDeleteQuestionModalOpen(false)}
                    >
                        <Modal.Header>Deleting question: {question.text}</Modal.Header>
                        <Modal.Content>
                            Are you sure you want to delete the question. This action is not
                            reversable.
                        </Modal.Content>
                        <Modal.Actions>
                            <Button
                                content="Cancel"
                                onClick={() => {
                                    setDeleteQuestionModalOpen(false);
                                }}
                            />
                            <Button
                                primary
                                content="Delete"
                                onClick={() => {
                                    deleteQuestion!(question.id);
                                    setDeleteQuestionModalOpen(false);
                                }}
                            />
                        </Modal.Actions>
                    </Modal>
                </>
            )}
            {!inEditor && (
                <QuestionDisplay
                    displayNumber={displayNumber}
                    disabled={disabled}
                    question={question}
                    value={value}
                    saveValue={saveValue}
                    error={error}
                    manageFiles={manageFiles}
                    subQuestionsErrors={subQuestionsErrors}
                />
            )}
        </div>
    );
};
