import { Tab } from "semantic-ui-react";
import { SecondLevelTabs } from "./SecondLevelTabs";
import { Dispatch, SetStateAction } from "react";
import "../Hierarchy/Hierarchy.css";

const getfirstLevelTabs = ({ years, setYears, allowMultipleYears }: IProps) => [
    {
        menuItem: "PGMs",
        render: () => (
            <SecondLevelTabs
                isPgm={true}
                paneNames={secondLevelTabsPGM}
                allowMultipleYears={allowMultipleYears}
                years={years}
                setYears={setYears}
            />
        ),
    },
    {
        menuItem: "HVDC & DC-PPM",
        render: () => (
            <SecondLevelTabs
                isPgm={false}
                paneNames={secondLevelTabsHDVC}
                allowMultipleYears={allowMultipleYears}
                years={years}
                setYears={setYears}
            />
        ),
    },
];

const secondLevelTabsPGM = ["Type A", "Type B", "Type C", "Type D"];
const secondLevelTabsHDVC = [
    "HVDC connecting SAs or CAs",
    "Embedded HVDC systems within 1 CA and connected to the transmission network",
    "Embedded HVDC systems within 1 CA and connected to the distribution network with cross border impact",
    "DC-connected PPMs",
];

interface IProps {
    years?: number[];
    setYears: Dispatch<SetStateAction<number[]>>;
    allowMultipleYears: Dispatch<SetStateAction<boolean>>;
}

export const FirstLevelTabs = ({ years, setYears, allowMultipleYears }: IProps) => {
    return (
        <div className="first-level-tabs">
            <Tab
                menu={{ fluid: false, vertical: false, tabular: true }}
                panes={getfirstLevelTabs({ years, setYears, allowMultipleYears })}
            />
        </div>
    );
};
