import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { IFrtChartData, IPoint } from "../../models/point";
import { getChartData, getChartYears } from "../../services/figuresService";
import {
    XAxis,
    YAxis,
    CartesianGrid,
    Legend,
    Tooltip,
    Scatter,
    ScatterChart,
    Line,
    Label,
} from "recharts";
import { Button } from "semantic-ui-react";
import {
    CountrySelector,
    ICountrySubOptions,
} from "../../components/CountrySelector/CountrySelector";
import { ICountry } from "../../models/country";
import { getGradient, shadeColor } from "../../helpers/gradient";
import { getBlocks } from "../../services/formsService";
import { getCountries } from "../../services/countriesService";
import { UserData } from "../../actions/authentificationActions";
import { useSelector } from "react-redux";
import { AppState } from "../../store/configureStore";
import "./FrtChart.css";

interface IProps {
    year?: number;
    setYears: Dispatch<SetStateAction<number[]>>;
}

type IFrtChartDisplayData = IFrtChartData & { color: string; isSuboption?: boolean };

const chartTypes: { [key: string]: string } = {
    frt_spgm_bc: "SPGM type B/C",
    frt_spgm_d: "SPGM type D",
    frt_ppm_bc: "PPM type B/C",
    frt_ppm_d: "PPM type D",
    frt_hvdc: "HVDC 3-phase fault",
};

export const FrtChart = ({ year, setYears }: IProps) => {
    const user: UserData = useSelector((state: AppState) => state.user);
    const [chartType, setChartType] = useState("frt_spgm_bc");
    const [data, setData] = useState<IFrtChartData[]>();
    const [displayData, setDisplayData] = useState<IFrtChartDisplayData[]>([]);
    const [lowerEnvelope, setLowerEnvelope] = useState<IPoint[]>();
    const [upperEnvelope, setUpperEnvelope] = useState<IPoint[]>();
    const [allCountries, setAllCountries] = useState<ICountry[]>([]);
    const [xDomain, setXDomain] = useState<[number, number]>();
    const [colors, setColors] = useState<string[]>([]);
    const [selectedCountries, setSelectedCountries] = useState<ICountry[]>();
    const [loadingCountries, setLoadingCountries] = useState(true);
    const [suboptions, setSubOptions] = useState<ICountrySubOptions>({});

    useEffect(() => {
        if (year && allCountries.length !== 0) {
            getChartData<IFrtChartData[]>(chartType, year).then(chartData => {
                if (chartData) {
                    setData(chartData);

                    const lower = chartData.find(c => c.name === "lower_envelope")!.points;
                    const upper = chartData.find(c => c.name === "upper_envelope")!.points;
                    setLowerEnvelope(lower);
                    setUpperEnvelope(upper);

                    let lx = lower.filter(p => p.x || p.x === 0).map(p => p.x!);
                    let ux = upper.filter(p => p.x || p.x === 0).map(p => p.x!);

                    let xValues = [...lx, ...ux];
                    let xmin = Math.min(...xValues);
                    let xmax = Math.max(...xValues);
                    setXDomain([xmin, xmax]);

                    setSubOptions(getInitialCountrySuboptions(chartData));
                }
            });
        }
    }, [chartType, year, allCountries]);

    useEffect(() => {
        setColors(getGradient(36));
    }, []);

    useEffect(() => {
        setLoadingCountries(true);
        getBlocks().then(b =>
            getCountries(b.find(x => x.name === "Network codes monitoring")?.id).then(res => {
                setAllCountries(res);
                setSelectedCountries(res.filter(c => c.abbreviation === user.countryAbbreviation));
                setLoadingCountries(false);
            })
        );
    }, [user]);

    useEffect(() => {
        getChartYears(chartType).then(years => setYears(years));
    }, [chartType, setYears]);

    useEffect(() => {
        if (data && selectedCountries) {
            const filtered = data.filter(d =>
                selectedCountries.some(c => c.abbreviation === d.country)
            );
            const display: IFrtChartDisplayData[] = [];
            const countryCounts: { [key: string]: number } = {};

            const duplicates = data
                .map(d => d.country)
                .filter((e, i, a) => e && a.indexOf(e) !== i);

            filtered.forEach(d => {
                let baseColor = colors[allCountries.findIndex(c => c.abbreviation === d.country)];
                let countryCount = countryCounts[d.country] ?? 0;
                display.push({
                    ...d,
                    color: shadeColor(baseColor, countryCount * 30),
                    isSuboption: duplicates.includes(d.country),
                });
                countryCounts[d.country] = countryCount + 1;
            });

            setDisplayData(display);
        }
    }, [data, selectedCountries]);

    const getInitialCountrySuboptions = (data: IFrtChartData[] | undefined) => {
        const initialySelected = selectedCountries
            ? selectedCountries.map(c => c.abbreviation)
            : [user.countryAbbreviation];
        let result: ICountrySubOptions = {};
        if (data) {
            const duplicates = data
                .map(d => d.country)
                .filter((e, i, a) => e && a.indexOf(e) !== i);
            duplicates.forEach(country => {
                result[country] = data
                    .filter(c => c.country === country)
                    .map(d => {
                        return {
                            name: d.name,
                            selected: initialySelected.includes(country),
                        };
                    });
            });
        }
        return result;
    };

    return (
        <div className="frt-chart-container">
            <div className="chart-type-selector">
                {Object.keys(chartTypes).map(key => (
                    <Button
                        onClick={setChartType.bind(null, key)}
                        primary={chartType === key}
                        key={key}
                    >
                        {chartTypes[key]}
                    </Button>
                ))}
            </div>
            <ScatterChart width={800} height={500}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis type="number" dataKey="x" domain={xDomain}>
                    <Label offset={-5} position="insideBottomRight" value="[s]" />
                </XAxis>
                <YAxis type="number" dataKey="y">
                    <Label offset={0} position="insideTopLeft" value="[p.u.]" />
                </YAxis>
                <Tooltip />
                <Legend />

                {displayData
                    .filter(
                        dd =>
                            !dd.isSuboption ||
                            (suboptions[dd.country] &&
                                suboptions[dd.country].some(
                                    so => so.name === dd.name && so.selected
                                ))
                    )
                    .map(d => (
                        <Scatter
                            key={d.name}
                            name={d.name}
                            data={d.points}
                            fill={d.color}
                            line
                            isAnimationActive={false}
                            shape="circle"
                        />
                    ))}

                {lowerEnvelope && (
                    <Scatter
                        name={"lower envelope"}
                        data={lowerEnvelope}
                        fill="#FF0000"
                        shape={<span />}
                        line={
                            <Line
                                stroke="#FF0000"
                                strokeDasharray="5 8"
                                dot={false}
                                dataKey=""
                                isAnimationActive={false}
                            />
                        }
                        isAnimationActive={false}
                    />
                )}
                {upperEnvelope && (
                    <Scatter
                        name={"upper envelope"}
                        data={upperEnvelope}
                        fill="#C00000"
                        shape={<span />}
                        line={
                            <Line
                                stroke="#C00000"
                                strokeDasharray="5 8"
                                dot={false}
                                dataKey=""
                                isAnimationActive={false}
                            />
                        }
                        isAnimationActive={false}
                    />
                )}
            </ScatterChart>
            <CountrySelector
                selectedCountries={selectedCountries ?? []}
                setSelectedCountries={setSelectedCountries}
                initializeUsersCountry={true}
                countries={allCountries}
                loadingCountries={loadingCountries}
                subOptions={suboptions}
                setSubOptions={setSubOptions}
            />
        </div>
    );
};
