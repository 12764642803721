import { getAuthorizationProperties } from "./authorizationHelper";
import { IChapter, IChapterContent } from "../models/chapter";
import { toast } from "..";

export async function getChaptersForReport(guid: string): Promise<IChapter[]> {
    let res = await fetch(`/api/chapters?report=${guid}`, {
        method: "GET",
        ...getAuthorizationProperties({ "Content-Type": "application/json" }),
    });
    return res.json();
}

export async function createNewChapter(chapter: Partial<IChapter>) {
    let res = await fetch("/api/chapters", {
        method: "POST",
        ...getAuthorizationProperties({ "Content-Type": "application/json" }),
        body: JSON.stringify(chapter),
    });
    if (res.ok) {
        return res.json();
    } else if (res.status === 409) {
        toast("Maximum number of levels is 5", false, 1000);
        return null;
    }
    throw Error;
}

export async function updateChapters(chapters: IChapter[], reportGuid: string) {
    return fetch(`/api/chapters/${reportGuid}`, {
        method: "PATCH",
        ...getAuthorizationProperties({ "Content-Type": "application/json" }),
        body: JSON.stringify(chapters),
    })
        .then(res => {
            return Promise.resolve(res.ok);
        })
        .catch(err => {
            return Promise.resolve(false);
        });
}

export async function getChapterContent(chapterGuid: string): Promise<IChapterContent[]> {
    let res = await fetch(`/api/chapters/content/${chapterGuid}`, {
        method: "GET",
        ...getAuthorizationProperties({ "Content-Type": "application/json" }),
    });

    if (!res.ok) {
        throw Error;
    }

    return res.json();
}

export async function reorderContent(chapterId: string, source: number, destination: number) {
    let res = await fetch(
        `/api/chapters/reorder/${chapterId}?source=${source}&destination=${destination}`,
        {
            method: "GET",
            ...getAuthorizationProperties({ "Content-Type": "application/json" }),
        }
    );

    if (!res.ok) {
        throw Error;
    }
}
