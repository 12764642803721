import { useCallback, useEffect, useState } from "react";
import { useParams, useHistory } from "react-router-dom";
import { Select, Button, Icon, Modal, Dropdown, Menu, Loader, Form } from "semantic-ui-react";
import ReactQuill from "react-quill";
import { IChapter, IChapterContent } from "../../models/chapter";
import { IParagraph } from "../../models/paragraph";
import { ReportPublishControls } from "../Report/ReportPublishControls";
import {
    getChaptersForReport,
    getChapterContent,
    reorderContent,
} from "../../services/chaptersService";
import { FigureSelectionModal } from "./FigureSelectionModal";
import {
    createParagraph,
    updateParagraph,
    deleteParagraph,
} from "../../services/paragraphsService";
import {
    addFigureToChapter,
    removeFigureFromChapter,
    updateFigureChapter,
} from "../../services/figureChaptersService";
import { toast } from "../..";
import toolbarConfig from "./TextEditorToolbarConfig.json";
import { IFigureChapter } from "../../models/figureChapters";
import { DragDropContext, Draggable, Droppable, DropResult } from "react-beautiful-dnd";
import { Breadcrumbs } from "../../components/Breadcrumbs/Breadcrumbs";
import "react-quill/dist/quill.snow.css";
import "./Chapter.css";

export const Chapter = () => {
    const history = useHistory();
    const params: { reportId: string; chapterId: string } = useParams();

    const [chapters, setChapters] = useState<IChapter[]>([]);

    const [paragraphDeleteSelection, setParagraphDeleteSelection] = useState("");
    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
    const [figureSelectDialogOpen, setFigureSelectDialogOpen] = useState(false);

    const [figureDeleteSelection, setFigureDeleteSelection] = useState("");
    const [figureDeleteOpen, setFigureDeleteOpen] = useState(false);

    const [figureEditNameDialogOpen, setFigureEditNameDialogOpen] = useState(false);
    const [figureEditNameSelection, setFigureEditNameSelection] = useState("");
    const [figureName, setFigureName] = useState("");

    const [chapterContent, setChapterContent] = useState<IChapterContent[]>([]);
    const [loadingContent, setLoadingContent] = useState(true);

    const [contentFocused, setContentFocused] = useState("");

    const refreshContent = useCallback(async () => {
        if (params.chapterId) {
            try {
                setLoadingContent(true);
                let content = await getChapterContent(params.chapterId);
                setChapterContent(content);
            } catch (err) {
                toast("A problem occured while fetching chapter content", false, 1500);
            } finally {
                setLoadingContent(false);
            }
        }
    }, [params.chapterId]);

    useEffect(() => {
        getChaptersForReport(params.reportId).then(res => {
            setChapters(res);
        });
    }, [params.reportId]);

    useEffect(() => {
        refreshContent();
    }, [params.chapterId, refreshContent]);

    const saveNewParagraph = () => {
        let paragraph: Partial<IParagraph> = {
            chapterId: params.chapterId,
        };

        createParagraph(paragraph).then(res => {
            toast("Succesfully created a new paragraph.", true, 1500);
            refreshContent();
        });
    };

    const saveExistingParagraph = (paragraphId: string) => {
        let paragraph = chapterContent.find(p => p.id === paragraphId) as IParagraph;

        if (paragraph) {
            updateParagraph(paragraph.id, paragraph);
        }
    };

    const onDeleteParagraph = () => {
        if (paragraphDeleteSelection) {
            deleteParagraph(paragraphDeleteSelection).then(res => {
                if (res.ok) {
                    toast("Paragraph was successfully deleted.", true, 1500);
                } else {
                    toast("There was a problem with deleting the paragraph.", false, 1500);
                }

                refreshContent();
            });

            setDeleteDialogOpen(false);
        }
    };

    const onDeleteFigure = () => {
        if (figureDeleteSelection) {
            removeFigureFromChapter(figureDeleteSelection).then(res => {
                if (res.ok) {
                    toast("Figure was successfully removed form chapter.", true, 1500);
                } else {
                    toast("There was a problem with removing figure from chapter.", false, 1500);
                }

                refreshContent();
            });

            setFigureDeleteOpen(false);
        }
    };

    const handleAddFigure = (guid: string, figureName: string) => {
        let newFigureChapter: Partial<IFigureChapter> = {
            chapterId: params.chapterId,
            figureId: guid,
            name: figureName,
        };

        addFigureToChapter(newFigureChapter).then(res => {
            refreshContent();
            setFigureSelectDialogOpen(false);
        });
    };

    const handleUpdateFigureName = () => {
        let figureChapter = chapterContent.find(
            cc => (cc as IFigureChapter).id === figureEditNameSelection
        ) as IFigureChapter;
        figureChapter.name = figureName;

        updateFigureChapter(figureChapter).then(res => {
            if (res.ok) {
                toast("Figure was successfully updated.", true, 1500);
            } else {
                toast("There was a problem with updating the figure.", false, 1500);
            }

            refreshContent();
            setFigureEditNameDialogOpen(false);
        });
    };

    const renderParagraph = (p: IParagraph, index: number) => {
        return (
            <div
                className="rte-content-container action-bar-container"
                onBlur={() => saveExistingParagraph(p.id)}
            >
                <ReactQuill
                    key={p.id}
                    className="text-editor"
                    value={p.content}
                    modules={toolbarConfig.modules}
                    formats={toolbarConfig.formats}
                    onChange={val => {
                        let newChapterContent: IChapterContent[] = chapterContent.map(cc => {
                            return { ...cc };
                        });

                        let targetParagraph = newChapterContent.find(
                            np => np.id === p.id
                        ) as IParagraph;

                        if (targetParagraph) {
                            targetParagraph.content = val;
                            setChapterContent(newChapterContent);
                        }
                    }}
                />

                <div className="rte-actions-container action-bar-container-options">
                    <Icon
                        size="large"
                        name="delete"
                        onClick={() => {
                            setParagraphDeleteSelection(p.id);
                            setDeleteDialogOpen(true);
                        }}
                        bordered
                    />
                </div>
            </div>
        );
    };

    const renderFigure = (f: IFigureChapter, index: number) => {
        return (
            <div className="action-bar-container figure-container" key={f.figureId}>
                <div className="figure-content-container">
                    <img src={`/api/figures/${f.figureId}`} alt={f.name} />
                    <p>{f.name}</p>
                </div>
                <div className="action-bar-container-options">
                    <Icon
                        size="large"
                        name="edit"
                        onClick={() => {
                            setFigureName(f.name);
                            setFigureEditNameSelection(f.id);
                            setFigureEditNameDialogOpen(true);
                        }}
                        bordered
                    />
                    <Icon
                        size="large"
                        name="delete"
                        onClick={() => {
                            setFigureDeleteSelection(f.id);
                            setFigureDeleteOpen(true);
                        }}
                        bordered
                    />
                </div>
            </div>
        );
    };

    const renderChapterContent = (oc: IChapterContent, index: number) => {
        if ((oc as IFigureChapter).figureId) {
            return renderFigure(oc as IFigureChapter, index);
        } else {
            return renderParagraph(oc as IParagraph, index);
        }
    };

    const unfocusElement = (newFocusedElement: string) => {
        if (contentFocused && newFocusedElement !== contentFocused) {
            (document.activeElement as HTMLElement).blur();
        }
    };

    const onDragEnd = (result: DropResult) => {
        if (
            result.source &&
            result.destination &&
            result.source.index !== result.destination.index
        ) {
            let newChapterContent: IChapterContent[] = chapterContent.map(cc => {
                return { ...cc };
            });

            let sourceItem = newChapterContent[result.source.index - 1];
            newChapterContent.splice(result.source.index - 1, 1);
            newChapterContent.splice(result.destination.index - 1, 0, sourceItem);

            newChapterContent.forEach((ncc, index) => {
                ncc.orderNumber = index + 1;
            });

            newChapterContent.sort((a, b) => a.orderNumber - b.orderNumber);

            let oldChapterContent = [...chapterContent];
            setChapterContent(newChapterContent);

            reorderContent(params.chapterId, result.source.index, result.destination.index).catch(
                err => {
                    toast("Unable to complete reordering", false, 1000);
                    setChapterContent(oldChapterContent);
                }
            );
        }
    };

    return (
        <div className="chapter-container">
            <div>
                <div className="container-header">
                    <h1>Content</h1>
                    <ReportPublishControls reportId={params.reportId} />
                </div>
                <Breadcrumbs />
                <div className="chapter-content-container">
                    <div className="chapter-content">
                        <label>Select a chapter</label>
                        <Select
                            className="chapter-select"
                            value={params.chapterId}
                            options={chapters.map(chapter => {
                                let prefix =
                                    chapter.sectionNumber != null
                                        ? chapter.sectionNumber + " "
                                        : "";

                                return {
                                    key: chapter.id,
                                    value: chapter.id,
                                    text: prefix + chapter.title,
                                };
                            })}
                            onChange={(e, data) =>
                                history.push(
                                    `/reports/overview/${params.reportId}/chapters/${data.value}`
                                )
                            }
                        />

                        <Loader active={loadingContent} inline="centered" />
                        <DragDropContext
                            onDragEnd={onDragEnd}
                            onDragStart={() => unfocusElement("")}
                        >
                            <Droppable droppableId="report-content-droppable">
                                {provided => (
                                    <div ref={provided.innerRef} {...provided.droppableProps}>
                                        {chapterContent.map((oc: IChapterContent, index) => {
                                            return (
                                                <div
                                                    key={oc.id}
                                                    onClick={() => unfocusElement(oc.id)}
                                                    onFocus={() => setContentFocused(oc.id)}
                                                >
                                                    <Draggable
                                                        draggableId={oc.id}
                                                        index={oc.orderNumber}
                                                    >
                                                        {prov => (
                                                            <div
                                                                ref={prov.innerRef}
                                                                {...prov.draggableProps}
                                                                {...prov.dragHandleProps}
                                                            >
                                                                {renderChapterContent(oc, index)}
                                                            </div>
                                                        )}
                                                    </Draggable>
                                                </div>
                                            );
                                        })}
                                        {provided.placeholder}
                                    </div>
                                )}
                            </Droppable>
                        </DragDropContext>

                        <Menu vertical className="add-menu">
                            <Dropdown item text="Add" className="add-dropdown">
                                <Dropdown.Menu>
                                    <Dropdown.Item onClick={saveNewParagraph}>
                                        Paragraph
                                    </Dropdown.Item>
                                    <Dropdown.Item onClick={() => setFigureSelectDialogOpen(true)}>
                                        Figure
                                    </Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                        </Menu>

                        <Modal open={deleteDialogOpen} onClose={() => setDeleteDialogOpen(false)}>
                            <Modal.Header>Deleting paragraph</Modal.Header>
                            <Modal.Content>
                                Are you sure you want to delete this paragraph. This action is not
                                reversable!
                            </Modal.Content>
                            <Modal.Actions>
                                <Button
                                    content="Cancel"
                                    onClick={() => setDeleteDialogOpen(false)}
                                />
                                <Button content="Delete" onClick={onDeleteParagraph} />
                            </Modal.Actions>
                        </Modal>

                        <Modal open={figureDeleteOpen} onClose={() => setFigureDeleteOpen(false)}>
                            <Modal.Header>Remove figure</Modal.Header>
                            <Modal.Content>
                                Removing the figure only removes the figure from this chapter and
                                does not delete the figure entirely.
                            </Modal.Content>
                            <Modal.Actions>
                                <Button
                                    content="Cancel"
                                    onClick={() => setFigureDeleteOpen(false)}
                                />
                                <Button content="Delete" onClick={onDeleteFigure} />
                            </Modal.Actions>
                        </Modal>

                        <Modal
                            open={figureEditNameDialogOpen}
                            onClose={() => setFigureEditNameDialogOpen(false)}
                        >
                            <Modal.Header>Edit figure name</Modal.Header>
                            <Modal.Content>
                                <Form>
                                    <Form.Field>
                                        <label>Name</label>
                                        <input
                                            type="text"
                                            value={figureName}
                                            onChange={e => setFigureName(e.target.value)}
                                        />
                                    </Form.Field>
                                </Form>
                            </Modal.Content>
                            <Modal.Actions>
                                <Button
                                    content="Cancel"
                                    onClick={() => setFigureEditNameDialogOpen(false)}
                                />
                                <Button content="Save" onClick={handleUpdateFigureName} />
                            </Modal.Actions>
                        </Modal>
                    </div>
                    <div className="chapter-preview"></div>
                </div>
            </div>
            <div className="navigation-container">
                <Button
                    primary
                    content="Table of contents"
                    onClick={() =>
                        history.push(`/reports/overview/${params.reportId}/table-of-contents`)
                    }
                />
            </div>

            <FigureSelectionModal
                open={figureSelectDialogOpen}
                onClose={() => setFigureSelectDialogOpen(false)}
                onSelect={handleAddFigure}
            />
        </div>
    );
};
