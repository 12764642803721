import { useCallback, useEffect, useState } from "react";
import { IReport } from "../../models/report";
import { getPublishedReports } from "../../services/reportsService";
import Carousel from "react-multi-carousel";
import { PublishedReportCard } from "../../containers/ReportCard/PublishedReportCard";
import { Label, Loader } from "semantic-ui-react";
import { toast } from "../..";
import { useSelector } from "react-redux";
import { AppState } from "../../store/configureStore";
import "react-multi-carousel/lib/styles.css";
import "./ReportsCarousel.css";

interface IProps {
    year?: number;
}

// Prop for Carousel
const responsive = {
    desktop: {
        breakpoint: { max: 4000, min: 200 },
        items: 4,
    },
};

export const PublishedCarousel = ({ year }: IProps) => {
    const user = useSelector((state: AppState) => state.user);
    const [publishedReports, setPublishedReports] = useState<IReport[]>([]);
    const [loadingReports, setLoadingReports] = useState(true);

    const fetchReports = useCallback(async () => {
        setLoadingReports(true);
        try {
            let res = await getPublishedReports(year);
            let published = res.filter(r => r.final);
            setPublishedReports(published);
            setLoadingReports(false);
        } catch (err) {
            toast("Unable to load reports. Please try again later.", false, 1500);
        } finally {
            setLoadingReports(false);
        }
    }, [year]);

    useEffect(() => {
        fetchReports();
    }, [fetchReports]);

    return (
        <>
            {user.role && publishedReports.length > 0 && (
                <div className="legend-container">
                    <div className="legend-item">
                        <Label circular color="green" />
                        <div>Public</div>
                    </div>
                    <div className="legend-item">
                        <Label circular color="orange" />
                        <div>Internal</div>
                    </div>
                </div>
            )}
            {publishedReports.length === 0 && !loadingReports && (
                <div className="empty-reports-message">
                    <b>{`No published reports${year ? " for selected year" : ""}.`}</b>
                </div>
            )}
            <Loader active={loadingReports} inline="centered" />
            <Carousel responsive={responsive}>
                {publishedReports.map(report => {
                    return (
                        <div className="action-bar-container" key={report.id}>
                            <PublishedReportCard report={report} refreshReports={fetchReports} />
                        </div>
                    );
                })}
            </Carousel>
        </>
    );
};
