import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Icon, Label } from "semantic-ui-react";
import { getNewsFeedItemCountSince } from "../../services/newsFeedService";
import { AppState } from "../../store/configureStore";
import "./NotificationsCount.css";

export const NotificationsCount = () => {
    const user = useSelector((state: AppState) => state.user);
    const [count, setCount] = useState(0);

    useEffect(() => {
        let savedTs: string | null = sessionStorage.getItem("newsFeedCheck");
        let ts = savedTs ?? user.lastLogin;

        getNewsFeedItemCountSince(ts!).then(num => {
            setCount(num);
            let now = new Date();
            sessionStorage.setItem("newsFeedCheck", now.toISOString());
        });
    }, [user.lastLogin]);

    return (
        <div className="notifications-count-container" onClick={setCount.bind(null, 0)}>
            <Icon name="bell" size="large" />
            {count ? (
                <Label size="mini" circular color="red">
                    {count}
                </Label>
            ) : null}
        </div>
    );
};
