import { IReport } from "../models/report";
import { getAuthorizationProperties } from "./authorizationHelper";
import { addReportToSessionStorage } from "../helpers/sessionGuidsHelper";

export async function getReportsForYear(year?: number): Promise<IReport[]> {
    const data = fetch(`/api/Reports${year ? `?year=${year}` : ""}`, {
        method: "GET",
        ...getAuthorizationProperties({ "Content-Type": "application/json" }),
    }).then(res => {
        return res.json().then(responseData => {
            responseData.forEach((report: IReport) => {
                if (report.id) addReportToSessionStorage(report.id, report.title);
            });
            return responseData;
        });
    });
    return data;
}

export async function getReport(guid: string): Promise<IReport> {
    const data = await fetch(`/api/Reports/${guid}`, {
        method: "GET",
        ...getAuthorizationProperties({ "Content-Type": "application/json" }),
    }).then(res => {
        return res.json();
    });
    addReportToSessionStorage(guid, data.title);
    return data;
}

export async function changePublicVisibility(guid: string, isPublic: boolean): Promise<IReport> {
    return await fetch(`/api/Reports/${guid}/public/${isPublic}`, {
        method: "PATCH",
        ...getAuthorizationProperties({ "Content-Type": "application/json" }),
    }).then(res => {
        return res.json();
    });
}

export async function generateReportPdf(guid: string): Promise<boolean> {
    let response = await fetch(`/api/reports/generate/${guid}`, {
        method: "GET",
        ...getAuthorizationProperties({ "Content-Type": "application/json" }),
    });

    const blob = await response.blob();
    const url = window.URL.createObjectURL(
        new Blob([blob], {
            type: "application/pdf",
        })
    );
    const link = document.createElement("a");
    link.href = url;
    link.target = "_blank";

    document.body.appendChild(link);
    link.click();
    link.parentNode!.removeChild(link);

    return Promise.resolve(response.ok);
}

export async function publishReport(guid: string, sendNotification: boolean): Promise<number> {
    const params = new URLSearchParams({ sendNotification: sendNotification.toString() });
    let response = await fetch(`/api/reports/publish/${guid}?` + params.toString(), {
        method: "GET",
        ...getAuthorizationProperties({ "Content-Type": "application/json" }),
    });

    if (!response.ok) {
        return Promise.reject(response.status);
    }

    const blob = await response.blob();
    const url = window.URL.createObjectURL(
        new Blob([blob], {
            type: "application/pdf",
        })
    );
    const link = document.createElement("a");
    link.href = url;
    link.target = "_blank";

    document.body.appendChild(link);
    link.click();
    link.parentNode!.removeChild(link);

    return Promise.resolve(response.status);
}

export async function unpublishReport(guid: string): Promise<boolean> {
    return await fetch(`/api/reports/unpublish/${guid}`, {
        method: "PATCH",
        ...getAuthorizationProperties({ "Content-Type": "application/json" }),
    }).then(res => {
        if (!res.ok) {
            return Promise.reject();
        }

        return Promise.resolve(true);
    });
}

export async function downloadPublishedReport(report: IReport) {
    let response = await fetch(`/api/reports/download/${report.id}`, {
        method: "GET",
        ...getAuthorizationProperties({ "Content-Type": "application/json" }),
    });

    if (!response.ok) {
        return Promise.reject();
    }

    const blob = await response.blob();
    const url = window.URL.createObjectURL(
        new Blob([blob], {
            type: "application/pdf",
        })
    );
    const link = document.createElement("a");
    link.href = url;
    link.target = "_blank";

    document.body.appendChild(link);
    link.click();
    link.parentNode!.removeChild(link);

    return Promise.resolve(response.ok);
}

export async function getPublishedReports(year?: number): Promise<IReport[]> {
    return fetch(`/api/reports/published?year=${year || ""}`, {
        method: "GET",
        ...getAuthorizationProperties({ "Content-Type": "application/json" }),
    }).then(res => {
        return res.json();
    });
}

export async function getPublicReports(year?: number): Promise<IReport[]> {
    return fetch(`/api/reports/public?year=${year || ""}`, {
        method: "GET",
        ...getAuthorizationProperties({ "Content-Type": "application/json" }),
    }).then(res => {
        return res.json();
    });
}

export async function getInProgressReports(): Promise<IReport[]> {
    return fetch(`/api/Reports/InProgress`, {
        method: "GET",
        ...getAuthorizationProperties({ "Content-Type": "application/json" }),
    }).then(res => {
        return res.json();
    });
}

export async function createNewReport(report: Partial<IReport>) {
    return fetch("/api/Reports", {
        method: "POST",
        ...getAuthorizationProperties({ "Content-Type": "application/json" }),
        body: JSON.stringify(report),
    }).then(res => {
        if (res.ok) {
            return res.json();
        } else if (res.status === 409) {
            throw Error(res.status.toString());
        }
        throw Error;
    });
}

export async function updateReport(report: Partial<IReport>): Promise<IReport> {
    return fetch(`/api/Reports/${report.id}`, {
        method: "PUT",
        ...getAuthorizationProperties({ "Content-Type": "application/json" }),
        body: JSON.stringify(report),
    }).then(res => {
        return res.json();
    });
}

export async function deleteReport(guid: string | null | undefined): Promise<IReport> {
    return fetch(`/api/Reports/${guid}`, {
        method: "DELETE",
        ...getAuthorizationProperties({ "Content-Type": "application/json" }),
    }).then(res => {
        return res.json();
    });
}

export async function copyReport(guid: string) {
    return await fetch(`/api/Reports/copy/${guid}`, {
        method: "GET",
        ...getAuthorizationProperties({ "Content-Type": "application/json" }),
    });
}
