import { DropdownItemProps } from "semantic-ui-react";
import { toast } from "..";
import { IBlock } from "../models/block";
import { ICategory } from "../models/category";
import { IForm } from "../models/form";
import { getAuthorizationProperties } from "./authorizationHelper";
import { addFormToSessionStorage } from "../helpers/sessionGuidsHelper";

export async function getForm(id: string): Promise<IForm> {
    const data = await fetch(`/api/forms/${id}`, {
        method: "GET",
        ...getAuthorizationProperties({
            "Content-Type": "application/json",
        }),
    }).then(res => {
        return res.json();
    });
    addFormToSessionStorage(id, data.name);
    return data;
}

export async function getForms(): Promise<IForm[]> {
    const data = await fetch(`/api/forms`, {
        method: "GET",
        ...getAuthorizationProperties({
            "Content-Type": "application/json",
        }),
    }).then(res => {
        return res.json().then(responseData => {
            responseData.forEach((form: IForm) => {
                addFormToSessionStorage(form.id, form.name);
            });
            return responseData;
        });
    });
    return data;
}

export async function getNonExhaustiveForms(
    year: number,
    asDropdownProps?: boolean
): Promise<IForm[] | DropdownItemProps[]> {
    const data = await fetch(`/api/forms/non-exhaustive?year=${year}`, {
        method: "GET",
    }).then(res => {
        return res.json();
    });

    if (!asDropdownProps) {
        return data;
    } else {
        return (data as IForm[]).map(f => {
            return {
                key: f.id,
                value: f.id,
                text: f.name,
            };
        });
    }
}

export async function getDataViewFormYears(dataSection: string) {
    const data = await fetch(`/api/forms/dataview/${dataSection}`, {
        method: "GET",
        ...getAuthorizationProperties({
            "Content-Type": "application/json",
        }),
    });

    return data.json();
}

export async function editDataViewAvailability(
    dataSection: string,
    isPublic: boolean,
    year: number[]
) {
    const res = await fetch(
        `/api/forms/dataview/${dataSection}/${isPublic}?${year
            .map(y => `year=${parseInt(y.toString())}`)
            .join("&")}`,
        {
            method: "PATCH",
            ...getAuthorizationProperties({
                "Content-Type": "application/json",
            }),
        }
    );

    if (res.ok) {
        return Promise.resolve();
    } else {
        return Promise.reject();
    }
}

export async function getNonExhaustiveForm(
    id: string,
    asDropdownProps?: boolean
): Promise<IForm | DropdownItemProps[]> {
    const data = await fetch(`/api/forms/non-exhaustive/${id}`, {
        method: "GET",
    }).then(res => {
        return res.json();
    });

    if (!asDropdownProps) {
        return data;
    } else {
        return (data as IForm).sections.flatMap(s => {
            return s.questions.map(q => {
                return {
                    key: q.id,
                    value: q.id,
                    text: q.text,
                };
            });
        });
    }
}

export async function createForm(form: IForm): Promise<IForm> {
    return await fetch("/api/forms/", {
        method: "POST",
        ...getAuthorizationProperties({ "Content-Type": "application/json" }),
        body: JSON.stringify(form),
    }).then(res => {
        if (res.ok) {
            return res.json();
        } else if (res.status === 409) {
            toast("Form name must be unique", false, 1000);
            return null;
        }

        throw Error;
    });
}

export async function createFormCategory(category: Partial<ICategory>): Promise<ICategory> {
    return await fetch("/api/forms/categories", {
        method: "POST",
        ...getAuthorizationProperties({ "Content-Type": "application/json" }),
        body: JSON.stringify(category),
    }).then(res => {
        if (res.ok) {
            return res.json();
        } else if (res.status === 409) {
            toast("Category name must be unique", false, 1000);
            return null;
        }
        throw Error;
    });
}

export async function editForm(id: string, form: Partial<IForm>): Promise<IForm> {
    return await fetch(`/api/forms/${id}`, {
        method: "PUT",
        ...getAuthorizationProperties({ "Content-Type": "application/json" }),
        body: JSON.stringify(form),
    }).then(res => {
        if (res.ok) {
            return res.json();
        } else if (res.status === 409) {
            toast("Form name must be unique", false, 1000);
            return null;
        }
        throw Error;
    });
}

export async function getInProgressForms(): Promise<IForm[]> {
    return await fetch("/api/forms/inprogress", {
        method: "GET",
        ...getAuthorizationProperties({ "Content-Type": "application/json" }),
    }).then(res => {
        return res.json();
    });
}

export async function getPublishedForms(): Promise<IForm[]> {
    let res = await fetch("/api/forms/published", {
        method: "GET",
        ...getAuthorizationProperties({ "Content-Type": "application/json" }),
    });
    return res.json();
}

export async function publishForm(formGuid: string) {
    let res = await fetch(`/api/forms/${formGuid}`, {
        method: "PATCH",
        ...getAuthorizationProperties({
            "Content-Type": "application/json",
        }),
    });
    if (res.ok) {
        return res.json();
    }
    throw Error;
}

export async function getActiveForms(): Promise<IForm[]> {
    let res = await fetch("/api/forms/active", {
        method: "GET",
        ...getAuthorizationProperties({ "Content-Type": "application/json" }),
    });
    return res.json();
}

export async function getFormCategoriesAsProps(): Promise<DropdownItemProps[]> {
    let res = await fetch("/api/forms/categories/props", {
        method: "GET",
        ...getAuthorizationProperties({ "Content-Type": "application/json" }),
    });
    return res.json();
}

export async function getFormsAsDropdownProps(
    searchParams: URLSearchParams
): Promise<DropdownItemProps[]> {
    return await fetch(`/api/forms/props?${searchParams}`, {
        method: "GET",
        ...getAuthorizationProperties({ "Content-Type": "application/json" }),
    }).then(res => {
        if (res.ok) {
            return res.json();
        }
        throw Error;
    });
}

export async function deleteForm(guid: string): Promise<IForm> {
    let res = await fetch(`/api/forms/${guid}`, {
        method: "DELETE",
        ...getAuthorizationProperties({ "Content-Type": "application/json" }),
    });
    if (res.ok) {
        return res.json();
    }
    throw Error;
}

export async function getBlocks(): Promise<IBlock[]> {
    let res = await fetch("/api/forms/blocks", {
        method: "GET",
        ...getAuthorizationProperties({ "Content-Type": "application/json" }),
    });
    return res.json();
}

export async function getCategory(guid: string): Promise<ICategory> {
    let res = await fetch(`/api/forms/category/${guid}`, {
        method: "GET",
        ...getAuthorizationProperties({ "Content-Type": "application/json" }),
    });
    return res.json();
}

export async function dismissWarning(guid: string): Promise<boolean> {
    let res = await fetch(`/api/forms/dismiss/${guid}`, {
        method: "PATCH",
        ...getAuthorizationProperties({ "Content-Type": "application/json" }),
    });
    return Promise.resolve(res.ok);
}
