import { Route, Switch } from "react-router-dom";
import { Home } from "./containers/Home/Home";
import { InputDataEditor } from "./containers/InputDataEditor/InputDataEditor";
import { FormsOverview } from "./containers/FormsOverview/FormsOverview";
import { DataDelivery } from "./containers/DataDelivery/DataDelivery";
import { FormsResults } from "./containers/FormsResults/FormsResults";
import { CreateForm } from "./containers/CreateForm/CreateForm";
import { FormEditor } from "./containers/FormEditor/FormEditor";
import { Layout } from "./containers/Layout/Layout";
import { Unauthorized } from "./containers/Unauthorized/Unauthorized";
import { UsersDashboard } from "./containers/UsersDashboard/UsersDashboard";
import { ReportsOverview } from "./containers/ReportsOverview/ReportsOverview";
import { Report } from "./containers/Report/Report";
import { DataView } from "./containers/DataView/DataView";
import { AdminFigures } from "./containers/Figures/AdminFigures";
import { NclpReportDownload } from "./containers/NclpReport/NclpReportDownload";
import { UserActivity } from "./containers/UserActivity/UserActivity";
import { ExternalPublication } from "./containers/ExternalPublication/ExternalPublication";
import { NonExhaustiveTabular } from "./containers/NonExhaustiveTabular/NonExhaustiveTabular";
import { FrtAdditionalChart } from "./containers/FrtChart/FrtAdditionalChart";
//import "react-datasheet/lib/react-datasheet.css";
import "./App.css";

export const App = () => {
    return (
        <Layout>
            <Switch>
                <Route path="/unauthorized" component={Unauthorized} />
                <Route path="/user-actions/manage-users" component={UsersDashboard} />
                <Route path="/user-actions/user-activity" component={UserActivity} />
                <Route path="/user-actions/external-publications" component={ExternalPublication} />
                <Route path="/input-data-editor" component={InputDataEditor} />
                <Route path="/forms/overview/data-delivery/:formId" component={DataDelivery} />
                <Route path="/forms/overview/edit/:id" component={FormEditor} />
                <Route path="/forms/create" component={CreateForm} />
                <Route path="/forms/overview" component={FormsOverview} />
                <Route path="/forms/results" component={FormsResults} />
                <Route path="/figures" component={AdminFigures} />
                <Route path="/reports/data-view/frt-additional" component={FrtAdditionalChart} />
                <Route path="/reports/data-view/:chartType?" component={DataView} />
                <Route path="/reports/download" component={NclpReportDownload} />
                <Route path="/reports/non-exhaustive-parameters" component={NonExhaustiveTabular} />
                <Route exact path="/reports/overview" component={ReportsOverview} />
                <Route exact path="/reports/overview/**" component={Report} />
                <Route component={Home} />
            </Switch>
        </Layout>
    );
};
