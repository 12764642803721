import { AdminForms } from "../../components/AdminForms/AdminForms";
import { ReportsCarousel } from "../../components/ReportsCarousel/ReportsCarousel";
import { NewsFeed } from "./NewsFeed";
import "./Dashboard.css";

export function AdminDashboard() {
    return (
        <div>
            <div id="news-feed" className="home-section">
                <NewsFeed />
            </div>

            <div className="home-section">
                <div className="section-title">
                    <h1>Forms under development</h1>
                </div>
                <AdminForms />
                <br />
                <br />
                <div className="section-title">
                    <h1>Reports under development</h1>
                </div>
                <ReportsCarousel />
            </div>
        </div>
    );
}
