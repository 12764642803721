import { Form } from "semantic-ui-react";
import SemanticDatepicker from "react-semantic-ui-datepickers";
import { IReport } from "../../models/report";
import { useEffect } from "react";
import { SemanticDatepickerProps } from "react-semantic-ui-datepickers/dist/types";
import { isEmpty } from "lodash";

interface IProps {
    report: Partial<IReport>;
    setReport: (report: Partial<IReport>) => void;
    saveChanges?: (report: Partial<IReport>) => void;
}

export const ReportBasicForm = ({ report, setReport, saveChanges }: IProps) => {
    useEffect(() => {
        if (!isEmpty(report) && report.id !== undefined) {
            saveChanges?.(report);
        }
    }, [report, saveChanges]);

    return (
        <Form>
            <Form.Field>
                <label>Title</label>
                <input
                    placeholder="Enter report title"
                    id="title"
                    type="text"
                    value={report.title ?? ""}
                    onChange={e => setReport({ ...report, title: e.target.value })}
                />
            </Form.Field>
            <Form.Field>
                <label>Version (Draft 1, Draft 2, Final, ...)</label>
                <input
                    placeholder="Enter report version"
                    id="title"
                    value={report.version ?? ""}
                    onChange={e => setReport({ ...report, version: e.target.value })}
                />
            </Form.Field>
            <Form.Field>
                <label>Date (Of this version)</label>
                <SemanticDatepicker
                    format="DD.MM.YYYY."
                    value={
                        report?.date === null ||
                        report?.date === undefined ||
                        report?.date === "" ||
                        report?.date === "01.01.0001. 00:00:00" ||
                        report?.date.startsWith("190")
                            ? null
                            : new Date(report.date)
                    }
                    onChange={(
                        event: React.SyntheticEvent<Element, Event> | undefined,
                        data: SemanticDatepickerProps
                    ) => {
                        if (event !== undefined) {
                            let utcDate = undefined;
                            if (data.value != null) {
                                utcDate = `${(data.value as Date).getFullYear()}-${
                                    (data.value as Date).getMonth() + 1
                                }-${(data.value as Date).getDate()}`;
                                setReport({ ...report, date: utcDate });
                            } else {
                                setReport({ ...report, date: undefined });
                            }
                        }
                    }}
                />
            </Form.Field>
            <Form.Field>
                <label>From (IMT, StG CNC, ...)</label>
                <input
                    placeholder="Enter report from"
                    id="title"
                    value={report.from ?? ""}
                    onChange={e => setReport({ ...report, from: e.target.value })}
                />
            </Form.Field>
        </Form>
    );
};
