import { useSelector } from "react-redux";
import { AppState } from "../../store/configureStore";
import { Image } from "semantic-ui-react";
import entsoeImg from "../../images/entsoe_fp_vision.jpg";
import { UserRole } from "../../actions/authentificationActions";
import { NCLPDashboard } from "../Dashboard/NCLPDashboard";
import { AdminDashboard } from "../Dashboard/AdminDashboard";
import { ViewerDashboard } from "../Dashboard/ViewerDashboard";
import "./Home.css";

export const Home = () => {
    const user = useSelector((state: AppState) => state.user);

    return (
        <div className="home-container">
            <div className="home-header">
                <Image src={entsoeImg} alt="entsoe welcome image" />
                <h1>Welcome to Implementation Monitoring Platform!</h1>
            </div>

            {(() => {
                switch (user.role) {
                    case UserRole.Admin:
                        return <AdminDashboard />;
                    case UserRole.NCLP:
                        return <NCLPDashboard />;
                    case UserRole.Viewer:
                        return <ViewerDashboard />;
                    default:
                        break;
                }
            })()}
        </div>
    );
};
