import { UserData } from "../actions/authentificationActions";
import { IUserFilter } from "../containers/UsersDashboard/UsersDashboard";
import { INewUser } from "../models/user";
import { getAuthorizationProperties } from "./authorizationHelper";

export async function getUsers(): Promise<UserData[]> {
    return await fetch("/users", {
        method: "GET",
        ...getAuthorizationProperties({ "Content-Type": "application/json" }),
    }).then(res => {
        return res.json();
    });
}

export async function getFilteredUsers(filter: IUserFilter): Promise<UserData[]> {
    return await fetch(`/users/filtered`, {
        method: "POST",
        ...getAuthorizationProperties({
            "Content-Type": "application/json",
        }),
        body: JSON.stringify(filter),
    }).then(res => {
        return res.json();
    });
}

export async function createUser(userDto: INewUser): Promise<UserData> {
    return await fetch("/users", {
        method: "POST",
        ...getAuthorizationProperties({ "Content-Type": "application/json" }),
        body: JSON.stringify(userDto),
    }).then(res => {
        return res.json();
    });
}

export async function countryPartSelect(partGuid: string): Promise<UserData> {
    const res = await fetch(`/users/partSelect/${partGuid}`, {
        ...getAuthorizationProperties({ "Content-Type": "application/json" }),
    });
    const user: UserData = await res.json();
    if (user.token) {
        sessionStorage.setItem("user", JSON.stringify(user));
    }
    return user;
}

export async function deleteUser(guid: string): Promise<Response> {
    return await fetch("/users/" + guid, {
        method: "DELETE",
        ...getAuthorizationProperties({ "Content-Type": "application/json" }),
    });
}

export async function changeUserRole(guid: string, role: string): Promise<Response> {
    return await fetch(`/users/${guid}/changeRole/${role}`, {
        method: "PATCH",
        ...getAuthorizationProperties({ "Content-Type": "application/json" }),
    });
}

export async function changeUserCountry(guid: string, countryGuid: string): Promise<Response> {
    return await fetch(
        `/users/${guid}/changeCountry?${countryGuid ? `countryGuid=${countryGuid}` : ""}`,
        {
            method: "PATCH",
            ...getAuthorizationProperties({ "Content-Type": "application/json" }),
        }
    );
}

export async function changeUserActive(guid: string, active: boolean): Promise<Response> {
    return await fetch(`/users/${guid}/changeActive/${active}`, {
        method: "PATCH",
        ...getAuthorizationProperties({ "Content-Type": "application/json" }),
    });
}

export async function changeUserFirstName(guid: string, firstName: string): Promise<Response> {
    return await fetch(`/users/${guid}/changeFirstName/${firstName}`, {
        method: "PATCH",
        ...getAuthorizationProperties({ "Content-Type": "application/json" }),
    });
}

export async function changeUserLastName(guid: string, lastName: string): Promise<Response> {
    return await fetch(`/users/${guid}/changeLastName/${lastName}`, {
        method: "PATCH",
        ...getAuthorizationProperties({ "Content-Type": "application/json" }),
    });
}
