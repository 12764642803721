import { IPaginationWrapper } from "../models/common";
import { getAuthorizationProperties } from "./authorizationHelper";

export async function getNewsFeedItems(page: number, items: number): Promise<IPaginationWrapper> {
    const params = new URLSearchParams({ page: page.toString(), items: items.toString() });
    return await fetch("/api/newsFeed?" + params.toString(), {
        method: "GET",
        ...getAuthorizationProperties({ "Content-Type": "application/json" }),
    }).then(res => {
        return res.json();
    });
}

export async function getNewsFeedItemCountSince(ts: string): Promise<number> {
    const params = new URLSearchParams({ lastCheck: ts });
    return await fetch("/api/newsFeed/since?" + params.toString(), {
        method: "GET",
        ...getAuthorizationProperties({ "Content-Type": "application/json" }),
    }).then(res => {
        return res.json();
    });
}
