import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "semantic-ui-react";
import { loginRequest } from "../../actions/authentificationActions";
import { AppState } from "../../store/configureStore";
import { RedirectIfLoggedIn } from "./RedirectIfLoggedIn";
import "./Login.css";
import { useHistory } from "react-router-dom";
import Logo from "../../images/entsoe_logo.svg";
import { getKeycloakRealm, keycloakLogout } from "../../services/authenticationService";

export const Login = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const loginState = useSelector((state: AppState) => state.login);
    const [realm, setRealm] = useState("");
    const [loading, setLoading] = useState(false);
    const keycloakAuthUrl = `https://keycloak.entsoe.eu/auth/realms/IMP/protocol/openid-connect/auth?client_id=${realm}&scope=openid&response_type=code&redirect_uri=${window.location.origin}/login`;

    const fetchKeycloakRealm = useCallback(async () => {
        keycloakLogout();
        setLoading(true);
        try {
            const newRealm = await getKeycloakRealm();
            if (newRealm.length === 0) console.error("Realm is not defined");
            else setRealm(newRealm);
        } catch (err) {
            console.error(err);
        } finally {
            setLoading(false);
        }
    }, []);

    useEffect(() => {
        if (window.location.search.length > 0) {
            setLoading(true);
            const urlParams = new URLSearchParams(window.location.search);
            const code = urlParams.get("code") ?? "";
            dispatch(loginRequest({ username: "", password: "", code: code }));
            setLoading(false);
        }
    }, [dispatch]);

    useEffect(() => {
        if (window.location.hostname === "localhost" && window.location.pathname === "/login") {
            history.push("/login/admin");
        } else if (
            window.location.origin.includes("solutions") ||
            window.location.origin.includes("entsoe")
        )
            fetchKeycloakRealm();
    }, [history, fetchKeycloakRealm]);

    return (
        <div className="login-container">
            <RedirectIfLoggedIn />
            <div className="login-wrapper">
                <img src={Logo} alt="Logo" />
                <section className="login-form-container">
                    <h1 style={{ textAlign: "center" }}>Implementation Monitoring Platform</h1>
                    {!loginState.isEnabled ? (
                        <div id="login-locked-message">
                            <h4>Your account is disabled.</h4>
                            <h4>Please contact your administrator to activate your account.</h4>
                        </div>
                    ) : (
                        <>
                            <h4 id="centered-text">Please log in to your account</h4>
                            <Button
                                fluid
                                primary
                                onClick={() => window.location.replace(keycloakAuthUrl)}
                                disabled={
                                    realm.length === 0 || loading || loginState.currentlySending
                                }
                                loading={loading || loginState.currentlySending}
                                content="Log in"
                            />
                        </>
                    )}
                </section>
            </div>
        </div>
    );
};
