import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Button, Dropdown, Icon, Menu, Modal } from "semantic-ui-react";
import { logout, setUser, UserData, UserRole } from "../../actions/authentificationActions";
import { AppState } from "../../store/configureStore";
import { NotificationsCount } from "../../components/NotificationsCount/NotificationsCount";
import Logo from "../../images/entsoe_logo.svg";
import "./Header.css";
import { useEffect, useState } from "react";
import { ICountry } from "../../models/country";
import { getParts } from "../../services/countriesService";
import { countryPartSelect } from "../../services/usersService";
import { toast } from "../..";

export const AdminHeader = () => {
    const dispatch = useDispatch();
    const user = useSelector((state: AppState) => state.user);
    const [selectionModalOpen, setSelectionModalOpen] = useState(false);
    const [parts, setParts] = useState<ICountry[]>([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (user.countryGuid) {
            getParts(user.countryGuid).then(setParts);
        }
    }, [user.countryGuid]);

    const handleSwitchToNCLP = (userInfo: UserData) => {
        const newUser = { ...userInfo, role: UserRole.NCLP, actualRole: userInfo.role } as UserData;
        if (newUser.token) {
            sessionStorage.setItem("user", JSON.stringify(newUser));
        }
        dispatch(setUser(newUser));
    };

    const handleCountrySelect = async (partGuid: string) => {
        try {
            setLoading(true);
            const newUserInfo = await countryPartSelect(partGuid);
            handleSwitchToNCLP(newUserInfo);
        } catch {
            toast("Error while switching to NCLP", false, 1000);
        } finally {
            setLoading(false);
        }
    };

    return (
        <>
            <Menu secondary>
                <Menu.Item as={Link} to="/" header>
                    <div id="logo-container">
                        <img src={Logo} alt="Logo" />
                        <p id="imp-logo-text">Implementation Monitoring Platform</p>
                    </div>
                </Menu.Item>

                <Dropdown item text="Forms" icon="chevron down">
                    <Dropdown.Menu>
                        <Menu.Item as={Link} to="/forms/overview" content="Overview" />
                        <Menu.Item as={Link} to="/forms/results" content="Results" />
                    </Dropdown.Menu>
                </Dropdown>

                <Menu.Item as={Link} to="/figures" content="Figures" />

                <Dropdown item text="Reports" icon="chevron down">
                    <Dropdown.Menu>
                        <Menu.Item
                            as={Link}
                            to="/reports/overview"
                            content="Yearly monitoring reports"
                        />
                        <Menu.Item
                            as={Link}
                            to="/reports/non-exhaustive-parameters"
                            content="Non-exhaustive parameters"
                        />
                        <Dropdown item text="Dynamic graphs" icon="chevron right">
                            <Dropdown.Menu>
                                <Menu.Item
                                    as={Link}
                                    to="/reports/data-view/generation-capacity"
                                    content="Generation and HVDC capacities"
                                />
                                <Menu.Item
                                    as={Link}
                                    to="/reports/data-view/frt"
                                    content="FRT non-exhaustive parameters"
                                />
                                <Menu.Item
                                    as={Link}
                                    to="/reports/data-view/frt-additional"
                                    content="Other non-exhaustive parameters"
                                />
                            </Dropdown.Menu>
                        </Dropdown>
                    </Dropdown.Menu>
                </Dropdown>

                <Dropdown item text="Users" icon="chevron down">
                    <Dropdown.Menu>
                        <Menu.Item as={Link} to="/user-actions/manage-users" content="Management" />
                        <Menu.Item as={Link} to="/user-actions/user-activity" content="Activity" />
                        <Menu.Item
                            as={Link}
                            to="/user-actions/external-publications"
                            content="External publication"
                        />
                    </Dropdown.Menu>
                </Dropdown>

                <Menu.Item position="right" as={Link} to="/">
                    <NotificationsCount />
                </Menu.Item>
                <Menu.Item as={Link} to="/">
                    <span>{user.displayName}</span>
                    <Icon name="user circle" size="large" />
                </Menu.Item>
                {user.countryGuid && (
                    <Menu.Item
                        onClick={
                            user.countryAbbreviation === "DK" || user.countryAbbreviation === "IE"
                                ? () => setSelectionModalOpen(true)
                                : () => handleSwitchToNCLP(user)
                        }
                    >
                        <span>View as NCLP</span>
                    </Menu.Item>
                )}
                <Menu.Item onClick={() => dispatch(logout())}>
                    <span>Logout</span>
                    <Icon name="sign-out" size="large" />
                </Menu.Item>
            </Menu>
            {/* modal for region selection */}
            <Modal
                className="region-select-modal"
                open={selectionModalOpen}
                size="tiny"
                closeOnDimmerClick={false}
                closeIcon
                onClose={() => setSelectionModalOpen(false)}
            >
                <Modal.Header>Select your region</Modal.Header>
                <Modal.Actions>
                    {parts.map(p => {
                        return (
                            <Button
                                key={p.id}
                                onClick={() => {
                                    handleCountrySelect(p.id);
                                    setSelectionModalOpen(false);
                                }}
                                loading={loading}
                            >
                                {p.name}
                            </Button>
                        );
                    })}
                </Modal.Actions>
            </Modal>
        </>
    );
};
