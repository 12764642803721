import { DropdownItemProps } from "semantic-ui-react";

export const figureSourceTypeOptions: DropdownItemProps[] = [
    { key: 0, text: "Uploaded", value: "0" },
    { key: 1, text: "Generated", value: "1" },
];

export enum EChartRequestState {
    NoInfo = 0,
    InProgress = 1,
    Canceled = 2,
    Failed = 3,
}

export interface IChartGenerationInfo {
    chartsGenerated: number;
    totalCharts: number;
    chartRequestState: number;
}

export interface ChartGenerationRequest {
    guid: string;
}

export interface IChartInfoData {
    year: string;
    formName: string;
    countries: string[];
    sections: string[];
}

export interface IChartInfoDataSingle {
    figureId: string;
    labels: Record<string, string>;
    countryGuids: string[];
}

export interface IFigureData {
    name: string;
    placeholderName?: string;
    year: string;
    category: string;
    formName: string;
}

export const figureDataInit: IFigureData = {
    name: "",
    year: "",
    category: "",
    formName: "",
};

export interface IFigureFilter extends IFigureData {
    source: string;
}

export interface IFigureDto extends IFigureData {
    id: string;
    figureType: number;
    graphType: string;
    originalQuestionId: string;
}

export const figureDtoInit: IFigureDto = {
    id: "",
    name: "",
    year: "",
    category: "",
    formName: "",
    figureType: -1,
    graphType: "",
    originalQuestionId: "",
};

export const figureFilterInit: IFigureFilter = {
    name: "",
    year: "",
    category: "",
    formName: "",
    source: "",
};
