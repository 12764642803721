import { useCallback, useEffect, useRef, useState } from "react";
import { Link, useParams, useHistory } from "react-router-dom";
import { Button, Card, Icon, Menu, Confirm, Modal, Checkbox, Loader } from "semantic-ui-react";
import { QuestionWrapper } from "../../components/QuestionWrapper/QuestionWrapper";
import generateGuid from "../../helpers/guid";
import { IForm, initNewForm } from "../../models/form";
import { IQuestion } from "../../models/question";
import { ISection } from "../../models/section";
import { dismissWarning, editForm, getForm, publishForm } from "../../services/formsService";
import {
    getSection,
    updateSectionQuestions,
    postSection,
    putSection,
    deleteSection,
} from "../../services/sectionsService";
import { CreateAndEditForm } from "./CreateAndEditForm";
import { SectionEditor } from "./SectionEditor";
import { DragDropContext, Droppable, Draggable, DropResult } from "react-beautiful-dnd";
import { toast } from "../..";
import { IOption } from "../../models/option";
import { Breadcrumbs } from "../../components/Breadcrumbs/Breadcrumbs";
import "./FormEditor.css";

export const FormEditor = () => {
    const params: { id: string } = useParams();
    const history = useHistory();
    const [activeSection, setActiveSection] = useState<ISection>();
    const [activeSectionEdit, setActiveSectionEdit] = useState(false);
    const [questions, setQuestions] = useState<IQuestion[]>([]);
    const [form, setForm] = useState<IForm>();
    const [open, setOpen] = useState(false);
    const [dialogOpened, setOpenDialog] = useState(false);
    const [confirmDialogMessage, setConfirmDialogMessage] = useState<string>();
    const [newForm, setNewForm] = useState(initNewForm);
    const [sectionModalOpen, setSectionModalOpen] = useState(false);
    const [sectionDeleteOpen, setSectionDeleteOpen] = useState(false);
    const [questionBeingDeleted, setQuestionBeingDeleted] = useState("");
    const [activeEditQuestion, setActiveEditQuestion] = useState("");
    const [reorderModalOpen, setReorderModalOpen] = useState(false);
    const [disconnectedWarningModalOpen, setDisconnectedWarningModalOpen] = useState(false);
    const [dismissChecked, setDismissChecked] = useState(false);
    const [reorderedQuestions, setReorderedQuestions] = useState<IQuestion[]>([]);
    const [brokenOptions, setBrokenOptions] = useState<IOption[]>([]);
    const [loadingQuestions, setLoadingQuestions] = useState(true);
    const [publishTooltip, setPublishTooltip] = useState("");
    const val = useRef<ISection>();

    const fetchForm = useCallback(
        async (updateActiveSection = true) => {
            setLoadingQuestions(true);
            const res = await getForm(params.id);
            if (res.id) {
                if (res.sections.length > 0 && updateActiveSection) {
                    setActiveSection(res.sections[0]);
                    if (res.sections[0].questions != null) {
                        setQuestions(res.sections[0].questions);
                    }
                } else {
                    setActiveSection(undefined);
                    setQuestions([]);
                }
                setForm(res);
                setNewForm(res);
                setLoadingQuestions(false);
            }
        },
        [params.id]
    );

    useEffect(() => {
        fetchForm();
    }, [fetchForm]);

    useEffect(() => {
        if (activeSection) val.current = { ...activeSection, questions };
    }, [activeSection, questions]);

    useEffect(() => {
        return () => {
            if (val.current) {
                updateSectionQuestions(val.current.id, val.current.questions);
            }
        };
    }, []);

    useEffect(() => {
        if (form) {
            let deadlineDate = new Date(form.deadline);
            let todayDate = new Date();

            if (form.sameRootPublished) {
                /*setPublishTooltip(
                    "Form with same hard copy root has already been published this year."
                );*/
            } else if (deadlineDate < todayDate) {
                setPublishTooltip(
                    "Form must have a set deadline in the future. Check the deadline in the form settings."
                );
            } else {
                setPublishTooltip("");
            }
        }
    }, [form]);

    const setSection = async (id: string) => {
        if (activeSection) {
            updateSectionQuestions(activeSection.id, questions);
        }
        let section = await getSection(id);
        setActiveSection(section);
        setQuestions(section.questions);
    };

    const addSection = (newSection: Partial<ISection>) => {
        postSection(newSection)
            .then(res => {
                fetchForm(false).then(() => {
                    setSection(res.id);
                });
                setSectionModalOpen(false);
                toast("Section created successfully", true, 1000);
            })
            .catch(() => toast("Error while creating section", false, 1000));
    };

    const updateSection = (updatedSection: Partial<ISection>) => {
        updatedSection.id = activeSection?.id;
        putSection(updatedSection)
            .then(res => {
                fetchForm(false).then(r => {
                    setActiveSectionEdit(false);
                    setSection(res.id);
                });
                toast("Section successfully updated", true, 1000);
            })
            .catch(() => toast("Error while updating section", false, 1000));
    };

    const deleteActiveSection = () => {
        deleteSection(activeSection?.id || "").then(res => {
            fetchForm();
            setSectionDeleteOpen(false);
        });
    };

    const addQuestion = () => {
        if (activeSection) {
            updateSectionQuestions(activeSection.id, questions);
        }

        let newOrderNum = Math.max(...questions.map(q => q.orderNumber), 0) + 1;

        let newQuestion: IQuestion = {
            id: generateGuid(),
            text: "",
            type: "ShortText",
            required: false,
            branching: false,
            fileUpload: false,
            other: false,
            section: activeSection!.id,
            orderNumber: newOrderNum,
            options: [],
            hasParent: false,
        };

        setQuestions([...questions, newQuestion]);
        setActiveEditQuestion(newQuestion.id);
    };

    const copyQuestion = (question: IQuestion) => {
        if (activeSection) {
            updateSectionQuestions(activeSection.id, questions);
        }

        let newOptions = question.options.map(o => {
            return { ...o, id: generateGuid() };
        });

        let newQuestion: IQuestion = {
            id: generateGuid(),
            text: question.text,
            type: question.type,
            remark: question.remark,
            required: question.required,
            branching: question.branching,
            fileUpload: question.fileUpload,
            imageGuid: question.imageGuid,
            section: activeSection!.id,
            orderNumber: question.orderNumber + 0.1,
            options: newOptions,
            other: question.other,
            hasParent: false,
            groups: [],
            subQuestions: [],
        };

        question.groups?.forEach(g => {
            newQuestion.groups?.push({ ...g, id: generateGuid(), questionId: newQuestion.id });
        });

        question.subQuestions?.forEach(sq => {
            let oldGroup = question.groups?.find(g => sq.groupId === g.id);
            newQuestion.subQuestions?.push({
                ...sq,
                id: generateGuid(),
                groupId: newQuestion.groups?.find(
                    g =>
                        g.name === oldGroup?.name &&
                        g.backgroundColorHex === oldGroup.backgroundColorHex &&
                        g.textColorHex === oldGroup.textColorHex
                )?.id,
            });
        });

        let newQuestions = questions;
        newQuestions.push(newQuestion);
        newQuestions.sort((a, b) => a.orderNumber - b.orderNumber);
        newQuestions.forEach((q, i) => {
            q.orderNumber = i + 1;
        });
        setQuestions(newQuestions);
        setActiveEditQuestion(newQuestion.id);
    };

    const isBranchingTarget = (id: string) => {
        let result = false;
        questions
            .filter(q => q.branching)
            .forEach(question => {
                question.options
                    .filter(o => o.branchingAction === "SkipToQuestion")
                    .forEach(option => {
                        if (option.branchTarget === id) {
                            result = true;
                        }
                    });
            });
        return result;
    };

    const safeDeleteQuestion = (id: string) => {
        if (isBranchingTarget(id)) {
            setQuestionBeingDeleted(id);
        } else {
            deleteQuestion(id);
        }
    };

    const deleteQuestion = (id: string, clearTargets = false) => {
        let newQuestions = questions.filter(o => o.id !== id);
        newQuestions.forEach((q, i) => {
            q.orderNumber = i + 1;
        });

        if (clearTargets) {
            newQuestions
                .filter(q => q.branching)
                .forEach(q => {
                    q.options
                        .filter(o => o.branchingAction === "SkipToQuestion")
                        .filter(o => o.branchTarget === id)
                        .forEach(o => {
                            o.branchingAction = undefined;
                            o.branchTarget = undefined;
                        });
                });
        }

        setQuestions([...newQuestions]);
        setQuestionBeingDeleted("");

        if (activeSection) {
            updateSectionQuestions(activeSection.id, newQuestions);
        }
    };

    const updateQuestion = (question: IQuestion) => {
        let newQuestions = questions.filter(q => q.id !== question.id);
        newQuestions.push(question);
        newQuestions.sort((a, b) => a.orderNumber - b.orderNumber);
        setQuestions(newQuestions);
    };

    const editFormProcedure = async () => {
        if (form !== undefined && newForm !== undefined) {
            let res = null;
            try {
                res = await editForm(form.id, newForm);
                if (res.id) {
                    setForm(res);
                }
                toast("Form edited successfully", true, 1000);
                setOpen(false);
            } catch (error) {
                if (res !== null) toast("Error while editing form", false, 1000);
            }
        }
    };

    const handlePublish = async () => {
        setOpenDialog(false);
        try {
            await publishForm(form!.id);
            toast("Form published successfully", true, 1000);
            history.push("/forms");
        } catch (error) {
            toast("Form did not publish", false, 1000);
        }
        setOpen(false);
    };

    const getReorderedQuestions = (source: number, destination: number) => {
        let sourceQuestion = questions.find(q => q.orderNumber === source);
        let destinationQuestion = questions.find(q => q.orderNumber === destination);

        if (sourceQuestion && destinationQuestion) {
            let newSourceQuestion = { ...sourceQuestion, orderNumber: destination };
            let newDestinationQuestion = {
                ...destinationQuestion,
                orderNumber: destinationQuestion.orderNumber + (source - destination) * 0.001,
            };
            let newQuestions = questions.map(q => {
                return { ...q };
            });
            newQuestions = newQuestions.filter(
                q => q.id !== newSourceQuestion.id && q.id !== newDestinationQuestion.id
            );
            newQuestions.push(newDestinationQuestion, newSourceQuestion);
            newQuestions.sort((a, b) => a.orderNumber - b.orderNumber);
            newQuestions.forEach((q, i) => {
                q.orderNumber = i + 1;
            });
            return newQuestions;
        } else {
            return [...questions];
        }
    };

    const findBranchingOutOfOrder = (list: IQuestion[]) => {
        let issues: IOption[] = [];
        list.filter(q => q.branching && q.options && q.options.length > 0).forEach(question => {
            question.options
                .filter(o => o.branchingAction === "SkipToQuestion" && o.branchTarget)
                .forEach(option => {
                    let target: IQuestion | undefined = list.find(
                        q => q.id === option.branchTarget
                    );
                    if (target && target.orderNumber < question.orderNumber) {
                        issues.push(option);
                    }
                });
        });
        return issues;
    };

    const onDragEnd = (result: DropResult) => {
        if (
            result.source &&
            result.destination &&
            result.source.index !== result.destination.index
        ) {
            let newQuestions = getReorderedQuestions(result.source.index, result.destination.index);
            let issues = findBranchingOutOfOrder(newQuestions);
            if (issues && issues.length > 0) {
                setBrokenOptions(issues);
                setReorderedQuestions(newQuestions);
                setReorderModalOpen(true);
            } else {
                if (activeSection?.id) {
                    let oldQuestions: IQuestion[] = [...questions];
                    setQuestions(newQuestions);
                    updateSectionQuestions(activeSection.id, newQuestions).catch(() => {
                        setQuestions(oldQuestions);
                        toast("Unable to complete reordering", false, 1000);
                    });
                }
            }
        }
    };

    const approveReorder = () => {
        brokenOptions.forEach(o => {
            o.branchingAction = undefined;
            o.branchTarget = undefined;
        });

        if (activeSection?.id) {
            let oldQuestions: IQuestion[] = [...questions];
            setQuestions(reorderedQuestions);
            updateSectionQuestions(activeSection.id, reorderedQuestions).catch(() => {
                setQuestions(oldQuestions);
                toast("Unable to complete reordering", false, 1000);
            });
        }

        setBrokenOptions([]);
        setReorderedQuestions([]);
        setReorderModalOpen(false);
    };

    const denyReorder = () => {
        setBrokenOptions([]);
        setReorderedQuestions([]);
        setReorderModalOpen(false);
    };

    const onWarningModalClosed = async () => {
        if (dismissChecked && form) {
            await dismissWarning(form.id);
            const modifiedForm = { ...form, editModalDismissed: true };
            setForm(modifiedForm);
        }
        setDisconnectedWarningModalOpen(false);
    };

    useEffect(() => {
        if (form && form.isHardCopy && !form.editModalDismissed) {
            const active = questions.find(q => q.id === activeEditQuestion);
            if (
                active &&
                active.isLinked &&
                (active.type === "MultipleChoice" || active.type === "Checkboxes")
            ) {
                setDismissChecked(false);
                setDisconnectedWarningModalOpen(true);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activeEditQuestion, form?.editModalDismissed]);

    return (
        <div className="form-editor-container">
            <div className="container-header">
                <h1>{form?.name}</h1>
                <div className="form-editor-actions">
                    <div className="parent-tooltip">
                        <div
                            hidden={publishTooltip === ""}
                            className="child-tooltip"
                            data-tooltip={publishTooltip}
                            data-position="top center"
                        />
                        <Button
                            primary
                            content="Publish"
                            disabled={publishTooltip !== ""}
                            onClick={() => {
                                let deadlines = newForm?.specialDeadlines
                                    ?.map(sd => sd.deadline)
                                    .filter(d => d && new Date(d) < new Date());
                                setConfirmDialogMessage(
                                    deadlines?.length
                                        ? `Some special deadlines are already expired. Are you sure you want to publish
                                this form with those values?`
                                        : undefined
                                );
                                setOpenDialog(true);
                            }}
                        />
                    </div>
                    <Confirm
                        open={dialogOpened}
                        header={"Publish Form"}
                        confirmButton="Publish"
                        content={confirmDialogMessage}
                        onCancel={() => setOpenDialog(false)}
                        onConfirm={(e, data: any) => handlePublish()}
                    />
                    <Button icon as={Link} to={`/forms/view/${form?.id}`} labelPosition="left">
                        <Icon name="eye" />
                        Preview
                    </Button>
                    <Button icon labelPosition="left" onClick={() => setOpen(true)}>
                        <Icon name="settings" />
                        Settings
                    </Button>
                </div>
            </div>
            <Breadcrumbs />
            <div className="editor">
                <div className="section-container">
                    <DragDropContext onDragEnd={onDragEnd}>
                        <Card.Group>
                            <div className="action-bar-container question-wrapper-container editor section">
                                <Card fluid className="question-wrapper">
                                    <Card.Content>
                                        <Card.Header>{activeSection?.name}</Card.Header>
                                    </Card.Content>
                                </Card>
                                {activeSection != null && (
                                    <div className="action-bar-container-options question-options">
                                        <Icon
                                            size="large"
                                            name="edit"
                                            onClick={() => setActiveSectionEdit(true)}
                                            bordered
                                        />
                                        <Icon
                                            size="large"
                                            name="delete"
                                            onClick={() => setSectionDeleteOpen(true)}
                                            bordered
                                        />
                                    </div>
                                )}
                            </div>

                            <Loader active={loadingQuestions} inline="centered" />
                            <Droppable droppableId="droppable">
                                {(provided, snapshot) => (
                                    <div ref={provided.innerRef} {...provided.droppableProps}>
                                        {questions?.map(q => (
                                            <Draggable
                                                key={q.id}
                                                draggableId={q.id}
                                                index={q.orderNumber}
                                                isDragDisabled={activeEditQuestion === q.id}
                                            >
                                                {(prov, snaps) => (
                                                    <div
                                                        ref={prov.innerRef}
                                                        {...prov.draggableProps}
                                                        {...prov.dragHandleProps}
                                                    >
                                                        <QuestionWrapper
                                                            inEditor={true}
                                                            question={q}
                                                            otherQuestions={questions}
                                                            saveChanges={updateQuestion}
                                                            hardSave={async () => {
                                                                if (
                                                                    activeSection?.id &&
                                                                    questions
                                                                ) {
                                                                    await updateSectionQuestions(
                                                                        activeSection.id,
                                                                        questions
                                                                    );
                                                                }
                                                            }}
                                                            deleteQuestion={safeDeleteQuestion}
                                                            activeEditQuestion={activeEditQuestion}
                                                            setActiveEditQuestion={
                                                                setActiveEditQuestion
                                                            }
                                                            copyQuestion={copyQuestion}
                                                            isFormHardCopy={form?.isHardCopy}
                                                        />
                                                    </div>
                                                )}
                                            </Draggable>
                                        ))}
                                        {provided.placeholder}
                                    </div>
                                )}
                            </Droppable>
                        </Card.Group>
                    </DragDropContext>

                    <Button
                        className={"add-question question-wrapper"}
                        primary
                        fluid
                        onClick={addQuestion}
                    >
                        Add question
                    </Button>
                </div>

                <Menu vertical>
                    {form?.sections.map(s => (
                        <Menu.Item
                            key={s.id}
                            position="right"
                            name={s.name}
                            active={activeSection?.id === s.id}
                            onClick={setSection.bind(null, s.id)}
                        >
                            {s.name}
                        </Menu.Item>
                    ))}
                    <Menu.Item position="right" icon onClick={() => setSectionModalOpen(true)}>
                        <Icon name="add circle" />
                        Add section
                    </Menu.Item>
                </Menu>
            </div>

            <CreateAndEditForm
                header="Edit Form"
                cancelAddForm={() => setOpen(false)}
                createFormProcedure={editFormProcedure}
                form={newForm}
                handleClose={() => setOpen(false)}
                setForm={setNewForm}
                open={open}
                confirmString="Edit"
            ></CreateAndEditForm>

            <Modal open={sectionDeleteOpen}>
                <Modal.Header>Are you sure you want to delete this section?</Modal.Header>
                <Modal.Content>
                    Deleting the section will delete all the questions in the section.
                </Modal.Content>
                <Modal.Actions>
                    <Button onClick={() => setSectionDeleteOpen(false)}>Cancel</Button>
                    <Button onClick={deleteActiveSection}>Delete</Button>
                </Modal.Actions>
            </Modal>

            <Modal open={questionBeingDeleted.length > 0}>
                <Modal.Header>Are you sure you want to delete this question?</Modal.Header>
                <Modal.Content>
                    This question is selected as a branching target in some multiple choice
                    questions. Deleting it will cause those options to have "Next" selected as
                    branching action.
                </Modal.Content>
                <Modal.Actions>
                    <Button onClick={() => setQuestionBeingDeleted("")}>Cancel</Button>
                    <Button onClick={deleteQuestion.bind(null, questionBeingDeleted, true)}>
                        Delete
                    </Button>
                </Modal.Actions>
            </Modal>

            <Modal open={reorderModalOpen}>
                <Modal.Header>Are you sure you want move selected question?</Modal.Header>
                <Modal.Content>
                    Reordering the questions this way will cause some branching actions to be set to
                    "Next" because the target question will appear before the multiple choice
                    question in the form.
                </Modal.Content>
                <Modal.Actions>
                    <Button onClick={denyReorder}>Cancel</Button>
                    <Button onClick={approveReorder}>Approve</Button>
                </Modal.Actions>
            </Modal>

            <Modal open={disconnectedWarningModalOpen}>
                <Modal.Header>Linked question</Modal.Header>
                <Modal.Content>
                    This question is linked to the same question in the original form. Editing the
                    question might cause some NCLP users to not be able to copy
                    their previous answers automatically!
                    <div className="dismiss-container">
                        <Checkbox
                            value="dismiss"
                            checked={dismissChecked}
                            onClick={() => setDismissChecked(!dismissChecked)}
                        />
                        <label>Dont show this message again</label>
                    </div>
                </Modal.Content>
                <Modal.Actions>
                    <Button onClick={onWarningModalClosed}>OK</Button>
                </Modal.Actions>
            </Modal>

            <SectionEditor
                creating
                isOpen={sectionModalOpen}
                onCancel={() => setSectionModalOpen(false)}
                onSave={addSection}
                formId={params.id}
                section={{}}
            />
            <SectionEditor
                creating={false}
                isOpen={activeSectionEdit}
                onCancel={() => setActiveSectionEdit(false)}
                onSave={updateSection}
                formId={params.id}
                section={activeSection || {}}
            />
        </div>
    );
};
