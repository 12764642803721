import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { Button, Input, Icon } from "semantic-ui-react";
import { loginRequest } from "../../actions/authentificationActions";
import { AppState } from "../../store/configureStore";
import { RedirectIfLoggedIn } from "./RedirectIfLoggedIn";
import Logo from "../../images/entsoe_logo.svg";

export const LocalLogin = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const loginState = useSelector((state: AppState) => state.login);
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const disabled = username.length === 0 || password.length === 0 || loginState.currentlySending;

    const handleKeyPress = (e: any) =>
        !disabled && (e.key === "Enter" || e.charCode === 13)
            ? dispatch(loginRequest({ username, password, code: "" }))
            : null;

    return (
        <div className="login-container">
            <RedirectIfLoggedIn />
            <div className="login-wrapper">
                <img src={Logo} alt="Logo" />
                <section className="login-form-container">
                    <h1 style={{ textAlign: "center" }}>Implementation Monitoring Platform</h1>
                    {!loginState.isEnabled ? (
                        <div id="login-locked-message">
                            <h4>{loginState.error || "Unable to log in."}</h4>
                            <h4>
                                Please follow the instructions sent to your email address to reset
                                your password and reactivate your account.
                            </h4>
                        </div>
                    ) : (
                        <>
                            <Input
                                id="username"
                                placeholder="Username"
                                value={username}
                                onChange={(e: any) => setUsername(e.target.value)}
                                onKeyPress={(e: any) => handleKeyPress(e)}
                            />
                            <Input
                                id="password"
                                type="password"
                                placeholder="Password"
                                value={password}
                                onChange={(e: any) => setPassword(e.target.value)}
                                onKeyPress={(e: any) => handleKeyPress(e)}
                            />
                            <div className="login-options">
                                <label id="cursor" onClick={() => history.push("/forgot-password")}>
                                    <Icon name="lock" />
                                    Forgot password?
                                </label>
                            </div>
                            <p id="login-error-message">{loginState.error}</p>
                            <hr />
                            <Button
                                fluid
                                primary
                                loading={loginState.currentlySending}
                                disabled={disabled}
                                onClick={() =>
                                    dispatch(loginRequest({ username, password, code: "" }))
                                }
                                content="Log in"
                            />
                        </>
                    )}
                </section>
            </div>
        </div>
    );
};
