import { useCallback, useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { createForm, getInProgressForms, deleteForm } from "../../services/formsService";
import { IForm, initNewForm } from "../../models/form";
import { CreateAndEditForm } from "../FormEditor/CreateAndEditForm";
import { Button, Grid, Card, Modal, Icon } from "semantic-ui-react";
import "./CreateForm.css";
import { toast } from "../..";
import { Breadcrumbs } from "../../components/Breadcrumbs/Breadcrumbs";

export const CreateForm = () => {
    const history = useHistory();
    const [open, setOpen] = useState(false);
    const [newForm, setNewForm] = useState(initNewForm);
    const [inProgressForms, setInProgressForms] = useState<IForm[]>([]);

    const [deleteFormSelected, setDeleteFormSelected] = useState<any>();

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const cancelAddForm = () => {
        handleClose();
        setNewForm({ ...newForm, description: "", name: "", category: "" });
    };

    const createFormProcedure = async () => {
        if (!newForm.name) {
            const res = await createForm(newForm);
            if (res.id) {
                history.push(`/forms/overview/edit/${res.id}`);
            }
        }
    };

    const fetchInProgressForms = useCallback(async () => {
        const forms = await getInProgressForms();
        Array.isArray(forms) && setInProgressForms(forms);
    }, []);

    const deleteInProgressForm = () => {
        deleteForm(deleteFormSelected)
            .then(() => {
                fetchInProgressForms();
                setDeleteFormSelected(null);
                toast("Form deleted successfully", true, 1000);
            })
            .catch(() => toast("Error on deleting form", false, 1000));
    };

    useEffect(() => {
        fetchInProgressForms();
    }, [fetchInProgressForms]);

    return (
        <div className="input-data-editor-container">
            <div className="container-header">
                <h1>Create form</h1>
            </div>
            <Breadcrumbs />
            <Grid stackable columns={4}>
                <Grid.Row>
                    <Grid.Column>
                        <Card onClick={handleClickOpen}>
                            <Card.Content>
                                <Card.Header>Blank</Card.Header>
                                <Card.Meta>Creates a new blank form</Card.Meta>
                                <Card.Description>
                                    <div
                                        style={{
                                            height: "150px",
                                        }}
                                    ></div>
                                </Card.Description>
                            </Card.Content>
                        </Card>
                    </Grid.Column>
                </Grid.Row>
            </Grid>

            <div className="container-header in-progress">
                <h1>In progress</h1>
            </div>

            <Grid stackable columns={3}>
                {inProgressForms.map(x => (
                    <Grid.Column className="form-item-container">
                        <Card as={Link} to={"/forms/overview/edit/" + x.id} className="form-item">
                            <Card.Content>
                                <Card.Header>{x.name}</Card.Header>
                                <Card.Meta>
                                    <div
                                        style={{
                                            maxHeight: "150px",
                                            minHeight: "150px",
                                        }}
                                    >
                                        {x.description}
                                    </div>
                                </Card.Meta>
                            </Card.Content>
                        </Card>
                        <div className="form-options">
                            <Icon
                                size="large"
                                name="delete"
                                onClick={() => setDeleteFormSelected(x.id)}
                                bordered
                            />
                        </div>
                    </Grid.Column>
                ))}
            </Grid>
            <CreateAndEditForm
                header="Create a new Form"
                cancelAddForm={cancelAddForm}
                createFormProcedure={createFormProcedure}
                form={newForm}
                handleClose={handleClose}
                setForm={setNewForm}
                open={open}
                confirmString="Create"
            ></CreateAndEditForm>

            <Modal open={deleteFormSelected != null}>
                <Modal.Header>Are you sure you want to delete this form?</Modal.Header>
                <Modal.Content>
                    Deleting the form will delete all of the section and questions in it.
                </Modal.Content>
                <Modal.Actions>
                    <Button onClick={() => setDeleteFormSelected(null)}>Cancel</Button>
                    <Button onClick={deleteInProgressForm}>Delete</Button>
                </Modal.Actions>
            </Modal>
        </div>
    );
};
