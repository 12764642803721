import { DropdownItemProps } from "semantic-ui-react";
import { IQuestion } from "../models/question";
import { ISection } from "../models/section";
import { getAuthorizationProperties } from "./authorizationHelper";

export async function getSection(id: string): Promise<ISection> {
    return await fetch(`/api/sections/${id}`, {
        method: "GET",
        ...getAuthorizationProperties({
            "Content-Type": "application/json",
        }),
    }).then(res => {
        return res.json();
    });
}

export async function getSectionsOfForm(formId: string): Promise<ISection[]> {
    return await fetch(`/api/sections/form/${formId}`, {
        method: "GET",
        ...getAuthorizationProperties({ "Content-Type": "application/json" }),
    }).then(res => {
        return res.json();
    });
}

export async function updateSectionQuestions(
    id: string,
    questions: IQuestion[]
): Promise<ISection> {
    return await fetch(`/api/sections/${id}/questions`, {
        method: "PATCH",
        ...getAuthorizationProperties({
            "Content-Type": "application/json",
        }),
        body: JSON.stringify(questions),
    }).then(res => {
        if (!res.ok) {
            throw Error;
        }

        return res.json();
    });
}

export async function getQuestionImage(questionId: string) {
    return await fetch(`/api/sections/questionImage/${questionId}`, {
        method: "GET",
        ...getAuthorizationProperties({ "Content-Type": "application/json" }),
    });
}

export async function updateImageForQuestion(questionId: string, image: File | null) {
    const formData = new FormData();
    image && formData.append("image", image, image.name);

    return await fetch(`/api/sections/questionImage/${questionId}`, {
        method: "PATCH",
        ...getAuthorizationProperties(),
        body: formData,
    }).then(res => {
        return res.json();
    });
}

export async function postSection(section: Partial<ISection>): Promise<ISection> {
    return await fetch("/api/sections", {
        method: "POST",
        ...getAuthorizationProperties({ "Content-Type": "application/json" }),
        body: JSON.stringify(section),
    }).then(res => {
        if (res.ok) {
            return res.json();
        }
        throw Error;
    });
}

export async function putSection(section: Partial<ISection>): Promise<ISection> {
    return await fetch("/api/sections", {
        method: "PUT",
        ...getAuthorizationProperties({ "Content-Type": "application/json" }),
        body: JSON.stringify(section),
    }).then(res => {
        if (res.ok) {
            return res.json();
        }
        throw Error;
    });
}

export async function deleteSection(guid: string): Promise<Response> {
    return await fetch(`/api/sections/${guid}`, {
        method: "DELETE",
        ...getAuthorizationProperties({ "Content-Type": "application/json" }),
    });
}

export async function getFormSectionsAsProps(
    formName: string,
    formYear: string
): Promise<DropdownItemProps[]> {
    return await fetch(`/api/sections/props?formName=${formName}&formYear=${formYear}`, {
        method: "GET",
        ...getAuthorizationProperties({ "Content-Type": "application/json" }),
    }).then(res => {
        if (res.ok) {
            return res.json();
        }
        throw Error;
    });
}
