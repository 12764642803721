import { useCallback, useEffect, useState } from "react";
import Carousel from "react-multi-carousel";
import { Loader, Select } from "semantic-ui-react";
import { toast } from "../../..";
import { yearsProps } from "../../../models/common";
import { IReport } from "../../../models/report";
import { getPublicReports } from "../../../services/reportsService";
import { PublishedReportCard } from "../../ReportCard/PublishedReportCard";
import "./PublicReportsOverview.css";

// Prop for Carousel
const responsive = {
    desktop: {
        breakpoint: { max: 4000, min: 200 },
        items: 4,
    },
};

export const PublicReportsOverview = () => {
    const [selectedYear, setSelectedYear] = useState<number>();
    const [reports, setReports] = useState<IReport[]>([]);
    const [loading, setLoading] = useState<boolean>(false);

    const fetchPublicReports = useCallback(async () => {
        try {
            setLoading(true);
            const res = await getPublicReports(selectedYear);
            setReports(res);
        } catch (err) {
            toast("Unable to fetch reports, please try again later..", false, 3000);
        } finally {
            setLoading(false);
        }
    }, [selectedYear]);

    useEffect(() => {
        fetchPublicReports();
    }, [fetchPublicReports]);

    return (
        <div className="public-reports-overview">
            <div className="container-header">
                <h1>Public reports</h1>
                <Select
                    clearable
                    value={selectedYear}
                    options={yearsProps}
                    onChange={(_e, data) => setSelectedYear(data.value as number)}
                />
            </div>

            <Loader active={loading} inline="centered" />
            <Carousel responsive={responsive}>
                {reports.map(report => {
                    return <PublishedReportCard key={report.id} report={report} />;
                })}
            </Carousel>
        </div>
    );
};
