export interface ISendNotification extends Record<string, string | boolean | undefined> {
    id?: string;
    newForm: boolean;
    formSubmittedNclp: boolean;
    formSubmittedAdmin: boolean;
    beforeDeadline: boolean;
    afterDeadline: boolean;
    dataDownloadAvailable: boolean;
}

export const sendNotificationInit: ISendNotification = {
    newForm: false,
    formSubmittedNclp: false,
    formSubmittedAdmin: false,
    beforeDeadline: false,
    afterDeadline: false,
    dataDownloadAvailable: false,
};

export const SendNotificationOptions = [
    { key: "newForm", value: "newForm", text: "New Form" },
    {
        key: "formSubmittedAdmin",
        value: "formSubmittedAdmin",
        text: "Form Submitted Administrator",
    },
    { key: "formSubmittedNclp", value: "formSubmittedNclp", text: "Form Submitted NCLP" },
    { key: "beforeDeadline", value: "beforeDeadline", text: "Before Deadline" },
    { key: "afterDeadline", value: "afterDeadline", text: "After Deadline" },
    {
        key: "dataDownloadAvailable",
        value: "dataDownloadAvailable",
        text: "Data Download Available",
    },
];

export const NotificationsTooltips = [
    {
        key: "newForm",
        value: "newForm",
        text: "Entso-E admin added a new form for you to fill! Deadline is @deadline.",
    },
    {
        key: "formSubmittedAdmin",
        value: "formSubmittedAdmin",
        text: "@country submitted @form_name form.",
    },
    {
        key: "formSubmittedNclp",
        value: "formSubmittedNclp",
        text: "Thank you for submitting the data in time! Please check the data. In case of need for modifications, contact the administrator",
    },
    {
        key: "beforeDeadline",
        value: "beforeDeadline",
        text: "Reminder to fill @form_name form. Only @days days left.",
    },
    {
        key: "dataDownloadAvailable",
        value: "dataDownloadAvailable",
        text: "Data collected through @form_name form is now available for download.",
    },
    {
        key: "afterDeadline",
        value: "afterDeadline",
        text: "Deadline for submitting the @form form has passed. Please contact Admin to get access again.",
    },
];
