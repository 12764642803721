import React, { useCallback, useEffect, useState } from "react";
import { Button, Dropdown, DropdownItemProps, Form, Modal, Popup, Select } from "semantic-ui-react";
import { toast } from "../..";
import { ICountryProps } from "../../models/country";
import staticFilterOptions from "../../components/FormsOverviewTable/staticFilterOptions.json";
import {
    generateFigure,
    pingFiguresRequest,
    cancelFigureGeneration,
} from "../../services/figuresService";
import { IChartInfoData, EChartRequestState } from "../../models/figure";
import { IForm } from "../../models/form";
import { ImpLoading } from "../../components/ImpLoading/ImpLoading";
import { getCountries } from "../../services/countriesService";
import { getBlocks } from "../../services/formsService";
import { getFormSectionsAsProps } from "../../services/sectionsService";

interface IProps {
    generateModalOpen: boolean;
    closeGenerateModal: () => void;
    refreshImages: () => void;
    allFormsWithAttributes: IForm[];
}

const selectAllProp: DropdownItemProps = {
    key: "Select all",
    value: "Select all",
    text: "Select all",
};

export const GenerateModal: React.FC<IProps> = ({
    generateModalOpen,
    closeGenerateModal,
    refreshImages,
    allFormsWithAttributes,
}) => {
    const [formToGenerate, setFormToGenerate] = useState("");
    const [yearToGenerate, setYearToGenerate] = useState("");
    const [countriesToGenerate, setCountriesToGenerate] = useState<string[]>([]);

    const [loading, setLoading] = useState(false);
    const [loadingMessage, setLoadingMessage] = useState("");
    const [cancelLoadingFunc, setCancelLoadingFunc] = useState<() => void>();

    const [countriesAsDropdownProps, setCountriesAsDropdownProps] = useState<ICountryProps[]>([]);
    const [yearDropdownProps, setYearDropdownProps] = useState<DropdownItemProps[]>(
        staticFilterOptions.year
    );
    const [formDropdownProps, setFormDropdownProps] = useState<DropdownItemProps[]>([]);

    const [sectionsAsDropdownProps, setSectionsAsDropdownProps] = useState<DropdownItemProps[]>([]);
    const [sectionsToGenerate, setSectionsToGenerate] = useState<string[]>([]);

    const handleGenerateFigure = async () => {
        const chartInfoData: IChartInfoData = {
            year: yearToGenerate,
            formName: formToGenerate,
            countries: countriesToGenerate,
            sections: sectionsToGenerate,
        };

        setLoadingMessage(
            "Getting necessary data for figure generation. This operation could take a few minutes. Please wait..."
        );
        setLoading(true);

        let response = await generateFigure(chartInfoData);

        if (response.guid) {
            setLoadingMessage(`Figure generation data loaded. Generation starting...`);

            let requestCanceled: boolean = false;
            setCancelLoadingFunc(() => {
                return async () => {
                    let cancelResult = await cancelFigureGeneration(response.guid);

                    if (cancelResult.ok) {
                        setLoading(false);
                        toast(
                            "Figure generation canceled successfully. Only some images were successfully generated.",
                            true,
                            2000
                        );

                        requestCanceled = true;
                    }
                };
            });

            if (response.guid) {
                handleCloseGenerateModal();

                const pingHandler = async () => {
                    const stopPing = () => {
                        setLoading(false);
                        setCancelLoadingFunc(undefined);
                        setTimeout(refreshImages, 2000);
                    };

                    const info = await pingFiguresRequest(response.guid);

                    switch (info.chartRequestState) {
                        case EChartRequestState.InProgress:
                            setLoadingMessage(
                                `Generated ${info.chartsGenerated} figures of total ${info.totalCharts}`
                            );

                            setTimeout(pingHandler, 1000);

                            break;
                        case EChartRequestState.Failed:
                            toast(
                                "Some figures were not generated successfully. Please try again later.",
                                false,
                                5000
                            );
                            stopPing();
                            break;
                        case EChartRequestState.Canceled:
                            stopPing();
                            break;
                        case EChartRequestState.NoInfo:
                            if (!requestCanceled) {
                                toast("Figures successfully generated!", true, 5000);
                            }

                            stopPing();
                            break;
                    }
                };

                pingHandler();
            }
        } else {
            toast("Error while generating figures. Initial data could not be loaded.", false, 2000);
            setLoading(false);
            setCancelLoadingFunc(undefined);
        }
    };

    useEffect(() => {
        const formsArray: DropdownItemProps[] = [];
        allFormsWithAttributes
            .filter(alwa => alwa.categoryObj?.name !== "Generation Capacity")
            .forEach(form => {
                if (!formsArray.some(e => e.value === form.name)) {
                    formsArray.push({ key: form.id, text: form.name, value: form.name });
                }
            });
        setFormDropdownProps(formsArray);
    }, [allFormsWithAttributes]);

    const handleSelectCountriesToGenerate = (e: any, data: any) => {
        if (data.value.includes("selectAll")) {
            const allCountries: string[] = countriesAsDropdownProps
                .slice(2, countriesAsDropdownProps.length)
                .filter(item => item.value)
                .map(item => item.value!.toString());
            setCountriesToGenerate(allCountries);
        } else if (data.value.includes("EU")) {
            const euCountries: string[] = countriesAsDropdownProps
                .slice(2, countriesAsDropdownProps.length)
                .filter(item => item.eu === 1)
                .filter(item => item.value)
                .map(item => item.value!.toString());
            setCountriesToGenerate(euCountries);
        } else {
            setCountriesToGenerate(data.value);
        }
    };

    const fetchSectionsAsDropdownProps = useCallback(async () => {
        try {
            if (formToGenerate && yearToGenerate) {
                const sections = await getFormSectionsAsProps(formToGenerate, yearToGenerate);

                setSectionsAsDropdownProps(sections);
            } else {
                setSectionsAsDropdownProps([]);
            }
        } catch (err) {
            toast("Unable to fetch sections for selected form.", false, 2000);
        }
    }, [formToGenerate, yearToGenerate]);

    const fetchCountriesAsDropdownProps = useCallback(async () => {
        try {
            const block = await getBlocks();
            const countries = await getCountries(
                block.find(x => x.name === "Network codes monitoring")?.id
            );
            if (Array.isArray(countries)) {
                const countriesAsProps: ICountryProps[] = [];
                countries.sort((a, b) => a.name.localeCompare(b.name));
                countries.forEach(country =>
                    countriesAsProps.push({
                        key: country.id,
                        text: country.name,
                        value: country.id,
                        eu: country.isEu ? 1 : 0,
                    })
                );

                const selectAll: ICountryProps = {
                    key: "selectAll",
                    text: "Select all",
                    value: "selectAll",
                    eu: 0,
                };
                const EU: ICountryProps = {
                    key: "EU",
                    text: "European Union",
                    value: "EU",
                    eu: 0,
                };
                countriesAsProps.unshift(EU);
                countriesAsProps.unshift(selectAll);
                setCountriesAsDropdownProps(countriesAsProps);
            }
        } catch (error) {
            toast("Error fetching countries", false, 1000);
        }
    }, []);

    useEffect(() => {
        fetchCountriesAsDropdownProps();
    }, [fetchCountriesAsDropdownProps]);

    useEffect(() => {
        fetchSectionsAsDropdownProps();
    }, [fetchSectionsAsDropdownProps]);

    const isGenerateDisabled = useCallback(() => {
        return (
            !yearToGenerate ||
            !formToGenerate ||
            countriesToGenerate.length === 0 ||
            sectionsToGenerate.length === 0
        );
    }, [formToGenerate, yearToGenerate, countriesToGenerate.length, sectionsToGenerate.length]);

    const handleFormSelection = useCallback(() => {
        const formsAsProps: DropdownItemProps[] = [];
        allFormsWithAttributes
            .filter(alwa => alwa.categoryObj?.name !== "Generation Capacity")
            .forEach(form => {
                if (
                    !yearToGenerate ||
                    new Date(form.timePublished).getFullYear() === parseInt(yearToGenerate)
                ) {
                    if (!formsAsProps.some(f => f.value === form.name)) {
                        formsAsProps.push({
                            key: form.id,
                            text: form.name,
                            value: form.name,
                        });
                    }
                }
            });
        setFormDropdownProps(formsAsProps);
    }, [allFormsWithAttributes, yearToGenerate]);

    useEffect(() => {
        handleFormSelection();
    }, [handleFormSelection]);

    const handleYearSelection = useCallback(() => {
        const yearsAsProps: DropdownItemProps[] = [];
        allFormsWithAttributes.forEach(form => {
            if (!formToGenerate || form.name === formToGenerate) {
                const year = new Date(form.timePublished).getFullYear().toString();
                if (!yearsAsProps.some(y => y.key === year)) {
                    yearsAsProps.push({
                        key: year,
                        text: year,
                        value: year,
                    });
                }
            }
        });
        setYearDropdownProps(yearsAsProps);
    }, [allFormsWithAttributes, formToGenerate]);

    useEffect(() => {
        handleYearSelection();
    }, [handleYearSelection]);

    const handleCloseGenerateModal = () => {
        setFormToGenerate("");
        setYearToGenerate("");
        setCountriesToGenerate([]);
        setSectionsToGenerate([]);
        closeGenerateModal();
    };

    return (
        <>
            <ImpLoading
                show={loading}
                message={loadingMessage}
                cancelFunction={cancelLoadingFunc}
            />
            <Modal open={generateModalOpen} className="generate-modal">
                <Modal.Header>Generate figure</Modal.Header>
                <Modal.Content>
                    <Form>
                        <Form.Field>
                            <label>Form</label>
                            <Select
                                fluid
                                clearable
                                value={formToGenerate}
                                options={formDropdownProps}
                                onChange={(e, data) => setFormToGenerate(data.value as string)}
                            />
                        </Form.Field>
                        <Form.Field>
                            <label>Year</label>
                            <Select
                                fluid
                                clearable
                                value={yearToGenerate}
                                options={yearDropdownProps}
                                onChange={(e, data) => setYearToGenerate(data.value as string)}
                            />
                        </Form.Field>
                        <Form.Field disabled={sectionsAsDropdownProps.length === 0}>
                            <label>Sections</label>
                            <Select
                                fluid
                                clearable
                                value={sectionsToGenerate}
                                options={
                                    sectionsAsDropdownProps.length > 1 &&
                                    sectionsAsDropdownProps.length !== sectionsToGenerate.length
                                        ? [selectAllProp, ...sectionsAsDropdownProps]
                                        : sectionsAsDropdownProps
                                }
                                onChange={(e, data) => {
                                    if ((data.value as string[]).includes("Select all")) {
                                        setSectionsToGenerate(
                                            sectionsAsDropdownProps.map(item =>
                                                item.value!.toString()
                                            )
                                        );
                                    } else {
                                        setSectionsToGenerate(data.value as string[]);
                                    }
                                }}
                                multiple
                            />
                        </Form.Field>
                        <Form.Field>
                            <label>Countries</label>
                            <Dropdown
                                fluid
                                multiple
                                search
                                selection
                                clearable
                                value={countriesToGenerate}
                                options={countriesAsDropdownProps}
                                onChange={(e, data) => handleSelectCountriesToGenerate(e, data)}
                            />
                        </Form.Field>
                    </Form>
                </Modal.Content>
                <Modal.Actions>
                    <Button onClick={handleCloseGenerateModal} content="Cancel" />
                    <Popup
                        disabled={!isGenerateDisabled()}
                        content="Please fill out all of the fields above"
                        trigger={
                            <span>
                                <Button
                                    primary
                                    onClick={handleGenerateFigure}
                                    disabled={isGenerateDisabled()}
                                    content="Generate"
                                />
                            </span>
                        }
                    />
                </Modal.Actions>
            </Modal>
        </>
    );
};
