import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Button, Card, DropdownItemProps, Form, Grid, Modal, Select } from "semantic-ui-react";
import { ReportBasicForm } from "../Report/ReportBasicForm";
import { createNewReport, getPublishedReports, copyReport } from "../../services/reportsService";
import { IReport } from "../../models/report";
import { yearsProps } from "../../models/common";
import { toast } from "../..";
import { ImpLoading } from "../../components/ImpLoading/ImpLoading";
import { ReportsCarousel } from "../../components/ReportsCarousel/ReportsCarousel";
import { PublishedCarousel } from "../../components/ReportsCarousel/PublishedCarousel";
import "./ReportsOverview.css";
import { Breadcrumbs } from "../../components/Breadcrumbs/Breadcrumbs";

export const ReportsOverview = () => {
    let history = useHistory();

    const [selectedYear, setSelectedYear] = useState<number>();
    const [createReportOpen, setCreateReportOpen] = useState(false);
    const [blankReportSelected, setBlankReportSelected] = useState(false);
    const [publishedReportSelected, setPublishedReportSelected] = useState(false);
    const [newReport, setNewReport] = useState<Partial<IReport>>({});
    const [tooltipMessage, setTooltipMessage] = useState("Enter title.");

    const [selectedPublishedReport, setSelectedPublishedReport] = useState("");
    const [reportsDropdownProps, setReportsDropdownProps] = useState<DropdownItemProps[]>([]);

    const [loading, setLoading] = useState(false);
    const [loadingMessage, setLoadingMessage] = useState("");

    useEffect(() => {
        getPublishedReports().then(res => {
            let reportsAsProps: DropdownItemProps[] = [];
            res.forEach(r => {
                reportsAsProps.push({
                    key: r.id,
                    value: r.id,
                    text: `${r.title} (${r.version} - ${
                        r.date ? new Date(r.date).toLocaleString() : "No date"
                    })`,
                });
            });

            setReportsDropdownProps(reportsAsProps);
        });
    }, []);

    let saveNewReport = async () => {
        try {
            let res = await createNewReport(newReport);
            if (res.id) {
                toast("Report created successfully", true, 1000);
                history.push("/reports/overview/" + res.id + "/front-page");
            }
        } catch {
            toast("Error while creating report", false, 1000);
        }
    };

    useEffect(() => {
        if (!newReport?.title) setTooltipMessage("Enter title.");
        else setTooltipMessage("");
    }, [newReport]);

    let onCopyReport = async () => {
        if (selectedPublishedReport) {
            setPublishedReportSelected(false);
            setCreateReportOpen(false);

            setLoadingMessage("Copying report...");
            setLoading(true);

            let res = await copyReport(selectedPublishedReport);
            setLoading(false);

            if (res.ok) {
                let newReportId = await res.text();

                toast("Report copied successfully.", true, 1000);
                history.push(`/reports/overview/${newReportId}/front-page`);
            } else {
                toast(
                    "A problem occured while copying report. Please try again later.",
                    true,
                    1000
                );
            }
        }
    };

    return (
        <div className="reports-page-container">
            <ImpLoading show={loading} message={loadingMessage} />

            <div className="container-header">
                <h1>Overview of published reports</h1>
                <Button
                    content="Create new report"
                    onClick={() => setCreateReportOpen(true)}
                    primary
                />
            </div>
            <Breadcrumbs />
            <div className="reports-container">
                <div className="year-selector">
                    <Select
                        fluid
                        clearable
                        value={selectedYear}
                        options={yearsProps}
                        onChange={(_e, data) => setSelectedYear(data.value as number)}
                    />
                </div>
                <div className="published-carousel">
                    <PublishedCarousel year={selectedYear} />
                </div>
            </div>

            <div className="reports-container">
                <div className="container-header">
                    <h1>Reports under development</h1>
                </div>
                <ReportsCarousel withDelete />
            </div>

            <Modal open={createReportOpen} className="report-modal">
                <Modal.Header>Create new report</Modal.Header>
                <Modal.Content>
                    <Grid columns={2}>
                        <Grid.Column>
                            <Card onClick={() => setBlankReportSelected(true)}>
                                <Card.Content
                                    style={{
                                        height: "200px",
                                        overflow: "auto",
                                    }}
                                >
                                    <Card.Header>Blank</Card.Header>
                                    <Card.Meta>Creates a new blank report</Card.Meta>
                                </Card.Content>
                            </Card>
                        </Grid.Column>

                        <Grid.Column>
                            <Card onClick={() => setPublishedReportSelected(true)}>
                                <Card.Content
                                    style={{
                                        height: "200px",
                                        overflow: "auto",
                                    }}
                                >
                                    <Card.Header>Published</Card.Header>
                                    <Card.Meta>Uses published report to create a new one</Card.Meta>
                                </Card.Content>
                            </Card>
                        </Grid.Column>
                    </Grid>
                </Modal.Content>
                <Modal.Actions>
                    <Button onClick={() => setCreateReportOpen(false)}>Cancel</Button>
                </Modal.Actions>
            </Modal>

            <Modal open={blankReportSelected} dimmer={false} className="create-new-report-modal">
                <Modal.Header>Enter report information</Modal.Header>
                <Modal.Content>
                    <ReportBasicForm report={newReport} setReport={setNewReport} />
                </Modal.Content>
                <Modal.Actions>
                    <Button onClick={() => setBlankReportSelected(false)} content="Cancel" />
                    <div className="parent-tooltip">
                        <div
                            hidden={!tooltipMessage}
                            className="child-tooltip"
                            data-tooltip={tooltipMessage}
                            data-position="top center"
                        />
                        <Button onClick={saveNewReport} disabled={tooltipMessage !== ""}>
                            Save
                        </Button>
                    </div>
                </Modal.Actions>
            </Modal>

            <Modal open={publishedReportSelected} className="create-new-report-modal">
                <Modal.Header>Creating from a previously published report</Modal.Header>
                <Modal.Content>
                    <Form>
                        <Form.Field>
                            <label>Template report:</label>
                            <Select
                                clearable
                                placeholder="Select a published form"
                                options={reportsDropdownProps}
                                onChange={(e, data) =>
                                    setSelectedPublishedReport(data.value as string)
                                }
                            />
                        </Form.Field>
                    </Form>
                </Modal.Content>
                <Modal.Actions>
                    <Button onClick={() => setPublishedReportSelected(false)} content="Cancel" />
                    <Button
                        onClick={onCopyReport}
                        content="Copy"
                        disabled={!selectedPublishedReport}
                    />
                </Modal.Actions>
            </Modal>
        </div>
    );
};
