import { useState } from "react";
import { Link } from "react-router-dom";
import { Button, Card, Icon, Modal } from "semantic-ui-react";
import { toast } from "../..";
import { IReport } from "../../models/report";
import { deleteReport } from "../../services/reportsService";
import "./ReportCard.css";

interface IProps {
    report: IReport;
    withDelete?: boolean;
    refresh: Function;
}

export const InProgressReportCard = ({ report, withDelete, refresh }: IProps) => {
    const [openModal, setOpenModal] = useState(false);

    const handleDeleteReport = (id: string) => {
        deleteReport(id)
            .then(() => {
                toast("Report successfully deleted", true, 1000);
                refresh();
            })
            .catch(() => {
                toast("Error on deleting report", false, 1000);
            })
            .finally(() => {
                setOpenModal(false);
            });
    };

    return (
        <>
            <Card
                as={Link}
                to={`/reports/overview/${report.id}/front-page`}
                className="report-card"
            >
                <Card.Content>
                    <Card.Header>
                        <h3>{report.title}</h3>
                    </Card.Header>
                    <Card.Meta></Card.Meta>
                    <Card.Description>
                        <p>
                            <strong>Version: </strong>
                            {report.version}
                        </p>
                        <p>
                            <strong>Date: </strong>
                            {!(report.date.startsWith("190") || report.date.startsWith("0001")) &&
                                new Date(report.date).toLocaleString("hr-HR", {
                                    day: "2-digit",
                                    month: "2-digit",
                                    year: "numeric",
                                })}
                        </p>
                    </Card.Description>
                </Card.Content>
            </Card>
            {withDelete && (
                <div className="action-bar-container-options">
                    <Icon size="large" name="delete" onClick={() => setOpenModal(true)} bordered />
                </div>
            )}

            {withDelete && (
                <Modal open={openModal}>
                    <Modal.Header>Are you sure you want to delete this report?</Modal.Header>
                    <Modal.Content>
                        Deleting the report will delete all of the chapters and paragraphs in it.
                    </Modal.Content>
                    <Modal.Actions>
                        <Button onClick={() => setOpenModal(false)}>Cancel</Button>
                        <Button
                            onClick={() => {
                                handleDeleteReport(report.id!);
                            }}
                        >
                            Delete
                        </Button>
                    </Modal.Actions>
                </Modal>
            )}
        </>
    );
};
