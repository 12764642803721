import { useCallback, useEffect, useState } from "react";
import { IForm } from "../../models/form";
import { getActiveForms } from "../../services/formsService";
import Carousel from "react-multi-carousel";

import { Legend } from "../Legend/Legend";
import { NclpFormCard } from "../../containers/FormCard/NclpFormCard";
import { Loader } from "semantic-ui-react";
import "react-multi-carousel/lib/styles.css";
import "./NclpForms.css";

interface IProps {
    withLegend?: boolean;
}

// Prop for Carousel
const responsive = {
    desktop: {
        breakpoint: { max: 4000, min: 200 },
        items: 4,
    },
};

export const NclpForms = (props: IProps) => {
    const [activeForms, setIsActiveForms] = useState<IForm[]>([]);
    const [loadingForms, setLoadingForms] = useState(true);
    const [timeoutRef, setTimeoutRef] = useState<NodeJS.Timeout>();

    const fetchInProgressForms = useCallback(async () => {
        const fetch = async (withLoading: boolean) => {
            setLoadingForms(withLoading);
            const inProgressForms = await getActiveForms();
            Array.isArray(inProgressForms) && setIsActiveForms(inProgressForms);
            setLoadingForms(false);
        };
        await fetch(true);

        let timeout = setTimeout(async () => {
            await fetch(false);
            setTimeoutRef(undefined);
        }, 2500);
        setTimeoutRef(timeout);
    }, []);

    useEffect(() => {
        fetchInProgressForms();
    }, [fetchInProgressForms]);

    useEffect(() => {
        return () => {
            if (timeoutRef) {
                clearTimeout(timeoutRef);
            }
        };
    }, [timeoutRef]);

    return (
        <div>
            <div className="section-title">
                <h1>Forms to submit</h1>
                {props.withLegend && activeForms.length > 0 && <Legend new partial />}
            </div>

            <div id="forms-content">
                <div className="forms-list">
                    <Loader active={loadingForms} inline="centered" />
                    <Carousel responsive={responsive}>
                        {activeForms.map(x => (
                            <div className="action-bar-container" key={x.id}>
                                <NclpFormCard form={x} />
                            </div>
                        ))}
                    </Carousel>
                    {activeForms.length === 0 && !loadingForms && <b>No forms to submit.</b>}
                </div>
            </div>
        </div>
    );
};
