import CookieConsent, { Cookies, getCookieConsentValue } from "react-cookie-consent";
import ReactGA from "react-ga4";
import { useEffect } from "react";
import "./CookieConsentPrompt.css";

export const CookieConsentPrompt = () => {
    const GOOGLE_ANALYTICS_ID = "G-LKG6W4HRGJ";

    const handleAcceptCookie = () => {
        if (GOOGLE_ANALYTICS_ID && process.env.NODE_ENV === "production") {
            ReactGA.initialize(GOOGLE_ANALYTICS_ID);
        }
    };

    const handleDeclineCookie = () => {
        //remove google analytics cookies
        Cookies.remove("_ga");
        Cookies.remove("_gat");
        Cookies.remove("_gid");
    };

    useEffect(() => {
        const isConsent = getCookieConsentValue();
        if (isConsent) {
            handleAcceptCookie();
        } else {
            handleDeclineCookie();
        }
    }, []);

    return (
        <CookieConsent
            enableDeclineButton
            onAccept={handleAcceptCookie}
            onDecline={handleDeclineCookie}
        >
            This website uses cookies to enhance the user experience.
        </CookieConsent>
    );
};
