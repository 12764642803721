export interface INewUser {
    username: string;
    password: string;
    firstName: string;
    lastName: string;
    role: string;
    countryGuid: string;
}

export const userInit: INewUser = {
    username: "",
    password: "",
    firstName: "",
    lastName: "",
    role: "",
    countryGuid: "",
};
