import { useState } from "react";
import { Select, Tab } from "semantic-ui-react";
import { Breadcrumbs } from "../../components/Breadcrumbs/Breadcrumbs";
import { yearsProps } from "../../models/common";
import "./InputDataEditor.css";
import { RfG } from "./Tabs/RfG";

export const InputDataEditor = () => {
    const [year, setYear] = useState(new Date().getFullYear());

    return (
        <div className="input-data-editor-container">
            <div className="container-header">
                <h1>Input data editor</h1>
                <Select
                    value={year}
                    options={yearsProps}
                    onChange={(e, data) => setYear(data.value as number)}
                />
            </div>
            <Breadcrumbs />
            <Tab
                menu={{ fluid: true, vertical: true, secondary: true }}
                menuPosition="left"
                panes={[
                    {
                        menuItem: "RfG",
                        render: () => (
                            <Tab.Pane>
                                <RfG />
                            </Tab.Pane>
                        ),
                    },
                    { menuItem: "HVDC", render: () => <Tab.Pane>Tab 2 Content</Tab.Pane> },
                    { menuItem: "DCC", render: () => <Tab.Pane>Tab 3 Content</Tab.Pane> },
                ]}
                grid={{ paneWidth: 13, tabWidth: 3 }}
            />
        </div>
    );
};
