import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Card } from "semantic-ui-react";
import { IForm } from "../../models/form";
import "./FormCard.css";

interface IProps {
    form: IForm;
}

export const NclpFormCard = (props: IProps) => {
    // Special deadline if it exists, regular deadline otherwise.
    const [usedDeadline, setUsedDeadline] = useState<string>("");

    useEffect(() => {
        if (props.form.specialDeadlines.length > 0 && props.form.specialDeadlines[0].deadline) {
            setUsedDeadline(props.form.specialDeadlines[0].deadline);
        } else {
            setUsedDeadline(props.form.deadline);
        }
    }, [props.form]);

    return (
        <Card
            key={props.form.id}
            as={Link}
            to={"/forms/view/" + props.form.id}
            className={props.form.resultStatus === "Partial" ? "yellow" : "blue"}
        >
            <Card.Content>
                <Card.Header>{props.form.name}</Card.Header>
                <Card.Meta>{props.form.description}</Card.Meta>
                <Card.Description>Category: {props.form.categoryObj?.name}</Card.Description>
                {!usedDeadline.startsWith("0001-01-01") && (
                    <Card.Description>
                        Deadline:{" "}
                        {new Date(usedDeadline).toLocaleString("hr-HR", {
                            day: "2-digit",
                            month: "2-digit",
                            year: "numeric",
                        })}
                    </Card.Description>
                )}
            </Card.Content>
        </Card>
    );
};
