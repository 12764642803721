import { Label } from "semantic-ui-react";
import "./Legend.css";

interface IProps {
    new?: boolean,
    partial?: boolean,
    submitted?: boolean,
    closedNotSubmitted?: boolean
}

export const Legend = (props: IProps) => {
    return <div className="legend-horizontal-container">
        {props.new &&
            <div className="legend-item">
                <Label circular color="blue" />
                <div>New</div>
            </div>
        }
        {props.partial &&
            <div className="legend-item">
                <Label circular color="yellow" />
                <div>Partially filled</div>
            </div>
        }
        {props.submitted &&
            <div className="legend-item">
                <Label circular color="green" />
                <div>Submitted</div>
            </div>
        }
        {props.closedNotSubmitted &&
            <div className="legend-item">
                <Label circular color="orange" />
                <div>Closed not submitted</div>
            </div>  
        }
    </div>
}