import { useState } from "react";
import { Accordion, Icon, Input, TextArea } from "semantic-ui-react";

export const RfG = () => {
    const [active, setActive] = useState<number[]>([]);

    const handleActive = (i: number) => {
        if (active.includes(i)) setActive(active.filter((a) => a !== i));
        else setActive(active.concat(i));
    };

    return (
        <Accordion exclusive={false}>
            <>
                <Accordion.Title
                    active={active.includes(0)}
                    index={0}
                    onClick={() => handleActive(0)}
                >
                    Frequency issues
                    <Icon name="chevron left" />
                </Accordion.Title>
                <Accordion.Content active={active.includes(0)}>
                    Lorem ipsum dolor, sit amet consectetur adipisicing elit. Cum asperiores modi
                    natus atque cupiditate odit soluta! Commodi similique quae nihil ullam iure,
                    deserunt, sint beatae, corporis architecto cum aliquid? Unde!
                </Accordion.Content>
            </>
            <>
                <Accordion.Title
                    active={active.includes(1)}
                    index={1}
                    onClick={() => handleActive(1)}
                >
                    Voltage issues
                    <Icon name="chevron left" />
                </Accordion.Title>
                <Accordion.Content active={active.includes(1)}>
                    Lorem ipsum dolor, sit amet consectetur adipisicing elit. Quo quod omnis eaque
                    sapiente laudantium accusamus quaerat optio libero aperiam eveniet ullam, sequi
                    assumenda quia nihil nostrum mollitia labore et architecto.
                </Accordion.Content>
            </>
            <>
                <Accordion.Title
                    active={active.includes(2)}
                    index={2}
                    onClick={() => handleActive(2)}
                >
                    Instrumentation, simulation models and protection issues
                    <Icon name="chevron left" />
                </Accordion.Title>
                <Accordion.Content active={active.includes(2)}>
                    Lorem ipsum dolor sit amet consectetur adipisicing elit. Delectus incidunt vero
                    modi quos laboriosam dolor voluptate consequatur fugiat cumque. Mollitia
                    provident rem fuga eveniet illum quas eum perspiciatis repudiandae quis?
                </Accordion.Content>
            </>
            <>
                <Accordion.Title
                    active={active.includes(3)}
                    index={3}
                    onClick={() => handleActive(3)}
                >
                    System restoration issues
                    <Icon name="chevron left" />
                </Accordion.Title>
                <Accordion.Content active={active.includes(3)}>
                    <section className="section-row">
                        <label>Max. allowed time active power recovery</label>
                        <Input />
                    </section>
                    <section className="section-row">
                        <label>Post-fault active power recovery</label>
                        <Input />
                    </section>
                    <section className="input-container">
                        <label>Conditions for automatic reconnection</label>
                        <TextArea rows={5} />
                    </section>
                </Accordion.Content>
            </>
        </Accordion>
    );
};
