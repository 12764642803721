import { Dispatch, Fragment, SetStateAction, useEffect, useState } from "react";
import {
    Button,
    DropdownItemProps,
    Modal,
    Segment,
    Select,
    Table,
    Radio,
    Popup,
    Loader,
    Input,
    Icon,
    Checkbox,
} from "semantic-ui-react";
import { toast } from "../..";
import { UserData, UserRole } from "../../actions/authentificationActions";
import {
    changeUserActive,
    changeUserCountry,
    changeUserRole,
    deleteUser,
} from "../../services/usersService";
import { userRoleOptions } from "../../store/userReducer";
import { useSelector } from "react-redux";
import { AppState } from "../../store/configureStore";
import { getCountries } from "../../services/countriesService";
import { initUserFilter, IUserFilter } from "./UsersDashboard";

interface IProps {
    users: UserData[];
    setUsers: Dispatch<SetStateAction<UserData[]>>;
    userFilter: IUserFilter;
    setUserFilter: Dispatch<SetStateAction<IUserFilter>>;
    loadingUsers: boolean;
}

export const ManageUsersTable = ({
    users,
    setUsers,
    loadingUsers,
    userFilter,
    setUserFilter,
}: IProps) => {
    const loggedUser: UserData = useSelector((state: AppState) => state.user);
    const [countries, setCountries] = useState<DropdownItemProps[]>([]);
    const [userToDelete, setUserToDelete] = useState("");

    const [usernameSearch, setUsernameSearch] = useState<string>(userFilter.username);

    const handleDeleteUser = async () => {
        const res = await deleteUser(userToDelete);
        if (!res.ok) toast("Unable to delete user", false);
        else {
            setUsers([...users].filter(x => x.guid !== userToDelete));
            toast("User successfully deleted", true);
        }
        setUserToDelete("");
    };

    const handleChangeUserRole = async (guid: string, role: string) => {
        const res = await changeUserRole(guid, role);
        if (res.ok) {
            let updatedUsers: UserData[] = users.map(u => {
                return {
                    ...u,
                };
            });
            let targetUser: UserData | undefined = updatedUsers.find(u => u.guid === guid);
            const oldRole = targetUser?.role;
            if (targetUser) {
                targetUser.role = role;
                if (
                    (oldRole === UserRole.Viewer || oldRole === UserRole.Admin) &&
                    role === UserRole.NCLP &&
                    !targetUser.countryGuid
                ) {
                    targetUser.isEnabled = false;
                }

                if (role === UserRole.Viewer) {
                    targetUser.countryGuid = undefined;
                }

                setUsers(updatedUsers);
            }
            toast("Updated user's role", true, 1500);
        } else {
            toast(await res.text(), false, 1500);
        }
    };

    const handleChangeUserCountry = async (guid: string, countryGuid: string) => {
        const res = await changeUserCountry(guid, countryGuid);
        if (res.ok) {
            let updatedUsers: UserData[] = users.map(u => {
                return {
                    ...u,
                };
            });
            let targetUser: UserData | undefined = updatedUsers.find(u => u.guid === guid);
            if (targetUser) {
                targetUser.countryGuid = countryGuid;
                setUsers(updatedUsers);
            }
            toast("Updated user's country", true, 1500);
        } else {
            toast(await res.text(), false, 1500);
        }
    };

    const handleChangeUserActive = async (guid: string, active: boolean) => {
        const res = await changeUserActive(guid, active);
        if (res.ok) {
            let updatedUsers: UserData[] = users.map(u => {
                return {
                    ...u,
                };
            });
            let targetUser: UserData | undefined = updatedUsers.find(u => u.guid === guid);
            if (targetUser) {
                targetUser.isEnabled = active;
                setUsers(updatedUsers);
            }
            toast("Updated user's enabled status", true, 1500);
        } else {
            toast(await res.text(), false, 1500);
        }
    };

    useEffect(() => {
        getCountries(undefined, true).then(res => {
            let dropdownCountries: DropdownItemProps[] = res.map(c => {
                return {
                    key: c.id,
                    text: c.name,
                    value: c.id,
                };
            });
            setCountries(dropdownCountries);
        });
    }, []);

    useEffect(() => {
        setUsernameSearch(userFilter.username);
    }, [userFilter.username]);

    const handleSearchKeyPress = (e: any) => {
        if (e.code === "Enter" || e.code === "NumpadEnter") {
            setUserFilter({ ...userFilter, username: usernameSearch });
        }
    };

    return (
        <div className="users-table-container">
            <h1>Edit users</h1>
            <Segment>
                <h2>
                    Table Filters <Icon className="filter" />
                </h2>
                <div className="filter-container">
                    <div>
                        <label htmlFor="email">
                            <b>E-mail: </b>
                        </label>
                        <Input
                            value={usernameSearch}
                            onChange={(e, data) => setUsernameSearch(data.value)}
                            onKeyPress={handleSearchKeyPress}
                            onBlur={() =>
                                setUserFilter({ ...userFilter, username: usernameSearch })
                            }
                        />
                    </div>
                    <div>
                        <label htmlFor="role">
                            <b>Role: </b>
                        </label>
                        <Select
                            options={userRoleOptions}
                            value={userFilter.role}
                            onChange={(e, data) =>
                                setUserFilter({ ...userFilter, role: data.value as string })
                            }
                            selectOnBlur={false}
                            clearable
                        />
                    </div>
                    <div>
                        <label htmlFor="country">
                            <b>Country: </b>
                        </label>
                        <Select
                            options={countries}
                            value={userFilter.country}
                            onChange={(e, data) =>
                                setUserFilter({ ...userFilter, country: data.value as string })
                            }
                            selectOnBlur={false}
                            clearable
                        />
                    </div>
                    <div>
                        <label htmlFor="enabled">
                            <b>Enabled: </b>
                        </label>
                        <Checkbox
                            checked={userFilter.enabled}
                            indeterminate={userFilter.enabled === undefined}
                            onChange={(e, data) => {
                                setUserFilter({
                                    ...userFilter,
                                    enabled:
                                        userFilter.enabled === undefined
                                            ? true
                                            : userFilter.enabled
                                            ? false
                                            : undefined,
                                });
                            }}
                            defaultIndeterminate
                        />
                    </div>
                    <div>
                        <Button
                            primary
                            content="Clear filters"
                            onClick={() => setUserFilter(initUserFilter)}
                        />
                    </div>
                </div>
                <Table basic="very" compact="very">
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell>
                                <span
                                    className="clickable"
                                    onClick={() =>
                                        setUserFilter({
                                            ...userFilter,
                                            usernameSort:
                                                userFilter.usernameSort === "ASC" ? "DESC" : "ASC",
                                        })
                                    }
                                >
                                    User{" "}
                                    <Icon
                                        className={
                                            userFilter.usernameSort === "ASC"
                                                ? "sort alphabet down"
                                                : "sort alphabet up"
                                        }
                                    />
                                </span>
                            </Table.HeaderCell>
                            <Table.HeaderCell className="dropdown-cell">Role</Table.HeaderCell>
                            <Table.HeaderCell className="dropdown-cell">Country</Table.HeaderCell>
                            <Table.HeaderCell collapsing>Enabled</Table.HeaderCell>
                            <Table.HeaderCell collapsing>Actions</Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        {users.map(user =>
                            user.username !== "admin@uprise.hr" ? (
                                <Fragment key={user.guid}>
                                    <Table.Row>
                                        <Table.Cell>{user.username}</Table.Cell>
                                        <Table.Cell singleLine>
                                            <div className="centered">
                                                <Select
                                                    selectOnBlur={false}
                                                    options={userRoleOptions}
                                                    value={user.role}
                                                    onChange={(e, data: any) =>
                                                        handleChangeUserRole(user.guid!, data.value)
                                                    }
                                                    disabled={loggedUser.role !== UserRole.Admin}
                                                />
                                            </div>
                                        </Table.Cell>
                                        <Table.Cell>
                                            {(user.role === UserRole.NCLP ||
                                                user.role === UserRole.Admin) && (
                                                <div className="centered">
                                                    <Select
                                                        selectOnBlur={false}
                                                        options={countries}
                                                        disabled={
                                                            loggedUser.role !== UserRole.Admin
                                                        }
                                                        clearable={user.role === UserRole.Admin}
                                                        value={user.countryGuid}
                                                        onChange={(e, data: any) =>
                                                            handleChangeUserCountry(
                                                                user.guid!,
                                                                data.value
                                                            )
                                                        }
                                                    />
                                                </div>
                                            )}
                                        </Table.Cell>
                                        <Table.Cell width={1} singleLine>
                                            <Popup
                                                disabled={
                                                    user.role === UserRole.NCLP
                                                        ? user.countryGuid !== null ||
                                                          user.countryGuid === ""
                                                        : user.role !== undefined
                                                }
                                                trigger={
                                                    <Radio
                                                        toggle
                                                        disabled={
                                                            user.role === UserRole.NCLP
                                                                ? !user.countryGuid
                                                                : false
                                                        }
                                                        checked={user.isEnabled}
                                                        onChange={(e, data: any) => {
                                                            handleChangeUserActive(
                                                                user.guid!,
                                                                data.checked
                                                            );
                                                        }}
                                                    />
                                                }
                                            >
                                                <Popup.Content>
                                                    You need to select user role and country before
                                                    you are able to activate the account.
                                                </Popup.Content>
                                            </Popup>
                                        </Table.Cell>
                                        <Table.Cell width={1} singleLine>
                                            <Button
                                                content="Delete"
                                                negative
                                                basic
                                                size="small"
                                                onClick={() => setUserToDelete(user.guid!)}
                                            />
                                        </Table.Cell>
                                    </Table.Row>
                                    <Modal
                                        open={userToDelete !== ""}
                                        onClose={() => setUserToDelete("")}
                                        dimmer="blurring"
                                        size="tiny"
                                    >
                                        <Modal.Header>
                                            Are you sure you want to delete this user?
                                        </Modal.Header>
                                        <Modal.Actions>
                                            <Button
                                                negative
                                                onClick={() => setUserToDelete("")}
                                                content="Cancel"
                                            />
                                            <Button
                                                primary
                                                onClick={() => handleDeleteUser()}
                                                content="Confirm"
                                            />
                                        </Modal.Actions>
                                    </Modal>
                                </Fragment>
                            ) : null
                        )}
                    </Table.Body>
                </Table>
                <Loader active={loadingUsers} inline="centered" />
            </Segment>
        </div>
    );
};
