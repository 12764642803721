// @ts-nocheck

import { PureComponent } from "react";

export class CustomizedAxisTick extends PureComponent {
    render() {
        return (
            <g transform={`translate(${this.props.x},${this.props.y})`}>
                <text x={0} y={0} dy={16} textAnchor="end" fill="#666" transform="rotate(-25)">
                    {this.props.payload.value}
                </text>
            </g>
        );
    }
}
