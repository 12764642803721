import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Select } from "semantic-ui-react";
import { AppState } from "../../store/configureStore";
import { Redirect } from "react-router-dom";
import { getParts } from "../../services/countriesService";
import { ICountry } from "../../models/country";
import { countryPartSelect } from "../../services/usersService";
import { setUser } from "../../actions/authentificationActions";
import Logo from "../../images/entsoe_logo.svg";
import "./CountrySelect.css";

export const CountrySelect = () => {
    const [parts, setParts] = useState<ICountry[]>([]);
    const [partId, setPartId] = useState<string>();
    const [loading, setLoading] = useState(false);
    const user = useSelector((state: AppState) => state.user);
    const dispatch = useDispatch();

    useEffect(() => {
        if (user.countryGuid) getParts(user.countryGuid).then(setParts);
    }, [user.countryGuid]);

    const handleCountrySelect = async () => {
        try {
            setLoading(true);
            const newUserInfo = await countryPartSelect(partId!)
            dispatch(setUser(newUserInfo));
        } catch {
            setLoading(false);
        }

    };

    return (
        <div className="country-select-container">
            {user.role && user.role !== "CountrySelect" && <Redirect to={{ pathname: "/" }} />}
            <div className="country-select-wrapper">
                <img src={Logo} alt="Logo" />
                <section className="country-select-form-container">
                    <h1>Implementation Monitoring Platform</h1>

                    <Select
                        placeholder="Select your region"
                        options={parts.map(p => {
                            return { key: p.id, value: p.id, text: p.name };
                        })}
                        onChange={(e, data) => setPartId(data.value as string)}
                    />
                    <Button
                        loading={loading}
                        disabled={!partId}
                        fluid
                        primary
                        content="Select"
                        onClick={handleCountrySelect}
                    />
                </section>
            </div>
        </div>
    );
};
