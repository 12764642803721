import { useCallback, useEffect, useState } from "react";
import {
    Button,
    DropdownItemProps,
    Input,
    Select,
    Pagination,
    Modal,
    Loader,
    Popup,
    Checkbox,
} from "semantic-ui-react";
import { toast } from "../..";
import staticFilterOptions from "../../components/FormsOverviewTable/staticFilterOptions.json";
import {
    figureDtoInit,
    figureFilterInit,
    figureSourceTypeOptions,
    IFigureDto,
} from "../../models/figure";
import { getFormCategoriesAsProps, getPublishedForms } from "../../services/formsService";
import { GenerateModal } from "./GenerateModal";
import { UploadModal } from "./UploadModal";
import { IForm } from "../../models/form";
import { bulkDeleteFigures, getFigureImage, getFigures } from "../../services/figuresService";
import { FigureDisplay } from "./FigureDisplay";
import { FigurePreviewDisplay } from "./FigurePreviewDisplay";
import { UploadEditModal } from "./UploadEditModal";
import { GenerateEditModal } from "./GenerateEditModal";
import { DeleteFigureModal } from "./DeleteFigureModal";
import { Breadcrumbs } from "../../components/Breadcrumbs/Breadcrumbs";
import { RegenerateModal } from "./RegenerateModal";
import "./Figures.css";

export const AdminFigures = () => {
    const numberOfItemsToFetch = 15;
    const [totalRows, setTotalRows] = useState(0);
    const [page, setPage] = useState(1);
    const [figureFilter, setFigureFilter] = useState(figureFilterInit);
    const [uploadModalOpen, setUploadModalOpen] = useState(false);
    const [generateModalOpen, setGenerateModalOpen] = useState(false);
    const [filteredFigures, setFilteredFigures] = useState<IFigureDto[]>([]);
    const [figureToEdit, setFigureToEdit] = useState(figureDtoInit);
    const [figureToOpen, setFigureToOpen] = useState(figureDtoInit);
    const [figureToDelete, setFigureToDelete] = useState(figureDtoInit);
    const [figureToRegenerate, setFigureToRegenerate] = useState(figureDtoInit);
    const [figureListView, setFigureListView] = useState(true);

    const [formCategories, setFormCategories] = useState<DropdownItemProps[]>([]);
    const [formsAsDropdownProps, setFormsAsDropdownProps] = useState<DropdownItemProps[]>([]);

    const [allFormsWithAttributes, setAllFormsWithAttributes] = useState<IForm[]>([]);
    const [loadingFigures, setLoadingFigures] = useState(true);

    const [selectedForDelete, setSelectedForDelete] = useState<string[]>([]);
    const [deleteSelectedModalOpen, setDeleteSelectedModalOpen] = useState(false);
    const [deleteFilteredModalOpen, setDeleteFilteredModalOpen] = useState(false);

    const fetchAllForms = useCallback(async () => {
        try {
            const allForms = await getPublishedForms();
            if (Array.isArray(allForms)) {
                setAllFormsWithAttributes(allForms);
                setFormsAsDropdownProps(handleFormsAsDropdownProps(allForms));
            }
        } catch (error) {
            toast("Error fetching forms", false, 1000);
        }
    }, []);

    useEffect(() => {
        fetchAllForms();
    }, [fetchAllForms]);

    useEffect(() => {
        setSelectedForDelete([]);
    }, [figureListView]);

    const handleFormsAsDropdownProps = (allForms: IForm[]): DropdownItemProps[] => {
        const dropdownItemPropsArray: DropdownItemProps[] = [];
        allForms.forEach(f => {
            if (!dropdownItemPropsArray.some(e => e.value === f.name)) {
                dropdownItemPropsArray.push({ key: f.id, text: f.name, value: f.name });
            }
        });
        return dropdownItemPropsArray;
    };

    const fetchFormCategories = useCallback(async () => {
        const categories = await getFormCategoriesAsProps();
        Array.isArray(categories) && setFormCategories(categories);
    }, []);

    useEffect(() => {
        fetchFormCategories();
    }, [fetchFormCategories]);

    const fetchFormsAsDropdownProps = useCallback(async () => {
        if (figureFilter.category) {
            const filteredForms = allFormsWithAttributes.filter(
                form => form.category === figureFilter.category
            );
            if (Array.isArray(filteredForms)) {
                const dropdownItemPropsArray: DropdownItemProps[] = [];
                filteredForms.forEach(f => {
                    if (!dropdownItemPropsArray.some(e => e.value === f.name)) {
                        dropdownItemPropsArray.push({ key: f.id, text: f.name, value: f.name });
                    }
                });
                setFormsAsDropdownProps(dropdownItemPropsArray);
            }
        } else if (figureFilter.formName) {
            const selectedForm = allFormsWithAttributes.find(
                form => form.name === figureFilter.formName
            );
            setFigureFilter({ ...figureFilter, category: selectedForm!.category });
        } else {
            setFormsAsDropdownProps(handleFormsAsDropdownProps(allFormsWithAttributes));
        }
    }, [allFormsWithAttributes, figureFilter]);

    useEffect(() => {
        fetchFormsAsDropdownProps();
    }, [fetchFormsAsDropdownProps]);

    const handleFilterFigures = useCallback(async () => {
        try {
            setLoadingFigures(true);
            const res = await getFigures(figureFilter, page, numberOfItemsToFetch);
            setFilteredFigures(res.result);
            setTotalRows(res.totalRows);
            setSelectedForDelete([]);
        } catch {
            toast("Error while filtering figures", false, 1000);
        } finally {
            setLoadingFigures(false);
        }
    }, [figureFilter, page]);

    useEffect(() => {
        handleFilterFigures();
    }, [handleFilterFigures]);

    useEffect(() => {
        setPage(1);
    }, [figureFilter]);

    const handleClearFilter = () => {
        setFigureFilter(figureFilterInit);
    };

    const handleFigureImageDownload = async (guid: string, filename: string) => {
        try {
            await getFigureImage(guid, filename, false);
        } catch {
            toast("Error downloading image", false, 1000);
        }
    };

    const handleAddToSelectedForDelete = (id: string, selected: boolean) => {
        if (selected) {
            setSelectedForDelete([...selectedForDelete, id]);
        } else {
            setSelectedForDelete(selectedForDelete.filter(e => e !== id));
        }
    };

    const handleBuklDelete = useCallback(
        async (withFilter: boolean) => {
            try {
                let filter = withFilter ? figureFilter : figureFilterInit;
                let deleteArray = withFilter ? [] : selectedForDelete;

                await bulkDeleteFigures(filter, deleteArray, withFilter);
                await handleFilterFigures();

                setSelectedForDelete([]);
                if (withFilter) {
                    setDeleteFilteredModalOpen(false);
                } else {
                    setDeleteSelectedModalOpen(false);
                }
                toast("Figures successfully deleted", true, 3000);
            } catch (err) {
                toast("Error deleting figures", false, 3000);
            }
        },
        [figureFilter, selectedForDelete, handleFilterFigures]
    );

    const isFilterEmpty = useCallback(() => {
        return (
            figureFilter.category === "" &&
            figureFilter.formName === "" &&
            figureFilter.name === "" &&
            figureFilter.source === "" &&
            figureFilter.year === ""
        );
    }, [figureFilter]);

    return (
        <div className="admin-figures-container">
            <div className="container-header">
                <h1>Figures</h1>
                <div>
                    <Button
                        className="country-button"
                        primary
                        content="Upload"
                        onClick={() => setUploadModalOpen(true)}
                    />
                    <Button
                        className="country-button"
                        primary
                        content="Generate"
                        onClick={() => setGenerateModalOpen(true)}
                    />
                </div>
            </div>
            <Breadcrumbs />
            <div className="admin-figures-filter">
                <div className="filter-section">
                    <p>Name</p>
                    <Input
                        value={figureFilter.name}
                        onChange={e => setFigureFilter({ ...figureFilter, name: e.target.value })}
                        icon="search"
                    />
                </div>
                <div className="filter-section year">
                    <p>Year</p>
                    <Select
                        fluid
                        clearable
                        selectOnBlur={false}
                        value={figureFilter.year}
                        options={staticFilterOptions.year}
                        onChange={(e, data) =>
                            setFigureFilter({ ...figureFilter, year: data.value as string })
                        }
                    />
                </div>
                <div className="filter-section source">
                    <p>Source</p>
                    <Select
                        fluid
                        clearable
                        selectOnBlur={false}
                        value={figureFilter.source}
                        options={figureSourceTypeOptions}
                        onChange={(e, data) =>
                            setFigureFilter({
                                ...figureFilter,
                                source: data.value as string,
                            })
                        }
                    />
                </div>
                <div className="filter-section category">
                    <p>Category</p>
                    <Select
                        fluid
                        clearable
                        selectOnBlur={false}
                        value={figureFilter.category}
                        options={formCategories}
                        onChange={(e, data) =>
                            setFigureFilter({ ...figureFilter, category: data.value as string })
                        }
                    />
                </div>
                <div className="filter-section form">
                    <p>Form</p>
                    <Select
                        fluid
                        clearable
                        selectOnBlur={false}
                        value={figureFilter.formName}
                        options={formsAsDropdownProps}
                        onChange={(e, data) =>
                            setFigureFilter({ ...figureFilter, formName: data.value as string })
                        }
                    />
                </div>
                <Button
                    className="filter-button"
                    primary
                    content="Clear filter"
                    onClick={handleClearFilter}
                />
                <Popup
                    content="Filter is empty"
                    disabled={!isFilterEmpty()}
                    trigger={
                        <span>
                            <Button
                                basic
                                color="red"
                                content="Delete filtered"
                                disabled={isFilterEmpty()}
                                onClick={() => setDeleteFilteredModalOpen(true)}
                            />
                        </span>
                    }
                />
            </div>

            <div id="view-select">
                <Button
                    primary={figureListView}
                    content="List view"
                    icon="th list"
                    onClick={() => setFigureListView(true)}
                />
                <Button
                    primary={!figureListView}
                    content="Large icons"
                    icon="th large"
                    onClick={() => setFigureListView(false)}
                />
            </div>

            <div className="admin-figures-content">
                <Loader active={loadingFigures} inline="centered" />
                {!loadingFigures && filteredFigures.length > 0 && figureListView ? (
                    <>
                        <div className="delete-selection-row">
                            <Checkbox
                                label="Select all"
                                checked={selectedForDelete.length === filteredFigures.length}
                                onChange={(e, data) => {
                                    if (data.checked) {
                                        setSelectedForDelete(filteredFigures.map(f => f.id));
                                    } else {
                                        setSelectedForDelete([]);
                                    }
                                }}
                            />
                            <Popup
                                content="No figures selected..."
                                disabled={selectedForDelete.length > 0}
                                trigger={
                                    <span>
                                        <Button
                                            primary
                                            content="Delete selected"
                                            disabled={selectedForDelete.length === 0}
                                            onClick={() => setDeleteSelectedModalOpen(true)}
                                        />
                                    </span>
                                }
                            />
                        </div>
                        {filteredFigures.map(ff => {
                            return (
                                <FigureDisplay
                                    key={ff.id}
                                    figure={ff}
                                    selectedForDelete={selectedForDelete.includes(ff.id)}
                                    setSelectedForDelete={handleAddToSelectedForDelete}
                                    setFigureToEdit={setFigureToEdit}
                                    setFigureToOpen={setFigureToOpen}
                                    setFigureToDelete={setFigureToDelete}
                                    handleFigureDownload={handleFigureImageDownload}
                                />
                            );
                        })}
                    </>
                ) : !loadingFigures && filteredFigures.length > 0 && !figureListView ? (
                    <div className="img-grid">
                        {filteredFigures.map(ff => {
                            return (
                                <FigurePreviewDisplay
                                    key={ff.id}
                                    figure={ff}
                                    setFigureToEdit={setFigureToEdit}
                                    setFigureToOpen={setFigureToOpen}
                                    setFigureToDelete={setFigureToDelete}
                                    handleFigureDownload={handleFigureImageDownload}
                                    handleRegenerateFigure={setFigureToRegenerate}
                                />
                            );
                        })}
                    </div>
                ) : (
                    <p>There are no figures.</p>
                )}
            </div>
            <GenerateModal
                generateModalOpen={generateModalOpen}
                closeGenerateModal={() => setGenerateModalOpen(false)}
                refreshImages={handleFilterFigures}
                allFormsWithAttributes={allFormsWithAttributes}
            />
            <UploadModal
                uploadModalOpen={uploadModalOpen}
                closeUploadModal={() => setUploadModalOpen(false)}
                allFormsWithAttributes={allFormsWithAttributes}
                handleClearFilter={handleClearFilter}
                handleFormsAsDropdownProps={handleFormsAsDropdownProps}
                formCategories={formCategories}
            />
            <UploadEditModal
                uploadedEditModalOpen={figureToEdit.figureType === 0}
                figureToEdit={figureToEdit}
                setFigureToEdit={setFigureToEdit}
                allFormsWithAttributes={allFormsWithAttributes}
                handleClearFilter={handleClearFilter}
                formCategories={formCategories}
            />
            <GenerateEditModal
                generatedEditModalOpen={figureToEdit.figureType === 1}
                figureToEdit={figureToEdit}
                setFigureToEdit={setFigureToEdit}
                handleClearFilter={handleClearFilter}
                formCategories={formCategories}
                formsAsDropdownProps={formsAsDropdownProps}
            />
            <DeleteFigureModal
                deleteFigureModalOpen={figureToDelete.id !== ""}
                closeDeleteFigureModal={() => setFigureToDelete(figureDtoInit)}
                figureToDelete={figureToDelete}
                handleClearFilter={handleClearFilter}
                refreshFigures={handleFilterFigures}
            />
            <RegenerateModal
                regenerateModalOpen={figureToRegenerate.id !== ""}
                figureToRegenerate={figureToRegenerate}
                setFigureToRegenerate={setFigureToRegenerate}
                refreshFigures={handleFilterFigures}
            />
            {figureToOpen.id && (
                <Modal open onClose={() => setFigureToOpen(figureDtoInit)}>
                    <Modal.Header>{figureToOpen.name}</Modal.Header>
                    <Modal.Content className="image-content-container">
                        <img
                            className="figure"
                            alt={figureToOpen.name}
                            src={`/api/figures/${figureToOpen.id}`}
                        />
                    </Modal.Content>
                    <Modal.Actions>
                        <Button onClick={() => setFigureToOpen(figureDtoInit)} content="Close" />
                    </Modal.Actions>
                </Modal>
            )}
            <div className="figures-pagination-container">
                <Pagination
                    activePage={page}
                    totalPages={Math.ceil(totalRows / numberOfItemsToFetch)}
                    onPageChange={(event, data) => setPage(data.activePage as number)}
                />
            </div>
            <Modal open={deleteSelectedModalOpen} onClose={() => setDeleteSelectedModalOpen(false)}>
                <Modal.Header>Delete selected figures</Modal.Header>
                <Modal.Content>
                    <p>Are you sure you want to delete the selected figures?</p>
                    <p>
                        Figures will remain visible in published reports, but will NOT be available
                        for new reports including reports created as a hard copy of a previously
                        published report or for published reports that are unpublished.
                    </p>
                    <p>This action is not reversible!</p>
                </Modal.Content>
                <Modal.Actions>
                    <Button content="Cancel" onClick={() => setDeleteSelectedModalOpen(false)} />
                    <Button primary content="Delete" onClick={() => handleBuklDelete(false)} />
                </Modal.Actions>
            </Modal>
            <Modal open={deleteFilteredModalOpen} onClose={() => setDeleteFilteredModalOpen(false)}>
                <Modal.Header>Delete filtered figures</Modal.Header>
                <Modal.Content>
                    <p>
                        Are you sure you want to delete the filtered figures? This will delete all
                        figures that would be returned by the current filter, including figures that
                        are on different pages in the pagination.
                    </p>
                    <p>
                        Figures will remain visible in published reports, but will NOT be available
                        for new reports including reports created as a hard copy of a previously
                        published report or for published reports that are unpublished.
                    </p>
                    <p>This action is not reversible!</p>
                </Modal.Content>
                <Modal.Actions>
                    <Button content="Cancel" onClick={() => setDeleteFilteredModalOpen(false)} />
                    <Button primary content="Delete" onClick={() => handleBuklDelete(true)} />
                </Modal.Actions>
            </Modal>
        </div>
    );
};
