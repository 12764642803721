import { useCallback, useEffect, useState } from "react";
import { Checkbox, Segment } from "semantic-ui-react";
import { toast } from "../..";
import { editDataViewAvailability, getDataViewFormYears } from "../../services/formsService";

export const NonExhaustiveSection = () => {
    const [loading, setLoading] = useState<boolean>(false);
    const [publishedNonExhaustiveFrt, setPublishedNonExhaustiveFrt] = useState<
        {
            year: number;
            isPublic: boolean;
        }[]
    >([]);
    const [publishedNonExhaustiveAdditional, setPublishedNonExhaustiveAdditional] = useState<
        {
            year: number;
            isPublic: boolean;
        }[]
    >([]);

    const fetchNonExhaustive = useCallback(async () => {
        try {
            setLoading(true);
            const frtRes = await getDataViewFormYears("frt");
            const additionalRes = await getDataViewFormYears("frtadditional");

            setPublishedNonExhaustiveFrt(frtRes.years);
            setPublishedNonExhaustiveAdditional(additionalRes.years);
        } catch (err) {
            toast('Unable to fetch data for "Non exhaustive parameters" section', false, 4000);
        } finally {
            setLoading(false);
        }
    }, [setPublishedNonExhaustiveFrt]);

    useEffect(() => {
        fetchNonExhaustive();
    }, [fetchNonExhaustive]);

    const updatePublicVisibilityForNonExhaustive = async (
        year: number,
        isPublic: boolean,
        dataSection: string
    ) => {
        try {
            await editDataViewAvailability(dataSection, isPublic, Array(1).fill(year));
            toast("Visibility successfully updated", true, 3000);

            let copyNonExh =
                dataSection === "frt"
                    ? [...publishedNonExhaustiveFrt]
                    : [...publishedNonExhaustiveAdditional];
            copyNonExh = copyNonExh.map(ne => {
                if (ne.year !== year) {
                    return ne;
                } else {
                    return { year: ne.year, isPublic: isPublic };
                }
            });

            if (dataSection === "frt") {
                setPublishedNonExhaustiveFrt(copyNonExh);
            } else {
                setPublishedNonExhaustiveAdditional(copyNonExh);
            }
        } catch (err) {
            toast("Unable to edit data view visibility.", false, 3000);
        }
    };

    return (
        <div className="non-exhaustive-container section-container">
            <div className="title-container">
                <h2>Dynamic graphs - Non exhaustive parameters:</h2>
            </div>
            <Segment>
                {publishedNonExhaustiveFrt.length > 0 ? (
                    <>
                        {publishedNonExhaustiveFrt.map(nonExh => {
                            return (
                                <div className="publication-section-row">
                                    <span>
                                        <b>[FRT non-exhaustive parameters]</b> External publication
                                        enabled:
                                    </span>
                                    <Checkbox
                                        toggle
                                        checked={nonExh.isPublic}
                                        onChange={(e, data) =>
                                            updatePublicVisibilityForNonExhaustive(
                                                nonExh.year,
                                                data.checked as boolean,
                                                "frt"
                                            )
                                        }
                                    />
                                </div>
                            );
                        })}

                        <br />

                        {publishedNonExhaustiveAdditional.map(nonExh => {
                            return (
                                <div className="publication-section-row">
                                    <span>
                                        <b>[Other non-exhaustive parameters]</b> External
                                        publication enabled:
                                    </span>
                                    <Checkbox
                                        toggle
                                        checked={nonExh.isPublic}
                                        onChange={(e, data) =>
                                            updatePublicVisibilityForNonExhaustive(
                                                nonExh.year,
                                                data.checked as boolean,
                                                "frtadditional"
                                            )
                                        }
                                    />
                                </div>
                            );
                        })}
                    </>
                ) : (
                    <div>{loading ? "Loading..." : "No results found"}</div>
                )}
            </Segment>
        </div>
    );
};
