import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { Menu, Popup, Tab } from "semantic-ui-react";
import { GraphDisplay } from "./GraphDisplay";

interface IProps {
    isPgm: boolean;
    paneNames: string[];
    chartType: string;
    years?: number[];
    setYears: Dispatch<SetStateAction<number[]>>;
    allowMultipleYears: Dispatch<SetStateAction<boolean>>;
    isMixedYears?: boolean;
}

const getPaneArray = ({
    paneNames,
    isPgm,
    years,
    setYears,
    allowMultipleYears,
    chartType,
    isMixedYears,
}: IProps) => {
    return paneNames.map(name => {
        return {
            menuItem: (
                <Menu.Item disabled={isMixedYears} className="menu-popup-container">
                    <Popup
                        position="top center"
                        disabled={!isMixedYears || name === "Total"}
                        trigger={<span className="menu-popup-trigger">{name}</span>}
                        content="Warning! For years prior to 2022, only graphs showing Total value are shown."
                    />
                </Menu.Item>
            ),
            render: () => (
                <GraphDisplay
                    technology={name}
                    type={chartType}
                    isPgm={isPgm}
                    years={years}
                    setYears={setYears}
                    allowMultipleYears={allowMultipleYears}
                />
            ),
        };
    });
};

// For years 2022 and later, we are adding an additional dimension and
// using different generator for data gathering.
const newDimensionStartYear = 2022;

export const ThirdLevelTabs = ({
    isPgm,
    chartType,
    paneNames,
    years,
    setYears,
    allowMultipleYears,
}: IProps) => {
    const [activeIndex, setActiveIndex] = useState(0);
    // If graph contains both years before and after 2022, we are showing total data, as data was not collected for individual technologies prior to 2022.
    const [isMixedYears, setIsMixedYears] = useState(false);

    useEffect(() => {
        setIsMixedYears(
            years !== undefined && years.some(y => y < 2022) && years.some(y => y >= 2022)
        );
    }, [setIsMixedYears, years]);

    useEffect(() => {
        if (isMixedYears) {
            setActiveIndex(4);
        }
    }, [isMixedYears]);

    return (
        <Tab.Pane>
            <div
                className={`third-level-tabs ${
                    isPgm && years && Math.max(...years) >= newDimensionStartYear
                        ? "show"
                        : "hidden"
                }`}
            >
                <Tab
                    menu={{ fluid: false, vertical: false, tabular: true }}
                    activeIndex={activeIndex}
                    onTabChange={(_, { activeIndex }) => setActiveIndex(activeIndex as number)}
                    panes={getPaneArray({
                        paneNames,
                        isPgm,
                        years,
                        setYears,
                        allowMultipleYears,
                        chartType,
                        isMixedYears,
                    })}
                ></Tab>
            </div>
        </Tab.Pane>
    );
};
