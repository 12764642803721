// @ts-ignore
import Gradient from "javascript-color-gradient";

function shuffleArray(array: string[]) {
    for (let i = array.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [array[i], array[j]] = [array[j], array[i]];
    }
}

export function getGradient(num: number): string[] {
    const colorGradient = new Gradient();
    colorGradient.setGradient(
        "#461156",
        "#B9037E",
        "#0F218B",
        "#00947F",
        "#28ACBF",
        "#FFCC00",
        "#CDB681",
        "#4B4748"
    );
    colorGradient.setMidpoint(num);
    let colorArray: string[] = colorGradient.getArray();
    shuffleArray(colorArray);
    return colorArray;
}

export function getGradientBetween(start: string, stop: string, num: number): string[] {
    const colorGradient = new Gradient();
    colorGradient.setGradient(start, stop);
    colorGradient.setMidpoint(num);
    let colorArray: string[] = colorGradient.getArray();
    return colorArray;
}

export function shadeColor(color: string, percent: number) {
    let R = parseInt(color.substring(1, 3), 16);
    let G = parseInt(color.substring(3, 5), 16);
    let B = parseInt(color.substring(5, 7), 16);

    R = Math.floor((R * (100 + percent)) / 100);
    G = Math.floor((G * (100 + percent)) / 100);
    B = Math.floor((B * (100 + percent)) / 100);

    R = R < 255 ? R : 255;
    G = G < 255 ? G : 255;
    B = B < 255 ? B : 255;

    let RR = R.toString(16).length === 1 ? `0${R.toString(16)}` : R.toString(16);
    let GG = G.toString(16).length === 1 ? `0${G.toString(16)}` : G.toString(16);
    let BB = B.toString(16).length === 1 ? `0${B.toString(16)}` : B.toString(16);

    return `#${RR}${GG}${BB}`;
}