import { useCallback, useEffect, useState } from "react";
import { Checkbox, Segment } from "semantic-ui-react";
import { toast } from "../..";
import { editDataViewAvailability, getDataViewFormYears } from "../../services/formsService";

export const GenerationCapacitiesSection = () => {
    const [loading, setLoading] = useState<boolean>(false);
    const [publishedGenCapacity, setPublishedGenCapacity] = useState<
        { year: number; isPublic: boolean }[]
    >([]);

    const fetchGenCapacity = useCallback(async () => {
        try {
            setLoading(true);
            const res = await getDataViewFormYears("generationcapacity");
            setPublishedGenCapacity(res.years);
        } catch (err) {
            toast(
                'Unable to fetch data for "Data view - Generation capacity" section',
                false,
                4000
            );
        } finally {
            setLoading(false);
        }
    }, [setPublishedGenCapacity]);

    useEffect(() => {
        fetchGenCapacity();
    }, [fetchGenCapacity]);

    const updateVisibilityForGenCap = async (year: number, isPublic: boolean) => {
        try {
            await editDataViewAvailability("generationCapacity", isPublic, Array(1).fill(year));
            toast("Visibility successfully updated", true, 3000);

            let copyGenCap = [...publishedGenCapacity];
            copyGenCap = copyGenCap.map(gc => {
                if (gc.year !== year) {
                    return gc;
                } else {
                    return { year: gc.year, isPublic: isPublic };
                }
            });

            setPublishedGenCapacity(copyGenCap);
        } catch (err) {
            toast("Unable to edit generation capacity visibility.", false, 3000);
        }
    };

    return (
        <div className="gen-capacity-container section-container">
            <div className="title-container">
                <h2>Dynamic graphs - Generation capacities:</h2>
            </div>
            <Segment>
                {publishedGenCapacity.length > 0 ? (
                    <>
                        {publishedGenCapacity.map(genCap => {
                            return (
                                <div className="publication-section-row">
                                    <span>
                                        <b>[{genCap.year}]</b> External publication enabled:{" "}
                                    </span>
                                    <Checkbox
                                        toggle
                                        checked={genCap.isPublic}
                                        onChange={(e, data) =>
                                            updateVisibilityForGenCap(
                                                genCap.year,
                                                data.checked as boolean
                                            )
                                        }
                                    />
                                </div>
                            );
                        })}
                    </>
                ) : (
                    <div>{loading ? "Loading..." : "No results found"}</div>
                )}
            </Segment>
        </div>
    );
};
