import { Dispatch, SetStateAction } from "react";
import { Icon } from "semantic-ui-react";
import { IFigureDto } from "../../models/figure";

export interface IProps {
    figure: IFigureDto;
    setFigureToEdit: Dispatch<SetStateAction<IFigureDto>>;
    setFigureToOpen: Dispatch<SetStateAction<IFigureDto>>;
    setFigureToDelete: Dispatch<SetStateAction<IFigureDto>>;
    handleFigureDownload: (id: string, name: string) => void;
    handleRegenerateFigure: (figure: IFigureDto) => void;
}

export const FigurePreviewDisplay = ({
    figure,
    setFigureToEdit,
    setFigureToOpen,
    setFigureToDelete,
    handleFigureDownload,
    handleRegenerateFigure,
}: IProps) => {
    return (
        <div className="action-bar-container large-icons" key={figure.id}>
            <div className="img-contain">
                <img
                    src={`/api/figures/${figure.id}?thumbnail=true`}
                    alt={figure.name}
                    onClick={() => setFigureToOpen(figure)}
                />
                <p>{figure.name}</p>
            </div>
            <div className="action-bar-container-options vertical">
                {figure.originalQuestionId && (
                    <Icon
                        id="button-regenerate"
                        name="redo"
                        bordered
                        onClick={() => handleRegenerateFigure(figure)}
                    />
                )}
                <Icon
                    id="button-edit"
                    name="edit"
                    bordered
                    onClick={() => setFigureToEdit(figure)}
                />
                <Icon
                    id="button-download"
                    name="download"
                    bordered
                    onClick={() => handleFigureDownload(figure.id, figure.name)}
                />
                <Icon
                    id="button-delete"
                    name="delete"
                    bordered
                    onClick={() => setFigureToDelete(figure)}
                />
            </div>
        </div>
    );
};
