import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { Button, Checkbox, Input, Select } from "semantic-ui-react";
import { ISubQuestion, SubQuestionType, SubQuestionTypeOptions } from "../../../../models/question";
import { ITableInputGroup } from "../../../../models/tableInputGroups";

export interface ISubQuestionFormInputProps {
    intialValue: ISubQuestion;
    setInitialValue:
        | Dispatch<SetStateAction<ISubQuestion | undefined>>
        | Dispatch<SetStateAction<ISubQuestion>>;
    saveButtonText: string;
    saveButtonIcon: string;
    groups?: ITableInputGroup[];
    onSaveAction: () => void;
}

export const SubQuestionFormInput = ({
    intialValue,
    setInitialValue,
    saveButtonText,
    saveButtonIcon,
    groups,
    onSaveAction,
}: ISubQuestionFormInputProps) => {
    const [subQuestionError, setSubQuestionError] = useState<string>("");
    const [valid, setValid] = useState<boolean>(true);

    const validateAndSave = () => {
        let validationFlag = true;
        if (!intialValue.dimensionText) {
            setSubQuestionError(
                `Name of the ${intialValue.isPrimaryDimension ? "row" : "column"} is mandatory.`
            );
            validationFlag = false;
        }
        if (!intialValue.isPrimaryDimension && !intialValue.subQuestionType) {
            setSubQuestionError("You must define the answer type for column.");
            validationFlag = false;
        }

        if (validationFlag) {
            onSaveAction();
        } else {
            setValid(validationFlag);
        }
    };

    const handleKeyPress = (e: React.KeyboardEvent<HTMLDivElement>) => {
        e.stopPropagation();
        if (valid && e.key === "Enter") {
            validateAndSave();
        }
    };

    useEffect(() => {
        setSubQuestionError("");
        setValid(true);
    }, [intialValue]);

    return (
        <div>
            <div className="table-dimension-input" onKeyPress={handleKeyPress}>
                <Input
                    autoFocus
                    placeholder={`New ${intialValue.isPrimaryDimension ? "row" : "column"} name...`}
                    key={intialValue.id}
                    value={intialValue.dimensionText}
                    onChange={e =>
                        setInitialValue({
                            ...intialValue,
                            dimensionText: e.target.value,
                        })
                    }
                />
                {!intialValue.isPrimaryDimension && (
                    <Select
                        clearable
                        selectOnBlur={false}
                        placeholder="Select type"
                        options={SubQuestionTypeOptions}
                        value={intialValue.subQuestionType}
                        onChange={(e, d) => {
                            setInitialValue({
                                ...intialValue,
                                subQuestionType: d.value as SubQuestionType,
                            });
                        }}
                    />
                )}
                <Select
                    clearable
                    selectOnBlur={false}
                    placeholder="Select group"
                    value={intialValue.groupId}
                    options={
                        groups
                            ? groups.map(g => {
                                  return { key: g.id, text: g.name, value: g.id };
                              })
                            : []
                    }
                    onChange={(e, d) => {
                        setInitialValue({ ...intialValue, groupId: d.value as string });
                    }}
                />
                <Checkbox
                    className="required-checkbox"
                    label="Required"
                    checked={intialValue.isRequired}
                    onChange={(e, data) =>
                        setInitialValue({ ...intialValue, isRequired: data.checked as boolean })
                    }
                />

                <Button
                    primary
                    type="button"
                    content={saveButtonText}
                    icon={saveButtonIcon}
                    disabled={!valid}
                    onClick={validateAndSave}
                />
            </div>
            {subQuestionError && (
                <span className="alert">
                    * <span>{subQuestionError}</span>
                </span>
            )}
        </div>
    );
};
