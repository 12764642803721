import { IOption } from "./option";
import { ITableInputGroup } from "./tableInputGroups";

export type QuestionType =
    | "ShortText"
    | "Number"
    | "Paragraph"
    | "MultipleChoice"
    | "Checkboxes"
    | "TableInput";

export type SubQuestionType = "Text" | "Numeric";

export interface IQuestion {
    id: string;
    text: string;
    type: QuestionType;
    min?: number;
    max?: number;
    required: boolean;
    remark?: boolean;
    branching: boolean;
    fileUpload: boolean;
    imageGuid?: string;
    orderNumber: number;
    section: string;
    options: IOption[];
    visible?: boolean;
    other: boolean;
    isLinked?: boolean;
    hasParent: boolean;
    subQuestions?: ISubQuestion[];
    groups?: ITableInputGroup[];
}

export interface ISubQuestion {
    id: string;
    dimensionText: string;
    dimensionOrderNumber: number;
    isRequired: boolean;
    isPrimaryDimension: boolean;
    subQuestionType: SubQuestionType;
    groupId?: string;
}

export const subQuestionInit: ISubQuestion = {
    id: "",
    dimensionText: "",
    dimensionOrderNumber: 1,
    isRequired: false,
    isPrimaryDimension: true,
    subQuestionType: "Text",
    groupId: "",
};

export const QuestionTypeOptions = [
    { key: "ShortText", value: "ShortText", text: "Short text" },
    { key: "Number", value: "Number", text: "Number" },
    { key: "Paragraph", value: "Paragraph", text: "Paragraph" },
    { key: "MultipleChoice", value: "MultipleChoice", text: "Multiple choice" },
    { key: "Checkboxes", value: "Checkboxes", text: "Checkboxes" },
    { key: "TableInput", value: "TableInput", text: "Table input" },
];

export const SubQuestionTypeOptions = [
    { key: "Text", value: "Text", text: "Text input" },
    { key: "Number", value: "Numeric", text: "Number input" },
];

export const BranchingActionOptions = [
    { key: "Next", value: "clear", text: "Next" },
    { key: "SkipToQuestion", value: "SkipToQuestion", text: "Skip to question" },
    { key: "SkipToNextSection", value: "SkipToNextSection", text: "Skip to next section" },
    { key: "SkipToEnd", value: "SkipToEnd", text: "Skip to end" },
];
