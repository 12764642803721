import { Dispatch, SetStateAction } from "react";
import { IQuestion } from "../../../../models/question";
import { ITableInputGroup } from "../../../../models/tableInputGroups";

export interface IGroupLegendProps {
    question: IQuestion;
    highlightedGroup?: ITableInputGroup;
    setHighlightedGroup?: Dispatch<SetStateAction<ITableInputGroup | undefined>>;
}

export const GroupLegend = ({
    question,
    highlightedGroup,
    setHighlightedGroup,
}: IGroupLegendProps) => {
    return (
        <>
            {question.groups && question.groups.length > 0 && (
                <div className="group-legend">
                    {question.groups.map(g => {
                        return (
                            <span
                                key={g.id}
                                className={`legend-element ${
                                    setHighlightedGroup ? "clickable" : ""
                                } ${highlightedGroup === g ? "selected" : ""}`}
                                onClick={() =>
                                    setHighlightedGroup?.(highlightedGroup !== g ? g : undefined)
                                }
                            >
                                <span
                                    className="circle"
                                    style={{
                                        backgroundColor: g.backgroundColorHex,
                                    }}
                                ></span>
                                <span>{g.name}</span>
                            </span>
                        );
                    })}
                </div>
            )}
        </>
    );
};
