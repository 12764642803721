import { NclpForms } from "../../components/NclpForms/NclpForms";
import './Dashboard.css';
import { NewsFeed } from "./NewsFeed";

export function NCLPDashboard() {
    return <div>
        <div id="news-feed" className="home-section">
            <NewsFeed />
        </div>
        <div className="home-section">
            <NclpForms withLegend />
        </div>
    </div>
}