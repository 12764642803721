import {
    ChartGenerationRequest,
    IChartGenerationInfo,
    IChartInfoData,
    IChartInfoDataSingle,
    IFigureData,
    IFigureFilter,
} from "../models/figure";
import { getAuthorizationProperties } from "./authorizationHelper";

export async function generateFigure(
    chartInfoData: IChartInfoData
): Promise<ChartGenerationRequest> {
    const response = await fetch(`/api/charts`, {
        method: "POST",
        ...getAuthorizationProperties({ "Content-Type": "application/json" }),
        body: JSON.stringify(chartInfoData),
    });

    return response.json();
}

export async function pingFiguresRequest(guid: string): Promise<IChartGenerationInfo> {
    const response = await fetch(`/api/charts/ping/${guid}`, {
        method: "GET",
        ...getAuthorizationProperties({ "Content-Type": "application/json" }),
    });

    return response.json();
}

export async function cancelFigureGeneration(guid: string) {
    return await fetch(`/api/charts/cancel/${guid}`, {
        method: "GET",
        ...getAuthorizationProperties({ "Content-Type": "application/json" }),
    });
}

export async function getChartData<T>(
    chartType: string,
    year: number,
    technology?: string
): Promise<T> {
    return await fetch(`/api/charts/data/${chartType}?year=${year}&technology=${technology}`, {
        method: "GET",
        ...getAuthorizationProperties({ "Content-Type": "application/json" }),
    })
        .then(res => res.json())
        .then(res => {
            return res.data;
        });
}

export async function getChartYears(chartType: string): Promise<number[]> {
    return await fetch(`/api/charts/data/years/${chartType}`, {
        method: "GET",
        ...getAuthorizationProperties({ "Content-Type": "application/json" }),
    }).then(res => res.json());
}

export async function uploadFigure(dto: IFigureData, file: File | undefined) {
    const formData = new FormData();
    formData.append("dto", JSON.stringify(dto));
    file && formData.append("file", file, file.name);
    return await fetch("/api/figures", {
        method: "POST",
        ...getAuthorizationProperties({}),
        body: formData,
    });
}

export async function editUploadedFigure(dto: IFigureData) {
    let res = await fetch("/api/figures", {
        method: "PUT",
        ...getAuthorizationProperties({ "Content-Type": "application/json" }),
        body: JSON.stringify(dto),
    });
    if (res.ok) {
        return res.json();
    }
    throw Error;
}

export async function getFigureLabels(id: string) {
    return await fetch(`/api/figures/labels/${id}`, {
        method: "GET",
        ...getAuthorizationProperties({ "Content-Type": "application/json" }),
    }).then(res => {
        if (res.ok) {
            return res.json();
        }
        throw Error;
    });
}

export async function regenerateFigure(data: IChartInfoDataSingle) {
    return await fetch(`/api/charts/regenerate`, {
        method: "POST",
        ...getAuthorizationProperties({ "Content-Type": "application/json" }),
        body: JSON.stringify(data),
    }).then(res => {
        if (res.ok) {
            return Promise.resolve();
        }
        throw Promise.reject();
    });
}

export async function editGeneratedFigure(dto: IFigureData) {
    return await fetch("/api/figures", {
        method: "PATCH",
        ...getAuthorizationProperties({ "Content-Type": "application/json" }),
        body: JSON.stringify(dto),
    }).then(res => {
        if (res.ok) {
            return res.json();
        }
        throw Error;
    });
}

export async function getFigures(filter: IFigureFilter, page: number, items: number) {
    const params = new URLSearchParams({ page: page.toString(), items: items.toString() });
    Object.entries(filter).forEach(x => {
        if (x[1] !== undefined && x[1] !== null && x[1] !== "") {
            params.append(x[0], x[1]);
        }
    });
    return await fetch("/api/figures?" + params.toString(), {
        method: "GET",
        ...getAuthorizationProperties({ "Content-Type": "application/json" }),
    }).then(res => {
        if (res.ok) {
            return res.json();
        }
        throw Error;
    });
}

export async function bulkDeleteFigures(
    filter: IFigureFilter,
    selectedFigure: string[],
    withFilter: boolean
) {
    const params = new URLSearchParams();

    if (withFilter) {
        Object.entries(filter).forEach(x => {
            if (x[1] !== undefined && x[1] !== null && x[1] !== "") {
                params.append(x[0], x[1]);
            }
        });
    } else {
        selectedFigure.forEach(x => {
            params.append("selectedFigure", x);
        });
    }

    return await fetch("/api/figures/bulk?" + params.toString(), {
        method: "DELETE",
        ...getAuthorizationProperties({ "Content-Type": "application/json" }),
    }).then(res => {
        if (!res.ok) {
            return Promise.reject();
        }

        return Promise.resolve();
    });
}

export async function getFigureImage(guid: string, filename: string, thumbnail: boolean) {
    const res = await fetch(`/api/figures/${guid}?thumbnail=${thumbnail}`, {
        method: "GET",
        ...getAuthorizationProperties({ "Content-Type": "application/json" }),
    });
    if (!res.ok) return false;
    const blob = await res.blob();
    const url = window.URL.createObjectURL(new Blob([blob], { type: "image/png" }));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", `${filename}.png`);
    document.body.appendChild(link);
    link.click();
    link.parentNode!.removeChild(link);
    return true;
}

export async function getFigureImagePreview(guid: string) {
    return await fetch(`/api/figures/${guid}`, {
        method: "GET",
        ...getAuthorizationProperties({ "Content-Type": "application/json" }),
    });
}

export async function deleteFigure(guid: string): Promise<boolean> {
    const response = await fetch(`/api/figures/${guid}`, {
        method: "DELETE",
        ...getAuthorizationProperties({ "Content-Type": "application/json" }),
    });
    return response.ok;
}
