import { useState, useEffect, useCallback } from "react";
import { IChapter } from "../../models/chapter";
import { Link, useHistory, useParams } from "react-router-dom";
import { Button, Icon, Modal, Loader, Popup } from "semantic-ui-react";
import { ReportPublishControls } from "../Report/ReportPublishControls";
import {
    getChaptersForReport,
    createNewChapter,
    updateChapters,
} from "../../services/chaptersService";
import { toast } from "../..";
import Tree, {
    RenderItemParams,
    TreeData,
    TreeItem,
    TreeSourcePosition,
    TreeDestinationPosition,
    moveItemOnTree,
} from "@atlaskit/tree";
import { ChapterEditModalContent } from "./ChapterEditModalContent";
import { Breadcrumbs } from "../../components/Breadcrumbs/Breadcrumbs";
import "./Chapters.css";

const chaptersToTree = (chapters: IChapter[]): TreeData => {
    const result: TreeData = {
        rootId: "tree-root",
        items: {
            "tree-root": {
                id: "tree-root",
                hasChildren: chapters && chapters.length > 0,
                children: [],
            },
        },
    };

    const sorted = [...chapters].sort((a, b) => a.depth - b.depth);
    sorted.forEach(chapter => {
        const hasChildren = chapters.some(
            c => c.parentChapterId && c.parentChapterId === chapter.id
        );

        const treeItem: TreeItem = {
            id: chapter.id,
            hasChildren,
            isExpanded: hasChildren,
            children: [],
        };

        const parentId = chapter.parentChapterId?.startsWith("00000000")
            ? "tree-root"
            : chapter.parentChapterId!;
        result.items[chapter.id] = treeItem;
        result.items[parentId].children.push(chapter.id);
    });

    return result;
};

const addLayer = (tree: TreeData, chapters: IChapter[], previous: string[], result: IChapter[]) => {
    let added: string[] = [];

    previous.forEach(parentId => {
        let item: TreeItem = tree.items[parentId];
        let parent = chapters.find(c => c.id === parentId);
        let orderNumber = 1;
        let nonNumberedCount = 0;
        item.children.forEach(id => {
            let chapter = chapters.find(c => c.id === id);
            if (chapter) {
                let numbered = chapter.numbered && (!parent || parent.numbered);
                let sectionNumber: string | undefined = parent?.sectionNumber
                    ? `${parent.sectionNumber}.${orderNumber - nonNumberedCount}`
                    : (orderNumber - nonNumberedCount).toString();
                sectionNumber = numbered ? sectionNumber : undefined;
                nonNumberedCount += numbered ? 0 : 1;

                added.push(id.toString());
                const newChapter = {
                    ...chapter,
                    orderNumber,
                    numbered,
                    sectionNumber,
                    depth: parent ? parent.depth + 1 : 1,
                    parentChapterId:
                        parentId === "tree-root"
                            ? "00000000-0000-0000-0000-000000000000"
                            : parentId,
                };
                result.push(newChapter);
                orderNumber++;
                chapters = chapters.filter(c => c.id !== newChapter.id);
                chapters = [...chapters, newChapter];
            }
        });
    });

    if (added.length > 0) {
        addLayer(tree, chapters, added, result);
    }
};

const deleteChapterAndDescendants = (chapters: IChapter[], chapterId: string) => {
    let newChapters = [...chapters];
    let previous = [chapterId];

    while (previous.length > 0) {
        const loopPrevious = [...previous];
        const loopChapters = newChapters.filter(c => !loopPrevious.includes(c.id));
        newChapters = loopChapters;

        let toRemove: string[] = [];

        previous.forEach(parentId => {
            let children = loopChapters.filter(c => c.parentChapterId === parentId);
            children.forEach(c => {
                toRemove.push(c.id);
            });
        });

        previous = toRemove;
    }

    newChapters.sort((a, b) => a.orderNumber - b.orderNumber);
    return newChapters;
};

const treeToChapters = (tree: TreeData, chapters: IChapter[]): IChapter[] => {
    const result: IChapter[] = [];
    let previousLayer = ["tree-root"];
    addLayer(tree, [...chapters], previousLayer, result);
    result.sort((a, b) => a.orderNumber - b.orderNumber);
    return result;
};

export const Chapters = () => {
    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
    const [createDialogOpen, setCreateDialogOpen] = useState(false);
    const [editDialogOpen, setEditDialogOpen] = useState(false);
    const [activeId, setActiveId] = useState<string>();
    const [chapters, setChapters] = useState<Array<IChapter>>([]);
    const [treeData, setTreeData] = useState<TreeData | null>(null);
    const [chaptersLoading, setChaptersLoading] = useState(false);
    const params: { reportId: string } = useParams();
    const history = useHistory();

    const emptyChapter = {
        reportId: params.reportId,
        parentChapterId: undefined,
        numbered: true,
        title: "",
    };

    const [newChapter, setNewChapter] = useState<Partial<IChapter>>(emptyChapter);

    let refreshChapters = useCallback(() => {
        setChaptersLoading(true);
        getChaptersForReport(params.reportId).then(res => {
            setChapters(res);
            setTreeData(chaptersToTree(res));
            setChaptersLoading(false);
        });
    }, [params.reportId]);

    useEffect(() => {
        refreshChapters();
    }, [refreshChapters]);

    let onDeleteChapter = async () => {
        const oldTree = { ...treeData! };
        oldTree.items = { ...treeData!.items };
        const filtered = deleteChapterAndDescendants(chapters, activeId!);
        const newTreeData = chaptersToTree(filtered);
        setTreeData(newTreeData);
        const newChapters = treeToChapters(newTreeData, filtered);

        if (await updateChapters(newChapters, params.reportId)) {
            setChapters(newChapters);
            toast("Chapter deleted successfully", true, 1500);
        } else {
            toast("Error deleting chapter", false, 1500);
            setTreeData(oldTree);
        }

        setDeleteDialogOpen(false);
    };

    let onCreateChapter = () => {
        createNewChapter(newChapter)
            .then(res => {
                if (res) {
                    setCreateDialogOpen(false);
                    refreshChapters();
                    toast("Chapter created successfully", true, 1500);
                }
            })
            .catch(() => toast("Error while creating chapter", false, 1500));
    };

    let onEditChapter = () => {
        const target = chapters.find(c => c.id === newChapter.id);
        target!.title = newChapter.title!;
        target!.numbered = newChapter.numbered!;

        const filtered = chapters.filter(c => c.id !== target!.id);
        const newChapters = [...filtered, target!];

        updateChapters(treeToChapters(treeData!, newChapters), params.reportId)
            .then(res => {
                setEditDialogOpen(false);
                refreshChapters();
                toast("Chapter edited successfully", true, 1500);
            })
            .catch(() => toast("Error while editing chapter", false, 1500));
    };

    const getMaxChildDepth = (chapter: IChapter): number => {
        const children: IChapter[] = chapters.filter(c => c.parentChapterId === chapter.id);
        if (children.length === 0) {
            return chapter.depth;
        }
        let depth = chapter.depth;
        children.forEach(child => {
            let childDepth = getMaxChildDepth(child);
            depth = childDepth > depth ? childDepth : depth;
        });
        return depth;
    };

    const checkDepth = (
        source: TreeSourcePosition,
        destination: TreeDestinationPosition
    ): boolean => {
        const destinationChapter = chapters.find(c => c.id === destination.parentId);
        if (!destinationChapter) {
            return true;
        } else {
            const parentChapterId =
                source.parentId === "tree-root"
                    ? "00000000-0000-0000-0000-000000000000"
                    : source.parentId.toString();
            const chapter = chapters.find(
                c => c.parentChapterId === parentChapterId && c.orderNumber === source.index + 1
            );
            const movedStackHeight = getMaxChildDepth(chapter!) - chapter!.depth + 1;
            return destinationChapter.depth + movedStackHeight <= 5;
        }
    };

    const onDragEnd = async (source: TreeSourcePosition, destination?: TreeDestinationPosition) => {
        if (destination && treeData) {
            if (!checkDepth(source, destination)) {
                toast("Max depth exceed", false, 1500);
            } else {
                const oldTree = { ...treeData };
                oldTree.items = { ...treeData.items };
                const newTree = moveItemOnTree(treeData, source, destination);
                Object.values(newTree.items).forEach(i => (i.isExpanded = true));
                setTreeData(newTree);
                const newChapters = treeToChapters(newTree, chapters);
                if (await updateChapters(newChapters, params.reportId)) {
                    setChapters(newChapters);
                } else {
                    toast("Error saving reorder", false, 1500);
                    setTreeData(oldTree);
                }
            }
        }
    };

    const renderChapter = ({ item, provided }: RenderItemParams) => {
        const chapter = chapters.find(c => c.id === item.id);
        return chapter ? (
            <div
                className="sections-content-container"
                key={chapter.id}
                ref={provided.innerRef}
                {...provided.draggableProps}
                {...provided.dragHandleProps}
            >
                <div className="chapters-container">
                    <Link to={`/reports/overview/${params.reportId}/chapters/${chapter.id}`}>
                        {chapter.sectionNumber} {chapter.title}
                    </Link>
                </div>
                <div className="sections-actions-container">
                    <Icon
                        name="edit"
                        onClick={() => {
                            setNewChapter(chapter);
                            setEditDialogOpen(true);
                        }}
                        bordered
                    />
                    <Icon
                        name="content"
                        onClick={() =>
                            history.push(
                                `/reports/overview/${params.reportId}/chapters/${chapter.id}`
                            )
                        }
                        bordered
                    />
                    <Icon
                        name="delete"
                        onClick={() => {
                            setActiveId(chapter.id);
                            setDeleteDialogOpen(true);
                        }}
                        bordered
                    />
                </div>
            </div>
        ) : undefined;
    };

    return (
        <div className="table-of-contents">
            <div>
                <div className="container-header">
                    <h1>Table of Contents</h1>
                    <ReportPublishControls reportId={params.reportId} />
                </div>
                <Breadcrumbs />
                <div className="sections-container">
                    {chapters?.length === 0 && (
                        <>
                            {chaptersLoading ? (
                                <Loader active />
                            ) : (
                                <b>This report has no chapters.</b>
                            )}
                        </>
                    )}

                    <div className="tree-container">
                        {treeData && (
                            <Tree
                                tree={treeData}
                                renderItem={renderChapter}
                                offsetPerLevel={30}
                                onDragEnd={onDragEnd}
                                isDragEnabled
                                isNestingEnabled
                            />
                        )}
                    </div>

                    <Button
                        className="sections-add-new"
                        content="Add new chapter"
                        primary
                        onClick={() => {
                            setNewChapter(emptyChapter);
                            setCreateDialogOpen(true);
                        }}
                    />

                    <Modal
                        open={deleteDialogOpen}
                        onClose={() => setDeleteDialogOpen(false)}
                        className="delete-chapter-modal"
                    >
                        <Modal.Header>
                            <p>Are you sure you want to delete this chapter?</p>
                        </Modal.Header>
                        <Modal.Content>
                            <div className="buttons-modal">
                                <Button onClick={() => setDeleteDialogOpen(false)}>No</Button>
                                <Button onClick={onDeleteChapter}>Yes</Button>
                            </div>
                        </Modal.Content>
                    </Modal>

                    <Modal open={createDialogOpen} onClose={() => setCreateDialogOpen(false)}>
                        <Modal.Header>Creating a new chapter</Modal.Header>
                        <Modal.Content>
                            <ChapterEditModalContent
                                newChapter={newChapter}
                                setNewChapter={setNewChapter}
                                chapters={chapters}
                            />
                        </Modal.Content>
                        <Modal.Actions>
                            <Button content="Back" onClick={() => setCreateDialogOpen(false)} />
                            <Popup
                                disabled={newChapter.title !== ""}
                                content="Chapter title cannot be empty"
                                trigger={
                                    <span className="popup-btn-container">
                                        <Button
                                            disabled={!newChapter.title}
                                            content="Submit"
                                            onClick={onCreateChapter}
                                        />
                                    </span>
                                }
                            />
                        </Modal.Actions>
                    </Modal>

                    <Modal open={editDialogOpen} onClose={() => setEditDialogOpen(false)}>
                        <Modal.Header>Editing chapter</Modal.Header>
                        <Modal.Content>
                            <ChapterEditModalContent
                                newChapter={newChapter}
                                setNewChapter={setNewChapter}
                                chapters={chapters}
                                editMode
                            />
                        </Modal.Content>
                        <Modal.Actions>
                            <Button content="Back" onClick={() => setEditDialogOpen(false)} />
                            <Popup
                                disabled={newChapter.title !== ""}
                                content="Chapter title cannot be empty"
                                trigger={
                                    <span className="popup-btn-container">
                                        <Button
                                            disabled={!newChapter.title}
                                            content="Submit"
                                            onClick={onEditChapter}
                                        />
                                    </span>
                                }
                            />
                        </Modal.Actions>
                    </Modal>
                </div>
            </div>
            <div className="navigation-container">
                <Button
                    primary
                    content="Front page"
                    onClick={() => history.push(`/reports/overview/${params.reportId}/front-page`)}
                />
                {chapters.length !== 0 ? (
                    <Button
                        primary
                        content="Content"
                        onClick={() =>
                            history.push(
                                `/reports/overview/${params.reportId}/chapters/${chapters[0].id}`
                            )
                        }
                    />
                ) : null}
            </div>
        </div>
    );
};
