import { NewsFeed } from "./NewsFeed";
import { PublishedCarousel } from "../../components/ReportsCarousel/PublishedCarousel";
import { useSelector, useDispatch } from "react-redux";
import { AppState } from "../../store/configureStore";
import { Button, Modal } from "semantic-ui-react";
import { useEffect, useState } from "react";
import { UserRole, setUser } from "../../actions/authentificationActions";
import "./Dashboard.css";

export function ViewerDashboard() {
    const dispatch = useDispatch();
    const user = useSelector((state: AppState) => state.user);
    const [firstLoginModalOpen, setFirstLoginModalOpen] = useState(false);

    useEffect(() => {
        if (user.role === UserRole.Viewer && user.firstLogin) {
            setFirstLoginModalOpen(true);
        }
    }, [user, setFirstLoginModalOpen]);

    const handleModalClose = () => {
        setFirstLoginModalOpen(false);
        dispatch(setUser({ ...user, firstLogin: false }));
    };

    return (
        <div>
            <div id="news-feed" className="home-section">
                <NewsFeed />
            </div>
            <div className="home-section">
                <div className="container-header">
                    <h1>Published reports</h1>
                </div>
                <PublishedCarousel />
            </div>

            <Modal id="first-login-modal" open={firstLoginModalOpen} size="small" dimmer="blurring">
                <Modal.Header>Welcome to ENTSO-E Implementation Monitoring Platform.</Modal.Header>
                <Modal.Content>
                    If you are a designated NCLP please ask for data submission rights at{" "}
                    <a href="mailto:servicedesk@entsoe.eu">servicedesk@entsoe.eu</a>
                </Modal.Content>
                <Modal.Actions>
                    <Button primary onClick={handleModalClose}>
                        I understand
                    </Button>
                </Modal.Actions>
            </Modal>
        </div>
    );
}
