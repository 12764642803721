import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { Button, DropdownItemProps, Input, Popup, Segment, Select } from "semantic-ui-react";
import { toast } from "../..";
import { UserData, UserRole } from "../../actions/authentificationActions";
import { userInit } from "../../models/user";
import { getCountries } from "../../services/countriesService";
import { createUser } from "../../services/usersService";
import { userRoleOptions } from "../../store/userReducer";

interface IProps {
    users: UserData[];
    setUsers: Dispatch<SetStateAction<UserData[]>>;
}

export const AddUserForm: React.FC<IProps> = ({ users, setUsers }) => {
    const [newUser, setNewUser] = useState(userInit);
    const [loading, setLoading] = useState(false);
    const disabled =
        loading ||
        newUser.username === "" ||
        newUser.password === "" ||
        newUser.firstName === "" ||
        newUser.lastName === "" ||
        newUser.role === "" ||
        (newUser.role === UserRole.NCLP && newUser.countryGuid === "");
    const [countries, setCountries] = useState<DropdownItemProps[]>([]);
    const [tooltip, setTooltip] = useState<string[]>([]);

    useEffect(() => {
        getCountries(undefined, true).then(res => {
            let dropdownCountries: DropdownItemProps[] = res.map(c => {
                return {
                    key: c.id,
                    text: c.name,
                    value: c.id,
                };
            });
            setCountries(dropdownCountries);
        });
    }, []);

    useEffect(() => {
        let body: string[] = [];
        if (!newUser.username) body.push("Username");
        if (!newUser.password) body.push("Password");
        if (!newUser.firstName) body.push("First name");
        if (!newUser.lastName) body.push("Last name");
        if (!newUser.role) body.push("Role");
        if (!newUser.countryGuid && newUser.role === UserRole.NCLP) body.push("Country");

        if (!body.length) {
            setTooltip([]);
        } else {
            setTooltip(body);
        }
    }, [newUser]);

    const handleAddNewUser = async () => {
        setLoading(true);
        try {
            const res = await createUser(newUser);
            setUsers(users.concat(res));
            setNewUser(userInit);
            toast("User added successfully", true);
        } catch (err) {
            toast(err as string, true);
        } finally {
            setLoading(false);
        }
    };

    const handleChangeRole = (data: any) => {
        if (data.value === UserRole.Admin || data.value === UserRole.Viewer)
            setNewUser({ ...newUser, role: data.value, countryGuid: "" });
        else setNewUser({ ...newUser, role: data.value });
    };

    return (
        <div className="add-user-form-container">
            <h1>Add user</h1>
            <Segment>
                <form className="add-user-form">
                    <section className="input-container">
                        <label>Username</label>
                        <Input
                            value={newUser.username}
                            onChange={(e: any) =>
                                setNewUser({ ...newUser, username: e.target.value })
                            }
                        />
                    </section>
                    <section className="input-container">
                        <label>Password</label>
                        <Input
                            type="password"
                            value={newUser.password}
                            onChange={(e: any) =>
                                setNewUser({ ...newUser, password: e.target.value })
                            }
                        />
                    </section>
                    <section className="input-container">
                        <label>First name</label>
                        <Input
                            value={newUser.firstName}
                            onChange={(e: any) =>
                                setNewUser({ ...newUser, firstName: e.target.value })
                            }
                        />
                    </section>
                    <section className="input-container">
                        <label>Last name</label>
                        <Input
                            value={newUser.lastName}
                            onChange={(e: any) =>
                                setNewUser({ ...newUser, lastName: e.target.value })
                            }
                        />
                    </section>
                    <section className="input-container">
                        <label>Role</label>
                        <Select
                            selectOnBlur={false}
                            options={userRoleOptions}
                            value={newUser.role}
                            onChange={(e, data: any) => handleChangeRole(data)}
                        />
                    </section>
                    <section className="input-container">
                        <label>Country</label>
                        <Select
                            selectOnBlur={false}
                            options={countries}
                            disabled={
                                newUser.role !== UserRole.NCLP && newUser.role !== UserRole.Admin
                            }
                            value={newUser.countryGuid}
                            onChange={(e, data: any) =>
                                setNewUser({ ...newUser, countryGuid: data.value })
                            }
                        />
                    </section>
                </form>
                <Popup
                    className="add-user-tooltip"
                    disabled={!tooltip.length}
                    trigger={
                        <div>
                            <Button
                                content="Add new user"
                                icon="add user"
                                disabled={disabled}
                                loading={loading}
                                primary
                                fluid
                                onClick={handleAddNewUser}
                            />
                        </div>
                    }
                >
                    <Popup.Content className="add-user-toolpit-content">
                        Following fields need to be filled:
                        <ul className="tooltip-list">
                            {tooltip.map(t => (
                                <li key={t}>{t}</li>
                            ))}
                        </ul>
                    </Popup.Content>
                </Popup>
            </Segment>
        </div>
    );
};
