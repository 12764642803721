import { useCallback, useEffect, useState } from "react";
import { Icon } from "semantic-ui-react";
import { INewsFeedItem } from "../../models/newsFeedItem";
import { getNewsFeedItems } from "../../services/newsFeedService";

export const NewsFeed = () => {
    const numberOfItemsToFetch = 3;
    const [totalRows, setTotalRows] = useState(0);
    const [loading, setLoading] = useState(false);
    const [page, setPage] = useState(1);
    const [newsFeedItems, setNewsFeedItems] = useState<INewsFeedItem[]>([]);

    const fetchNewsFeedItems = useCallback(async () => {
        setLoading(true);
        const res = await getNewsFeedItems(page, numberOfItemsToFetch);
        if (res.result) {
            setNewsFeedItems(res.result as INewsFeedItem[]);
            setTotalRows(res.totalRows);
        }
        setLoading(false);
    }, [page]);

    useEffect(() => {
        fetchNewsFeedItems();
    }, [fetchNewsFeedItems]);

    useEffect(() => {
        setPage(1);
        setNewsFeedItems([]);
    }, []);

    return (
        <div id="news-feed" className="home-section">
            <div className="section-title">
                <h1>News feed</h1>
            </div>
            {!loading && newsFeedItems.length === 0 ? (
                <b>No news feed items found.</b>
            ) : (
                <div className="news-feed-display">
                    <div>
                        {newsFeedItems.map(x => (
                            <div className="feed-item" key={x.id}>
                                <strong>
                                    {new Date(x.timestamp).toLocaleString("hr-HR", {
                                        timeZone: "UTC", 
                                        day: "2-digit",
                                        month: "2-digit",
                                        year: "numeric",
                                        hour: "2-digit",
                                        minute: "2-digit",
                                        second: "2-digit",
                                    })}
                                </strong>{" "}
                                {x.text}
                            </div>
                        ))}
                    </div>
                    <div className="news-feed-pagination-container">
                        <Icon
                            name="angle up"
                            size="big"
                            disabled={
                                loading || page === 1
                            }
                            onClick={() => {
                                if (page > 1) {
                                    setPage(page - 1);
                                }
                            }}
                        />
                        <Icon
                            name="angle down"
                            size="big"
                            disabled={loading || totalRows <= page * numberOfItemsToFetch}
                            onClick={() => setPage(page + 1)}
                        />
                    </div>
                </div>
            )}
        </div>
    );
};
