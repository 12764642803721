import { Route, Switch } from "react-router-dom";
import { Chapters } from "../Chapters/Chapters";
import { ReportFrontPage } from "./ReportFrontPage";
import { Chapter } from "../Chapter/Chapter";
import "./Report.css";

export const Report = () => {
    return (
        <Switch>
            <Route exact path="/reports/overview/:reportId/front-page" component={ReportFrontPage} />
            <Route exact path="/reports/overview/:reportId/table-of-contents" component={Chapters} />
            <Route exact path="/reports/overview/:reportId/chapters/:chapterId" component={Chapter} />
        </Switch>
    );
};
