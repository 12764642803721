import { Dispatch, SetStateAction, useState } from "react";
import { Button, DropdownItemProps, Form, Input, Modal, Select } from "semantic-ui-react";
import { toast } from "../..";
import { figureDtoInit, IFigureDto } from "../../models/figure";
import { editGeneratedFigure } from "../../services/figuresService";
import staticFilterOptions from "../../components/FormsOverviewTable/staticFilterOptions.json";

interface IProps {
    generatedEditModalOpen: boolean;
    figureToEdit: IFigureDto;
    setFigureToEdit: Dispatch<SetStateAction<IFigureDto>>;
    handleClearFilter: () => void;
    formCategories: DropdownItemProps[];
    formsAsDropdownProps: DropdownItemProps[];
}

export const GenerateEditModal = ({
    generatedEditModalOpen,
    figureToEdit,
    setFigureToEdit,
    handleClearFilter,
    formCategories,
    formsAsDropdownProps,
}: IProps) => {
    const [loading, setLoading] = useState(false);

    const handleEditGeneratedFigure = async () => {
        setLoading(true);
        try {
            await editGeneratedFigure(figureToEdit);
            handleClearFilter();
            toast("Successfully edited figure", true, 1000);
        } catch {
            toast("Error editing figure", false, 1000);
        } finally {
            setFigureToEdit(figureDtoInit);
            setLoading(false);
        }
    };

    return (
        <Modal open={generatedEditModalOpen}>
            <Modal.Header>Edit {figureToEdit.name}</Modal.Header>
            <Modal.Content>
                <Form>
                    <Form.Field>
                        <label>Name</label>
                        <Input
                            value={figureToEdit.name}
                            onChange={(e, data) =>
                                setFigureToEdit({ ...figureToEdit, name: data.value })
                            }
                        />
                    </Form.Field>
                    <Form.Field>
                        <label>Year</label>
                        <Select
                            disabled
                            className="figure-property-display"
                            options={staticFilterOptions.year}
                            value={figureToEdit.year.toString()}
                        />
                    </Form.Field>
                    <Form.Field>
                        <label>Category</label>
                        <Select
                            disabled
                            className="figure-property-display"
                            options={formCategories}
                            value={figureToEdit.category}
                        />
                    </Form.Field>
                    <Form.Field>
                        <label>Form</label>
                        <Select
                            disabled
                            className="figure-property-display"
                            value={figureToEdit.formName}
                            options={formsAsDropdownProps}
                        />
                    </Form.Field>
                </Form>
            </Modal.Content>
            <Modal.Actions>
                <Button onClick={() => setFigureToEdit(figureDtoInit)} content="Cancel" />
                <Button
                    primary
                    loading={loading}
                    disabled={loading || figureToEdit.name === ""}
                    content="Edit"
                    onClick={handleEditGeneratedFigure}
                />
            </Modal.Actions>
        </Modal>
    );
};
