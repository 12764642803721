import { IParagraph } from "../models/paragraph";
import { getAuthorizationProperties } from "./authorizationHelper";

export async function getParagraph(id: string): Promise<IParagraph> {
    return await fetch(`/api/paragraphs/${id}`, {
        method: "GET",
        ...getAuthorizationProperties({ "Content-Type": "application/json" }),
    }).then(res => {
        return res.json();
    });
}

export async function getParagraphs(chapterId: string): Promise<IParagraph[]> {
    return await fetch(`/api/Paragraphs/chapter/${chapterId}`, {
        method: "GET",
        ...getAuthorizationProperties({ "Content-Type": "application/json" }),
    }).then(res => {
        return res.json();
    });
}

export async function createParagraph(paragraph: Partial<IParagraph>): Promise<IParagraph> {
    return await fetch(`/api/paragraphs`, {
        method: "POST",
        ...getAuthorizationProperties({ "Content-Type": "application/json" }),
        body: JSON.stringify(paragraph),
    }).then(res => {
        return res.json();
    });
}

export async function updateParagraph(
    paragraphId: string,
    paragraph: IParagraph
): Promise<IParagraph> {
    return await fetch(`/api/paragraphs/${paragraphId}`, {
        method: "PATCH",
        ...getAuthorizationProperties({ "Content-Type": "application/json" }),
        body: JSON.stringify(paragraph),
    }).then(res => {
        return res.json();
    });
}

export async function deleteParagraph(id: string): Promise<Response> {
    return await fetch(`/api/paragraphs/${id}`, {
        method: "DELETE",
        ...getAuthorizationProperties({ "Content-Type": "application/json" }),
    });
}
