import { useState } from "react";
import { Select } from "semantic-ui-react";
import { Breadcrumbs } from "../../components/Breadcrumbs/Breadcrumbs";
import { PublishedCarousel } from "../../components/ReportsCarousel/PublishedCarousel";
import { yearsProps } from "../../models/common";

export const NclpReportDownload = () => {
    const [selectedYear, setSelectedYear] = useState<number>();

    return (
        <div className="reports-download-container">
            <div className="container-header">
                <h1>Download</h1>
                <div className="year-selector">
                    <Select
                        fluid
                        clearable
                        value={selectedYear}
                        options={yearsProps}
                        onChange={(_e, data) => setSelectedYear(data.value as number)}
                    />
                </div>
            </div>
            <Breadcrumbs />
            <br />
            <br />
            <PublishedCarousel year={selectedYear} />
        </div>
    );
};
