import { UserData } from "../actions/authentificationActions";

export function getAuthorizationProperties(additionalHeaders = {}) {
    const headers = new Headers({ ...additionalHeaders });
    const user: UserData | null =
        sessionStorage.getItem("user") !== null
            ? JSON.parse(sessionStorage.getItem("user") || "")
            : null;
    user && headers.append("Authorization", "Bearer " + user.token);
    return { headers };
}
