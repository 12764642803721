import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Dropdown, Icon, Menu } from "semantic-ui-react";
import { logout, UserRole, setUser, UserData } from "../../actions/authentificationActions";
import { AppState } from "../../store/configureStore";
import Logo from "../../images/entsoe_logo.svg";
import { NotificationsCount } from "../../components/NotificationsCount/NotificationsCount";
import "./Header.css";
import { useEffect, useState } from "react";
import { ICountry } from "../../models/country";
import { getParent } from "../../services/countriesService";
import { countryPartSelect } from "../../services/usersService";

export const NclpHeader = () => {
    const dispatch = useDispatch();
    const user = useSelector((state: AppState) => state.user);
    const [parentCountry, setParentCountry] = useState<ICountry>();

    useEffect(() => {
        if (user.countryGuid) {
            getParent(user.countryGuid).then(setParentCountry);
        }
    }, [user.countryGuid]);

    const handleSwitchToAdmin = async () => {
        let newUser = {
            ...user,
            role: UserRole.Admin,
            actualRole: user.role,
        } as UserData;

        if (parentCountry?.id) {
            newUser = {
                ...(await countryPartSelect(parentCountry.id)),
                role: UserRole.Admin,
                actualRole: user.role,
            } as UserData;
        }
        if (newUser.token) {
            sessionStorage.setItem("user", JSON.stringify(newUser));
        }
        dispatch(setUser(newUser));
    };

    return (
        <Menu secondary>
            <Menu.Item as={Link} to="/" header>
                <div id="logo-container">
                    <img src={Logo} alt="Logo" />
                    <p id="imp-logo-text">Implementation Monitoring Platform</p>
                </div>
            </Menu.Item>
            <Menu.Item as={Link} to="/forms/results" content="Forms" />
            <Dropdown item text="Reports" icon="chevron down">
                <Dropdown.Menu>
                    <Dropdown item text="Dynamic graphs" icon="chevron right">
                        <Dropdown.Menu>
                            <Menu.Item
                                as={Link}
                                to="/reports/data-view/generation-capacity"
                                content="Generation and HVDC capacities"
                            />
                            <Menu.Item
                                as={Link}
                                to="/reports/data-view/frt"
                                content="FRT non-exhaustive parameters"
                            />
                            <Menu.Item
                                as={Link}
                                to="/reports/data-view/frt-additional"
                                content="Other non-exhaustive parameters"
                            />
                        </Dropdown.Menu>
                    </Dropdown>
                    <Menu.Item
                        as={Link}
                        to="/reports/non-exhaustive-parameters"
                        content="Non-exhaustive parameters"
                    />
                    <Menu.Item as={Link} to="/reports/download" content="Download" />
                </Dropdown.Menu>
            </Dropdown>

            <Menu.Item position="right" as={Link} to="/">
                <NotificationsCount />
            </Menu.Item>
            <Menu.Item as={Link} to="/">
                <span>{user.displayName}</span>
                <Icon name="user circle" size="large" />
            </Menu.Item>
            {user.actualRole === UserRole.Admin && (
                <Menu.Item onClick={() => handleSwitchToAdmin()}>
                    <span>View as Admin</span>
                </Menu.Item>
            )}
            <Menu.Item onClick={() => dispatch(logout())}>
                <span>Logout</span>
                <Icon name="sign-out" size="large" />
            </Menu.Item>
        </Menu>
    );
};
