import { useEffect, useState } from "react";
import { matchPath, useLocation } from "react-router";
import { Link } from "react-router-dom";
import { Breadcrumb, Icon } from "semantic-ui-react";
import Routes from "./Routes";
import "./Breadcrumbs.css";
import {
    getFormNameFromSessionStorage,
    getReportNameFromSessionStorage,
} from "../../helpers/sessionGuidsHelper";

interface IBreadcrumb {
    path: string;
    name: string;
    link: string | null;
    end: boolean | undefined;
}

export const Breadcrumbs = () => {
    const location = useLocation();
    const [crumbs, setCrumbs] = useState<IBreadcrumb[]>();
    const [guidFromRoute, setGuidFromRoute] = useState("");

    const getCrumbs = (route: string) => {
        return Routes.filter(({ path }) => matchPath(route, { path })?.isExact).map(crumb => ({
            path: crumb.path,
            name: crumb.name,
            link: crumb.link,
            end: crumb.end,
        }));
    };

    const getGuidFromRoute = async () => {
        const regex = /\w{8}-\w{4}-\w{4}-\w{4}-\w{12}/;
        const guidArray = window.location.pathname.match(regex)?.map(g => g.toString());
        let guid = null;
        if (guidArray) guid = guidArray[0];
        if (guid) {
            setGuidFromRoute(guid);
        }
    };

    const getCrumbName = (crumb: IBreadcrumb) => {
        if (crumb.name === "FormID") return getFormNameFromSessionStorage(guidFromRoute);
        if (crumb.name === "ReportID") return getReportNameFromSessionStorage(guidFromRoute);
        return crumb.name;
    };

    useEffect(() => {
        setCrumbs(getCrumbs(location.pathname));
        getGuidFromRoute();
    }, [location.pathname]);

    return crumbs?.at(-1)?.end ? (
        <Breadcrumb className="breadcrumbs-container">
            {crumbs?.map((crumb, key) => (
                <Breadcrumb.Section className="breadcrumb-section" key={key}>
                    {key + 1 === crumbs?.length ? (
                        <span key={key} className="bold">
                            {getCrumbName(crumb)}
                        </span>
                    ) : (
                        <>
                            {crumb.link ? (
                                <>
                                    <Link
                                        key={key}
                                        className="underline"
                                        to={crumb.link.toString()}
                                    >
                                        {getCrumbName(crumb)}
                                    </Link>
                                    <Breadcrumb.Divider>
                                        <Icon name="angle right" />
                                    </Breadcrumb.Divider>
                                </>
                            ) : (
                                <>
                                    <span key={key}>{getCrumbName(crumb)}</span>
                                    <Breadcrumb.Divider>
                                        <Icon name="angle right" />
                                    </Breadcrumb.Divider>
                                </>
                            )}
                        </>
                    )}
                </Breadcrumb.Section>
            ))}
        </Breadcrumb>
    ) : null;
};
