import React, {
    Dispatch,
    Fragment,
    SetStateAction,
    useCallback,
    useEffect,
    useMemo,
    useState,
} from "react";
import {
    Button,
    Modal,
    Form,
    DropdownItemProps,
    Select,
    Checkbox,
    Icon,
    Radio,
    Popup,
} from "semantic-ui-react";
import { IForm } from "../../models/form";
import { ICategory } from "../../models/category";
import SemanticDatepicker from "react-semantic-ui-datepickers";
import { createFormCategory, getFormCategoriesAsProps } from "../../services/formsService";
import { toast } from "../..";
import { NotificationsTooltips, SendNotificationOptions } from "../../models/sendNotification";
import { getCountries } from "../../services/countriesService";
import "./CreateAndEditForm.css";

interface IProps {
    header: string;
    open: boolean;
    form: IForm;
    handleClose: () => void;
    cancelAddForm: () => void;
    createFormProcedure: () => void;
    setForm: Dispatch<SetStateAction<IForm>>;
    confirmString: string;
    disableExceptDeadline?: boolean;
}

export const CreateAndEditForm: React.FC<IProps> = ({
    header,
    open,
    form,
    handleClose,
    cancelAddForm,
    createFormProcedure,
    setForm,
    confirmString,
    disableExceptDeadline,
}) => {
    const createCategoryDropdownItem: DropdownItemProps = useMemo(
        () => ({
            key: "new-category-key",
            text: "New Category",
            icon: "add circle",
            onClick: (e: any, a: any) => setCreateCategoryOpen(true),
        }),
        []
    );

    const [formCategories, setFormCategories] = useState<DropdownItemProps[]>([
        createCategoryDropdownItem,
    ]);
    const [category, setCategory] = useState<Partial<ICategory>>({});
    const [createCategoryOpen, setCreateCategoryOpen] = useState(false);
    const [tooltipMessage, setTooltipMessage] = useState("");
    const [countries, setCountries] = useState<DropdownItemProps[]>([]);
    const [openNotifications, setOpenNotifications] = useState(false);

    useEffect(() => {
        getCountries().then(res => {
            let dropdownCountries: DropdownItemProps[] = res.map(c => {
                return {
                    key: c.id,
                    text: c.name,
                    value: c.id,
                };
            });
            setCountries(dropdownCountries);
        });
    }, []);

    useEffect(() => {
        if (form?.category === "" && form?.name === "")
            setTooltipMessage("Enter form name and select category");
        else if (form?.name === "") setTooltipMessage("Enter form name");
        else if (form?.category === "") setTooltipMessage("Select category");
        else if (form?.specialDeadlines?.filter(sd => !sd.countryId || !sd.deadline).length) {
            setTooltipMessage("Some special deadline entries are missing data");
        } else setTooltipMessage("");
    }, [form]);

    const createCategory = async () => {
        if (category?.name !== "") {
            let res = null;
            try {
                res = await createFormCategory(category);
                if (res.id) {
                    setCreateCategoryOpen(false);
                    toast("Category successfully created", true, 1000);
                }
                fetchFormCategories();
            } catch (error) {
                if (res !== null) toast("Error while creating category", false, 1000);
            }
        }
    };

    const fetchFormCategories = useCallback(async () => {
        const categories = await getFormCategoriesAsProps();
        Array.isArray(categories) &&
            setFormCategories([createCategoryDropdownItem].concat(categories));
    }, [createCategoryDropdownItem]);

    useEffect(() => {
        fetchFormCategories();
    }, [fetchFormCategories]);

    return (
        <Fragment>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="form-dialog-title"
                className="create-new-form-modal"
            >
                <Modal.Header id="form-dialog-title">{header}</Modal.Header>
                <Modal.Content>
                    <Form>
                        <Form.Field>
                            <label>Form Name</label>
                            <input
                                placeholder="Form Name"
                                id="name"
                                value={form?.name}
                                onChange={(e: any) => setForm({ ...form, name: e.target.value })}
                                disabled={disableExceptDeadline}
                            />
                        </Form.Field>
                        <Form.Field>
                            <label>Form description</label>
                            <input
                                placeholder="Form description"
                                id="description"
                                value={form?.description}
                                onChange={(e: any) =>
                                    setForm({ ...form, description: e.target.value })
                                }
                                disabled={disableExceptDeadline}
                            />
                        </Form.Field>
                        <Form.Field>
                            <label>Deadline</label>
                        </Form.Field>

                        <Form.Field>
                            <SemanticDatepicker
                                format="DD.MM.YYYY."
                                value={
                                    !form?.deadline ||
                                    form?.deadline === "01.01.0001. 00:00:00" ||
                                    form.deadline.startsWith("0001-01-01")
                                        ? null
                                        : new Date(form.deadline)
                                }
                                onChange={(event: any, data: any) => {
                                    let utcDate = undefined;
                                    if (data.value) {
                                        utcDate = `${(data.value as Date).getFullYear()}-${
                                            (data.value as Date).getMonth() + 1
                                        }-${(data.value as Date).getDate()}`;
                                    }
                                    setForm({
                                        ...form,
                                        deadline: utcDate ?? "",
                                    });
                                }}
                            />
                            <Button
                                className="special-deadline-btn"
                                icon="plus"
                                content="Add special deadline"
                                onClick={() => {
                                    let newSpecialDeadlines = form?.specialDeadlines
                                        ? [...form.specialDeadlines]
                                        : [];
                                    newSpecialDeadlines.push({ formId: form.id });
                                    setForm({ ...form, specialDeadlines: newSpecialDeadlines });
                                }}
                            />
                            {form?.specialDeadlines &&
                                form.specialDeadlines.length > 0 &&
                                form.specialDeadlines.map((d, i) => {
                                    return (
                                        <Form.Field key={i} className="action-bar-container">
                                            <label>NCLP</label>
                                            <div className="special-deadline-container">
                                                <Select
                                                    clearable
                                                    value={d.countryId}
                                                    options={countries.filter(
                                                        c =>
                                                            !form.specialDeadlines.find(
                                                                x => x.countryId === c.value
                                                            ) ||
                                                            c.value ===
                                                                form.specialDeadlines[i].countryId
                                                    )}
                                                    onChange={(e, data) => {
                                                        let updatedDeadlines = [
                                                            ...form.specialDeadlines,
                                                        ];
                                                        updatedDeadlines[i].countryId =
                                                            data.value as string;
                                                        setForm({
                                                            ...form,
                                                            specialDeadlines: updatedDeadlines,
                                                        });
                                                    }}
                                                />
                                                <SemanticDatepicker
                                                    format="DD.MM.YYYY."
                                                    value={
                                                        !d.deadline ||
                                                        d.deadline === "01.01.0001. 00:00:00"
                                                            ? null
                                                            : new Date(d.deadline)
                                                    }
                                                    onChange={(event: any, data: any) => {
                                                        let utcDate = undefined;
                                                        if (data.value != null) {
                                                            utcDate = `${(
                                                                data.value as Date
                                                            ).getFullYear()}-${
                                                                (data.value as Date).getMonth() + 1
                                                            }-${(data.value as Date).getDate()}`;
                                                        }

                                                        let updatedDeadlines = [
                                                            ...form.specialDeadlines,
                                                        ];
                                                        updatedDeadlines[i].deadline = utcDate;
                                                        setForm({
                                                            ...form,
                                                            specialDeadlines: updatedDeadlines,
                                                        });
                                                    }}
                                                />
                                                <div className="action-bar-container-options">
                                                    <Icon
                                                        name="delete"
                                                        onClick={() => {
                                                            let updatedDeadlines = [
                                                                ...form.specialDeadlines,
                                                            ];
                                                            updatedDeadlines.splice(i, 1);
                                                            setForm({
                                                                ...form,
                                                                specialDeadlines: updatedDeadlines,
                                                            });
                                                        }}
                                                        bordered
                                                    />
                                                </div>
                                            </div>
                                        </Form.Field>
                                    );
                                })}
                        </Form.Field>
                        <Form.Field>
                            <label>Category</label>
                            <Form.Select
                                fluid
                                options={formCategories}
                                value={form?.category}
                                placeholder="Category"
                                onChange={(e, data: any) =>
                                    setForm({ ...form, category: data.value })
                                }
                                disabled={disableExceptDeadline || form?.isHardCopy}
                            />
                        </Form.Field>
                        <Form.Field>
                            <label className="user-download-toggle">Allow user download</label>
                            <Checkbox
                                toggle
                                defaultChecked={form?.userDownload}
                                onChange={(e, data: any) =>
                                    setForm({ ...form, userDownload: data.checked })
                                }
                            />
                        </Form.Field>
                        <Form.Field>
                            <label>Notifications</label>
                            <Button
                                content={
                                    openNotifications ? "Close notifications" : "Add notifications"
                                }
                                icon={openNotifications ? "close" : "plus"}
                                onClick={() => setOpenNotifications(!openNotifications)}
                            />
                            <div
                                className={`notifications-container ${
                                    openNotifications ? "notifications-visible" : ""
                                }`}
                            >
                                {form.sendNotification &&
                                    Object.keys(form.sendNotification)
                                        .filter(n => n !== "id")
                                        .map(x => (
                                            <Popup
                                                key={x}
                                                content={
                                                    NotificationsTooltips.find(z => z.value === x)
                                                        ?.text
                                                }
                                                position="top left"
                                                disabled={!openNotifications}
                                                trigger={
                                                    <Radio
                                                        className="notification-toggle"
                                                        label={
                                                            SendNotificationOptions.find(
                                                                y => y.value === x
                                                            )?.text
                                                        }
                                                        toggle
                                                        checked={
                                                            form.sendNotification[x] as boolean
                                                        }
                                                        onClick={() =>
                                                            setForm({
                                                                ...form,
                                                                sendNotification: {
                                                                    ...form.sendNotification,
                                                                    [x]: !form.sendNotification[x],
                                                                },
                                                            })
                                                        }
                                                    />
                                                }
                                            />
                                        ))}
                            </div>
                        </Form.Field>
                    </Form>
                </Modal.Content>
                <Modal.Actions>
                    <Button onClick={cancelAddForm}>Cancel</Button>
                    <div className="parent-tooltip">
                        <div
                            hidden={tooltipMessage === ""}
                            className="child-tooltip"
                            data-tooltip={tooltipMessage}
                            data-position="top center"
                        />
                        <Button onClick={createFormProcedure} disabled={tooltipMessage !== ""}>
                            {confirmString}
                        </Button>
                    </div>
                </Modal.Actions>
            </Modal>

            <Modal open={createCategoryOpen}>
                <Modal.Header>Creating a new category</Modal.Header>
                <Modal.Content>
                    <Form>
                        <Form.Field>
                            <label>Category name</label>
                            <input
                                placeholder="Category name"
                                id="name"
                                value={category.name}
                                required={true}
                                onChange={(e: any) =>
                                    setCategory({ ...category, name: e.target.value })
                                }
                            />
                        </Form.Field>
                    </Form>
                </Modal.Content>
                <Modal.Actions>
                    <Button onClick={createCategory}>Create Category</Button>
                    <Button onClick={() => setCreateCategoryOpen(false)}>Cancel</Button>
                </Modal.Actions>
            </Modal>
        </Fragment>
    );
};
