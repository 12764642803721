import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { SketchPicker } from "react-color";
import { Button, Input, Modal, Popup } from "semantic-ui-react";
import { ITableInputGroup } from "../../../../models/tableInputGroups";

export interface ITableGroupInputProps {
    intialValue: ITableInputGroup;
    setInitialValue:
        | Dispatch<SetStateAction<ITableInputGroup | undefined>>
        | Dispatch<SetStateAction<ITableInputGroup>>;
    saveButtonText: string;
    saveButtonIcon: string;
    onSaveAction: () => void;
    isInEdit?: boolean;
    onDeleteAction?: () => void;
}
export const TableGroupInputForm = ({
    intialValue,
    setInitialValue,
    saveButtonText,
    saveButtonIcon,
    onSaveAction,
    isInEdit,
    onDeleteAction,
}: ITableGroupInputProps) => {
    const [subQuestionError, setSubQuestionError] = useState<string>("");
    const [valid, setValid] = useState<boolean>(true);

    const [deleteModalOpen, setDeleteModalOpen] = useState<boolean>(false);

    const validateAndSave = () => {
        let validationFlag = true;
        if (!intialValue.name) {
            setSubQuestionError(`Name of the group is mandatory.`);
            validationFlag = false;
        }

        if (validationFlag) {
            onSaveAction();
        } else {
            setValid(validationFlag);
        }
    };

    const handleKeyPress = (e: React.KeyboardEvent<HTMLDivElement>) => {
        e.stopPropagation();
        if (valid && e.key === "Enter") {
            validateAndSave();
        }
    };

    useEffect(() => {
        setSubQuestionError("");
        setValid(true);
    }, [intialValue]);

    return (
        <div>
            <div className="table-dimension-input" onKeyPress={handleKeyPress}>
                <Input
                    value={intialValue.name}
                    placeholder="New group name..."
                    onChange={e => {
                        setInitialValue({ ...intialValue, name: e.target.value });
                    }}
                />
                <Popup
                    trigger={
                        <span className="color-picker-activator clickable">
                            Text color:{" "}
                            <span
                                className="color-preview"
                                style={{
                                    backgroundColor: intialValue.textColorHex,
                                }}
                            ></span>
                        </span>
                    }
                    pinned
                    on={"click"}
                >
                    <Popup.Header>Pick color</Popup.Header>
                    <Popup.Content>
                        <SketchPicker
                            disableAlpha
                            color={intialValue.textColorHex}
                            onChange={color => {
                                setInitialValue({
                                    ...intialValue,
                                    textColorHex: color.hex,
                                });
                            }}
                        />
                    </Popup.Content>
                </Popup>
                <Popup
                    trigger={
                        <span className="color-picker-activator clickable">
                            Background color:{" "}
                            <span
                                className="color-preview"
                                style={{
                                    backgroundColor: intialValue.backgroundColorHex,
                                }}
                            ></span>
                        </span>
                    }
                    pinned
                    on={"click"}
                >
                    <Popup.Header>Pick color</Popup.Header>
                    <Popup.Content>
                        <SketchPicker
                            disableAlpha
                            color={intialValue.backgroundColorHex}
                            onChange={color => {
                                setInitialValue({
                                    ...intialValue,
                                    backgroundColorHex: color.hex,
                                });
                            }}
                        />
                    </Popup.Content>
                </Popup>
                <Button
                    primary
                    type="button"
                    content={saveButtonText}
                    icon={saveButtonIcon}
                    disabled={!valid}
                    onClick={validateAndSave}
                />
                {isInEdit && onDeleteAction && (
                    <Button
                        className="group-delete-btn"
                        basic
                        type="button"
                        color="red"
                        icon="times"
                        onClick={e => setDeleteModalOpen(true)}
                    />
                )}

                <Modal open={deleteModalOpen} onClose={() => setDeleteModalOpen(false)}>
                    <Modal.Header>Confirm delete</Modal.Header>
                    <Modal.Content>
                        Are you sure you want to delete the group? This action is not reversible
                    </Modal.Content>
                    <Modal.Actions>
                        <Button content="Cancel" onClick={() => setDeleteModalOpen(false)} />
                        <Button content="Delete group" primary onClick={() => onDeleteAction?.()} />
                    </Modal.Actions>
                </Modal>
            </div>
            {subQuestionError && (
                <span className="alert">
                    * <span>{subQuestionError}</span>
                </span>
            )}
        </div>
    );
};
