import { useState } from "react";
import {
    BranchingActionOptions,
    IQuestion,
    QuestionType,
    QuestionTypeOptions,
} from "../../models/question";
import {
    Button,
    Checkbox,
    Dimmer,
    Divider,
    Form,
    FormField,
    Icon,
    Input,
    Loader,
    Modal,
    Radio,
    Select,
    TextArea,
} from "semantic-ui-react";
import { BranchingActionType, IOption } from "../../models/option";
import generateGuid from "../../helpers/guid";
import { TableInputCreate } from "./TableInput/TableInputCreate";
import { FileDropzone } from "../Dropzone/FileDropzone";
import { toast } from "../..";
import { updateImageForQuestion } from "../../services/sectionsService";
import "./QuestionCreate.css";

function truncateString(str: string, num: number) {
    if (str.length > num) {
        return str.slice(0, num) + "...";
    } else {
        return str;
    }
}

interface IProps {
    question: IQuestion;
    otherQuestions: IQuestion[];
    saveChanges: (question: IQuestion) => void;
    hardSave?: () => void;
    isFormHardCopy?: boolean;
}

function getBranchingOptions(question: IQuestion, allQuestions: IQuestion[]) {
    let result = BranchingActionOptions;
    if (question.orderNumber > allQuestions.length - 1) {
        result = result.filter(o => o.value !== "SkipToQuestion");
    }
    return result;
}

export const QuestionCreate: React.FC<IProps> = ({
    question,
    saveChanges,
    hardSave,
    otherQuestions,
    isFormHardCopy,
}) => {
    const [newOptionValue, setNewOptionValue] = useState<string>("");
    const [imageUpload, setImageUpload] = useState<boolean>(false);
    const [imageUploadLoading, setImageUploadLoading] = useState<boolean>(false);

    const setQuestionProperty = (key: string, value: any) => {
        saveChanges({ ...question, [key]: value });
    };

    const addOption = (other: boolean) => {
        let newOption: IOption = {
            id: generateGuid(),
            value: other ? "Other" : newOptionValue,
            question: question.id,
            orderNumber: 0,
        };
        let newOptions = [...question.options, newOption];
        newOptions = newOptions.map((o, i) => {
            return { ...o, orderNumber: i + 1 };
        });
        setNewOptionValue("");
        setQuestionProperty("options", [...newOptions]);
    };

    const deleteOption = (key: string | undefined) => {
        let newOptions = question.options
            .filter(o => o.id !== key)
            .map((o, i) => {
                return { ...o, orderNumber: i + 1 };
            });
        setQuestionProperty("options", [...newOptions]);
    };

    const handleUploadFile = async (file: File | null) => {
        try {
            setImageUploadLoading(true);
            // Question must already exist on backend before image is saved.
            await hardSave?.();
            const res = await updateImageForQuestion(question.id, file);
            saveChanges({ ...question, imageGuid: res.newImageGuid });
            setImageUpload(false);
        } catch (err) {
            toast("Image upload failed", false, 3000);
        } finally {
            setImageUploadLoading(false);
        }
    };

    const editOption = (
        key: string,
        value: string,
        branchingAction: BranchingActionType | "clear" | null = null,
        branchingTarget: string | null = null
    ) => {
        let newOptions = question.options.map(o => {
            if (o.id === key) {
                let newO = { ...o, value: value };
                if (branchingAction === "clear") {
                    newO.branchingAction = undefined;
                    newO.branchTarget = undefined;
                } else if (branchingAction === "SkipToQuestion" && !branchingTarget) {
                    newO.branchingAction = "SkipToQuestion";
                    newO.branchTarget = otherQuestions.find(
                        q => q.orderNumber === question.orderNumber + 2
                    )?.id;
                } else {
                    newO.branchingAction =
                        branchingAction !== null ? branchingAction : newO.branchingAction;
                    newO.branchTarget =
                        branchingTarget !== null ? branchingTarget : newO.branchTarget;
                }
                return newO;
            } else {
                return o;
            }
        });
        setQuestionProperty("options", newOptions);
    };

    const changeType = (type: QuestionType) => {
        let newOptions = question.options;
        if (type !== "Checkboxes" && type !== "MultipleChoice") {
            newOptions = [];
        }
        saveChanges({ ...question, type: type, options: newOptions });
    };

    const handleRequiredChange = (newRequired: boolean) => {
        if (newRequired && question.remark) {
            saveChanges({ ...question, required: newRequired, remark: false });
        } else {
            setQuestionProperty.bind(null, "required")(newRequired);
        }
    };

    return (
        <Form className="question-create">
            <div className="header-container">
                <Form.Field>
                    <label>Question type:</label>
                    <Select
                        placeholder="Select question type"
                        options={QuestionTypeOptions}
                        value={question.type}
                        onChange={(e, d) => {
                            changeType(d.value as QuestionType);
                        }}
                    />
                </Form.Field>
                <Form.Field>
                    <label>Attachment: </label>
                    <Checkbox
                        toggle
                        checked={question.fileUpload}
                        onChange={e =>
                            setQuestionProperty.bind(null, "fileUpload")(!question.fileUpload)
                        }
                    />
                </Form.Field>
                <Form.Field>
                    <label>Required:</label>
                    <Checkbox
                        toggle
                        checked={question.required}
                        onChange={handleRequiredChange.bind(null, !question.required)}
                    />
                </Form.Field>
                <Form.Field>
                    <label>Remark:</label>
                    <Checkbox
                        toggle
                        disabled={question.required}
                        checked={question.remark}
                        onChange={e => setQuestionProperty.bind(null, "remark")(!question.remark)}
                    />
                </Form.Field>
                {isFormHardCopy && question.hasParent && (
                    <Form.Field className="linked">
                        <label>Linked:</label>
                        <Checkbox
                            toggle
                            checked={question.isLinked}
                            onChange={e =>
                                setQuestionProperty.bind(null, "isLinked")(!question.isLinked)
                            }
                        />
                    </Form.Field>
                )}
            </div>

            <Form.Field>
                <label className="question-text-label">
                    <span>Question text:</span>
                    <span className="attach-image" onClick={() => setImageUpload(true)}>
                        <Icon name="attach" title="Attach image" className="clickable" />
                    </span>
                </label>
                <TextArea
                    value={question.text}
                    onChange={(e: any) => setQuestionProperty.bind(null, "text")(e.target.value)}
                />
            </Form.Field>
            {question.imageGuid && (
                <Form.Field>
                    <div className="question-image-container">
                        <img
                            src={`/api/sections/questionImage/${question.imageGuid}`}
                            alt={question.text}
                        />
                    </div>
                </Form.Field>
            )}
            {question.imageGuid && (
                <FormField className="remove-image-btn-container">
                    <Button content="Remove image" onClick={() => handleUploadFile(null)} />
                </FormField>
            )}
            {(question.type === "Number" || question.type === "ShortText") && (
                <>
                    <Divider />
                    <div className="boundaries-container">
                        <Form.Field>
                            <label>Min{question.type === "ShortText" && " length"}:</label>
                            <Input
                                type="number"
                                value={question.min || question.min === 0 ? question.min : ""}
                                onChange={(e: any) =>
                                    setQuestionProperty.bind(
                                        null,
                                        "min"
                                    )(e.target.value ? parseFloat(e.target.value) : undefined)
                                }
                            />
                        </Form.Field>
                        <Form.Field>
                            <label>Max{question.type === "ShortText" && " lenght"}:</label>
                            <Input
                                type="number"
                                value={question.max || question.max === 0 ? question.max : ""}
                                onChange={(e: any) =>
                                    setQuestionProperty.bind(
                                        null,
                                        "max"
                                    )(e.target.value ? parseFloat(e.target.value) : undefined)
                                }
                            />
                        </Form.Field>
                    </div>
                </>
            )}

            {(question.type === "MultipleChoice" || question.type === "Checkboxes") && (
                <>
                    <Divider />
                    <Form.Field>
                        <label>Options:</label>
                        {question.options.map(o =>
                            o.value !== "Other" ? (
                                <Input
                                    className="option-input"
                                    key={o.id}
                                    action={{
                                        icon: "delete",
                                        onClick: deleteOption.bind(null, o.id),
                                    }}
                                    value={o.value}
                                    onChange={e => {
                                        editOption.bind(null, o.id)(e.target.value);
                                    }}
                                />
                            ) : null
                        )}
                        <Input
                            className="option-input"
                            action={{ icon: "plus", onClick: addOption.bind(null, false) }}
                            placeholder="Add option ..."
                            value={newOptionValue}
                            onChange={e => setNewOptionValue(e.target.value)}
                        />
                        <Radio
                            toggle
                            label="Other"
                            checked={question.options.some(o => o.value === "Other")}
                            onChange={
                                question.options.some(o => o.value === "Other")
                                    ? deleteOption.bind(
                                          null,
                                          question.options.find(o => o.value === "Other")?.id
                                      )
                                    : addOption.bind(null, true)
                            }
                        />
                    </Form.Field>
                </>
            )}

            {question.type === "MultipleChoice" && (
                <>
                    <Divider />
                    <Form.Field className="branching-toggle">
                        <label>Use branching:</label>
                        <Checkbox
                            toggle
                            checked={question.branching}
                            onChange={e =>
                                setQuestionProperty.bind(null, "branching")(!question.branching)
                            }
                        />
                    </Form.Field>

                    {question.branching && (
                        <>
                            <Divider />
                            {question.options.map(o => (
                                <Form.Field key={o.id}>
                                    <label>{truncateString(o.value, 50)}</label>
                                    <div className="branching-options">
                                        <p>Action:</p>
                                        <Select
                                            placeholder="Select action"
                                            options={getBranchingOptions(question, otherQuestions)}
                                            value={o.branchingAction ?? "clear"}
                                            onChange={(e, d) => {
                                                editOption.bind(
                                                    null,
                                                    o.id,
                                                    o.value
                                                )(d.value as BranchingActionType | "clear");
                                            }}
                                        />
                                        <p>Target:</p>
                                        <Select
                                            placeholder="Select target"
                                            disabled={o.branchingAction !== "SkipToQuestion"}
                                            value={o.branchTarget}
                                            onChange={(e, d) => {
                                                editOption.bind(
                                                    null,
                                                    o.id,
                                                    o.value,
                                                    o.branchingAction
                                                )(d.value?.toString());
                                            }}
                                            options={otherQuestions
                                                .filter(x => x.orderNumber > question.orderNumber)
                                                .map(x => {
                                                    return {
                                                        key: x.id,
                                                        value: x.id,
                                                        text: truncateString(x.text, 50),
                                                    };
                                                })}
                                        />
                                    </div>
                                </Form.Field>
                            ))}
                        </>
                    )}
                </>
            )}

            {question.type === "TableInput" && (
                <TableInputCreate
                    question={question}
                    saveChanges={saveChanges}
                    hardSave={hardSave}
                />
            )}

            {/** Dropzone for uploading images to questions. */}
            <Modal open={imageUpload} onClose={() => setImageUpload(false)}>
                <Modal.Header>Add image to question</Modal.Header>
                <Dimmer.Dimmable blurring as={Modal.Content} dimmed={imageUploadLoading}>
                    <Dimmer inverted active={imageUploadLoading}>
                        <Loader content="Loading" />
                    </Dimmer>

                    {question.imageGuid && (
                        <div id="modal-img-container">
                            <img src={`/api/sections/questionImage/${question.imageGuid}`} alt="" />
                        </div>
                    )}
                    <FileDropzone
                        handleSetFile={handleUploadFile}
                        acceptAllFormats={false}
                        msg="Drag and drop image here or click to browse files."
                    />
                </Dimmer.Dimmable>
            </Modal>
        </Form>
    );
};
