import { getAuthorizationProperties } from "./authorizationHelper";
import { ICountry } from "../models/country";

export async function getCountries(
    guid?: string | undefined,
    userDropdown?: boolean | undefined
): Promise<ICountry[]> {
    const params = new URLSearchParams();
    if (guid) {
        params.append("guid", guid);
    }
    if (userDropdown) {
        params.append("userDropdown", userDropdown.toString());
    }
    let res = await fetch(`/api/countries?` + params.toString(), {
        method: "GET",
        ...getAuthorizationProperties({ "Content-Type": "application/json" }),
    });
    return res.json();
}

export async function getCountry(guid: string): Promise<ICountry> {
    let res = await fetch(`/api/countries/${guid}`, {
        method: "GET",
        ...getAuthorizationProperties({ "Content-Type": "application/json" }),
    });
    return res.json();
}

export async function getParts(guid: string): Promise<ICountry[]> {
    const res = await fetch(`/api/countries/parts/${guid}`, {
        method: "GET",
        ...getAuthorizationProperties({ "Content-Type": "application/json" }),
    });
    return res.json();
}

export async function getParent(guid: string): Promise<ICountry> {
    const res = await fetch(`/api/countries/parent/${guid}`, {
        method: "GET",
        ...getAuthorizationProperties({ "Content-Type": "application/json" }),
    });
    return res.json();
}
