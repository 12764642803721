import { useEffect, useState } from "react";
import { useParams, useHistory } from "react-router-dom";
import { Button } from "semantic-ui-react";
import { ReportBasicForm } from "./ReportBasicForm";
import { IReport } from "../../models/report";
import { getReport, updateReport } from "../../services/reportsService";
import { ReportPublishControls } from "./ReportPublishControls";
import "./ReportFrontPage.css";
import { Breadcrumbs } from "../../components/Breadcrumbs/Breadcrumbs";

export const ReportFrontPage = () => {
    const params: { reportId: string } = useParams();
    const history = useHistory();
    const [report, setReport] = useState<Partial<IReport>>({});

    useEffect(() => {
        if (params.reportId) {
            getReport(params.reportId).then(res => {
                setReport(res);
            });
        }
    }, [params.reportId]);

    const saveChanges = () => {
        if (report.id) {
            updateReport(report);
        }
    };

    return (
        <div className="report-container">
            <div>
                <div className="container-header">
                    <h1>Front Page</h1>
                    <ReportPublishControls reportId={report.id} saveChanges={saveChanges} />
                </div>
                <Breadcrumbs />
                <div className="front-page-data">
                    <div className="basic-info">
                        <ReportBasicForm
                            report={report}
                            setReport={setReport}
                            saveChanges={saveChanges}
                        />
                    </div>
                </div>
            </div>
            <div className="navigation-container-foward">
                <Button
                    content="Table of contents"
                    primary
                    onClick={() => {
                        saveChanges();
                        history.push(`/reports/overview/${params.reportId}/table-of-contents`);
                    }}
                />
            </div>
        </div>
    );
};
