import { useEffect, useState } from "react";
import { Container, Input, Popup, Table, TextArea } from "semantic-ui-react";
import { IQuestion, ISubQuestion } from "../../../models/question";
import { ESubValueType, ISubValue, IValue } from "../../../models/value";
import generateGuid from "../../../helpers/guid";
import { toast } from "../../..";
import { GroupLegend } from "./HelperComponents/GroupLegend";
import { isNaN } from "lodash";
import "./TableInput.css";

export interface ITableInputDisplayProps {
    question: IQuestion;
    value: IValue;
    saveValues: (subValues: ISubValue[]) => void;
    errors?: [string, string, string][];
    disabled?: boolean;
}

export const TableInputDisplay = ({
    question,
    value,
    saveValues,
    errors,
    disabled,
}: ITableInputDisplayProps) => {
    const [rowSubQuestions, setRowSubQuestions] = useState<ISubQuestion[]>([]);
    const [colSubQuestions, setColSubQuestions] = useState<ISubQuestion[]>([]);

    const [subValues, setSubValues] = useState<ISubValue[]>([]);

    useEffect(() => {
        if (question.subQuestions) {
            let rows = question.subQuestions.filter(sq => sq.isPrimaryDimension);
            let cols = question.subQuestions.filter(sq => !sq.isPrimaryDimension);

            // Just in case backend returns unsorted sub questions.
            rows.sort((f, s) => f.dimensionOrderNumber - s.dimensionOrderNumber);
            cols.sort((f, s) => f.dimensionOrderNumber - s.dimensionOrderNumber);

            setRowSubQuestions(rows);
            setColSubQuestions(cols);
        }
    }, [question, setRowSubQuestions, setColSubQuestions]);

    useEffect(() => {
        if (value.subValues) {
            setSubValues(value.subValues);
        }
    }, [value, setSubValues]);

    const updateSubValue = (
        primarySubQuestion: ISubQuestion,
        secondarySubQuestion: ISubQuestion,
        newValue: string | number,
        newValueType: ESubValueType
    ) => {
        let newSubValues = [...subValues];

        let subValueIndex: number = subValues.findIndex(
            sv =>
                sv.primaryDimensionSubQuestion === primarySubQuestion.id &&
                sv.secondaryDimensionSubQuestion === secondarySubQuestion.id
        );

        let subValue: ISubValue =
            subValueIndex !== -1
                ? newSubValues[subValueIndex]
                : {
                      id: generateGuid(),
                      primaryDimensionSubQuestion: primarySubQuestion.id,
                      secondaryDimensionSubQuestion: secondarySubQuestion.id,
                      valueId: value.id,
                  };

        switch (newValueType) {
            case ESubValueType.Text:
                subValue.textValue = newValue as string;
                break;
            case ESubValueType.Numeric:
                subValue.numeric = newValue as number;
                break;
        }

        // If updated content is empty string, null or undefined, remove this subvalue from the array.
        if (
            !subValue.textValue &&
            (subValue.numeric === undefined || subValue.numeric === null) &&
            subValueIndex !== -1
        ) {
            newSubValues.splice(subValueIndex, 1);
        } else {
            if (subValueIndex !== -1) {
                newSubValues[subValueIndex] = subValue;
            } else {
                newSubValues.push(subValue);
            }
        }

        setSubValues(newSubValues);
    };

    const findSubValue = (primDimId: string, secDimId: string, type: "number" | "text") => {
        let subValue = subValues.find(
            sv =>
                sv.primaryDimensionSubQuestion === primDimId &&
                sv.secondaryDimensionSubQuestion === secDimId
        );

        if (subValue) {
            if (type === "text") {
                return subValue.textValue ?? "";
            }
            if (type === "number") {
                return subValue.numeric !== undefined &&
                    subValue.numeric !== null &&
                    !isNaN(subValue.numeric)
                    ? subValue.numeric
                    : "";
            }

            return "";
        } else {
            // Default values for string/number.
            if (type === "text") {
                return "";
            } else {
                return "";
            }
        }
    };

    // Client wants special handling for the first cell in the table if the form is "Generation Capacity" and the table is PGM table.
    // Specifically, the first cell should contain the text which usually repeats in the row questions.
    // That also means that in that specific case, the text in first cells of each row should be specially handled to not repeat the text from the first cell.
    const displayTableTopLeft = () => {
        // We do not have access to the form name here, so we are checking just the question name.
        // NOTE: Client changed the name of the question, so we are checking for the new name.
        if (question.text.toLowerCase().includes("collection of rfg data")) {
            return <span>Installed generation capacity [MW]</span>;
        }

        if (question.text.toLowerCase().includes("collection of hvdc data")) {
            return <span>Installed capacity [MW]</span>;
        }

        return <></>;
    };

    // Client wants special handling for the first cell in the table if the form is "Generation Capacity" and the table is PGM table.
    // Specifically, the first cell should contain the text which usually repeats in the row questions.
    // That also means that in that specific case, the text in first cells of each row should be specially handled to not repeat the text from the first cell.
    const displayFirstRowCell = (dimensionText: string) => {
        // We do not have access to the form name here, so we are checking just the question name.
        // NOTE: Client changed the name of the question, so we are checking for the new name.
        if (question.text.toLowerCase().includes("collection of rfg data")) {
            // Special logic that the client requested is that if the text contains "Type A", then only "Type A" should be displayed, and not the rest of the question text.
            // The same applies to "Type B", "Type C" and Type "D".
            if (dimensionText.toLowerCase().includes("type a")) {
                return <span>Type A</span>;
            }
            if (dimensionText.toLowerCase().includes("type b")) {
                return <span>Type B</span>;
            }
            if (dimensionText.toLowerCase().includes("type c")) {
                return <span>Type C</span>;
            }
            if (dimensionText.toLowerCase().includes("type d")) {
                return <span>Type D</span>;
            }
        }

        // Special handling of the hvdc table requested by the client.
        if (question.text.toLowerCase().includes("collection of hvdc data")) {
            let splitText = dimensionText.split("-");
            // If the last part of the text contains "Installed" and "[MW]" then remove only the last part and trim the combination of the rest.
            if (
                splitText.length > 1 &&
                splitText[splitText.length - 1].toLowerCase().includes("installed") &&
                splitText[splitText.length - 1].toLowerCase().includes("[mw]")
            ) {
                return (
                    <span>
                        {splitText
                            .slice(0, splitText.length - 1)
                            .join("-")
                            .trim()}
                        {/** If the final part of the text contains the text "converter stations" then append the text " - converter stations" to the end */}
                        {splitText[splitText.length - 1]
                            .toLowerCase()
                            .includes("converter stations") ? (
                            <span> - converter stations</span>
                        ) : (
                            <></>
                        )}
                    </span>
                );
            }
        }

        return <span>{dimensionText}</span>;
    };

    // Inspired from: https://stackoverflow.com/a/61020180
    const sanitizeFloatText = (content: string): string => {
        if (!content) {
            return "";
        }

        //Index of first comma
        const posC = content.indexOf(",");

        if (posC === -1) {
            //No commas found, treat as float
            return content;
        }

        //Index of first full stop
        const posFS = content.indexOf(".");

        if (posFS === -1) {
            //Uses commas and not full stops - swap them (e.g. 1,23 --> 1.23)
            return content.replace(/,/g, ".");
        }

        //Uses both commas and full stops - ensure correct order and remove 1000s separators
        return posC < posFS
            ? content.replace(/,/g, "")
            : content.replace(/\./g, "").replace(",", ".");
    };

    const parsePastedContent = (data: string, primId: string, secId: string) => {
        let primary = [...(question.subQuestions?.filter(sq => sq.isPrimaryDimension) ?? [])];
        let secondary = [...(question.subQuestions?.filter(sq => !sq.isPrimaryDimension) ?? [])];
        let newSubValues = [...subValues];

        primary.sort((f, s) => f.dimensionOrderNumber - s.dimensionOrderNumber);
        secondary.sort((f, s) => f.dimensionOrderNumber - s.dimensionOrderNumber);

        let primIndex = primary.findIndex(sq => sq.id === primId);
        let secIndex = secondary.findIndex(sq => sq.id === secId);

        // Spliting data.value into 2D array. \n splits by first dimension (row) and \t splits by second.
        // Note: if text content contains \n or \t inside cell as a legitimate
        // value, then those sections are wrapped in quotes.
        let splitContent: string[][] | null = fullSplit(data);
        if (!splitContent) {
            return;
        }

        // First check if number of rows are within limit.
        if (primIndex + splitContent.length > primary.length) {
            toast(
                `Pasting action found ${splitContent.length} rows, but only ${
                    primary.length - primIndex
                } rows are available from current cell.`,
                false,
                5000
            );
            return;
        }

        // Then for each row check if we have enough column space.
        const availableColSpaces = secondary.length - secIndex;
        for (let i = 0; i < splitContent.length; i++) {
            if (splitContent[i].length > availableColSpaces) {
                toast(
                    `Pasting action found ${splitContent[i].length} columns in row [${
                        i + 1
                    }] but only ${availableColSpaces} columns are available from current cell`,
                    false,
                    5000
                );

                return;
            }
        }

        let currentRow = primIndex;
        splitContent.forEach(row => {
            let currentCol = secIndex;

            row.forEach(cell => {
                let targetSubValueIndex: number = subValues.findIndex(
                    sv =>
                        sv.primaryDimensionSubQuestion === primary[currentRow].id &&
                        sv.secondaryDimensionSubQuestion === secondary[currentCol].id
                );

                let targetSubValue: ISubValue =
                    targetSubValueIndex !== -1
                        ? newSubValues[targetSubValueIndex]
                        : {
                              id: generateGuid(),
                              primaryDimensionSubQuestion: primary[currentRow].id,
                              secondaryDimensionSubQuestion: secondary[currentCol].id,
                              valueId: value.id,
                          };

                // TODO: Here there will have to be some consideration weather this is a number or text question.
                // If it is a number question, then we will also have to parse the input (beware of . and , when parsing decimal numbers)
                if (secondary[currentCol].subQuestionType === "Text") {
                    targetSubValue.textValue = cell;
                } else if (secondary[currentCol].subQuestionType === "Numeric") {
                    let parsed = parseFloat(sanitizeFloatText(cell));
                    targetSubValue.numeric = parsed;
                }

                if (targetSubValueIndex === -1) {
                    newSubValues.push(targetSubValue);
                }

                currentCol++;
            });

            currentRow++;
        });

        setSubValues(newSubValues);
    };

    // Helper function that splits the text by \n and \t only if they are outside of double quotes
    // That way splitting should still work properly even if copied content contains a new line by
    // default.
    // Answer found: https://stackoverflow.com/a/57105078
    const fullSplit = (text: string) => {
        let myRegExp = /(?:(\t)|(\r?\n)|"((?:[^"]+|"")*)"|([^\t\r\n]+))/gi;
        let match = myRegExp.exec(text);
        let emptyRow: string[] = [];
        let row = emptyRow.slice();
        let rows: string[][] = [];
        let prevTab = false;
        let prevRow = false;

        while (match !== null) {
            if (match[4]) {
                // Unquoted data.
                row.push(match[4]);
                prevTab = false;
                prevRow = false;
            } else if (match[3]) {
                // Quoted data (replace escaped double quotes with single)
                row.push(match[3].replace(/""/g, '"'));
                //row.push(match[3]);
                prevTab = false;
                prevTab = false;
            } else if (match[1]) {
                // Tab seperator
                if (prevTab) {
                    // Two tabs means empty data
                    row.push("");
                }
                if (prevRow) {
                    // If first char after new line is a tab, then first cell should be empty
                    row.push("");
                }
                prevTab = true;
                prevRow = false;
            } else if (match[2]) {
                // End of the row
                if (prevTab) {
                    // Previously had a tab, so include the empty data
                    row.push("");
                }
                prevTab = false;
                prevRow = true;
                rows.push(row);

                // Here we are ensuring the new empty row doesn't reference the old one.
                row = emptyRow.slice();
            }

            match = myRegExp.exec(text);
        }

        // Handles missing new line at end of string
        if (row.length) {
            if (prevTab) {
                // Previously had a tab, so include the empty data
                row.push("");
            }
            rows.push(row);
        }

        return rows;
    };

    return (
        <Container className="table-input-display">
            <GroupLegend question={question} />
            <div className="table-wrapper">
                <Table celled compact size="small" collapsing>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell>{displayTableTopLeft()}</Table.HeaderCell>
                            {colSubQuestions.map((sq, index) => {
                                return (
                                    <Table.HeaderCell
                                        key={sq.id}
                                        style={{
                                            backgroundColor: question.groups?.find(
                                                g => g.id === sq.groupId
                                            )?.backgroundColorHex,
                                        }}
                                    >
                                        <span
                                            style={{
                                                color: question.groups?.find(
                                                    g => g.id === sq.groupId
                                                )?.textColorHex,
                                            }}
                                        >
                                            {sq.isRequired && <span className="alert">* </span>}
                                            {sq.dimensionText}
                                        </span>
                                    </Table.HeaderCell>
                                );
                            })}
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        {rowSubQuestions.map((sq, index) => {
                            return (
                                <Table.Row key={sq.id}>
                                    {/** First cell in each row just displays the subquestion text. */}
                                    <Table.Cell
                                        style={{
                                            backgroundColor: question.groups?.find(
                                                g => g.id === sq.groupId
                                            )?.backgroundColorHex,
                                        }}
                                        className="row-display-cell"
                                    >
                                        <Popup
                                            content={sq.dimensionText}
                                            trigger={
                                                <span
                                                    style={{
                                                        color: question.groups?.find(
                                                            g => g.id === sq.groupId
                                                        )?.textColorHex,
                                                    }}
                                                >
                                                    {sq.isRequired && (
                                                        <span className="alert">* </span>
                                                    )}
                                                    {displayFirstRowCell(sq.dimensionText)}
                                                </span>
                                            }
                                        />
                                    </Table.Cell>
                                    {/** After that display as many cells as there are defined columns. */}
                                    {colSubQuestions.map(colSubQuestion => {
                                        return (
                                            <Table.Cell
                                                key={sq.id + colSubQuestion.id}
                                                className={`input-cell ${
                                                    errors?.find(
                                                        e =>
                                                            e[1] === sq.id &&
                                                            e[2] === colSubQuestion.id
                                                    )
                                                        ? "validation-error"
                                                        : ""
                                                }`}
                                            >
                                                <div className="cell-content-container">
                                                    {colSubQuestion.subQuestionType === "Text" && (
                                                        <TextArea
                                                            rows={2}
                                                            disabled={disabled}
                                                            value={
                                                                findSubValue(
                                                                    sq.id,
                                                                    colSubQuestion.id,
                                                                    "text"
                                                                ) ?? ""
                                                            }
                                                            onChange={(e: any, data: any) => {
                                                                // This is how we differentiate between copy/paste change and classic typing change event.
                                                                if (
                                                                    (e.nativeEvent as InputEvent)
                                                                        .inputType ===
                                                                    "insertFromPaste"
                                                                ) {
                                                                    parsePastedContent(
                                                                        data.value,
                                                                        sq.id,
                                                                        colSubQuestion.id
                                                                    );
                                                                } else {
                                                                    updateSubValue(
                                                                        sq,
                                                                        colSubQuestion,
                                                                        data.value,
                                                                        ESubValueType.Text
                                                                    );
                                                                }
                                                            }}
                                                            onBlur={(e: any) => {
                                                                saveValues(subValues);
                                                            }}
                                                        />
                                                    )}
                                                    {colSubQuestion.subQuestionType ===
                                                        "Numeric" && (
                                                        <Input
                                                            disabled={disabled}
                                                            type="number"
                                                            value={findSubValue(
                                                                sq.id,
                                                                colSubQuestion.id,
                                                                "number"
                                                            )}
                                                            onChange={e => {
                                                                if (
                                                                    (e.nativeEvent as InputEvent)
                                                                        .inputType !==
                                                                    "insertFromPaste"
                                                                ) {
                                                                    updateSubValue(
                                                                        sq,
                                                                        colSubQuestion,
                                                                        e.target.valueAsNumber,
                                                                        ESubValueType.Numeric
                                                                    );
                                                                }
                                                            }}
                                                            onPaste={(e: any) => {
                                                                parsePastedContent(
                                                                    e.clipboardData.getData("Text"),
                                                                    sq.id,
                                                                    colSubQuestion.id
                                                                );
                                                            }}
                                                            onBlur={(e: any) => {
                                                                saveValues(subValues);
                                                            }}
                                                        />
                                                    )}
                                                </div>
                                            </Table.Cell>
                                        );
                                    })}
                                </Table.Row>
                            );
                        })}
                    </Table.Body>
                </Table>
            </div>
        </Container>
    );
};
