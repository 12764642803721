import { useEffect, useState } from "react";
import { Modal, Form, Button, Input, Popup } from "semantic-ui-react";
import { ISection } from "../../models/section";

interface IProps {
    isOpen: boolean;
    creating: boolean;
    section: Partial<ISection>;
    formId: string;
    onCancel: () => void;
    onSave: (section: Partial<ISection>) => void;
}

export const SectionEditor = ({ isOpen, creating, section, formId, onCancel, onSave }: IProps) => {
    const [newSection, setNewSection] = useState<Partial<ISection>>(section);

    useEffect(() => {
        setNewSection({ ...section, form: formId });
    }, [section, formId]);

    return (
        <Modal open={isOpen}>
            <Modal.Header>
                {creating ? "Creating a new section" : `Editing section: ${section.name}`}
            </Modal.Header>
            <Modal.Content>
                <Form>
                    <Form.Field>
                        <label>Section name</label>
                        <Input
                            value={newSection.name || ""}
                            onChange={e => setNewSection({ ...newSection, name: e.target.value })}
                        />
                    </Form.Field>
                </Form>
            </Modal.Content>
            <Modal.Actions>
                <Button onClick={onCancel}>Cancel</Button>
                <Popup
                    disabled={newSection.name !== undefined && newSection.name !== ""}
                    content="Section name cannot be empty"
                    trigger={
                        <span className="action-span">
                            <Button
                                disabled={!newSection.name?.length}
                                onClick={() => onSave(newSection)}
                            >
                                Save
                            </Button>
                        </span>
                    }
                />
            </Modal.Actions>
        </Modal>
    );
};
