import { useState, useEffect, useCallback } from "react";
import { useHistory } from "react-router-dom";
import {
    Button,
    Card,
    Grid,
    Modal,
    Select,
    DropdownItemProps,
    Form,
    Checkbox,
} from "semantic-ui-react";
import { createForm, getPublishedForms, getForm } from "../../services/formsService";
import { initNewForm, IForm } from "../../models/form";
import { CreateAndEditForm } from "../FormEditor/CreateAndEditForm";
import { toast } from "../..";
import "./FormSelectionModal.css";

interface IProps {
    open: boolean;
    onClose: () => void;
}

export const FormSelectionModal = ({ open, onClose }: IProps) => {
    const history = useHistory();
    const [newForm, setNewForm] = useState(initNewForm);
    const [blankSelected, setBlankSelected] = useState<boolean>(false);
    const [publishedSelected, setPublishedSelected] = useState<boolean>(false);
    const [publishedForms, setPublishedForms] = useState<IForm[]>([]);
    const [activePublished, setActivePublished] = useState<any>();
    const [selectionOptions, setSelectionOptions] = useState<DropdownItemProps[]>([]);

    useEffect(() => {
        let updatedSelectionOptions: DropdownItemProps[] = [];
        publishedForms.forEach(form => {
            if (!updatedSelectionOptions.some(uso => uso.text === form.name))
                updatedSelectionOptions.push({
                    key: form.id,
                    value: form.id,
                    text: form.name,
                });
        });
        setSelectionOptions(updatedSelectionOptions);
    }, [publishedForms]);

    const fetchPublishedForms = useCallback(async () => {
        const inProgressForms = await getPublishedForms();
        Array.isArray(inProgressForms) && setPublishedForms(inProgressForms);
    }, []);

    useEffect(() => {
        fetchPublishedForms();
    }, [fetchPublishedForms]);

    const cancelAddForm = () => {
        setBlankSelected(false);
        setNewForm(initNewForm);
    };

    const createFormProcedure = async () => {
        if (newForm.name !== "") {
            let res = null;
            try {
                res = await createForm(newForm);
                if (res.id) {
                    history.push(`/forms/overview/edit/${res.id}`);
                    toast("Form created successfully", true, 1000);
                }
            } catch (error) {
                if (res !== null) toast("Error while creating form", false, 1000);
            }
        }
    };

    return (
        <Modal open={open} className="create-form-modal">
            <Modal.Header>Create new form</Modal.Header>
            <Modal.Content>
                <SelectionContent
                    setBlankSelected={setBlankSelected}
                    setPublishedSelected={setPublishedSelected}
                />
            </Modal.Content>
            <Modal.Actions>
                <Button onClick={onClose}>Cancel</Button>
            </Modal.Actions>

            <CreateAndEditForm
                header="Create a new Form"
                cancelAddForm={cancelAddForm}
                createFormProcedure={createFormProcedure}
                form={newForm}
                handleClose={() => setBlankSelected(false)}
                setForm={setNewForm}
                open={blankSelected}
                confirmString="Create"
            ></CreateAndEditForm>

            <Modal open={publishedSelected} className="clone-options from-published-modal">
                <Modal.Header>Creating from a previously published form</Modal.Header>
                <Modal.Content>
                    <Form>
                        <Form.Field>
                            <label>Template form:</label>
                            <Select
                                placeholder="Select a published form"
                                options={selectionOptions}
                                onChange={(e, data) => setActivePublished(data.value)}
                            />
                        </Form.Field>
                        <Form.Field>
                            <label>Hard copy:</label>
                            <Checkbox
                                toggle
                                checked={newForm.isHardCopy}
                                onChange={e =>
                                    setNewForm({ ...newForm, isHardCopy: !newForm.isHardCopy })
                                }
                            />
                        </Form.Field>
                    </Form>
                </Modal.Content>
                <Modal.Actions>
                    <Button
                        onClick={() => {
                            setPublishedSelected(false);
                        }}
                    >
                        Cancel
                    </Button>
                    <Button
                        disabled={!activePublished}
                        onClick={async () => {
                            const fetchedForm = await getForm(activePublished);
                            if (fetchedForm != null && fetchedForm.name) {
                                fetchedForm.deadline = "";
                                setNewForm({
                                    ...fetchedForm,
                                    specialDeadlines: [],
                                    userDownload: false,
                                    isHardCopy: newForm.isHardCopy,
                                });
                            }
                            setBlankSelected(true);
                        }}
                    >
                        Create
                    </Button>
                </Modal.Actions>
            </Modal>
        </Modal>
    );
};

interface IContentProps {
    setBlankSelected: (selected: boolean) => void;
    setPublishedSelected: (selecte: boolean) => void;
}

// Content extracted to clean up FormSelectionModal component.
function SelectionContent(props: IContentProps) {
    return (
        <Grid stackable columns={2}>
            <Grid.Row>
                <Grid.Column>
                    <Card onClick={() => props.setBlankSelected(true)}>
                        <Card.Content>
                            <Card.Header>Blank</Card.Header>
                            <Card.Meta>Creates a new blank form</Card.Meta>
                        </Card.Content>
                    </Card>
                </Grid.Column>
                <Grid.Column>
                    <Card onClick={() => props.setPublishedSelected(true)}>
                        <Card.Content>
                            <Card.Header>Published</Card.Header>
                            <Card.Meta>Uses published form to create a new one</Card.Meta>
                        </Card.Content>
                    </Card>
                </Grid.Column>
            </Grid.Row>
        </Grid>
    );
}
