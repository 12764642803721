// path - used to find breadcrumb list
// name - breadcrumb name
// link - location that breadcrumb links to

let routes = [
    { path: "/unauthorized", name: "Unauthorized", link: null },

    //Users
    { path: "/user-actions/*", name: "Users", link: null, end: false },
    {
        path: "/user-actions/manage-users",
        name: "Manage Users",
        link: "/user-actions/manage-users",
        end: true,
    },
    {
        path: "/user-actions/user-activity",
        name: "User Activity",
        link: "/user-actions/user-activity",
        end: true,
    },

    //Input Data Editor
    {
        path: "/input-data-editor",
        name: "Input Data Editor",
        link: "/input-data-editor",
        end: true,
    },

    //Annual Report Generation
    {
        path: "/annual-report-generation",
        name: "Annual Report Generation",
        link: "/annual-report-generation",
        end: true,
    },

    //Forms
    { path: "/forms/*", name: "Forms", link: null, end: false },
    { path: "/forms/overview", name: "Overview", link: "/forms/overview", end: true },
    { path: "/forms/overview/*", name: "Overview", link: "/forms/overview", end: false },
    { path: "/forms/overview/data-delivery/*", name: "Data Delivery", link: null, end: false },
    {
        path: "/forms/overview/data-delivery/:formId",
        name: "FormID",
        link: "/forms/overview/data-delivery/:formId",
        end: true,
    },
    {
        path: "/forms/overview/data-delivery/overview",
        name: "Data Delivery Overview",
        link: "/forms/overview/data-delivery/overview",
        end: true,
    },
    { path: "/forms/overview/edit/*", name: "Edit", link: null, end: false },
    { path: "/forms/overview/edit/:id", name: "FormID", link: null, end: true },
    { path: "/forms/create", name: "Create", link: "/forms/create", end: true },
    { path: "/forms/results", name: "Results", link: "/forms/results", end: true },

    //Figures
    { path: "/figures", name: "Figures", link: "/figures", end: true },

    //Reports
    { path: "/reports/*", name: "Reports", link: null, end: false },
    { path: "/reports/overview", name: "Overview", link: "/reports/overview", end: true },
    { path: "/reports/overview/*", name: "Overview", link: "/reports/overview", end: false },
    { path: "/reports/data-view/*", name: "Dynamic graphs", link: null, end: false },
    {
        path: "/reports/data-view/FRT",
        name: "FRT non-exhaustive parameters",
        link: null,
        end: true,
    },
    {
        path: "/reports/data-view/generation-capacity",
        name: "Generation and HVDC capacities",
        link: null,
        end: true,
    },
    { path: "/reports/download", name: "Download", link: null, end: true },
    { path: "/reports/overview/:id/*", name: "ReportID", link: null, end: false },
    { path: "/reports/overview/:id/front-page", name: "Front Page", link: null, end: true },
    {
        path: "/reports/overview/:id/table-of-contents",
        name: "Table of Contents",
        link: null,
        end: true,
    },
    { path: "/reports/overview/:id/chapters/*", name: "Content", link: null, end: true },
];

export default routes;
