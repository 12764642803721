import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
    Bar,
    BarChart,
    Legend,
    ResponsiveContainer,
    Tooltip,
    TooltipProps,
    XAxis,
    YAxis,
} from "recharts";
import { Button } from "semantic-ui-react";
import { toast } from "../..";
import { UserData } from "../../actions/authentificationActions";
import { CountrySelector } from "../../components/CountrySelector/CountrySelector";
import { getGradient } from "../../helpers/gradient";
import { IWindowSize, useWindowSize } from "../../hooks/ResizeHook";
import { IAdditionalChartQuestion } from "../../models/additionalChart";
import { ICountry } from "../../models/country";
import { getCountries } from "../../services/countriesService";
import { getChartData, getChartYears } from "../../services/figuresService";
import { getBlocks } from "../../services/formsService";
import { AppState } from "../../store/configureStore";
import "./FrtChart.css";

const chartTypes: { [key: string]: string } = {
    rfg_frequency_ranges: "RfG Frequency Ranges",
    rfg_rocof: "RfG RoCoF",
    rfg_lfsm_o: "RfG LFSM-O",
    rfg_lfsm_u: "RfG LFSM-U",
    rfg_blackstart: "RfG Blackstart capability",
    dcc_frequency_ranges: "DCC Frequency Ranges",
};

export const FrtAdditionalChart = () => {
    const user: UserData = useSelector((state: AppState) => state.user);
    const [chartYears, setChartYears] = useState<number[]>([]);
    const [selectedYear, setSelectedYear] = useState<number>(2020);
    const [chartType, setChartType] = useState("rfg_frequency_ranges");

    const [colors, setColors] = useState<string[]>([]);
    const [data, setData] = useState<Record<string, string | number>[]>([]);
    const [filteredData, setFilteredData] = useState<Record<string, string | number>[]>([]);

    const [selectedCountries, setSelectedCountries] = useState<ICountry[]>([]);
    const [allCountries, setAllCountries] = useState<ICountry[]>([]);
    const [maxRowsInOption, setMaxRowsInOption] = useState(0);

    const windowSize: IWindowSize = useWindowSize();

    useEffect(() => {
        setColors(getGradient(36));
    }, []);

    const getCountryColor = (country: string) => {
        return colors[allCountries.findIndex(c => c.name === country)];
    };

    useEffect(() => {
        getBlocks().then(b =>
            getCountries(b.find(x => x.name === "Network codes monitoring")?.id).then(res => {
                setAllCountries(res);
                setSelectedCountries(res.filter(c => c.abbreviation === user.countryAbbreviation));
            })
        );
    }, [user.countryAbbreviation]);

    useEffect(() => {
        if (selectedYear && allCountries.length !== 0) {
            getChartData<IAdditionalChartQuestion[]>(chartType, selectedYear)
                .then(res => {
                    res.sort((q1, q2) => q1.questionText.localeCompare(q2.questionText));
                    let newData: Record<string, string | number>[] = [];

                    res.forEach(question => {
                        let keys = Object.keys(question.options);
                        keys.forEach((optionKey, optionIndex) => {
                            let dataPoint: Record<string, string | number> = {
                                questionName: question.questionText,
                                optionName: optionKey,
                            };

                            if (optionIndex === keys.length - 1) {
                                dataPoint.state = `end|${keys.length}|${question.shortText}`;
                            }

                            question.options[optionKey].forEach((country, answerIndex) => {
                                dataPoint[country] = 1;
                            });

                            newData.push(dataPoint);
                        });
                    });

                    if (newData.length > 0) {
                        // Set last data point as last so that we don't draw a line after last data point.
                        let lastDataState: string[] = (
                            newData[newData.length - 1].state as string
                        ).split("|");

                        lastDataState[0] = "last";
                        newData[newData.length - 1].state = lastDataState.join("|");
                        setData(newData);
                    } else {
                        // No options found. Potentially question does not have updated options for selected year. Clear data.
                        setData([]);
                    }
                })
                .catch(err => {
                    toast("Unable to load chart data.", false, 3000);
                });
        }
    }, [chartType, selectedYear, allCountries]);

    useEffect(() => {
        getChartYears(chartType).then(years => {
            setChartYears(years);

            /*if (years.length !== 0) {
                setSelectedYear(Math.max(...years));
            }*/
        });
    }, [chartType, setChartYears]);

    useEffect(() => {
        const filteredData = data.map(dataPoint => {
            let newDataPoint: Record<string, string | number> = {
                questionName: dataPoint.questionName,
                optionName: dataPoint.optionName,
                state: dataPoint.state,
            };

            selectedCountries.forEach(country => {
                if (dataPoint[country.name]) {
                    newDataPoint[country.name] = 1;
                }
            });

            return newDataPoint;
        });

        setFilteredData(filteredData);
    }, [data, selectedCountries]);

    const customTooltip = ({ payload }: TooltipProps) => {
        const filteredPayload = payload?.filter(p => p.payload[p.dataKey as string] !== undefined);
        filteredPayload?.sort((p1, p2) => {
            let c1: ICountry | undefined = allCountries.find(
                c => c.name === (p1.dataKey as string)
            );
            let c2: ICountry | undefined = allCountries.find(
                c => c.name === (p2.dataKey as string)
            );

            return (c2?.abbreviation as string).localeCompare(c1?.abbreviation as string);
        });

        return (
            <div className="frt-additional-tooltip">
                {payload?.length && <p>{payload[0].payload.optionName}</p>}
                {filteredPayload && filteredPayload.length > 0 ? (
                    <>
                        {filteredPayload.map(p => {
                            return (
                                <p
                                    key={p.dataKey as string}
                                    style={{
                                        color: getCountryColor(p.dataKey?.toString() ?? ""),
                                        margin: 0,
                                    }}
                                >
                                    {p.dataKey}
                                </p>
                            );
                        })}
                    </>
                ) : (
                    <p>
                        <b>No data</b>
                    </p>
                )}
            </div>
        );
    };

    const renderQuestionTick = (tickProps: any) => {
        const { x, y, payload } = tickProps;
        const { value, offset } = payload;

        if (value && x && y && offset) {
            const [, count, questionName] = value.split("|");
            if (!count) return null;

            return (
                <text
                    x={x - offset * (count - 1)}
                    y={10}
                    textAnchor="middle"
                    fontSize={12}
                    fontWeight="600"
                >{`${questionName}`}</text>
            );
        }

        return null;
    };

    function getTextWidth(text: string, font: string) {
        const canvas = document.createElement("canvas");
        const context: CanvasRenderingContext2D | null = canvas.getContext("2d");

        if (!context) return 0;

        context.font = font || getComputedStyle(document.body).font;

        return context.measureText(text).width;
    }

    const renderOptionTick = (tickProps: any) => {
        const { x, y, payload } = tickProps;
        const { value, offset } = payload;
        if (!value) return;

        let optionAsArray: string[] = [];
        let splitOption = value.split(" ");

        // Split option into multiple lines if it is too long
        splitOption.forEach((word: string, index: number) => {
            if (index === 0) {
                // Handle case where first word is longer than offset
                if (getTextWidth(word, "8px Roboto") > offset) {
                    let splitWord = word.split("");
                    let newWord = "";
                    splitWord.forEach((letter: string, letterIndex: number) => {
                        if (getTextWidth(newWord + letter, "10px Roboto") < offset * 1.25) {
                            newWord += letter;
                        } else {
                            if (letterIndex >= splitWord.length - 2) {
                                newWord += letter;
                                return;
                            }

                            optionAsArray.push(newWord + "-");
                            newWord = letter;
                        }
                    });
                    optionAsArray.push(newWord);
                } else {
                    optionAsArray.push(word);
                }
            } else {
                let lastWord = optionAsArray[optionAsArray.length - 1];
                if (getTextWidth(lastWord + " " + word, "8px Roboto") < offset) {
                    optionAsArray[optionAsArray.length - 1] = lastWord + " " + word;
                } else {
                    // Handle case where word is longer than offset
                    if (getTextWidth(word, "8px Roboto") > offset) {
                        let splitWord = word.split("");
                        let newWord = "";
                        splitWord.forEach((letter: string, letterIndex: number) => {
                            if (getTextWidth(newWord + letter, "10px Roboto") < offset * 1.25) {
                                newWord += letter;
                            } else {
                                if (letterIndex >= splitWord.length - 2) {
                                    newWord += letter;
                                    return;
                                }

                                optionAsArray.push(newWord + (optionAsArray.length < 3 ? "-" : ""));
                                newWord = letter;
                            }
                        });
                        optionAsArray.push(newWord);
                    } else {
                        optionAsArray.push(word);
                    }
                }
            }
        });

        if (maxRowsInOption < optionAsArray.length) {
            setMaxRowsInOption(optionAsArray.length);
        }

        if (x && y) {
            return (
                <text x={x} y={y - 16} textAnchor="middle" fontSize={10} width={offset}>
                    {optionAsArray.map((word: string, index: number) => {
                        if (index === 4) return "...";
                        else if (index > 4) return null;
                        else {
                            return (
                                <tspan
                                    key={index}
                                    x={x}
                                    dy={index === 0 ? 0 : 12}
                                    textAnchor="middle"
                                    fontSize={11}
                                    width={offset}
                                >
                                    {word}
                                </tspan>
                            );
                        }
                    })}
                </text>
            );
        }

        return null;
    };

    const renderQuestionsSplitTicks = (tickProps: any) => {
        const { x, y, payload } = tickProps;
        const { value, offset } = payload;

        if (value) {
            const [state] = value.split("|");
            if (state === "last") {
                return null;
            }

            const pathX = Math.floor(x + offset) + 0.5;
            return <path d={`M${pathX},${y - 60}v${-1000}`} stroke="grey" strokeWidth={2} />;
        }

        return null;
    };

    return (
        <div className="additional-frt">
            <div className="container-header">
                <h1>Other non-exhaustive parameters</h1>
            </div>
            {chartYears.length > 0 ? (
                <div className="frt-chart-container">
                    <div className="chart-type-selector">
                        {Object.keys(chartTypes).map(key => (
                            <Button
                                onClick={setChartType.bind(null, key)}
                                primary={chartType === key}
                                key={key}
                            >
                                {chartTypes[key]}
                            </Button>
                        ))}
                    </div>
                    <div className="additional-chart-wrapper">
                        <ResponsiveContainer
                            debounce={1}
                            height={
                                windowSize.height
                                    ? windowSize.height / 1.9 + maxRowsInOption * 0.1
                                    : 480
                            }
                            className="additional-chart"
                        >
                            <BarChart
                                data={filteredData}
                                margin={{ top: 16, left: 5, right: 5, bottom: 5 }}
                            >
                                <Tooltip
                                    content={customTooltip}
                                    filterNull={false}
                                    isAnimationActive={false}
                                />
                                <XAxis
                                    tick={renderOptionTick}
                                    dataKey="optionName"
                                    xAxisId="options"
                                    axisLine={false}
                                    tickLine={false}
                                    interval={0}
                                />
                                <XAxis
                                    tick={renderQuestionsSplitTicks}
                                    dataKey="state"
                                    tickCount={data.length}
                                    axisLine={false}
                                    tickLine={false}
                                    interval={0}
                                    xAxisId="splitlines"
                                />
                                <XAxis
                                    tick={renderQuestionTick}
                                    dataKey="state"
                                    tickCount={data.length}
                                    interval={0}
                                />

                                <YAxis allowDecimals={false} />
                                {allCountries
                                    .filter(c => selectedCountries.indexOf(c) !== -1)
                                    .map(country => {
                                        return (
                                            <Bar
                                                animationDuration={850}
                                                animationEasing="ease-in-out"
                                                key={country.name}
                                                dataKey={country.name}
                                                stackId="a"
                                                fill={getCountryColor(country.name)}
                                            />
                                        );
                                    })}
                                <Legend
                                    verticalAlign="bottom"
                                    wrapperStyle={{ position: "relative" }}
                                />
                            </BarChart>
                        </ResponsiveContainer>
                    </div>
                    <CountrySelector
                        selectedCountries={selectedCountries ?? []}
                        setSelectedCountries={(countries: ICountry[]) => {
                            countries.sort((c1, c2) => c1.name.localeCompare(c2.name));
                            setSelectedCountries(countries);
                        }}
                        initializeUsersCountry={true}
                        countries={allCountries}
                        loadingCountries={false}
                        subOptions={{}}
                        setSubOptions={() => {}}
                    />
                </div>
            ) : (
                <h2>No data available...</h2>
            )}
        </div>
    );
};
