import { useSelector } from "react-redux";
import { Redirect } from "react-router-dom";
import { Button } from "semantic-ui-react";
import { UserRole } from "../../actions/authentificationActions";
import { Breadcrumbs } from "../../components/Breadcrumbs/Breadcrumbs";
import { AppState } from "../../store/configureStore";
import "./UserActivity.css";

export const UserActivity = () => {
    const user = useSelector((state: AppState) => state.user);

    return user.role !== UserRole.Admin ? (
        <Redirect to="/unauthorized" />
    ) : (
        <>
            <div className="container-header">
                <h1>User activity</h1>
            </div>
            <Breadcrumbs />
            <div className="activity-container">
                <Button
                    className="activity-button"
                    content="Google analytics"
                    primary
                    onClick={() =>
                        window.open("https://analytics.google.com/analytics/web/", "_blank")
                    }
                />
            </div>
        </>
    );
};
