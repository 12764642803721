import { IFile } from "./file";

export interface IValue {
    id: string;
    question: string;
    result: string;
    numeric?: number;
    textual?: string;
    options?: string[];
    remarkText?: string;
    lastEditedDate?: string;
    editRemarkText?: string;
    files: IFile[];
    subValues?: ISubValue[];
}

// Used for table input. Represents one cell of the table.
export interface ISubValue {
    id: string;
    textValue?: string;
    numeric?: number;
    primaryDimensionSubQuestion: string;
    secondaryDimensionSubQuestion: string;
    valueId: string;
}

export enum ESubValueType {
    Text,
    Numeric,
}
