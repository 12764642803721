import { useCallback, useEffect, useState } from "react";
import { Button, Modal, Input, Form, Pagination } from "semantic-ui-react";
import { toast } from "../..";
import { figureFilterInit, IFigureDto, IFigureFilter } from "../../models/figure";
import { getFigures } from "../../services/figuresService";
import "./FigureSelectionModal.css";

interface IProps {
    open: boolean;
    onClose: () => void;
    onSelect: (guid: string, figureName: string) => void;
}

export const FigureSelectionModal = ({ open, onClose, onSelect }: IProps) => {
    const [figureFilter, setFigureFilter] = useState<IFigureFilter>(figureFilterInit);
    const [figures, setFigures] = useState<IFigureDto[]>([]);

    const [page, setPage] = useState(1);
    const [totalImages, setTotalImages] = useState(0);
    const numberOfItemsToFetch = 8; // 4x2 thumbnail grid.

    const refreshFigures = useCallback(async () => {
        try {
            const res = await getFigures(figureFilter, page, numberOfItemsToFetch);
            setFigures(res.result);
            setTotalImages(res.totalRows);
        } catch (err) {
            toast("Unable to load figures", false, 1000);
        }
    }, [figureFilter, page]);

    useEffect(() => {
        refreshFigures();
    }, [refreshFigures]);

    useEffect(() => {
        setPage(1);
    }, [figureFilter]);

    return (
        <Modal open={open} onClose={onClose}>
            <Modal.Header>Figure selection</Modal.Header>
            <Modal.Content>
                <Input
                    value={figureFilter.name}
                    onChange={(e, data) => setFigureFilter({ ...figureFilter, name: data.value })}
                    icon="search"
                />
                <div className="figures-container">
                    {figures.map(f => {
                        return (
                            <FigureSelectDisplay
                                key={f.id}
                                figure={f}
                                onSelectionConfirm={onSelect}
                            />
                        );
                    })}
                </div>
                <div className="selection-modal-pagination-container">
                    <Pagination
                        activePage={page}
                        totalPages={Math.ceil(totalImages / numberOfItemsToFetch)}
                        onPageChange={(event, data) => setPage(data.activePage as number)}
                    />
                </div>
            </Modal.Content>
            <Modal.Actions>
                <Button content="Cancel" onClick={onClose} />
            </Modal.Actions>
        </Modal>
    );
};

interface IFigSelectProps {
    figure: IFigureDto;
    onSelectionConfirm: (guid: string, figureName: string) => void;
}

const FigureSelectDisplay = ({ figure, onSelectionConfirm }: IFigSelectProps) => {
    const [displayOpen, setDisplayOpen] = useState(false);
    const [figureChapterName, setFigureChapterName] = useState(
        figure.placeholderName || figure.name
    );

    return (
        <div>
            <div>
                <img
                    src={`/api/figures/${figure.id}?thumbnail=true`}
                    alt={figure.name}
                    onClick={() => setDisplayOpen(true)}
                />
                <p>{figure.name}</p>
            </div>

            <Modal open={displayOpen} onClose={() => setDisplayOpen(false)}>
                <Modal.Header>{figure.name}</Modal.Header>
                <Modal.Content className="image-content-container">
                    <Form>
                        <Form.Field>
                            <label>Figure name</label>
                            <Input
                                placeholder="Name used for this figure in this chapter.."
                                value={figureChapterName}
                                onChange={e => setFigureChapterName(e.target.value)}
                            />
                        </Form.Field>
                    </Form>

                    <img className="figure" src={`/api/figures/${figure.id}`} alt={figure.name} />
                </Modal.Content>
                <Modal.Actions>
                    <Button content="Close" onClick={() => setDisplayOpen(false)} />
                    <Button
                        content="Add"
                        onClick={() => onSelectionConfirm(figure.id, figureChapterName)}
                    />
                </Modal.Actions>
            </Modal>
        </div>
    );
};
