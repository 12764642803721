import { useCallback, useEffect, useState } from "react";
import { Button, DropdownItemProps, Form, Input, Modal, Select } from "semantic-ui-react";
import { FileDropzone } from "../../components/Dropzone/FileDropzone";
import staticFilterOptions from "../../components/FormsOverviewTable/staticFilterOptions.json";
import { IForm } from "../../models/form";
import { figureDataInit } from "../../models/figure";
import { toast } from "../..";
import { uploadFigure } from "../../services/figuresService";

interface IProps {
    uploadModalOpen: boolean;
    allFormsWithAttributes: IForm[];
    closeUploadModal: () => void;
    handleClearFilter: () => void;
    handleFormsAsDropdownProps: (allForms: IForm[]) => DropdownItemProps[];
    formCategories: DropdownItemProps[];
}

export const UploadModal = ({
    uploadModalOpen,
    allFormsWithAttributes,
    closeUploadModal,
    handleClearFilter,
    handleFormsAsDropdownProps,
    formCategories,
}: IProps) => {
    const [figureToUpload, setFigureToUpload] = useState(figureDataInit);
    const [imageToUpload, setImageToUpload] = useState<File>();
    const [loading, setLoading] = useState(false);
    const [formDropdownProps, setFormDropdownProps] = useState<DropdownItemProps[]>([]);

    useEffect(() => {
        setFormDropdownProps(handleFormsAsDropdownProps(allFormsWithAttributes));
    }, [allFormsWithAttributes, handleFormsAsDropdownProps]);

    const fetchFormsAsDropdownProps = useCallback(async () => {
        if (figureToUpload.category) {
            const filteredForms = allFormsWithAttributes.filter(
                form => form.category === figureToUpload.category
            );
            if (Array.isArray(filteredForms)) {
                const dropdownItemPropsArray: DropdownItemProps[] = [];
                filteredForms.forEach(f => {
                    if (!dropdownItemPropsArray.some(e => e.value === f.name)) {
                        dropdownItemPropsArray.push({ key: f.id, text: f.name, value: f.name });
                    }
                });
                setFormDropdownProps(dropdownItemPropsArray);
            }
        } else if (figureToUpload.formName) {
            const selectedForm = allFormsWithAttributes.find(
                form => form.name === figureToUpload.formName
            );
            setFigureToUpload({ ...figureToUpload, category: selectedForm!.category });
        } else {
            setFormDropdownProps(handleFormsAsDropdownProps(allFormsWithAttributes));
        }
    }, [allFormsWithAttributes, figureToUpload, handleFormsAsDropdownProps]);

    useEffect(() => {
        fetchFormsAsDropdownProps();
    }, [fetchFormsAsDropdownProps]);

    const handleUploadFigure = async () => {
        setLoading(true);
        try {
            await uploadFigure(figureToUpload, imageToUpload);
            toast("Successfully added figure", true, 1000);
        } catch {
            toast("Error adding figure", false, 1000);
        } finally {
            setLoading(false);
            handleCloseModal();
        }
    };

    const handleCloseModal = () => {
        handleClearFilter();
        setFigureToUpload(figureDataInit);
        setImageToUpload(undefined);
        closeUploadModal();
    };

    return (
        <Modal open={uploadModalOpen}>
            <Modal.Header>Upload figure</Modal.Header>
            <Modal.Content>
                <Form>
                    <Form.Field>
                        <label>Name</label>
                        <Input
                            value={figureToUpload.name}
                            onChange={(e, data) =>
                                setFigureToUpload({ ...figureToUpload, name: data.value })
                            }
                        />
                    </Form.Field>
                    <Form.Field>
                        <label>Year</label>
                        <Select
                            fluid
                            value={figureToUpload.year}
                            options={staticFilterOptions.year}
                            onChange={(e, data) =>
                                setFigureToUpload({ ...figureToUpload, year: data.value as string })
                            }
                        />
                    </Form.Field>
                    <Form.Field>
                        <label>Category</label>
                        <Select
                            fluid
                            value={figureToUpload.category}
                            options={formCategories}
                            onChange={(e, data) =>
                                setFigureToUpload({
                                    ...figureToUpload,
                                    category: data.value as string,
                                })
                            }
                        />
                    </Form.Field>
                    <Form.Field>
                        <label>Form</label>
                        <Select
                            fluid
                            value={figureToUpload.formName}
                            options={formDropdownProps}
                            onChange={(e, data) =>
                                setFigureToUpload({
                                    ...figureToUpload,
                                    formName: data.value as string,
                                })
                            }
                        />
                    </Form.Field>
                    <Form.Field>
                        <FileDropzone
                            handleSetFile={(f: File) => setImageToUpload(f)}
                            msg={
                                imageToUpload
                                    ? imageToUpload.name
                                    : "Drag and drop image here or click to select"
                            }
                        />
                    </Form.Field>
                </Form>
            </Modal.Content>
            <Modal.Actions>
                <Button onClick={handleCloseModal} content="Cancel" />
                <Button
                    primary
                    loading={loading}
                    disabled={loading || figureToUpload.name === "" || imageToUpload === undefined}
                    content="Upload"
                    onClick={() => handleUploadFigure()}
                />
            </Modal.Actions>
        </Modal>
    );
};
