import { IFile } from "../models/file";
import { getAuthorizationProperties } from "./authorizationHelper";

export async function uploadFile(valueId: string, file: File): Promise<IFile> {
    const formData = new FormData();
    formData.append("file", file, file.name);
    const res = await fetch(`/api/files/value/${valueId}`, {
        method: "POST",
        ...getAuthorizationProperties(),
        body: formData,
    });
    if (res.ok) return await res.json();
    else throw Error("Error uploading file!");
}

export async function deleteFile(fileId: string) {
    const res = await fetch(`/api/files/${fileId}`, {
        method: "DELETE",
        ...getAuthorizationProperties(),
    });
    if (!res.ok) throw Error("Error deleting file!");
}
