import { useEffect, useState } from "react";
import { Button, Checkbox, Container, Divider, Form, Table } from "semantic-ui-react";
import { toast } from "../../..";
import { IQuestion, ISubQuestion, subQuestionInit } from "../../../models/question";
import { groupInit, ITableInputGroup } from "../../../models/tableInputGroups";
import { SubQuestionFormInput } from "./HelperComponents/SubQuestionInputForm";
import { TableGroupInputForm } from "./HelperComponents/TableGroupInputForm";
import { GroupLegend } from "./HelperComponents/GroupLegend";
import generateGuid from "../../../helpers/guid";
import "./TableInput.css";

export interface ITableInputProps {
    question: IQuestion;
    saveChanges: (question: IQuestion) => void;
    hardSave?: () => void;
}

export const TableInputCreate = ({ question, saveChanges, hardSave }: ITableInputProps) => {
    const [showDetailedControls, setShowDetailedControls] = useState(true);
    const [tempQState, setTempQState] = useState<IQuestion>(question);
    const [newSubQuestionRow, setNewSubquestionRow] = useState<ISubQuestion>(subQuestionInit);
    const [newSubQuestionCol, setNewSubquestionCol] = useState<ISubQuestion>({
        ...subQuestionInit,
        isPrimaryDimension: false,
    });
    const [newTableGroup, setNewTableGroup] = useState<ITableInputGroup>({
        ...groupInit,
        questionId: question.id,
    });

    const [highlightedQuestion, setHighLightedQuestion] = useState<ISubQuestion>();
    const [highlightedGroup, setHighlightedGroup] = useState<ITableInputGroup>();

    const addSubQuestion = (isRow: boolean) => {
        let copyQuestion = { ...tempQState };
        if (!copyQuestion.subQuestions) copyQuestion.subQuestions = [];

        let subQuestion = isRow ? { ...newSubQuestionRow } : { ...newSubQuestionCol };
        subQuestion.dimensionOrderNumber = copyQuestion.subQuestions.filter(sq =>
            isRow ? sq.isPrimaryDimension : !sq.isPrimaryDimension
        ).length;
        subQuestion.id = generateGuid();
        copyQuestion.subQuestions.push(subQuestion);

        setTempQState(copyQuestion);
        saveChanges(copyQuestion);
        if (isRow) {
            setNewSubquestionRow({
                ...subQuestionInit,
                dimensionOrderNumber:
                    copyQuestion.subQuestions.filter(sq => sq.isPrimaryDimension).length + 1,
            });
        } else {
            setNewSubquestionCol({
                ...subQuestionInit,
                isPrimaryDimension: false,
                dimensionOrderNumber:
                    copyQuestion.subQuestions.filter(sq => !sq.isPrimaryDimension).length + 1,
            });
        }
    };

    const reorderSubQuestions = (id: string, isRow: boolean, moveToHigherOrder: boolean) => {
        try {
            let copyQuestion = { ...tempQState };

            let subQuestions = tempQState.subQuestions ? [...tempQState.subQuestions] : [];
            let targetIndex = subQuestions.findIndex(sq => sq.id === id);

            const filteredSubQuestions = subQuestions.filter(sq =>
                isRow ? sq.isPrimaryDimension : !sq.isPrimaryDimension
            );
            const filteredIndex = filteredSubQuestions.findIndex(sq => sq.id === id);
            const replacementTarget =
                filteredSubQuestions[moveToHigherOrder ? filteredIndex + 1 : filteredIndex - 1];

            let replacementIndex = subQuestions.findIndex(sq => sq.id === replacementTarget.id);

            let temp = subQuestions[targetIndex].dimensionOrderNumber;
            subQuestions[targetIndex].dimensionOrderNumber =
                subQuestions[replacementIndex].dimensionOrderNumber;
            subQuestions[replacementIndex].dimensionOrderNumber = temp;

            subQuestions.sort((f, s) => f.dimensionOrderNumber - s.dimensionOrderNumber);
            copyQuestion.subQuestions = subQuestions;

            setTempQState(copyQuestion);
            saveChanges(copyQuestion);
        } catch (err) {
            toast("Reordering falied!", false, 3000);
        }
    };

    const removeSubQuestion = (id: string) => {
        try {
            let copyQuestion = { ...tempQState };
            let subQuestions = tempQState.subQuestions ? [...tempQState.subQuestions] : [];

            const targetQuestionIndex = subQuestions.findIndex(sq => sq.id === id);
            subQuestions = subQuestions.map(sq => {
                if (
                    sq.isPrimaryDimension ===
                        subQuestions[targetQuestionIndex].isPrimaryDimension &&
                    sq.dimensionOrderNumber > subQuestions[targetQuestionIndex].dimensionOrderNumber
                ) {
                    return { ...sq, dimensionOrderNumber: sq.dimensionOrderNumber - 1 };
                } else {
                    return { ...sq };
                }
            });
            subQuestions.splice(targetQuestionIndex, 1);

            copyQuestion.subQuestions = subQuestions;
            setTempQState(copyQuestion);
            saveChanges(copyQuestion);
            setHighLightedQuestion(undefined);
        } catch (err) {
            toast("Delete falied!", false, 3000);
        }
    };

    const saveHighlightedQuestion = (highlightedQuestion: ISubQuestion) => {
        try {
            let copyQuestion = { ...tempQState };
            let subQuestions = tempQState.subQuestions ? [...tempQState.subQuestions] : [];

            const targetQuestionIndex = subQuestions.findIndex(
                sq => sq.id === highlightedQuestion.id
            );

            subQuestions[targetQuestionIndex].isRequired = highlightedQuestion.isRequired;
            subQuestions[targetQuestionIndex].dimensionText = highlightedQuestion.dimensionText;
            subQuestions[targetQuestionIndex].subQuestionType = highlightedQuestion.subQuestionType;
            subQuestions[targetQuestionIndex].groupId = highlightedQuestion.groupId;

            copyQuestion.subQuestions = subQuestions;
            setTempQState(copyQuestion);
            saveChanges(copyQuestion);
            setHighLightedQuestion(undefined);
        } catch (err) {
            toast("Edit was unsuccessful", false, 3000);
        }
    };

    const addNewGroup = () => {
        let groups = question.groups ? [...question.groups] : [];
        let newGroup = { ...newTableGroup };
        newGroup.id = generateGuid();

        groups.push(newGroup);
        saveChanges({ ...question, groups: groups });
        setNewTableGroup(groupInit);
    };

    const saveHighlightedGroup = (highlightedGroup: ITableInputGroup) => {
        try {
            let copyQuestion = { ...tempQState };
            let groups = tempQState.groups ? [...tempQState.groups] : [];

            const targetGroupIndex = groups.findIndex(g => g.id === highlightedGroup.id);

            groups[targetGroupIndex].backgroundColorHex = highlightedGroup.backgroundColorHex;
            groups[targetGroupIndex].textColorHex = highlightedGroup.textColorHex;
            groups[targetGroupIndex].name = highlightedGroup.name;

            copyQuestion.groups = groups;
            setTempQState(copyQuestion);
            saveChanges(copyQuestion);
            setHighlightedGroup(undefined);
        } catch (err) {
            toast("Edit was unsuccessful", false, 3000);
        }
    };

    const deleteGroup = (id: string) => {
        try {
            let copyQuestion = { ...tempQState };
            let groups = tempQState.groups ? [...tempQState.groups] : [];

            const targetGroupIndex = groups.findIndex(g => g.id === id);

            if (targetGroupIndex !== -1) {
                groups.splice(targetGroupIndex, 1);
            }

            copyQuestion.groups = groups;
            setTempQState(copyQuestion);
            saveChanges(copyQuestion);
            setHighlightedGroup(undefined);
        } catch (err) {
            toast("Delete failed!", false, 3000);
        }
    };

    useEffect(() => {
        if (!showDetailedControls) {
            setHighLightedQuestion(undefined);
        }
    }, [showDetailedControls, setHighLightedQuestion]);

    useEffect(() => {
        let sortedQuestion = { ...question };
        if (sortedQuestion.subQuestions && sortedQuestion.subQuestions.length > 0) {
            let subQuestions = [...sortedQuestion.subQuestions];
            subQuestions.sort((f, s) => f.dimensionOrderNumber - s.dimensionOrderNumber);
            sortedQuestion.subQuestions = subQuestions;
        }
        setTempQState(sortedQuestion);
    }, [question]);

    useEffect(() => {
        hardSave?.();
    }, [question, hardSave]);

    return (
        <Container className="table-input-create">
            <Divider />
            <div>
                {/** Add input for rows */}
                <Form.Field>
                    <label>Table rows:</label>
                    <SubQuestionFormInput
                        key={newSubQuestionRow.id}
                        intialValue={newSubQuestionRow}
                        setInitialValue={setNewSubquestionRow}
                        saveButtonText="Add row"
                        saveButtonIcon="plus"
                        groups={question.groups}
                        onSaveAction={() => addSubQuestion(true)}
                    />
                </Form.Field>
                {/** Add input for columns */}
                <Form.Field>
                    <label>Table columns:</label>
                    <SubQuestionFormInput
                        key={newSubQuestionCol.id}
                        intialValue={newSubQuestionCol}
                        setInitialValue={setNewSubquestionCol}
                        saveButtonText="Add column"
                        saveButtonIcon="plus"
                        groups={question.groups}
                        onSaveAction={() => addSubQuestion(false)}
                    />
                </Form.Field>
                <br />
                <br />
                {/** Add input for groups */}
                <Form.Field>
                    <label>Table groups:</label>
                    <TableGroupInputForm
                        key={newTableGroup.id}
                        intialValue={newTableGroup}
                        setInitialValue={setNewTableGroup}
                        saveButtonText="Add group"
                        saveButtonIcon="plus"
                        onSaveAction={addNewGroup}
                    />
                </Form.Field>
            </div>
            {/** Display group legend */}
            {/** TODO: Probably export this to a component */}
            <GroupLegend
                question={question}
                highlightedGroup={highlightedGroup}
                setHighlightedGroup={setHighlightedGroup}
            />
            {/** Add group editing functionality */}
            {highlightedGroup && (
                <Form.Field>
                    <label>Edit group:</label>
                    <TableGroupInputForm
                        key={highlightedGroup.id}
                        intialValue={highlightedGroup}
                        setInitialValue={setHighlightedGroup}
                        saveButtonText="Save changes"
                        saveButtonIcon="save"
                        onSaveAction={() => saveHighlightedGroup(highlightedGroup)}
                        isInEdit
                        onDeleteAction={() => deleteGroup(highlightedGroup.id)}
                    />
                </Form.Field>
            )}

            {/** Add table display of rows/cols */}
            {tempQState.subQuestions && (
                <div className="table-wrapper">
                    <Table celled>
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell>
                                    <div className="main-header-cell">
                                        <Checkbox
                                            toggle
                                            checked={showDetailedControls}
                                            onChange={(e, data) =>
                                                setShowDetailedControls(data.checked ?? false)
                                            }
                                        />
                                        <span>Detailed controls</span>
                                    </div>
                                </Table.HeaderCell>
                                {tempQState.subQuestions
                                    ?.filter(sq => !sq.isPrimaryDimension)
                                    .map((sq, index) => {
                                        return (
                                            <Table.HeaderCell
                                                style={{
                                                    backgroundColor: question.groups?.find(
                                                        g => g.id === sq.groupId
                                                    )?.backgroundColorHex,
                                                }}
                                                className={
                                                    sq.id === highlightedQuestion?.id
                                                        ? "highlighted"
                                                        : ""
                                                }
                                            >
                                                <div
                                                    className="clickable"
                                                    onClick={() =>
                                                        setHighLightedQuestion(
                                                            highlightedQuestion?.id !== sq.id
                                                                ? sq
                                                                : undefined
                                                        )
                                                    }
                                                >
                                                    <div className="table-header-text">
                                                        <span
                                                            style={{
                                                                color: question.groups?.find(
                                                                    g => g.id === sq.groupId
                                                                )?.textColorHex,
                                                            }}
                                                        >
                                                            {sq.isRequired && (
                                                                <span className="alert">* </span>
                                                            )}
                                                            {sq.dimensionText}
                                                        </span>
                                                        {showDetailedControls && (
                                                            <Button
                                                                basic
                                                                type="button"
                                                                color="red"
                                                                icon="times"
                                                                size="mini"
                                                                onClick={e => {
                                                                    e.stopPropagation();
                                                                    removeSubQuestion(sq.id);
                                                                }}
                                                            />
                                                        )}
                                                    </div>
                                                    {showDetailedControls && (
                                                        <div className="arrow-buttons">
                                                            {index === 0 ? (
                                                                <div></div>
                                                            ) : (
                                                                <Button
                                                                    icon="angle left"
                                                                    type="button"
                                                                    size="mini"
                                                                    onClick={e => {
                                                                        e.stopPropagation();
                                                                        reorderSubQuestions(
                                                                            sq.id,
                                                                            false,
                                                                            false
                                                                        );
                                                                    }}
                                                                />
                                                            )}
                                                            {index + 1 ===
                                                            tempQState.subQuestions?.filter(
                                                                sq => !sq.isPrimaryDimension
                                                            ).length ? (
                                                                <div></div>
                                                            ) : (
                                                                <Button
                                                                    icon="angle right"
                                                                    type="button"
                                                                    size="mini"
                                                                    onClick={e => {
                                                                        e.stopPropagation();
                                                                        reorderSubQuestions(
                                                                            sq.id,
                                                                            false,
                                                                            true
                                                                        );
                                                                    }}
                                                                />
                                                            )}
                                                        </div>
                                                    )}
                                                </div>
                                            </Table.HeaderCell>
                                        );
                                    })}
                            </Table.Row>
                        </Table.Header>
                        <Table.Body>
                            {tempQState.subQuestions
                                ?.filter(sq => sq.isPrimaryDimension)
                                .map((sq, index) => {
                                    return (
                                        <Table.Row
                                            className={
                                                sq.id === highlightedQuestion?.id
                                                    ? "highlighted"
                                                    : ""
                                            }
                                        >
                                            <Table.Cell
                                                style={{
                                                    backgroundColor: question.groups?.find(
                                                        g => g.id === sq.groupId
                                                    )?.backgroundColorHex,
                                                }}
                                                className="row-display-cell"
                                            >
                                                <div
                                                    className="row-cell clickable table-row-initial"
                                                    onClick={e => {
                                                        e.stopPropagation();
                                                        setHighLightedQuestion(
                                                            highlightedQuestion?.id !== sq.id
                                                                ? sq
                                                                : undefined
                                                        );
                                                    }}
                                                >
                                                    <div>
                                                        {showDetailedControls && (
                                                            <Button
                                                                basic
                                                                color="red"
                                                                type="button"
                                                                icon="times"
                                                                size="mini"
                                                                onClick={e => {
                                                                    e.stopPropagation();
                                                                    removeSubQuestion(sq.id);
                                                                }}
                                                            />
                                                        )}
                                                        <span
                                                            style={{
                                                                color: question.groups?.find(
                                                                    g => g.id === sq.groupId
                                                                )?.textColorHex,
                                                            }}
                                                        >
                                                            {sq.isRequired && (
                                                                <span className="alert">* </span>
                                                            )}
                                                            {sq.dimensionText}
                                                        </span>
                                                    </div>
                                                    {showDetailedControls && (
                                                        <div className="arrow-buttons-col">
                                                            {index === 0 ? (
                                                                <div></div>
                                                            ) : (
                                                                <Button
                                                                    icon="angle up"
                                                                    type="button"
                                                                    size="mini"
                                                                    onClick={e => {
                                                                        e.stopPropagation();
                                                                        reorderSubQuestions(
                                                                            sq.id,
                                                                            true,
                                                                            false
                                                                        );
                                                                    }}
                                                                />
                                                            )}
                                                            {index + 1 ===
                                                            tempQState.subQuestions?.filter(
                                                                sq => sq.isPrimaryDimension
                                                            ).length ? (
                                                                <div></div>
                                                            ) : (
                                                                <Button
                                                                    icon="angle down"
                                                                    type="button"
                                                                    size="mini"
                                                                    onClick={e => {
                                                                        e.stopPropagation();
                                                                        reorderSubQuestions(
                                                                            sq.id,
                                                                            true,
                                                                            true
                                                                        );
                                                                    }}
                                                                />
                                                            )}
                                                        </div>
                                                    )}
                                                </div>
                                            </Table.Cell>
                                            {tempQState.subQuestions
                                                ?.filter(sq => !sq.isPrimaryDimension)
                                                .map(sq => {
                                                    return (
                                                        <Table.Cell
                                                            className={
                                                                sq.id === highlightedQuestion?.id
                                                                    ? "highlighted"
                                                                    : ""
                                                            }
                                                        ></Table.Cell>
                                                    );
                                                })}
                                        </Table.Row>
                                    );
                                })}
                        </Table.Body>
                    </Table>
                </div>
            )}
            {highlightedQuestion && (
                <Form.Field>
                    <label>
                        Edit selected {highlightedQuestion.isPrimaryDimension ? "row" : "column"}
                    </label>
                    <SubQuestionFormInput
                        intialValue={highlightedQuestion}
                        setInitialValue={setHighLightedQuestion}
                        saveButtonText="Save changes"
                        saveButtonIcon="save"
                        groups={question.groups}
                        onSaveAction={() => saveHighlightedQuestion(highlightedQuestion)}
                    />
                </Form.Field>
            )}
            {/** Consider adding transpose functionality*/}
        </Container>
    );
};
