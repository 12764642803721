import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Button } from "semantic-ui-react";
import { UserData } from "../../../actions/authentificationActions";
import {
    CountrySelector,
    ICountrySubOptions,
} from "../../../components/CountrySelector/CountrySelector";
import { ICountry } from "../../../models/country";
import { getCountries } from "../../../services/countriesService";
import { getChartData, getChartYears } from "../../../services/figuresService";
import { getBlocks } from "../../../services/formsService";
import { AppState } from "../../../store/configureStore";
import { GenerationCapacityChange } from "../Charts/GenerationCapacityChange/GenerationCapacityChange";
import { GenerationCapacityPercentage } from "../Charts/GenerationCapacityPercentage/GenerationCapacityPercentage";
import { GenerationCapacityPie } from "../Charts/GenerationCapacityPie/GenerationCapacityPie";
import "../Hierarchy/Hierarchy.css";

const subtypes = [
    "Compliant - Without any derogations",
    "Compliant - With derogations",
    "Compliant - Modernized (partially or fully compliant)",
    "Total Compliant",
    "Total Non-Compliant",
    "Total",
    "Percentage relationship",
    "Change",
];

interface IProps {
    type: string;
    isPgm: boolean;
    years?: number[];
    setYears: Dispatch<SetStateAction<number[]>>;
    allowMultipleYears: Dispatch<SetStateAction<boolean>>;
    technology?: string;
}

export interface GenerationCapacityData {
    [type: string]: {
        [country: string]: number[];
    };
}

export const GraphDisplay = ({
    type,
    isPgm,
    years,
    setYears,
    allowMultipleYears,
    technology,
}: IProps) => {
    const user: UserData = useSelector((state: AppState) => state.user);
    const [subtype, setSubtype] = useState("Compliant - Without any derogations");
    const [data, setData] = useState<GenerationCapacityData>();
    const [allCountries, setAllCountries] = useState<ICountry[]>([]);
    const [selectedCountries, setSelectedCountries] = useState<ICountry[]>([]);
    const [availableYears, setAvailableYears] = useState<number[]>([]);

    const [loadingCountries, setLoadingCountries] = useState(true);
    const [suboptions, setSubOptions] = useState<ICountrySubOptions>({});

    useEffect(() => {
        if (years && years[0]) {
            const chartType = isPgm ? "pgm_percentage" : "hvdc_percentage";
            getChartData<GenerationCapacityData>(chartType, years[0], technology).then(setData);
        }
    }, [isPgm, years, technology]);

    useEffect(() => {
        const chartType = isPgm ? "pgm_percentage" : "hvdc_percentage";
        getChartYears(chartType).then(setAvailableYears);
    }, [isPgm]);

    useEffect(() => {
        setYears(availableYears);
    }, [availableYears, setYears]);

    useEffect(() => {
        setLoadingCountries(true);
        getBlocks().then(b =>
            getCountries(b.find(x => x.name === "Generation Capacity")?.id).then(res => {
                setAllCountries(res);
                setSelectedCountries(res.filter(c => c.abbreviation === user.countryAbbreviation));
                setLoadingCountries(false);
            })
        );
    }, []);

    useEffect(() => {
        allowMultipleYears(subtype === "Change");
    }, [subtype, allowMultipleYears]);

    return (
        <div className="graph-container">
            <div className="vertical-buttons">
                {subtypes.map(s => (
                    <Button
                        key={s}
                        className="vertical-button"
                        primary={s === subtype}
                        content={s}
                        onClick={() => setSubtype(s)}
                    />
                ))}
            </div>
            <div className="graph-display-container">
                <div className="graph-display">
                    {data && subtype === "Percentage relationship" && (
                        <GenerationCapacityPercentage
                            type={type}
                            data={data}
                            selectedCountries={selectedCountries}
                        />
                    )}
                    {data && subtype === "Change" && (
                        <GenerationCapacityChange
                            type={type}
                            technology={technology}
                            isPgm={isPgm}
                            years={years}
                            selectedCountries={selectedCountries}
                            totalYears={availableYears.length}
                        />
                    )}
                    {data && subtype !== "Change" && subtype !== "Percentage relationship" && (
                        <GenerationCapacityPie
                            type={type}
                            subtype={subtype}
                            data={data}
                            selectedCountries={selectedCountries}
                            allCountries={allCountries}
                        />
                    )}
                </div>
                <CountrySelector
                    selectedCountries={selectedCountries}
                    setSelectedCountries={setSelectedCountries}
                    initializeUsersCountry={true}
                    countries={allCountries}
                    loadingCountries={loadingCountries}
                    subOptions={suboptions}
                    setSubOptions={setSubOptions}
                />
            </div>
        </div>
    );
};
