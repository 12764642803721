import { Reducer } from "redux";
import { DropdownItemProps } from "semantic-ui-react";
import {
    SET_USER,
    CLEAR_USER,
    AuthentificationActionType,
    UserData,
    UserRole,
    CLEAR_PASSWORD_RECOVERY_TOKEN,
} from "../actions/authentificationActions";

var savedState: UserData | null =
    sessionStorage.getItem("user") !== null
        ? JSON.parse(sessionStorage.getItem("user") || "")
        : null;

const initialState: UserData = {
    guid: undefined,
    username: undefined,
    firstName: undefined,
    lastName: undefined,
    displayName: undefined,
    token: undefined,
    role: undefined,
    lastLogin: undefined,
    isEnabled: false,
};

export const userRoleOptions: DropdownItemProps[] = [
    { key: UserRole.Admin, text: "Admin", value: UserRole.Admin },
    { key: UserRole.NCLP, text: "NCLP", value: UserRole.NCLP },
    { key: UserRole.Viewer, text: "Viewer", value: UserRole.Viewer },
];

export const userReducer: Reducer<UserData> = (
    state: UserData = savedState || initialState,
    action: AuthentificationActionType
): UserData => {
    switch (action.type) {
        case SET_USER:
            return { ...action.user };
        case CLEAR_USER:
            return { ...initialState };
        case CLEAR_PASSWORD_RECOVERY_TOKEN:
            return { ...state, passwordRecoveryToken: undefined };
        default:
            return state;
    }
};

export default userReducer;
