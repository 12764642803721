import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Button, Checkbox, Modal } from "semantic-ui-react";
import { generateReportPdf, getReport, publishReport } from "../../services/reportsService";
import { toast } from "../..";
import { ImpLoading } from "../../components/ImpLoading/ImpLoading";
import "./ReportPublishControls.css";

interface IProps {
    reportId?: string;
    saveChanges?: Function;
}

export const ReportPublishControls = ({ reportId, saveChanges }: IProps) => {
    const history = useHistory();
    const [publishModalOpen, setPublishModalOpen] = useState<boolean>();
    const [sendNotification, setSendNotification] = useState<boolean>(true);
    const [disablePublish, setDisablePublish] = useState(true);
    const publishTooltip = "Select date";

    const [loading, setLoading] = useState(false);
    const [loadingMessage, setLoadingMessage] = useState("");

    const onPreview = () => {
        if (reportId) {
            setLoadingMessage("Generating PDF preview...");
            setLoading(true);
            generateReportPdf(reportId).finally(() => {
                setLoading(false);
            });
        }
    };

    const onPublish = async () => {
        if (reportId) {
            try {
                setLoadingMessage("Publishing report...");
                setLoading(true);
                await publishReport(reportId, sendNotification);
                toast("Report published successfully", true, 1000);
                history.push("/reports/overview");
            } catch (err) {
                toast("An error occured while trying to publish the report.", false, 1000);
            } finally {
                setLoading(false);
            }
        }
    };

    useEffect(() => {
        // disable/enable publish button
        if (reportId) {
            getReport(reportId).then(res => {
                if (res.date?.startsWith("0001") || res.date?.startsWith("190"))
                    setDisablePublish(true);
                else setDisablePublish(false);
            });
        } else setDisablePublish(true);
    }, [reportId, saveChanges]);

    return (
        <div className="publish-container">
            <Button primary content="Preview" onClick={onPreview} />
            <ImpLoading show={loading} message={loadingMessage} />
            <div className="parent-tooltip">
                <div
                    hidden={!disablePublish}
                    className="child-tooltip"
                    data-tooltip={publishTooltip}
                    data-position="top center"
                />
                <Button
                    primary
                    disabled={disablePublish}
                    content="Publish"
                    onClick={() => setPublishModalOpen(true)}
                />
            </div>

            <Modal open={publishModalOpen} onClose={() => setPublishModalOpen(false)}>
                <Modal.Header>Publish Report</Modal.Header>
                <Modal.Content>
                    <p>
                        Are you sure you want to publish this report? You will no longer be able to
                        change its content.
                    </p>
                    <Checkbox
                        toggle
                        checked={sendNotification}
                        label={"Send notification"}
                        onChange={() => setSendNotification(!sendNotification)}
                    />
                </Modal.Content>
                <Modal.Actions>
                    <Button content="Cancel" onClick={() => setPublishModalOpen(false)} />
                    <Button primary content="Publish" onClick={onPublish} />
                </Modal.Actions>
            </Modal>
        </div>
    );
};
