import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Button, Loader, Select } from "semantic-ui-react";
import { ImpLoading } from "../../components/ImpLoading/ImpLoading";
import MapChart from "./MapChart";
import { IFormResultDataDelivery } from "../../models/formResultDataDelivery";
import {
    downloadByPercentages,
    downloadByCategories,
    getDataDeliveryResults,
} from "../../services/resultsService";
import gradient from "../../images/gradient.jpg";
import { toast } from "../..";
import { Breadcrumbs } from "../../components/Breadcrumbs/Breadcrumbs";

import "react-multi-carousel/lib/styles.css";
import "./DataDelivery.css";

export const DataDelivery = () => {
    const params: { formId: string } = useParams();
    const [isPercentage, setIsPercentage] = useState<boolean>(false);
    const [activeSection, setActiveSection] = useState<string>();

    const [loading, setLoading] = useState(false);
    const [loadingMessage, setLoadingMessage] = useState("");

    const [resultsData, setResultsData] = useState<IFormResultDataDelivery>();

    useEffect(() => {
        getDataDeliveryResults(params.formId).then(res => {
            setResultsData(res);
        });
    }, [params.formId]);

    let onExcelDownload = async () => {
        if (resultsData) {
            setLoadingMessage("Generating Excel file...");
            setLoading(true);

            let func = isPercentage ? downloadByPercentages : downloadByCategories;

            try {
                await func(resultsData.formId);
            } catch (err) {
                toast("A problem has occured while trying to generate Excel file", false, 1500);
            } finally {
                setLoading(false);
            }
        }
    };

    return (
        <div className="code-compliance-overview-container">
            <ImpLoading show={loading} message={loadingMessage} />
            <div className="container-header">
                <h1>
                    Data Delivery - {resultsData?.categoryName} - {resultsData?.formName}
                </h1>
                <Select
                    options={[
                        { text: "Categories", key: "Categories", value: false },
                        { text: "Percentage", key: "Percentage", value: true },
                    ]}
                    defaultValue={false}
                    onChange={(e, data) => setIsPercentage(data.value as boolean)}
                />
            </div>
            <Breadcrumbs />
            <div className="code-compliance-overview-content">
                {resultsData ? (
                    <div className="code-compliance-content-container">
                        {resultsData?.sectionResults && resultsData?.sectionResults.length > 1 && (
                            <div className="sections-selection-container">
                                {resultsData.sectionResults.map(sectionResult => {
                                    return (
                                        <Button
                                            key={sectionResult.sectionId}
                                            primary={sectionResult.sectionId === activeSection}
                                            content={sectionResult.sectionName}
                                            onClick={() => {
                                                setActiveSection(
                                                    sectionResult.sectionId === activeSection
                                                        ? undefined
                                                        : sectionResult.sectionId
                                                );
                                            }}
                                        />
                                    );
                                })}
                            </div>
                        )}
                        <div className="code-compliance-overview-content-map">
                            <MapChart
                                dataDeliveryResults={resultsData}
                                activeSection={activeSection}
                                isPercentage={isPercentage}
                            />
                            <div className="legend-container">
                                <section className="map-legend">
                                    {!isPercentage && (
                                        <>
                                            <p>
                                                <span className="new"></span>No data delivered
                                            </p>
                                            <p>
                                                <span className="partial"></span>Partially filled
                                            </p>
                                        </>
                                    )}
                                    <p>
                                        <span className="submitted"></span>Submitted
                                    </p>
                                    {isPercentage && (
                                        <div className="gradient">
                                            <span>100%</span>
                                            <span>0%</span>
                                            <img src={gradient} alt="color gradient" />
                                        </div>
                                    )}
                                </section>
                                <section className="export-button">
                                    <Button
                                        primary
                                        icon="download"
                                        content="Export"
                                        onClick={onExcelDownload}
                                    />
                                </section>
                            </div>
                        </div>
                    </div>
                ) : (
                    <Loader active />
                )}
            </div>
        </div>
    );
};
