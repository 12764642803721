import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { Button, Input } from "semantic-ui-react";
import { RedirectIfLoggedIn } from "../Login/RedirectIfLoggedIn";
import "../Login/Login.css";
import { checkPasswordToken, resetPassword } from "../../services/authenticationService";

export const ResetPassword = () => {
    const [password, setPassword] = useState("");
    const [repeatedPassword, setRepeatedPassword] = useState("");
    const [passwordDontMatch, setPasswordDontMatch] = useState(false);
    const [validToken, setValidToken] = useState(false);
    const [token, setToken] = useState("");
    const location: any = useLocation();
    const [success, setSuccess] = useState(false);
    const [sendingRequest, setSendingRequest] = useState(false);
    const disabled = password.length === 0 || repeatedPassword.length === 0 || sendingRequest;

    const handleResetPassword = async () => {
        setSendingRequest(true);
        const userDto = { token: token, password: password };
        const res = await resetPassword(userDto);
        if (res.ok) {
            setSuccess(true);
        }
        setSendingRequest(false);
    };

    useEffect(() => {
        const tokenString = location.search;
        const newToken = tokenString.substring(tokenString.indexOf("=") + 1);
        setToken(newToken);
        checkPasswordToken(newToken).then((res) => {
            if (res.ok) {
                setValidToken(true);
            }
        });
    }, [location.search]);

    useEffect(() => {
        if (password !== "" && repeatedPassword !== "" && password !== repeatedPassword)
            setPasswordDontMatch(true);
        else setPasswordDontMatch(false);
    }, [password, repeatedPassword]);

    return (
        <div className="login-container">
            <RedirectIfLoggedIn />
            <div className="center-login-container">
                <section className="login-form-container">
                    {success ? (
                        <React.Fragment>
                            <p>
                                Password successfully reset. Try logging in with your new password.
                            </p>
                            <Button
                                fluid
                                primary
                                as={Link}
                                to="/Login"
                                content="Back to log in page"
                            />
                        </React.Fragment>
                    ) : validToken ? (
                        <React.Fragment>
                            <h2 id="centered-text">Reset password</h2>
                            <Input
                                id="password"
                                type="password"
                                placeholder="Password"
                                value={password}
                                onChange={(e: any) => setPassword(e.target.value)}
                            />
                            <Input
                                id="password"
                                type="password"
                                placeholder="Repeated password"
                                value={repeatedPassword}
                                onChange={(e: any) => setRepeatedPassword(e.target.value)}
                            />
                            {passwordDontMatch && (
                                <p id="login-error-message">Passwords don't match</p>
                            )}
                            <hr />
                            <Button
                                fluid
                                primary
                                loading={sendingRequest}
                                disabled={disabled}
                                content="Reset password"
                                onClick={handleResetPassword}
                            />
                        </React.Fragment>
                    ) : (
                        <p id="login-error-message">
                            Password reset token has expired or is invalid. Please try resetting
                            your password again.
                        </p>
                    )}
                </section>
            </div>
        </div>
    );
};
