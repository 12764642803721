import { useCallback, useEffect, useState } from "react";
import { Button, Dropdown, DropdownItemProps, Loader } from "semantic-ui-react";
import { toast } from "../..";
import { IForm } from "../../models/form";
import { IResult } from "../../models/result";
import { getCountries } from "../../services/countriesService";
import { getNonExhaustiveForm, getNonExhaustiveForms } from "../../services/formsService";
import { getNonExhaustiveResults } from "../../services/resultsService";
import { ResultsTable } from "./ResultsTable";
import { ImpLoading } from "../../components/ImpLoading/ImpLoading";
import "./NonExhaustiveTabular.css";

// const defaultFormNames = ["dcc", "hvdc", "rfg"];

export const NonExhaustiveTabular = () => {
    const [formLoading, setFormLoading] = useState<boolean>(false);
    const [searchableForms, setSearchableForms] = useState<DropdownItemProps[]>([]);
    const [searchableQuestions, setSearchableQuestions] = useState<DropdownItemProps[]>([]);
    const [searchableCountries, setSearchableCountries] = useState<DropdownItemProps[]>([]);

    const [allResults, setAllResults] = useState<IResult[]>([]);
    const [form, setForm] = useState<IForm>();

    const [selectedCountries, setSelectedCountries] = useState<string[]>([]);
    const [selectedYear, setSelectedYear] = useState<number>(2020);
    const [selectedForm, setSelectedForm] = useState<string>("");
    const [selectedQuestions, setSelectedQuestions] = useState<string[]>([]);

    // Client wants to search questions and not lose the query when they select a question, which means query must be controlled
    const [searchQuery, setSearchQuery] = useState<string>("");

    const [downloadLoading, setDownloadLoading] = useState<boolean>(false);

    const fetchCountries = useCallback(async () => {
        try {
            let countries = await getCountries();
            let dropdownCountries = countries.map(c => {
                return { key: c.id, value: c.id, text: c.name };
            });
            setSearchableCountries(dropdownCountries);
        } catch (err) {
            toast("Unable to fetch available countries", false, 3000);
        }
    }, []);

    const fetchForm = useCallback(async () => {
        if (!selectedForm) {
            setSearchableQuestions([]);
        } else {
            try {
                setFormLoading(true);
                const res = (await getNonExhaustiveForm(selectedForm)) as IForm;
                setForm(res);
                setSearchableQuestions(
                    res.sections
                        .flatMap(s => s.questions)
                        .map(q => {
                            return { key: q.id, value: q.id, text: q.text };
                        })
                );
            } catch (err) {
                toast("Unable to load questions for selected form", false, 3000);
            } finally {
                setFormLoading(false);
            }
        }
    }, [selectedForm, setSearchableQuestions]);

    const fetchForms = useCallback(async () => {
        try {
            const forms = (await getNonExhaustiveForms(selectedYear, true)) as DropdownItemProps[];
            setSearchableForms(forms);
            setSelectedForm("");
        } catch (err) {
            toast("Unable to fetch forms", false, 3000);
        }
    }, [selectedYear]);

    const fetchResults = useCallback(async () => {
        if (!selectedForm || selectedCountries.length === 0) {
            setAllResults([]);
        } else {
            try {
                const res = await getNonExhaustiveResults(selectedForm, selectedCountries);
                res.sort((a, b) => a.countryName?.localeCompare(b.countryName || "") ?? 0);

                setAllResults(res);
            } catch (err) {
                toast("Unable to fetch results for selected form", false, 3000);
            }
        }
    }, [selectedForm, selectedCountries]);

    useEffect(() => {
        fetchCountries();
    }, [fetchCountries]);

    useEffect(() => {
        fetchForms();
    }, [fetchForms]);

    useEffect(() => {
        fetchForm();
    }, [fetchForm]);

    useEffect(() => {
        fetchResults();
    }, [fetchResults]);

    const handleRootExcelDownload = useCallback(() => {
        setDownloadLoading(true);
        try {
            const link = document.createElement("a");
            link.href = `/210730_-_Non_exhaustive_Requirements_-_RfG_DCC_HVDC_Evaluation.xlsm`;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        } finally {
            setDownloadLoading(false);
        }
    }, [setDownloadLoading]);

    return (
        <div className="non-exhaustive-tabular">
            <ImpLoading show={downloadLoading} message="Downloading Excel file..." />
            <div className="container-header">
                <h1>Non-Exhaustive Parameters</h1>
                <div>
                    <Button
                        primary
                        loading={downloadLoading}
                        className="download-button"
                        content="Download original data"
                        icon="download"
                        onClick={handleRootExcelDownload}
                    />
                </div>
            </div>
            <div className="tabular-view-container">
                <div className="tabular-view-controls-container">
                    <Dropdown
                        placeholder="Select countries"
                        fluid
                        multiple
                        search
                        selection
                        clearable
                        options={searchableCountries}
                        selectOnBlur={false}
                        selectOnNavigation={false}
                        value={selectedCountries}
                        onChange={(e, data) => setSelectedCountries(data.value as string[])}
                    />
                    <Dropdown
                        placeholder="Select form"
                        fluid
                        search
                        selection
                        clearable
                        options={searchableForms}
                        selectOnBlur={false}
                        selectOnNavigation={false}
                        value={selectedForm}
                        onChange={(e, data) => setSelectedForm(data.value as string)}
                    />
                    <Dropdown
                        placeholder="Select questions"
                        fluid
                        multiple
                        selection
                        clearable
                        options={searchableQuestions}
                        selectOnBlur={false}
                        selectOnNavigation={false}
                        value={selectedQuestions}
                        onChange={(e, data) => setSelectedQuestions(data.value as string[])}
                        // Custom search props
                        search
                        searchQuery={searchQuery}
                        onSearchChange={(e, data) => setSearchQuery(data.searchQuery)}
                    />
                </div>
                <div className="tabular-view-table-container">
                    {formLoading && <Loader active>Loading form questions...</Loader>}
                    {!formLoading && form && allResults.length > 0 && (
                        <ResultsTable
                            form={form}
                            results={allResults}
                            updateResults={setAllResults}
                            selectedQuestions={selectedQuestions}
                        />
                    )}
                </div>
                <br />
                <div>
                    <p>
                        <b>Legend for common answers:</b>
                    </p>
                    <div>
                        <div>
                            <span>
                                <b>G</b> - Requirement generally implemented in national
                                implementation in accordance to NC
                            </span>
                        </div>
                        <div>
                            <span>
                                <b>S</b> - Requirement to be site-specifically implemented in due
                                time before plant design
                            </span>
                        </div>
                        <div>
                            <span>
                                <b>NI-A</b> - No consideration
                            </span>
                        </div>
                        <div>
                            <span>
                                <b>NI-B</b> - TSO internal consideration
                            </span>
                        </div>
                        <div>
                            <span>
                                <b>NI-C</b> - preliminary, shared with stakeholders, but not
                                publicly available
                            </span>
                        </div>
                        <div>
                            <span>
                                <b>NI-D</b> - preliminary, shared with stakeholders and public
                                available
                            </span>
                        </div>
                        <div>
                            <span>
                                <b>NI-E</b> - submitted for approval
                            </span>
                        </div>
                        <div>
                            <span>
                                <b>NI-F</b> - not applicable
                            </span>
                        </div>
                        <div>
                            <span>
                                <b>0</b> - needs no implementation
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
