export interface ITableInputGroup {
    id: string;
    name: string;
    backgroundColorHex: string;
    textColorHex: string;
    questionId: string;
}

export const groupInit: ITableInputGroup = {
    id: "",
    name: "",
    backgroundColorHex: "#1c3968",
    textColorHex: "#ffffff",
    questionId: "",
};
