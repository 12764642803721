import { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Redirect } from "react-router-dom";
import { UserData, UserRole } from "../../actions/authentificationActions";
import { AppState } from "../../store/configureStore";
import { getFilteredUsers } from "../../services/usersService";
import { ManageUsersTable } from "./ManageUsersTable";
import { AddUserForm } from "./AddUserForm";
import { Breadcrumbs } from "../../components/Breadcrumbs/Breadcrumbs";
import { toast } from "../..";
import "./UsersDashboard.css";

export interface IUserFilter {
    username: string;
    usernameSort: "ASC" | "DESC";
    role?: string;
    country?: string;
    page: number;
    enabled?: boolean;
}

export const initUserFilter: IUserFilter = {
    username: "",
    usernameSort: "ASC",
    role: "",
    country: "",
    page: 1,
    enabled: undefined,
};

export const UsersDashboard = () => {
    const user = useSelector((state: AppState) => state.user);
    const [users, setUsers] = useState<UserData[]>([]);
    const [loadingUsers, setLoadingUsers] = useState(true);
    const [userFilter, setUserFilter] = useState<IUserFilter>(initUserFilter);

    const fetchFilteredUsers = useCallback(async () => {
        try {
            setLoadingUsers(true);
            const res = await getFilteredUsers(userFilter);
            Array.isArray(res) && setUsers(res);
            setLoadingUsers(false);
        } catch (err) {
            toast("Unable to fetch users", false, 3000);
        }
    }, [userFilter]);

    useEffect(() => {
        fetchFilteredUsers();
    }, [fetchFilteredUsers]);

    return user.role !== UserRole.Admin ? (
        <Redirect to="/unauthorized" />
    ) : (
        <>
            <div className="container-header">
                <h1>Manage users</h1>
            </div>
            <Breadcrumbs />
            <div className="users-dashboard-container">
                <ManageUsersTable
                    users={users}
                    setUsers={setUsers}
                    loadingUsers={loadingUsers}
                    userFilter={userFilter}
                    setUserFilter={setUserFilter}
                />
                <AddUserForm users={users} setUsers={setUsers} />
            </div>
        </>
    );
};
