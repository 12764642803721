import { useCallback, useState } from "react";
import { useSelector } from "react-redux";
import { Button, Card, Icon, Modal, Popup } from "semantic-ui-react";
import { toast } from "../..";
import { UserRole } from "../../actions/authentificationActions";
import { ImpLoading } from "../../components/ImpLoading/ImpLoading";
import { IReport } from "../../models/report";
import { downloadPublishedReport, unpublishReport } from "../../services/reportsService";
import { AppState } from "../../store/configureStore";
import "./ReportCard.css";

interface IProps {
    report: IReport;
    refreshReports?: () => void;
}

export const PublishedReportCard = ({ report, refreshReports }: IProps) => {
    const user = useSelector((state: AppState) => state.user);
    const [loading, setLoading] = useState(false);
    const [loadingMessage, setLoadingMessage] = useState("");
    const [unpublishModalOpen, setUnpublishModalOpen] = useState(false);

    const onReportDownload = async (report: IReport) => {
        if (report.id) {
            setLoadingMessage("Downloading report...");
            setLoading(true);
            await downloadPublishedReport(report);
            setLoading(false);
        }
    };

    const handleReportUnpublish = useCallback(async () => {
        if (!report.id) return;

        try {
            await unpublishReport(report.id);
            toast("Report successfully unpublished", true, 3000);
            refreshReports?.();
            setUnpublishModalOpen(false);
        } catch (err) {
            toast("Unable to unpublish report", false, 3000);
        }
    }, [report, refreshReports]);

    return (
        <>
            <ImpLoading show={loading} message={loadingMessage} />
            <Card
                key={report.id}
                onClick={() => onReportDownload(report)}
                className={`report-card${user.role ? " admin " : " "}${
                    report.isPublic ? "public" : "internal"
                }`}
            >
                <Card.Content>
                    <Card.Header>
                        <h3>{report.title}</h3>
                    </Card.Header>
                    <Card.Meta></Card.Meta>
                    <Card.Description>
                        <p>
                            <strong>Version: </strong>
                            {report.version}
                        </p>
                        <p>
                            <strong>Date: </strong>
                            {!(report.date.startsWith("190") || report.date.startsWith("0001")) &&
                                new Date(report.date).toLocaleString("hr-HR", {
                                    day: "2-digit",
                                    month: "2-digit",
                                    year: "numeric",
                                })}
                        </p>
                    </Card.Description>
                </Card.Content>
            </Card>
            {user.role === UserRole.Admin && (
                <>
                    <div className="action-bar-container-options">
                        <Popup
                            content="Unpublish report"
                            trigger={
                                <Icon
                                    size="large"
                                    name="eye slash"
                                    onClick={() => setUnpublishModalOpen(true)}
                                    bordered
                                />
                            }
                        />
                    </div>
                    <Modal open={unpublishModalOpen} onClose={() => setUnpublishModalOpen(false)}>
                        <Modal.Header>Are you sure you want to unpublish the report?</Modal.Header>
                        <Modal.Content>
                            <p>
                                Unpublishing the report will remove it from the public view if the
                                report was publicly available and allow you to additionally edit
                                it's content.
                            </p>
                            <p>You will need to publish it again once the editing is finished.</p>
                        </Modal.Content>
                        <Modal.Actions>
                            <Button content="Cancel" onClick={() => setUnpublishModalOpen(false)} />
                            <Button content="Unpublish" primary onClick={handleReportUnpublish} />
                        </Modal.Actions>
                    </Modal>
                </>
            )}
        </>
    );
};
