import { useEffect, useState } from "react";
import { useParams } from "react-router";
import { DropdownProps, Select } from "semantic-ui-react";
import { Breadcrumbs } from "../../components/Breadcrumbs/Breadcrumbs";
import { FrtChart } from "../FrtChart/FrtChart";
import { FirstLevelTabs } from "../NclpReport/Hierarchy/FirstLevelTabs";
import "./DataView.css";

export const DataView = () => {
    const [years, setYears] = useState<number[]>([]);
    const [selectedYears, setSelectedYears] = useState<number[]>([]);
    const [allowMultipleYears, setAllowMultipleYears] = useState(false);
    const params: { chartType: string } = useParams();

    useEffect(() => {
        if (
            years &&
            years.length > 0 &&
            !allowMultipleYears &&
            (selectedYears.length === 0 || !years.includes(selectedYears[0]))
        ) {
            setSelectedYears([Math.max(...years)]);
        }
    }, [selectedYears, years, allowMultipleYears, setSelectedYears]);

    useEffect(() => {
        if (!allowMultipleYears) {
            setSelectedYears(s => (s && s[0] ? [s[0]] : []));
        }
    }, [allowMultipleYears, setSelectedYears]);

    useEffect(() => {
        setAllowMultipleYears(false);
        setSelectedYears([]);
    }, [params.chartType, setSelectedYears, setAllowMultipleYears]);

    const handleYearsSelect = (_: React.SyntheticEvent, data: DropdownProps) => {
        if (allowMultipleYears) {
            setSelectedYears(data.value! as number[]);
        } else {
            setSelectedYears([data.value! as number]);
        }
    };

    return (
        <div className="data-view-container">
            <div className="container-header">
                <h1>
                    {params.chartType && params.chartType.toLowerCase() === "frt"
                        ? "FRT non-exhaustive parameters"
                        : "Generation and HVDC capacities"}
                </h1>
                {params.chartType.toLowerCase() !== "frt" && years && years.length > 0 && (
                    <div className="year-filter">
                        <Select
                            fluid
                            multiple={allowMultipleYears}
                            value={allowMultipleYears ? selectedYears : selectedYears[0]}
                            options={years.map(y => {
                                return { text: y, value: y, key: y };
                            })}
                            onChange={handleYearsSelect}
                        />
                    </div>
                )}
            </div>
            <Breadcrumbs />

            <div id="graphs-container" className={`${years.length === 0 ? "hide" : "show"}`}>
                {params.chartType && params.chartType.toLowerCase() === "frt" ? (
                    <FrtChart year={2020} setYears={setYears} />
                ) : (
                    <FirstLevelTabs
                        years={selectedYears}
                        setYears={setYears}
                        allowMultipleYears={setAllowMultipleYears}
                    />
                )}
            </div>
            <div className={`${years.length !== 0 ? "hide" : "show"}`}>
                <br />
                <h2>No data available...</h2>
            </div>
        </div>
    );
};
