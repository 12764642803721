import { useEffect, useState } from "react";
import {
    Bar,
    BarChart,
    CartesianGrid,
    Legend,
    ResponsiveContainer,
    Tooltip,
    TooltipFormatter,
    XAxis,
    YAxis,
} from "recharts";
import { CustomizedAxisTick } from "../../../../components/CustomizedAxisTick/CustomizedAxisTick";
import { ICountry } from "../../../../models/country";
import { GenerationCapacityData } from "../../Hierarchy/GraphDisplay";

interface IProps {
    type: string;
    data: GenerationCapacityData;
    selectedCountries: ICountry[];
}

function formatData(chartData: { [country: string]: number[] }, selectedCountries: ICountry[]) {
    return selectedCountries.map(country => {
        const values = chartData[country.abbreviation];
        if (values) {
            const total = values.reduce((a, b) => a + b, 0);
            const percentages = values.map(v => (total === 0 ? 0 : (v * 100) / total));
            return {
                name: country.name,
                withoutDerogations: percentages[0],
                withDerogations: percentages[1],
                partiallyCompliant: percentages[2],
                nonCompliant: percentages[3],
            };
        } else {
            return {
                name: country.name,
                withoutDerogations: 0,
                withDerogations: 0,
                partiallyCompliant: 0,
                nonCompliant: 0,
            };
        }
    });
}

const formatTooltip: TooltipFormatter = value =>
    `${(Math.round((value as number) * 100) / 100).toFixed(2)}%`;

export const GenerationCapacityPercentage = ({ type, data, selectedCountries }: IProps) => {
    const [chartData, setChartData] = useState<{ [country: string]: number[] }>();

    useEffect(() => {
        if (data) {
            setChartData(data[type]);
        }
    }, [type, data]);

    return (
        <>
            {chartData && (
                <ResponsiveContainer>
                    <BarChart data={formatData(chartData, selectedCountries)}>
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis
                            dataKey="name"
                            height={100}
                            interval={0}
                            tick={selectedCountries.length > 0 && <CustomizedAxisTick />}
                        />
                        <YAxis domain={[0, 100]} allowDataOverflow tickFormatter={t => `${t}%`} />
                        <Tooltip formatter={formatTooltip} />
                        <Legend />
                        <Bar
                            isAnimationActive={false}
                            dataKey="withoutDerogations"
                            name="without derogations"
                            stackId="a"
                            fill="#92D050"
                            maxBarSize={40}
                        />
                        <Bar
                            isAnimationActive={false}
                            dataKey="withDerogations"
                            name="with derogations"
                            stackId="a"
                            fill="#FFCC00"
                            maxBarSize={40}
                        />
                        <Bar
                            isAnimationActive={false}
                            dataKey="partiallyCompliant"
                            name="partially compliant"
                            stackId="a"
                            fill="#FF4D00"
                            maxBarSize={40}
                        />
                        <Bar
                            isAnimationActive={false}
                            dataKey="nonCompliant"
                            name="non compliant"
                            stackId="a"
                            fill="#461156"
                            maxBarSize={40}
                        />
                    </BarChart>
                </ResponsiveContainer>
            )}
        </>
    );
};
