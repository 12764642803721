import { IResult, Percentages } from "../models/result";
import { IFormResultRow } from "../models/formResultRow";
import { getAuthorizationProperties } from "./authorizationHelper";
import { IResultsFilter, IResultsFilterData } from "../models/resultsFilter";
import { IFormResultDataDelivery } from "../models/formResultDataDelivery";
import { addFormToSessionStorage } from "../helpers/sessionGuidsHelper";
import { IValue } from "../models/value";

export async function getResult(id: string): Promise<IResult> {
    let res = await fetch(`/api/results/${id}`, {
        method: "GET",
        ...getAuthorizationProperties({
            "Content-Type": "application/json",
        }),
    });
    return res.json();
}

export async function getResultOfForm(formId: string): Promise<IResult> {
    let res = await fetch(`/api/results/form/${formId}`, {
        method: "GET",
        ...getAuthorizationProperties({
            "Content-Type": "application/json",
        }),
    });
    return res.json();
}

// Function returns results for non-exhaustive forms for public tabular view.
export async function getNonExhaustiveResults(
    formId: string,
    countryIds?: string[]
): Promise<IResult[]> {
    const res = await fetch(
        `/api/results/non-exhaustive/${formId}?${countryIds
            ?.map(c => `countryGuid=${c}&`)
            .join("")}`,
        {
            method: "GET",
        }
    );

    return res.json();
}

export async function getFormOverviewResults(formId: string): Promise<IResult[]> {
    let res = await fetch(`/api/results/overview/form/${formId}`, {
        method: "GET",
        ...getAuthorizationProperties({
            "Content-Type": "application/json",
        }),
    });
    return res.json();
}

export async function updateResultValues(result: IResult): Promise<IResult> {
    let res = await fetch(`/api/results/${result.id}`, {
        method: "PATCH",
        ...getAuthorizationProperties({
            "Content-Type": "application/json",
        }),
        body: JSON.stringify(result),
    });
    return res.json();
}

export async function updateSingleValue(value: IValue, resultGuid: string): Promise<IValue> {
    const res = await fetch(`/api/results/singlequestion/${resultGuid}`, {
        method: "PATCH",
        ...getAuthorizationProperties({
            "Content-Type": "application/json",
        }),
        body: JSON.stringify(value),
    });

    return res.json();
}

export async function getFilteredResults(
    filterData: IResultsFilterData
): Promise<IFormResultRow[]> {
    let searchParams: URLSearchParams = new URLSearchParams();
    Object.entries(filterData).forEach(x => {
        // Do not append undefined values to the search params
        if (x[1]) {
            searchParams.append(x[0], x[1]);
        }
    });
    const response = await fetch("/api/Results/Filtered?" + searchParams.toString(), {
        method: "GET",
        ...getAuthorizationProperties({
            "Content-Type": "application/json",
        }),
    });
    return response.json();
}

export async function getFilters(category: string, block: string): Promise<IResultsFilter> {
    let params: URLSearchParams = new URLSearchParams();

    if (category) {
        params.append("category", category);
    }
    if (block) {
        params.append("block", block);
    }

    let res = await fetch(`/api/Results/Filters?${params.toString()}`, {
        method: "GET",
        ...getAuthorizationProperties({
            "Content-Type": "application/json",
        }),
    });

    return res.json();
}

export async function submitResultValues(result: IResult): Promise<IResult> {
    return fetch(`/api/results/submit/${result.id}`, {
        method: "PATCH",
        ...getAuthorizationProperties({
            "Content-Type": "application/json",
        }),
        body: JSON.stringify(result),
    }).then(res => {
        return res.json();
    });
}

export async function unsubmitResultValues(resultId: string): Promise<IResult> {
    let res = await fetch(`/api/results/unsubmit/${resultId}`, {
        method: "PATCH",
        ...getAuthorizationProperties({
            "Content-Type": "application/json",
        }),
    });
    return res.json();
}

export async function resetResult(id: string): Promise<IResult> {
    let res = await fetch(`/api/results/reset/${id}`, {
        method: "PATCH",
        ...getAuthorizationProperties({
            "Content-Type": "application/json",
        }),
    });
    return res.json();
}

export async function getDataDeliveryResults(formId: string): Promise<IFormResultDataDelivery> {
    const data = await fetch(`/api/results/datadelivery/${formId}`, {
        method: "GET",
        ...getAuthorizationProperties({ "Content-Type": "application/json" }),
    });

    let parsedData: IFormResultDataDelivery = await data.json();
    addFormToSessionStorage(formId, parsedData.formName);

    return parsedData;
}

export async function getSectionPercentages(id: string): Promise<Percentages> {
    let res = await fetch(`/api/results/percentage/section/${id}`, {
        method: "GET",
        ...getAuthorizationProperties(),
    });
    return res.json();
}

export async function getFormPercentages(id: string): Promise<Percentages> {
    let res = await fetch(`/api/results/percentage/form/${id}`, {
        method: "GET",
        ...getAuthorizationProperties(),
    });
    return res.json();
}

export async function downloadXls(formId: string, sectionIds: string[]) {
    let searchParams: URLSearchParams = new URLSearchParams();
    sectionIds.forEach(s => {
        searchParams.append("sectionId", s);
    });

    let response = await fetch(`/api/results/download/${formId}?${searchParams.toString()}`, {
        method: "GET",
        ...getAuthorizationProperties({ "Content-Type": "application/json" }),
    });

    await downloadXlsxBlob(response);
}

export async function downloadPdfFile(resultId: string) {
    let response = await fetch(`/api/results/download/pdf/${resultId}`, {
        method: "GET",
        ...getAuthorizationProperties({ "Content-Type": "application/json" }),
    });

    if (!response.ok) {
        Promise.reject();
    }

    await downloadPdfBlob(response);
}

export async function downloadByPercentages(formId: string) {
    let response = await fetch(`/api/results/download/percentages/${formId}`, {
        method: "GET",
        ...getAuthorizationProperties({ "Content-Type": "application/json" }),
    });

    await downloadXlsxBlob(response);
}

export async function downloadByCategories(formId: string) {
    let response = await fetch(`/api/results/download/categories/${formId}`, {
        method: "GET",
        ...getAuthorizationProperties({ "Content-Type": "application/json" }),
    });

    await downloadXlsxBlob(response);
}

async function downloadXlsxBlob(response: Response) {
    if (!response || !response.ok) {
        throw Error;
    }

    let contentDispostion = response.headers
        .get("content-disposition")
        ?.split(";")[1]
        ?.split("filename=")[1];

    let fileName: string = contentDispostion || "Form results.xlsx";
    fileName = fileName.replaceAll('"', "");

    if (!fileName.endsWith(".xlsx")) {
        fileName = "Form results.xlsx";
    }

    const blob = await response.blob();
    const url = window.URL.createObjectURL(
        new Blob([blob], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        })
    );
    const link = document.createElement("a");
    link.href = url;
    link.download = fileName;

    document.body.appendChild(link);
    link.click();
    link.parentNode!.removeChild(link);
}

async function downloadPdfBlob(response: Response) {
    if (!response || !response.ok) {
        throw Error;
    }

    let contentDispostion = response.headers
        .get("content-disposition")
        ?.split(";")[1]
        ?.split("filename=")[1];

    let fileName: string = contentDispostion || "Export results.xlsx";
    fileName = fileName.replaceAll('"', "");

    if (!fileName.endsWith(".pdf")) {
        fileName = "Export results.pdf";
    }

    const blob = await response.blob();
    const url = window.URL.createObjectURL(
        new Blob([blob], {
            type: "application/pdf",
        })
    );
    const link = document.createElement("a");
    link.href = url;
    link.download = fileName;

    document.body.appendChild(link);
    link.click();
    link.parentNode!.removeChild(link);
}
