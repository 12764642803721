import { CookieConsentPrompt } from "../../components/CookieConsentPrompt/CookieConsentPrompt";
import { Header } from "../Header/Header";

export const Layout = (props: any) => {
    return (
        <div id="layout">
            <Header />
            <main className="main">{props.children}</main>
            <CookieConsentPrompt />
        </div>
    );
};
