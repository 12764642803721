import { useEffect, useState } from "react";
import {
    Cell,
    Legend,
    Pie,
    PieChart,
    PieLabelRenderProps,
    ResponsiveContainer,
    Tooltip,
    TooltipFormatter,
} from "recharts";
import { getGradient } from "../../../../helpers/gradient";
import { ICountry } from "../../../../models/country";
import { GenerationCapacityData } from "../../Hierarchy/GraphDisplay";
import "./GenerationCapacityPie.css";

interface IProps {
    type: string;
    subtype: string;
    data: GenerationCapacityData;
    selectedCountries: ICountry[];
    allCountries: ICountry[];
}

// Consts for graph positioning
const OFFSET_PER_LINE = 12;
const STANDARD_RADIUS = 180;
const SMALL_RADIUS = 140;
const SMALL_RADIUS_COUNTRY_LIMIT = 12;
const TEXT_START_RADIUS_RATIO = 1.08;
const LINE_END_RADIUS_RATIO = 1.02;
const ANGLE_OF_LABEL_DOWN = 90;

function formatData(
    data: GenerationCapacityData,
    selectedCountries: ICountry[],
    type: string,
    subtype: string
): { country: string; value: number }[] {
    const chartData = data[type];
    if (chartData) {
        return selectedCountries
            .map(country => {
                const values = chartData[country.abbreviation];
                if (values) {
                    let value = 0;

                    if (subtype === "Compliant - Without any derogations") {
                        value = values[0];
                    } else if (subtype === "Compliant - With derogations") {
                        value = values[1];
                    } else if (
                        subtype === "Compliant - Modernized (partially or fully compliant)"
                    ) {
                        value = values[2];
                    } else if (subtype === "Total Compliant") {
                        value = values.slice(0, -1).reduce((a, b) => a + b, 0);
                    } else if (subtype === "Total Non-Compliant") {
                        value = values[3];
                    } else if (subtype === "Total") {
                        value = values.reduce((a, b) => a + b, 0);
                    }
                    return {
                        country: country.name,
                        value: value,
                    };
                } else {
                    return {
                        country: country.name,
                        value: 0,
                    };
                }
            })
            .filter(({ value }) => value > 0);
    } else {
        return [];
    }
}

export const GenerationCapacityPie = ({
    type,
    subtype,
    data,
    selectedCountries,
    allCountries,
}: IProps) => {
    const [colors, setColors] = useState<string[]>([]);
    const [chartData, setChartData] = useState<{ country: string; value: number }[]>([]);
    const [total, setTotal] = useState<number>();
    const [chartRadius, setChartRadius] = useState(STANDARD_RADIUS);

    useEffect(() => {
        setColors(getGradient(36));
    }, []);

    useEffect(() => {
        const newData = formatData(data, selectedCountries, type, subtype);
        let sorted = [...newData];
        sorted.sort((a, b) => a.value - b.value);

        setChartRadius(STANDARD_RADIUS);
        setChartData(sorted);
        setTotal(newData.map(d => d.value).reduce((a, b) => a + b, 0));
    }, [data, selectedCountries, type, subtype]);

    const cetCountryColor = (country: string) => {
        return colors[allCountries.findIndex(c => c.name === country)];
    };

    const formatTooltip: TooltipFormatter = value => {
        return (
            <>
                <p>{`${(Math.round((value as number) * 100) / 100).toFixed(2)} [MW]`}</p>
                {total && <p>{`${(Math.round((value as number) * 100) / total).toFixed(2)} %`}</p>}
            </>
        );
    };

    // Old custom label code. DON'T DELETE. If new approach does't work return to this.

    /*const renderCustomizedLabel = ({ x, y, name, textAnchor }: PieLabelRenderProps) => {
        let fill = cetCountryColor(name);
        return (
            <text x={x} y={y} textAnchor={textAnchor} fill={fill} dominantBaseline="central">
                {name}
            </text>
        );
    };*/

    const renderCustomizedLabel = ({
        cx,
        cy,
        midAngle,
        innerRadius,
        outerRadius,
        value,
        color,
        name,
        startAngle,
        endAngle,
        ...props
    }: PieLabelRenderProps) => {
        color = cetCountryColor(name);
        const RADIAN = Math.PI / 180;
        const diffAngle = endAngle! - startAngle!;
        const delta = (360 - diffAngle) / 15 - 1;
        const radius =
            (innerRadius as number) + ((outerRadius as number) - (innerRadius as number));
        const x = (cx as number) + (radius + delta) * Math.cos(-midAngle! * RADIAN);
        let y = 0;

        if (midAngle! < 90) {
            y = (cy as number) - props.index! * OFFSET_PER_LINE;
            if (y > (props.y as number)) y = props.y as number;
        } else {
            y = (cy as number) - Math.sin(midAngle! * RADIAN) * TEXT_START_RADIUS_RATIO * radius;
        }

        if (props.index === SMALL_RADIUS_COUNTRY_LIMIT && endAngle! < 90) {
            // If we have too many chart slices in the first quadrant, reduce the size of pie chart to fit the labels.
            setChartRadius(SMALL_RADIUS);
        }

        return (
            <text
                x={x}
                y={y}
                fill={color}
                textAnchor={midAngle! < ANGLE_OF_LABEL_DOWN || midAngle! > 270 ? "start" : "end"}
                dominantBaseline="central"
                fontWeight="normal"
                fontSize={12}
            >
                {name}
            </text>
        );
    };

    const mapInterval = (
        srcStart: number,
        srcEnd: number,
        destStart: number,
        destEnd: number,
        point: number
    ) => {
        return destStart + ((destEnd - destStart) / (srcEnd - srcStart)) * (point - srcStart);
    };

    const renderCustomizedLabelLine = (props: any) => {
        let {
            cx,
            cy,
            midAngle,
            innerRadius,
            outerRadius,
            color,
            startAngle,
            endAngle,
            name,
            ...other
        } = props;
        color = cetCountryColor(name);
        const RADIAN = Math.PI / 180;
        const diffAngle = endAngle - startAngle;
        const radius = innerRadius + (outerRadius - innerRadius);

        let maxOffset = 0;
        let minOffset = 0;

        if (midAngle! < 90) {
            maxOffset = (cy as number) - props.index! * OFFSET_PER_LINE;
            minOffset =
                (cy as number) - Math.sin(midAngle! * RADIAN) * LINE_END_RADIUS_RATIO * radius;
            if (maxOffset > other.points[other.points.length - 1].y) {
                maxOffset = other.points[other.points.length - 1].y;
            }
        } else {
            maxOffset =
                (cy as number) - Math.sin(midAngle! * RADIAN) * LINE_END_RADIUS_RATIO * radius;
            minOffset = (cy as number) - Math.sin(midAngle! * RADIAN) * radius;
        }

        let path = "";
        for (let i = 0, max = (360 - diffAngle) / 15; i < max; i++) {
            path += `${cx + (radius + i) * Math.cos(-midAngle * RADIAN) * 0.98},${mapInterval(
                0,
                max,
                minOffset,
                maxOffset,
                i
            )} `;
        }
        return <polyline points={path} stroke={color} fill="none" />;
    };

    return (
        <>
            {data && (
                <ResponsiveContainer className="generation-capacity-pie">
                    <PieChart>
                        <Pie
                            dataKey="value"
                            nameKey="country"
                            isAnimationActive={false}
                            data={chartData}
                            label={renderCustomizedLabel}
                            labelLine={renderCustomizedLabelLine}
                            innerRadius={0}
                            outerRadius={chartRadius}
                            angle={45}
                        >
                            {chartData.map((entry, index) => (
                                <Cell key={`cell-${index}`} fill={cetCountryColor(entry.country)} />
                            ))}
                        </Pie>
                        <Tooltip formatter={formatTooltip} />
                        <Legend layout="vertical" verticalAlign="middle" align="right" />
                    </PieChart>
                </ResponsiveContainer>
            )}
        </>
    );
};
