import React, { useState } from "react";
import { Button, Input } from "semantic-ui-react";
import { RedirectIfLoggedIn } from "../Login/RedirectIfLoggedIn";
import { forgotPassword } from "../../services/authenticationService";
import "../Login/Login.css";

export const ForgotPassword = () => {
    const [username, setUsername] = useState("");
    const [success, setSuccess] = useState(false);
    const [sendingRequest, setSendingRequest] = useState(false);
    const disabled = username.length === 0 || sendingRequest;

    const handleSendForgotPassword = async () => {
        setSendingRequest(true);
        const response = await forgotPassword(username);
        if (response.ok) setSuccess(true);
        setSendingRequest(false);
    };

    return (
        <div className="login-container">
            <RedirectIfLoggedIn />
            <div className="center-login-container">
                <section className="login-form-container">
                    {success ? (
                        <p>
                            We have sent you an email with instructions how to reset your password.
                        </p>
                    ) : (
                        <React.Fragment>
                            <h2 id="centered-text">Forgot password</h2>
                            <p>
                                Enter your email address and we'll send you an email with
                                instructions how to reset your password.
                            </p>
                            <Input
                                id="username"
                                value={username}
                                onChange={(e: any) => setUsername(e.target.value)}
                            />
                            <hr />
                            <Button
                                fluid
                                primary
                                loading={sendingRequest}
                                disabled={disabled}
                                content="Send password reset email"
                                onClick={handleSendForgotPassword}
                            />
                        </React.Fragment>
                    )}
                </section>
            </div>
        </div>
    );
};
