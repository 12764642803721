import { useSelector } from "react-redux";
import { Redirect } from "react-router-dom";
import { AppState } from "../../store/configureStore";

export const RedirectIfLoggedIn = () => {
    const user = useSelector((state: AppState) => state.user);
    const login = useSelector((state: AppState) => state.login);

    if (user.token && login.loggedIn) return <Redirect to={{ pathname: "/" }} />;
    else return null;
};
