import React, { memo, useEffect, useState } from "react";
import { ComposableMap, Geographies, Geography } from "react-simple-maps";
import { Percentages } from "../../models/result";
import { getFormPercentages, getSectionPercentages } from "../../services/resultsService";
import geoJson from "./geoJson.json";

// @ts-ignore
import Gradient from "javascript-color-gradient";
import ReactTooltip from "react-tooltip";
import { ICountryResults, IFormResultDataDelivery } from "../../models/formResultDataDelivery";

const getPercentageData = async (
    categoryData: { name: string; value: string }[],
    formId: string,
    activeSection?: string
) => {
    let percentages: Percentages;
    let newData = [...categoryData];

    if (activeSection) {
        percentages = await getSectionPercentages(activeSection);
    } else {
        percentages = await getFormPercentages(formId);
    }

    newData.forEach(cd => {
        if (cd.value !== "Submitted" && percentages[cd.name]) {
            cd.value = Math.round(percentages[cd.name]).toString();
        }
    });

    return newData;
};

const getCategoryData = (data: IFormResultDataDelivery, activeSection?: string) => {
    if (data.sectionResults.length === 0) {
        return [];
    }

    let targetData: ICountryResults[];

    if (activeSection) {
        let activeSectionResults = data.sectionResults.find(sr => sr.sectionId === activeSection);
        targetData = activeSectionResults?.countryResults || [];
    } else {
        targetData = data.formResults;
    }

    return targetData.map(cr => {
        return {
            name: cr.countryName,
            value: cr.resultStatus,
        };
    });
};

interface IMapProps {
    dataDeliveryResults: IFormResultDataDelivery;
    activeSection?: string;
    isPercentage: boolean;
}

const MapChart = ({ dataDeliveryResults, activeSection, isPercentage }: IMapProps) => {
    const [data, setData] = useState<{ name: string; value: string }[]>([]);
    const [colors, setColors] = useState<{ [id: string]: string }>({});
    const [tooltipCountry, setTooltipCountry] = useState("");
    const [tooltipInfo, setTooltipInfo] = useState("");

    useEffect(() => {
        async function getNewData() {
            let newData = getCategoryData(dataDeliveryResults, activeSection);
            if (isPercentage && dataDeliveryResults) {
                newData = await getPercentageData(
                    newData,
                    dataDeliveryResults.formId,
                    activeSection
                );
            }
            setData(newData);
        }
        getNewData();
    }, [activeSection, isPercentage, dataDeliveryResults]);

    useEffect(() => {
        const colorGradient = new Gradient();
        colorGradient.setGradient("#0d4da1", "#ffd500");
        colorGradient.setMidpoint(101);
        let colorArray = colorGradient.getArray();
        let colorsMap: { [id: string]: string } = {};
        colorsMap["New"] = "#0D4DA1";
        colorsMap["Partial"] = "#FFD500";
        colorsMap["Submitted"] = "#00A990";
        colorsMap["default"] = "#e9e9ea";

        colorArray.forEach((color: string, i: number) => {
            colorsMap[i.toString()] = color;
        });
        setColors(colorsMap);
    }, []);

    const setTooltipContent = (d?: { name: string; value: string }) => {
        if (!d) return;
        setTooltipCountry(d.name);
        if (isPercentage) {
            switch (d.value.toLowerCase()) {
                case "new":
                    setTooltipInfo("0%");
                    break;
                case "submitted":
                    setTooltipInfo("Submitted");
                    break;
                default:
                    setTooltipInfo(`${d.value}%`);
            }
        } else {
            switch (d.value.toLowerCase()) {
                case "submitted":
                    setTooltipInfo("Submitted");
                    break;
                case "partial":
                    setTooltipInfo("Partially filled");
                    break;
                case "new":
                    setTooltipInfo("No data delivered");
            }
        }
    };

    const clearTooltipContent = () => {
        setTooltipCountry("");
        setTooltipInfo("");
    };

    return (
        <>
            <ComposableMap
                data-tip=""
                projection="geoMercator"
                projectionConfig={{
                    rotate: [-20, -53.0, 5],
                    scale: 900,
                }}
                zoomAndPan=""
                className="map-chart"
            >
                <Geographies geography={geoJson}>
                    {({ geographies }) => {
                        const map = geographies.map((geo, i) => {
                            const d = data.find(x => x.name === geo.properties.NAME);

                            if (geo.properties.ALLOW_SKIP && !d) {
                                return <React.Fragment key={i}></React.Fragment>;
                            }

                            return (
                                <Geography
                                    key={geo.rsmKey}
                                    geography={geo}
                                    fill={d?.value ? colors[d.value] : colors["default"]}
                                    stroke="#a4a9b2"
                                    onMouseEnter={() => {
                                        setTooltipContent(d);
                                    }}
                                    onMouseLeave={() => {
                                        clearTooltipContent();
                                    }}
                                    style={{
                                        default: { outline: "none" },
                                        hover: { outline: "none" },
                                        pressed: { outline: "none" },
                                    }}
                                />
                            );
                        });
                        return map;
                    }}
                </Geographies>
            </ComposableMap>
            <ReactTooltip type={"light"}>
                {tooltipCountry && (
                    <>
                        <b>{tooltipCountry}</b>
                        <br />
                        {tooltipInfo}
                    </>
                )}
            </ReactTooltip>
        </>
    );
};

export default memo(MapChart);
