import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { Checkbox, Form, Select } from "semantic-ui-react";
import { IChapter } from "../../models/chapter";

export interface IEditModalContentProps {
    newChapter: Partial<IChapter>;
    setNewChapter: Dispatch<SetStateAction<Partial<IChapter>>>;
    chapters: IChapter[];
    editMode?: boolean;
}

export const ChapterEditModalContent = ({
    newChapter,
    setNewChapter,
    chapters,
    editMode,
}: IEditModalContentProps) => {
    const [numberedDisabled, setNumberedDisabled] = useState(false);
    // Helper function to check whether or not numbered toggle for new chapter should be disabled.
    // Unnumbered chapter cannot have numbered child chapters.
    useEffect(() => {
        let parentChapter = chapters.filter(c => c.id === newChapter.parentChapterId)?.[0];
        let disabled = parentChapter != null && !parentChapter.numbered;

        setNumberedDisabled(disabled);
        if (!newChapter.id) {
            setNewChapter(prevChapter => ({ ...prevChapter, numbered: !disabled }));
        }
    }, [setNewChapter, chapters, newChapter.id, newChapter.parentChapterId]);

    return (
        <Form>
            <Form.Field>
                <label>Title</label>
                <input
                    type="text"
                    placeholder="Enter chapter title here"
                    value={newChapter.title || ""}
                    onChange={e => setNewChapter({ ...newChapter, title: e.target.value })}
                />
            </Form.Field>
            <Form.Field disabled={editMode}>
                <label>Parent chapter</label>
                <Select
                    clearable
                    placeholder="Select parent chapter"
                    value={newChapter.parentChapterId}
                    options={chapters.map(chapter => {
                        let prefix =
                            chapter.sectionNumber != null ? chapter.sectionNumber + " " : "";

                        return {
                            key: chapter.id,
                            value: chapter.id,
                            text: prefix + chapter.title,
                        };
                    })}
                    onChange={(e, data) => {
                        setNewChapter({
                            ...newChapter,
                            parentChapterId: data.value === "" ? undefined : (data.value as string),
                        });
                    }}
                />
            </Form.Field>
            <Form.Field disabled={numberedDisabled}>
                <label>Numbered</label>
                <Checkbox
                    toggle
                    checked={newChapter.numbered}
                    onChange={(e, data) => setNewChapter({ ...newChapter, numbered: data.checked })}
                />
            </Form.Field>
        </Form>
    );
};
